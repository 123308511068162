import { ParsedUrlQuery } from "querystring";
import { useCallback, useEffect, useRef } from "react";
import { useRouter as useNextRouter } from "next/router";
import { toast } from "react-toastify";
import { ErrorService } from "@shared/utils/Sentry";

export const FALLBACK_URL_KEY_NAME = "fallbackUrl";

// == Hook =================================================================

export function useRouter<TQuery extends ParsedUrlQuery>({ shouldDisableScroll = false } = {}) {
  const nextRouter = useNextRouter();

  const previousPath = useRef<string | null>(null);

  useEffect(() => {
    if (!shouldDisableScroll) return;
    window.history.scrollRestoration = "manual";
    nextRouter.beforePopState((state) => {
      state.options.scroll = false;
      return true;
    });
  }, [nextRouter, shouldDisableScroll]);

  useEffect(() => {
    previousPath.current = nextRouter.asPath;
  }, [nextRouter.asPath]);

  const safeBack = useCallback(() => {
    // TODO: rewrite safe back - currently it's not working as required
    // const fallbackUrl = localStorage.getItem(FALLBACK_URL_KEY_NAME);

    // if (!fallbackUrl) {
    //   nextRouter.back();
    //   return;
    // }

    // if (!previousPath.current || previousPath.current === nextRouter.asPath) {
    //   void nextRouter.replace(fallbackUrl);
    //   return;
    // }

    nextRouter.back();
  }, [nextRouter]);

  const getQuery = useCallback(
    (key: string) => {
      const query = nextRouter.query?.[key];
      return Array.isArray(query) ? query?.[0] : (query as string);
    },
    [nextRouter.query]
  );

  return {
    ...nextRouter,
    query: nextRouter.query as TQuery,
    push: async (...params: Parameters<typeof nextRouter["push"]>): Promise<boolean> => {
      try {
        return await nextRouter.push(...params);
      } catch (error) {
        toast.error("There was an error, please try again.");
        ErrorService.error(error);
        return false;
      }
    },
    previousPath,
    getQuery,

    /**
     * Goes back only in its domain, relying on FALLBACK_URL_KEY_NAME value
     * as a fallback url if there are no history records.
     * DO NOT USE: for jumping between different domains; after a page reload.
     */
    safeBack,
  };
}
