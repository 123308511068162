import qs from "querystring";
import { useCallback, useEffect, useState } from "react";
import { authenticate, IAuthResp } from "@core-shop/services";
import { CLOUD_TOKEN_PARAM_NAME } from "@core-shop/utils";
import { BASE_CLOUD_URL } from "@shared/config";
import { useRouter } from "@shared/hooks";
import { routes } from "@shared/utils";
import { deleteToken, getToken, setToken } from "./tokenStorage";

interface IDeleteTokenFromUrl {
  pathname: string;
  query: Record<string, string>;
}

function deleteTokenFromUrl({ pathname = "", query }: IDeleteTokenFromUrl) {
  const params = { ...query };
  delete params[CLOUD_TOKEN_PARAM_NAME];
  delete params.partner;
  const updatedParams = Object.keys(params).length ? `?${qs.stringify(params)}` : "";
  return `${pathname}${updatedParams}`;
}

export function useAuthenticate({ isDisabled = false } = {}) {
  const router = useRouter();
  const { asPath } = router;
  const tokenParam = router.getQuery(CLOUD_TOKEN_PARAM_NAME);
  const [state, setState] = useState<IAuthResp>();

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isAuthCheckInProgress, setIsAuthCheckInProgress] = useState(true);

  const handleAuthResult = useCallback(
    (isAuthSuccessfull: boolean, authResponse?: IAuthResp) => {
      setIsAuthorized(isAuthSuccessfull);
      setIsAuthCheckInProgress(false);

      if (!isAuthSuccessfull || !authResponse) {
        void router.push(routes.shopcloud.error("Invalid token error"));
        return;
      }

      const url = new URL(`${BASE_CLOUD_URL}${asPath}`);

      const newUrl = deleteTokenFromUrl({
        pathname: url.pathname,
        query: router.query as Record<string, string>,
      });

      void router.replace(newUrl);
    },
    [asPath, router]
  );

  const handleToken = useCallback(async () => {
    const token = tokenParam || getToken();
    if (!token) {
      void router.push(routes.shopcloud.error("Invalid token error"));
      return false;
    }

    const authResponse = await authenticate({ token });
    setState(authResponse);

    if (authResponse.isValid) {
      setToken(token);
      handleAuthResult(true, authResponse);
      return true;
    }

    deleteToken();
    handleAuthResult(false);
    setIsAuthCheckInProgress(false);
    return false;
  }, [handleAuthResult, router, tokenParam]);

  useEffect(() => {
    if (isDisabled) {
      setIsAuthCheckInProgress(false);
      return;
    }
    if (!isAuthorized && !isAuthCheckInProgress) return;
    if (!router.isReady || isAuthorized) return;
    void handleToken();
  }, [handleToken, isAuthCheckInProgress, isAuthorized, isDisabled, router.isReady]);

  return { isAuthCheckInProgress, isAuthorized, state, refreshAuth: handleToken };
}
