import { getAuthHeaderValue } from "@core-shop/services/api";
import { request } from "@core-shop/services/api/request";
import { CloudData, CloudViewer, OptInMutation, OptInMutationVariables } from "@core-shop/types";
import { CLOUD_AUTH_HEADER_NAME } from "@core-shop/utils";
import { getCloudData } from "./api/getCloudData";
import { getCloudViewerQuery } from "./api/getCloudViewer";
import { optIn } from "./api/optIn";

export function getCloudDataQuery({ token = "" } = {}) {
  return request<CloudData>(
    getCloudData,
    undefined,
    !token
      ? undefined
      : {
          headers: {
            [CLOUD_AUTH_HEADER_NAME]: getAuthHeaderValue(token),
          },
        }
  );
}

export function getCloudViewer() {
  return request<CloudViewer>(getCloudViewerQuery);
}

export function optInMutation(vars: OptInMutationVariables) {
  return request<OptInMutation, OptInMutationVariables>(optIn, vars);
}
