import React from "react";
import { useCloudIntercom } from "@hooks/useCloudIntercom";

interface IProps {
  children: React.ReactNode;
}

export const AnalyticsProvider: React.FC<IProps> = ({ children }) => {
  useCloudIntercom();

  return <>{children}</>;
};
