import React, { useMemo } from "react";
import {
  useCloudAuthState,
  useDefaultStorefront,
  useStorefront,
  useToggleFavoriteBrand,
} from "@core-shop/hooks";
import { getPaginatedNodes } from "@core-shop/utils";
import { IStorefrontViewProps } from "./IStorefrontViewProps";

export enum StorefrontIdAliases {
  teambuy = "teambuy",
}

export interface IProps {
  View: React.FC<IStorefrontViewProps>;
  id?: StorefrontIdAliases | string;
}

const ID_ALIASES_VALUES: Record<StorefrontIdAliases, string> = {
  [StorefrontIdAliases.teambuy]: "1235",
};

export const Storefront: React.FC<IProps> = ({ View, id: rawId }) => {
  const id = useMemo(() => {
    const aliases = Object.keys(ID_ALIASES_VALUES);
    if (rawId && aliases.includes(rawId)) return ID_ALIASES_VALUES[rawId as StorefrontIdAliases];
    return rawId;
  }, [rawId]);

  const defaultStorefrontQuery = useDefaultStorefront({ enabled: !id });
  const storefrontQuery = useStorefront({ id: id ?? "", enabled: !!id });

  const collections: IStorefrontViewProps["collections"] | undefined = useMemo(() => {
    const currentCollections = id
      ? storefrontQuery.data?.StoreFront?.collections
      : defaultStorefrontQuery.data?.DefaultStoreFront?.collections;

    const formattedCollections = currentCollections?.map((collection) => ({
      ...collection,
      brands: getPaginatedNodes(collection.brands.edges),
    }));

    return formattedCollections as IStorefrontViewProps["collections"];
  }, [
    defaultStorefrontQuery.data?.DefaultStoreFront?.collections,
    id,
    storefrontQuery.data?.StoreFront?.collections,
  ]);

  const title = useMemo(
    () =>
      id ? storefrontQuery.data?.StoreFront?.name : defaultStorefrontQuery.data?.DefaultStoreFront?.name,
    [defaultStorefrontQuery.data?.DefaultStoreFront?.name, id, storefrontQuery.data?.StoreFront?.name]
  );

  const toggleFavoriteMutation = useToggleFavoriteBrand();
  const { fgStatus, isLoading } = useCloudAuthState();

  return (
    <View
      collections={collections ?? []}
      shouldShowLikeButton={!isLoading && fgStatus.hasUser}
      storefrontQuery={id ? storefrontQuery : defaultStorefrontQuery}
      title={title}
      toggleFavoriteMutation={toggleFavoriteMutation}
    />
  );
};
