import {
  GiftCardListQuery_Viewer_giftcardsPaginated_edges_node,
  GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_accessLogs_edges_node,
} from "@core-shop/types";
import { getPaginatedNodes } from "@core-shop/utils";

type TLog = GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_accessLogs_edges_node;

export interface IFormattedGiftcard {
  balance?: string;
  brandName?: string;
  datePurchaised?: string;
  dateUpdated?: string;
  history?: TLog[];
  id?: string;
  image?: string;
  isArchived?: boolean;
  isReloadable?: boolean;
  name?: string;
}

export function formatGiftcard(
  node: GiftCardListQuery_Viewer_giftcardsPaginated_edges_node
): IFormattedGiftcard {
  if (!node) return {};

  let name = "";
  if (!node.isReloadable) name = node.value.formatted;
  name = `${name} ${node.link.name ?? ""}`;

  const history = getPaginatedNodes(node.accessLogs?.edges);

  return {
    id: node.id,
    history,
    name,
    balance: node.balance?.formatted ?? "",
    image: node.link.images.image ?? "",
    datePurchaised: node.transaction?.createdAt,
    dateUpdated: node?.updatedAt?.split(" ")[0],
    isReloadable: node.isReloadable,
    brandName: node.link.defaultBrand.name,
  };
}
