import gql from "graphql-tag";

export const getNotificationsSettingsQuery = gql`
  query GetNotificationsSettings {
    ShopCloud {
      id
      viewer {
        id
        notifyUpdate
        unsubscribedAt
      }
    }
  }
`;
