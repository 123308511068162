export enum EntityKey {
  ACTIVITY = "ACTIVITY",
  AUTOCOMPLETE_SEARCH = "AUTOCOMPLETE_SEARCH",
  BRAND = "BRAND",
  BRAND_SEARCH = "BRAND_SEARCH",
  CAMPAIGN = "CAMPAIGN",
  CLOUD = "CLOUD",
  COLLECTION = "COLLECTION",
  INTENT = "INTENT",
  LABEL_PROMO_SEARCH = "LABEL_PROMO_SEARCH",
  LINK = "LINK",
  NEWS = "NEWS",
  ONBOARDING = "ONBOARDING",
  RECOMMENDED_SEARCHES = "RECOMMENDED_SEARCHES",
  SEARCH = "SEARCH",
  STOREFRONT = "STOREFRONT",
  VIEWER = "VIEWER",
  WALLET = "WALLET",
}
