import React, { forwardRef, useState, useEffect } from "react";
import { ExclamationCircleIcon, EyeIcon } from "@heroicons/react/solid";
import { TSvgReactComponent } from "@root/types/utils";
import classNames from "classnames";
import { IconPosition } from "@shared-tailwind/types";
import { Loader } from "../Loader/Loader";

// == Types ================================================================

type THTMLInputProps = React.HTMLProps<HTMLInputElement>;

export interface IInputProps {
  className?: string;
  containerClassName?: string;
  errorText?: string;
  Icon?: TSvgReactComponent;
  iconPosition?: IconPosition;
  id?: string;
  isDisabled?: boolean;
  isError?: boolean;
  isLoading?: boolean;
  label?: string;
  labelClassName?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onChangeValue?: (value: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  onClickIcon?: () => void;
  placeholder?: string;
  textLeft?: string;
  textRight?: string;
  value?: string;
  hint?: string;
}

// get all props from React.HTMLProps<HTMLInputElement>, but override with IInputProps if duplicates are present
type TInputProps = IInputProps & Pick<THTMLInputProps, Exclude<keyof THTMLInputProps, keyof IInputProps>>;

// == Constants ============================================================

const { left, right } = IconPosition;
const iconContainerRightClasses = "absolute inset-y-0 flex items-center right-0 pr-3";

// == Component ============================================================

export const Input = forwardRef<HTMLInputElement, TInputProps>(
  (
    {
      value,
      containerClassName,
      Icon,
      id,
      isError,
      label,
      placeholder,
      className,
      onChange,
      onChangeValue,
      onClickIcon,
      textLeft,
      textRight,
      errorText,
      isDisabled,
      iconPosition,
      isLoading,
      type,
      labelClassName,
      hint,
      ...inputProps
    },
    ref
  ) => {
    const hasError = errorText || isError;
    const hasIconRight = !hasError && Icon && iconPosition === right && !isLoading;
    const [innerType, setInnerType] = useState<string>();

    useEffect(() => {
      setInnerType(type);
    }, [type]);

    return (
      <div className={classNames("w-full", containerClassName)}>
        {label && (
          <label
            className={classNames(labelClassName || "block text-sm font-medium text-gray-700 mb-1")}
            htmlFor={id}
          >
            {label}
          </label>
        )}
        <div className="relative shadow-sm">
          {Icon && iconPosition !== right && (
            <div
              className={classNames("absolute inset-y-0 flex items-center pointer-events-none left-0 pl-3", {
                "cursor-pointer": onClickIcon,
              })}
              role="presentation"
              onClick={onClickIcon}
            >
              <Icon aria-hidden="true" className="h-6 w-6 text-gray-400" />
            </div>
          )}
          {textLeft && (
            <span
              className={classNames(
                "absolute inset-y-0 flex items-center pointer-events-none left-0 pl-3",
                errorText || isError ? "text-red-400" : "text-gray-400"
              )}
            >
              {textLeft}
            </span>
          )}
          <input
            className={classNames(
              "block w-full outline-none font-light placeholder-thin text-gray-900",
              "border border-gray-300 rounded-md py-1.5 px-4",
              Icon && iconPosition === left && "pl-10",
              (isLoading || hasError || (Icon && iconPosition === left)) && "pr-10",
              errorText || isError
                ? "border-red-300 focus:ring-red-500 focus:border-red-500 text-red-900 placeholder-red-300"
                : "focus:ring-primary-500 focus:border-primary-500",
              textLeft && "pl-7",
              textRight && "pr-12",
              isDisabled && "opacity-50",
              !onChange && !onChangeValue && !ref && "caret-transparent",
              className
            )}
            disabled={isDisabled}
            id={id}
            placeholder={placeholder}
            ref={ref}
            value={value ?? undefined}
            onChange={(e) => {
              onChange?.(e);
              onChangeValue?.(e.target.value, e);
            }}
            {...inputProps}
            type={innerType}
          />

          {!hasError && textRight && (
            <span className={classNames(iconContainerRightClasses, "text-gray-400")}>{textRight}</span>
          )}
          {hasError && (
            <div className={iconContainerRightClasses}>
              <ExclamationCircleIcon aria-hidden="true" className="h-6 w-6 text-red-500" />
            </div>
          )}
          {hasIconRight && (
            <div className={iconContainerRightClasses} role="presentation" onClick={onClickIcon}>
              <Icon aria-hidden="true" className="h-6 w-6 text-gray-400" />
            </div>
          )}
          {!isLoading && !hasIconRight && !hasError && type === "password" && (
            <div className={iconContainerRightClasses}>
              <EyeIcon
                aria-hidden="true"
                className="h-6 w-6 text-gray-400 cursor-pointer"
                onClick={() => setInnerType((current) => (current === "password" ? "text" : "password"))}
              />
            </div>
          )}
          {isLoading && !hasError && (
            <div className={iconContainerRightClasses}>
              <Loader size={20} />
            </div>
          )}
        </div>
        {hint && <p className="text-sm text-gray-500 italic">{hint}</p>}
        {(isError || errorText) && <p className="text-sm text-red-500 absolute">{errorText}</p>}
      </div>
    );
  }
);

Input.defaultProps = {
  iconPosition: left,
};

// == Styles ===============================================================
