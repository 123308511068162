import React, { useContext } from "react";

interface IContextValue {
  isAuthCheckInProgress: boolean;
  isAuthorized: boolean;
  refreshAuth: () => Promise<boolean>;
  shouldOptIn: boolean;
}

export const defaultContext: IContextValue = {
  isAuthorized: false,
  isAuthCheckInProgress: true,
  shouldOptIn: false,
  refreshAuth: () => new Promise(() => false),
};

export const AuthContext = React.createContext(defaultContext);

export const useAuthContext = () => useContext(AuthContext);
