import invariant from "ts-invariant";

// == Types ================================================================

export enum Environment {
  development = "development",
  production = "production",
  staging = "staging",
}

// == Constants ============================================================

// == Functions ============================================================

export function missingENVError(envVariable: string): string {
  throw new Error(`API_ENVIRONMENT is ${API_ENVIRONMENT} or ${envVariable} is blank.`);
}

// == Exports ==============================================================

export const PRODUCTION_ENVIRONMENT = Environment.production;
export const STAGING_ENVIRONMENT = Environment.staging;
export const DEVELOPMENT_ENVIRONMENT = Environment.development;

export const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION;

export enum AppName {
  APP = "APP",
  AUTH = "AUTH",
  BANK = "BANK",
  BUDGET = "BUDGET",
  CLOUD = "CLOUD",
  FUNDING = "FUNDING",
  MARKETING = "MARKETING",
  MAX = "MAX",
}

export const APP_NAME = process.env.NEXT_PUBLIC_APP_NAME as AppName;
export const IS_BUDGET_APP = process.env.NEXT_PUBLIC_APP_NAME === AppName.BUDGET;
export const IS_FUNDING_APP = process.env.NEXT_PUBLIC_APP_NAME === AppName.FUNDING;
export const IS_APP_APP = process.env.NEXT_PUBLIC_APP_NAME === AppName.APP;
export const IS_AUTH_APP = process.env.NEXT_PUBLIC_APP_NAME === AppName.AUTH;
export const IS_CLOUD = process.env.NEXT_PUBLIC_APP_NAME === AppName.CLOUD;
export const IS_MARKETING_APP = process.env.NEXT_PUBLIC_APP_NAME === AppName.MARKETING;

export const IS_SERVER = !!(process.env.NEXT_PUBLIC_IS_SERVER || typeof window === "undefined");
export const IS_CLIENT = !IS_SERVER;

export const DEPLOYMENT_ENVIRONMENT = process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT as Environment;

export const API_ENVIRONMENT = (process.env.NEXT_PUBLIC_API_ENVIRONMENT ??
  DEPLOYMENT_ENVIRONMENT) as Environment;

export const IS_PRODUCTION_API = API_ENVIRONMENT === PRODUCTION_ENVIRONMENT;
export const IS_STAGING_API = API_ENVIRONMENT === STAGING_ENVIRONMENT;
export const IS_DEVELOPMENT_API = API_ENVIRONMENT === DEVELOPMENT_ENVIRONMENT;

export const IS_PRODUCTION = DEPLOYMENT_ENVIRONMENT === PRODUCTION_ENVIRONMENT;
export const IS_STAGING = DEPLOYMENT_ENVIRONMENT === STAGING_ENVIRONMENT;
export const IS_DEVELOPMENT = DEPLOYMENT_ENVIRONMENT === DEVELOPMENT_ENVIRONMENT;

export const TEST_ENV = process.env.IS_TEST;

export const IS_FLIPGIVE_DOMAIN = (host?: string) => {
  const regex = /(?:^\.?|^.+\.)(almostflip|flipgive)\.(com|test)$/;
  if (IS_CLIENT) return regex.test(window.location.host);
  if (!host) return false;
  invariant(host, "IS_FLIPGIVE_DOMAIN was not provided host header on server");

  return regex.test(host);
};

export const INTERCOM_APP_ID = process.env.INTERCOM_APP_ID as string;

// Main URL
export const BASE_MAIN_URL = process.env.BASE_MAIN_URL as string;

// API URL
export const BASE_API_URL = process.env.BASE_API_URL as string;

export const GRAPHQL_API_URL = `${BASE_API_URL}/graphql`;

// images url
// when an image is uploaded, if it's local, url needs base string

const SHOULD_USE_LOCAL_ENV = process.env.ACCESS_TOKEN && IS_DEVELOPMENT ? Environment.development : undefined;

export const BASE_IMAGE =
  {
    [PRODUCTION_ENVIRONMENT]: "",
    [STAGING_ENVIRONMENT]: "",
    [DEVELOPMENT_ENVIRONMENT]: BASE_API_URL,
  }[SHOULD_USE_LOCAL_ENV || API_ENVIRONMENT] || "";

// ADMIN URL
export const BASE_ADMIN_URL = process.env.BASE_ADMIN_URL as string;

// Budget App URL
export const BASE_BUDGET_URL = process.env.BASE_BUDGET_URL as string;

// Funding App URL
export const BASE_FUNDING_URL = process.env.BASE_FUNDING_URL as string;

// Auth App URL
export const BASE_AUTH_URL = process.env.BASE_AUTH_URL as string;

// App App URL
export const BASE_APP_URL = process.env.BASE_APP_URL as string;

// Marketing App URL
export const BASE_MARKETING_URL = process.env.BASE_MARKETING_URL as string;

// Banking App URL
export const BASE_BANKING_URL = process.env.BASE_BANKING_URL as string;

// Cloud App URL
export const BASE_CLOUD_URL = process.env.BASE_CLOUD_URL as string;

// Cloud API URL
export const CLOUD_API_URL = process.env.CLOUD_API as string;

// IpInfo
export const IP_INFO_URL = process.env.IP_INFO_URL as string;
