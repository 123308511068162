import gql from "graphql-tag";
import { favoriteBrandFragment } from "./fragments/favoritesFragment";

export const reorderFavoritesMutation = gql`
  mutation ReorderFavoritesNewMutation($brandIds: [ID!]!) {
    ViewerReorderFavoriteBrands(brandIds: $brandIds) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Viewer {
        id
        favoriteBrands(first: 50) {
          id
          ...FavoriteBrandFragment
        }
      }
    }
  }
  ${favoriteBrandFragment}
`;
