import gql from "graphql-tag";

export const getProfileSettingsQuery = gql`
  query GetProfileSettings {
    ShopCloud {
      id
      viewer {
        id
        hidePurchaseActivity
        privacyLevel
      }
    }
  }
`;
