import { pluralize } from "@shared/utils";
import classNames from "classnames";

interface IProps {
  dealCount: number;
  label: string | null;
  secondaryLabel: string | null;
}

export const TopDeal: React.FC<IProps> = ({ secondaryLabel = "", label = "", dealCount = 0 }) => {
  return (
    <div
      className={classNames(
        "w-full text-center bg-deals-500",
        "text-gray-900 text-[11px] h-5 leading-5 uppercase"
      )}
    >
      <span>
        {/* eslint-disable-next-line react/no-danger */}
        {label} {secondaryLabel && <span dangerouslySetInnerHTML={{ __html: secondaryLabel }} />}
        {!!dealCount && ` + ${pluralize("other deal", dealCount)}`}
      </span>
    </div>
  );
};
