import React from "react";
import { SearchIcon } from "@heroicons/react/solid";
import { TSvgReactComponent } from "@root/types/utils";
import classNames from "classnames";
import { Dropdown } from "@shared-tailwind/atoms";
import { IconPosition } from "@shared-tailwind/types";
import { Select, ISelectOption } from "../Select/Select";

// == Types ================================================================

interface IProps<TData> {
  options: ISelectOption<TData>[];
  value: string;
  ButtonIcon?: TSvgReactComponent;
  buttonTitle?: string;
  className?: string;
  CustomItem?: React.FC<ISelectOption<TData>>;
  DropdownComponent?: React.ReactNode;
  isClearable?: boolean;
  isLoading?: boolean;
  onChange?: (value: string) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  onSelect?: (val: string) => void;
  placeholder?: string;
}

// == Constants ============================================================

SearchInput.defaultProps = {
  value: "",
  placeholder: "Search",
  options: [],
};

// == Component ============================================================

export function SearchInput<TData = void>({
  value,
  onChange,
  buttonTitle,
  ButtonIcon,
  DropdownComponent,
  className,
  options,
  CustomItem,
  isLoading,
  placeholder,
  isClearable,
  onKeyDown,
  onSelect,
}: IProps<TData>) {
  return (
    <div className={classNames("flex rounded-md w-full", className)}>
      <Select<TData>
        className={classNames((buttonTitle || ButtonIcon) && "rounded-r-none")}
        CustomItem={CustomItem}
        inputOnChange={onChange}
        inputProps={{ Icon: SearchIcon, iconPosition: IconPosition.left, placeholder, onKeyDown }}
        inputValue={value}
        isClearable={isClearable}
        isLoading={isLoading}
        options={options}
        onChange={(val) => onSelect?.(val)}
      />
      {(buttonTitle || ButtonIcon) && (
        <Dropdown
          className="rounded-r-md border border-gray-300 relative -left-1 z-10"
          Component={DropdownComponent}
          Icon={ButtonIcon}
          title={buttonTitle ?? ""}
        />
      )}
    </div>
  );
}

// == Styles ===============================================================
