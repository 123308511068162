import { ReactNode, useEffect, useState } from "react";
import { useCloud } from "@core-shop/hooks";
import { defaultCloudStylesContextValue, CloudStylesContext } from "./CloudStylesContext";
import { createStylesStr } from "./createStylesStr";
import { TColors } from "./types";

interface IProps {
  children: ReactNode;
}

export const CloudStylesProvider: React.FC<IProps> = ({ children }) => {
  const [colors, setColors] = useState<TColors>(defaultCloudStylesContextValue);
  const cloudQuery = useCloud();

  useEffect(() => {
    if (cloudQuery.isLoading) return;

    const { ShopCloud } = cloudQuery.data ?? {};
    if (!ShopCloud) return;

    setColors({
      primary: ShopCloud?.primaryColor || defaultCloudStylesContextValue.primary,
      secondary: ShopCloud?.secondaryColor || defaultCloudStylesContextValue.secondary,
      deals: ShopCloud?.dealColor || defaultCloudStylesContextValue.deals,
    });
  }, [cloudQuery.data, cloudQuery.isLoading]);

  return (
    <CloudStylesContext.Provider value={colors}>
      <style>{createStylesStr(colors)}</style>
      {children}
    </CloudStylesContext.Provider>
  );
};
