import React from "react";
import { useCloudViewer, useProfileSettings, useUpdateProfileSettings } from "@core-shop/hooks";
import { IProfileSettingsViewProps } from "./IProfileSettingsViewProps";

interface IProps {
  View: React.FC<IProfileSettingsViewProps>;
}

export const ProfileSettings: React.FC<IProps> = ({ View }) => {
  const cloudViewerQuery = useCloudViewer();
  const email = cloudViewerQuery.data?.ShopCloud?.viewer?.email;
  const country = cloudViewerQuery.data?.ShopCloud?.viewer?.country;

  const profileSettingsQuery = useProfileSettings();
  const hidePurchaseActivity = profileSettingsQuery.data?.ShopCloud?.viewer?.hidePurchaseActivity;
  const privacyLevel = profileSettingsQuery.data?.ShopCloud?.viewer?.privacyLevel;

  const updateProfileSettingsMutation = useUpdateProfileSettings();

  return (
    <View
      cloudViewerQuery={cloudViewerQuery}
      country={country}
      email={email}
      hidePurchaseActivity={hidePurchaseActivity}
      privacyLevel={privacyLevel}
      profileSettingsQuery={profileSettingsQuery}
      updateProfileSettingsMutation={updateProfileSettingsMutation}
    />
  );
};
