import React, { useMemo } from "react";
import { Label } from "@shared-tailwind/atoms";
import { Select } from "@shared-tailwind/molecules";

interface IProps {
  maxCards: number;
  minCards: number;
  onChange: (value: number) => void;
  quantity: number;
}

export const Quantity: React.FC<IProps> = ({ maxCards, minCards, quantity, onChange }) => {
  const quantityOptions = useMemo(
    () =>
      Array(maxCards)
        .fill("")
        .map((_val, i) => ({ value: `${i + 1}`, label: `${i + 1}` }))
        .slice(minCards - 1),
    [maxCards, minCards]
  );

  return (
    <div className="mb-3">
      <Label>
        Quantity <span className="text-gray-500 ml-2">(minimum {minCards})</span>
      </Label>
      <Select
        hasMinDropdown
        hasReadonlyInput
        shouldOpenToTop
        withArrow
        className="w-full"
        inputValue={quantity.toString() ?? "0"}
        options={quantityOptions}
        onChange={(value) => onChange(+value)}
      />
    </div>
  );
};
