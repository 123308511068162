import { StorefrontIdAliases } from "@core-shop/components";
import {
  BASE_ADMIN_URL,
  BASE_BUDGET_URL,
  BASE_MAIN_URL,
  BASE_APP_URL,
  BASE_AUTH_URL,
  BASE_FUNDING_URL,
  BASE_BANKING_URL,
  IS_FUNDING_APP,
  IS_APP_APP,
  IS_BUDGET_APP,
  IS_PRODUCTION,
  BASE_CLOUD_URL,
} from "@shared/config/environment";
import { TValueOf } from "@shared/types";
import { siteCookie } from "@shared/utils/siteCookie";
import { addQueryParam, addQueryParams } from "./addQueryParam";

// == Types ================================================================

// == Constants ============================================================

// == Functions ============================================================

const BASE_SITE_URL = () => siteCookie.readClient()?.base_url ?? BASE_MAIN_URL;

// == Exports ==============================================================

export const budget = {
  root: () => (IS_BUDGET_APP ? "/" : BASE_BUDGET_URL),
  mobile: () => (IS_BUDGET_APP ? "/mobile" : `${BASE_BUDGET_URL}/mobile`),
  playerReport: () => (IS_BUDGET_APP ? "/player-report" : `${BASE_BUDGET_URL}/player-report`),
  bankAccount: () => (IS_BUDGET_APP ? "/settings/bank-account" : `${BASE_BUDGET_URL}/settings/bank-account`),
  expenses: () => (IS_BUDGET_APP ? "/plan/expenses" : `${BASE_BUDGET_URL}/expenses`),
  income: () => (IS_BUDGET_APP ? "/plan/income" : `${BASE_BUDGET_URL}/income`),
  expensesTemplate: (id: string) =>
    IS_BUDGET_APP ? `/plan/teams/${id}/expenses` : `${BASE_BUDGET_URL}/teams/${id}/expenses`,
  incomeTemplate: (id: string) =>
    IS_BUDGET_APP ? `/plan/teams/${id}/income` : `${BASE_BUDGET_URL}/teams/${id}/income`,
  playerFees: () => (IS_BUDGET_APP ? "/player-fees" : `${BASE_BUDGET_URL}/player-fees`),
  activities: () => (IS_BUDGET_APP ? "/transactions" : `${BASE_BUDGET_URL}/transactions`),
  externalBankApp: {
    landing: { href: `${BASE_BANKING_URL}` },
  },
  noTeam: () => (IS_BUDGET_APP ? "/no-team" : `${BASE_BUDGET_URL}/no-team`),
  noBudget: () => (IS_BUDGET_APP ? "/no-budget" : `${BASE_BUDGET_URL}/no-budget`),
};

export const app = {
  home: {
    href: BASE_APP_URL,
    pathname: "/",
  },
  notifications: {
    href: `${BASE_APP_URL}/user/notifications`,
    pathname: "/user/notifications",
  },
  userTeams: {
    href: `${BASE_APP_URL}/my-team`,
    pathname: "/my-team",
  },
  checkout: {
    index: (intentToken: string) => `${BASE_APP_URL}/checkout/${intentToken}`,
    status: (intentToken: string) =>
      `${BASE_APP_URL}/checkout/${intentToken}/status${document.location.search}`,
  },
  teams: {
    new: {
      href: `${BASE_APP_URL}/teams/new`,
      pathname: "/teams/new",
    },
    join: {
      href: `${BASE_APP_URL}/teams/join`,
      pathname: "/teams/join",
    },
    teamRoster: {
      index: () => `${IS_APP_APP ? "" : BASE_APP_URL}/teams/team-roster`,
      edit: () => `${IS_APP_APP ? "" : BASE_APP_URL}/teams/team-roster/edit`,
    },
    invite: () => `${IS_APP_APP ? "" : BASE_APP_URL}/teams/invite`,
  },
  teamSettings: {
    href: () => `${BASE_APP_URL}/teams/more/edit`,
    pathname: () => `/teams/more/edit`,
  },
  userSettings: {
    href: `${BASE_APP_URL}/user/edit`,
    pathname: "/user/edit",
  },
  selectApp: {
    href: `${BASE_APP_URL}`,
    pathname: "/",
  },
  payouts: {
    index: `${BASE_APP_URL}/payouts`,
    setup: `${BASE_APP_URL}/payouts/setup`,
    method: `${BASE_APP_URL}/payouts/method`,
    request: `${BASE_APP_URL}/payouts/request`,
  },
} as const;

export const auth = {
  login: { href: `${BASE_AUTH_URL}/login`, pathname: "/login" },
  signup: { href: `${BASE_AUTH_URL}/signup`, pathname: "/signup" },
  verify: { href: `${BASE_AUTH_URL}/verify`, pathname: "/verify" },
  forgotPassword: { href: `${BASE_AUTH_URL}/forgot-password`, pathname: "/forgot-password" },
  changePassword: { href: `${BASE_AUTH_URL}/change-password`, pathname: "/change-password" },
} as const;

export const funding = {
  root: `${IS_FUNDING_APP ? "" : BASE_FUNDING_URL}/`,
  favorites: `${IS_FUNDING_APP ? "" : BASE_FUNDING_URL}/favorites`,
  organization: {
    index: (id: string) => `/organizations/${id}/onboarding`,
    logo: (id: string) => `/organizations/${id}/onboarding/logo`,
    teams: (id: string) => `/organizations/${id}/onboarding/teams`,
    funds: (id: string) => `/organizations/${id}/onboarding/funds`,
    invite: (id: string) => `/organizations/${id}/onboarding/invite`,
    calculator: (id: string) => `/organizations/${id}/calculator`,
    requested: (id: string) => `/organizations/${id}/requested`,
    more: (id: string) => `/teams/${id}/more`,
    shopFunds: (id: string) => `/teams/${id}/funds`,
  },
  close: {
    withdraw: (id: string) => `/teams/${id}/close/withdraw`,
    disburse: (id: string) => `/teams/${id}/close/disburse`,
    thankYou: (id: string) => `/teams/${id}/close/thankyou`,
    reminder: (id: string) => `/teams/${id}/close/reminder`,
    root: (id: string) => `/teams/${id}/close`,
  },
  teams: {
    root: (id?: string) => {
      if (!id) return IS_FUNDING_APP ? "" : BASE_FUNDING_URL;
      return IS_FUNDING_APP ? `/teams/${id}` : `${BASE_FUNDING_URL}/teams/${id}`;
    },
    new: () => (IS_FUNDING_APP ? "/teams/new" : `${BASE_FUNDING_URL}/teams/new`),
    onboarding: () => (IS_FUNDING_APP ? "/teams/onboarding" : `${BASE_FUNDING_URL}/teams/onboarding`),
    resources: {
      handoutPDF: ({ id, accessCode }: { accessCode?: string; id?: string }) => {
        if (!id) return undefined;
        const path = `/api/teams/${id}/resources/handout?accessCode=${accessCode ?? ""}`;
        return IS_FUNDING_APP || IS_APP_APP ? path : `${BASE_FUNDING_URL}${path}`;
      },
      faqPDF: ({ id, accessCode }: { accessCode?: string; id?: string }) => {
        if (!id) return undefined;
        const path = `/api/teams/${id}/resources/faq?accessCode=${accessCode ?? ""}`;
        return IS_FUNDING_APP || IS_APP_APP ? path : `${BASE_FUNDING_URL}${path}`;
      },
      allBrandsPDF: (id: string) => {
        const path = `/api/teams/${id}/resources/all-brands`;
        return IS_FUNDING_APP || IS_APP_APP ? path : `${BASE_FUNDING_URL}${path}`;
      },
    },
    invite: () => `${IS_FUNDING_APP ? "" : BASE_FUNDING_URL}/teams/invite`,
    referrals: (id?: string) => `/teams/${id ?? ""}/referrals`,
  },
};

export const api = {
  country: `${BASE_APP_URL}/api/country`,
};

export const marketing = {
  clubsLeagues: {
    orgs: (slug: string, code?: string) =>
      `${BASE_MAIN_URL}/clubs-leagues/${slug}/${code ? `?joinCode=${code}` : ""}`,
    teams: (slug: string, code?: string) =>
      `${BASE_MAIN_URL}/clubs-leagues/${slug}/teams${code ? `?joinCode=${code}` : ""}`,
  },
  banking: {
    waitlist: () => "/banking-waitlist",
  },
  home: "/home",
  solutions: {
    root: "/solutions",
    coaches: "/solutions/coaches-team-managers",
    parents: "/solutions/parents-athletes",
    clubs: "/solutions/clubs-leagues",
    teams: `${BASE_MAIN_URL}/teams`,
  },
  apps: {
    root: "/apps",
    shop: "/apps/shop",
    bank: "/apps/banking",
    budget: "/apps/budget",
    max: "/max",
  },
};

export const more: { [key: string]: (campaignId?: string, fundraiserId?: string) => string } = {
  max: () => `${BASE_MAIN_URL}/max`,
  app: () => `${BASE_MAIN_URL}/app`,
  linkedCards: () => external.linkedCardsUrl(),
  referrals: (_fundraiserId, campaignId = "") =>
    campaignId ? `${BASE_FUNDING_URL}/teams/${campaignId}/referrals` : "",
  askFans: (_fundraiserId, campaignId = "") =>
    campaignId ? `${BASE_MAIN_URL}/teams/${campaignId}/more?openModal=get-more-supporters` : "",
  download: () => "",
};

export const external: Record<string, (...params: $IntentionalAny) => string> = {
  shop: () => `${BASE_MAIN_URL}/shop`,
  organizationAdminUrl: (organizationId: string) =>
    `${BASE_SITE_URL()}/organizations/${organizationId}/admin`,
  organizationUrl: (slug: string) => `${BASE_SITE_URL()}/${slug}`,
  organizationIndexUrl: () => BASE_SITE_URL(),
  fundsPageEarningsUrl: (campaignId: string) => `${BASE_SITE_URL()}/teams/${campaignId}/funds#content`,
  teamViewPage: (campaignId: string) => `${BASE_SITE_URL()}/campaigns/${campaignId}`,
  fundsPageWithdrawalsUrl: (campaignId: string) => `${BASE_SITE_URL()}/teams/${campaignId}/funds#withdraw`,
  beneficiaryUrl: (campaignId: string) => `${BASE_SITE_URL()}/teams/${campaignId}/guide/beneficiary/intro`,
  termsOfUse: () => `${BASE_SITE_URL()}/pages/terms-of-use`,
  privacy: () => `${BASE_SITE_URL()}/pages/privacy`,
  coachingCalls: () => `${BASE_SITE_URL()}/pages/coaching-calls`,
  budgetCoachingCall: () => "https://flipgivebudgetapp.youcanbook.me/",
  logoutUrl: () => `${BASE_SITE_URL()}/logout`,
  flipGiveWallet: () => `${BASE_SITE_URL()}/user/purchases`,
  personalAccountUrl: () => `${BASE_SITE_URL()}/user/personal`,
  myActivity: () => `${BASE_SITE_URL()}/user/activity`,
  settingsUrl: () => `${BASE_APP_URL}/user/edit`,
  manageOrganizationUrl: () => `${BASE_SITE_URL()}/organizations/admin`,
  merchantUrl: () => `${BASE_SITE_URL()}/user/merchants`,
  paymentRecipientGuidelines: () => `${BASE_SITE_URL()}/pages/payment-recipient-guidelines`,
  settingsPage({ campaignId, campaignUrl }: { campaignId?: string; campaignUrl?: string }) {
    if (campaignUrl) return `${campaignUrl}/more/edit`;
    if (campaignId) return `${BASE_SITE_URL()}/teams/${campaignId}/more/edit`;
    return `${BASE_SITE_URL()}/more`;
  },
  morePage({ campaignId }: { campaignId?: string }) {
    if (campaignId) return `${BASE_SITE_URL()}/teams/${campaignId}/more`;
    return `${BASE_SITE_URL()}/more`;
  },
  myTeams: () => `${BASE_SITE_URL()}/user/teams`,
  userSettingsPage: (baseUrl: string = BASE_APP_URL) => `${baseUrl}/user/edit`,
  favoritesModalUrl: () => `${BASE_SITE_URL()}/?modal=my_favorites`,
  linkedCardsUrl: () => `${BASE_SITE_URL()}/user/linked_cards`,
  fundingUrl: () => BASE_FUNDING_URL,
  helpPage: () => "https://help.flipgive.com/",
  helpModal: (campaignId: string) => `${BASE_MAIN_URL}/teams/${campaignId}?openModal=help`,
  rootUrl: () => BASE_SITE_URL(),
  superAdminUrl: () => BASE_ADMIN_URL,
  personalAccountPage: () => "http://help.flipgive.com/en/articles/2921269-personal-account-what-is-it",
  closeTeamPage: () => "http://help.flipgive.com/en/articles/4966792-how-do-i-close-my-team",
  membersUrl: (campaignUrl: string) => `${campaignUrl}/members`,
  teamInviteUrl: (campaignUrl: string) => `${campaignUrl}/members?invite=true`,
  playerRosterUrl: (campaignUrl: string) => `${campaignUrl}/players`,
  teamPaymentsUrl: (campaignUrl: string, id: string) =>
    campaignUrl ? `${campaignUrl}/guide/payments` : `${BASE_MAIN_URL}/teams/${id}/guide/payments`,
  createTeamUrl: (baseUrl: string = BASE_SITE_URL()) => `${baseUrl}/teams/new`,
  joinTeamUrl: (baseUrl: string = BASE_SITE_URL()) => `${baseUrl}/teams/join`,
  transferFundsUrl: (baseUrl: string = BASE_SITE_URL()) => `${baseUrl}/user/funds`,
  playbookUrl: (baseUrl: string = BASE_SITE_URL()) => `${baseUrl}/playbook`,
  calculatorUrl: (baseUrl: string = BASE_SITE_URL()) => `${baseUrl}/how-much-can-i-earn`,
  switchTeamUrl: ({ baseUrl = BASE_SITE_URL(), campaignId }: { campaignId: string; baseUrl?: string }) =>
    `${baseUrl}/user/switching?set_default=${campaignId}`,
  userActivityFeedItemUrl: ({
    baseUrl = BASE_SITE_URL(),
    activityId,
  }: {
    activityId: string;
    baseUrl?: string;
  }) => `${baseUrl}/user/feed/${activityId}`,
  campaignActivityFeedItemUrl: ({
    baseUrl = BASE_SITE_URL(),
    activityId,
    campaignId,
  }: {
    activityId: string;
    campaignId: string;
    baseUrl?: string;
  }) => `${baseUrl}/teams/${campaignId}/activity/${activityId}`,
  activityPage: (baseUrl: string = BASE_SITE_URL()) => `${baseUrl}/activity`,
  fundsPage: (baseUrl: string = BASE_SITE_URL()) => `${baseUrl}/funds`,
  printableHandout: (campaignId: string) =>
    `${BASE_SITE_URL()}/flipgive-getting-started.pdf?campaign_id=${campaignId}`,
  shopLandingPage: () => `${BASE_MAIN_URL}/shop`,
  budgetLandingPage: () => `${BASE_MAIN_URL}/budget`,
  bankLandingPage: () => `${BASE_MAIN_URL}/banking`,
  bankDashboard: () => (IS_PRODUCTION ? "https://banking.flipgive.com" : "https://banking.almostflip.com"),
  closedTeamReport: (campaignId: string) => `${BASE_MAIN_URL}/teams/${campaignId}/report`,
  brandModal: (id: string) => `${BASE_MAIN_URL}?brand_id=${id}`,
  detailedBrandPage: ({
    campaignId,
    brandId,
    brandSlug,
    isAuthenticated,
  }: {
    brandId?: string;
    brandSlug?: string;
    campaignId?: string;
    isAuthenticated?: string;
  }) =>
    isAuthenticated
      ? `${BASE_MAIN_URL}/teams/${campaignId ?? ""}/?brand_id=${brandId ?? ""}`
      : `${BASE_MAIN_URL}/brands/${brandSlug ?? ""}`,
  logout: () => `${BASE_MAIN_URL}/logout`,
  stores: (id: string) => `${BASE_MAIN_URL}/stores/${id}`,
  publicTeams: () => `${BASE_MAIN_URL}/teams`,
  publicTeamsPage: (id: string) => `${BASE_MAIN_URL}/teams/${id}`,
  about: () => `${BASE_MAIN_URL}/about`,
  aboutUS: () => "https://www.getflipgive.com/about-us",
  stories: () => `${BASE_MAIN_URL}/stories`,
  locations: () => `${BASE_MAIN_URL}/locations`,
  brands: () => `${BASE_MAIN_URL}/brands`,
  contact: () => `${BASE_MAIN_URL}/pages/contact`,
  packages: () => `https://www.getflipgive.com/packages`,
  press: () => "https://www.getflipgive.com/press-releases",
  careers: () => "https://www.getflipgive.com/careers",
  company: () => "https://www.getflipgive.com",
  category: () => `${BASE_MAIN_URL}/category`,
  grants: () => "https://www.flipgivegrants.com",
  collection: (id: string) => `${BASE_MAIN_URL}/collection/${id}`,
  storefront: (id: string) => `${BASE_MAIN_URL}/stores/${id}`,
  activity: (id: string) => `${BASE_MAIN_URL}/activity/${id}`,
};

export const navbar = {
  products: [
    {
      name: "Shop",
      href: `${BASE_MAIN_URL}/shop`,
      description: "An easy-to-use cash back app for teams.",
      imageUrl: "https://flipgive.imgix.net/images/site/icons/navbar/shop-icon.png?auto=compress,format",
    },
    {
      name: "Budget",
      href: `${BASE_MAIN_URL}/budget`,
      description: "A free team budgeting and payment solution.",
      imageUrl: "https://flipgive.imgix.net/images/site/icons/navbar/budget-icon.png?auto=compress,format",
    },
    {
      name: "Banking",
      href: external.bankLandingPage(),
      description: "A banking app and cash back card for teams.",
      imageUrl: "https://flipgive.imgix.net/images/site/icons/navbar/bank-icon.png?auto=compress,format",
    },
  ],
  resources: [
    {
      name: "About Us",
      href: `${BASE_MAIN_URL}/about`,
    },
    {
      name: "Careers",
      href: `${BASE_MAIN_URL}/pages/careers`,
    },
    {
      name: "Merchant Solutions",
      href: "https://getflipgive.com",
    },
    {
      name: "Clubs & Leagues",
      href: `${BASE_MAIN_URL}/clubs-leagues`,
    },
    {
      name: "Contact",
      href: `${BASE_MAIN_URL}/contact`,
    },
  ],
};

export const queryParams = {
  isIntentLoader: "isIntentLoader",
  brandId: "brandId",
  linkId: "linkId",
  locationId: "locationId",
  isOnboardingActive: "isOnboardingActive",
  shouldOpenInNewWebWiewWindow: "newWindow",
  amountCents: "amountCents",
  fundraiserId: "fundraiserId",
  isGift: "isGift",
  isPhysical: "isPhysical",
  collectionId: "collectionId",
  storefrontId: "storefrontId",
  linkType: "linkType",
  quantity: "quantity",
  deviceAttributes: "deviceAttributes",
  reloadThresholdCents: "reloadThresholdCents",
  promotionId: "promotionId",
  search: "search",
  isCloud: "isCloud",
  cloudAuthToken: "cloudToken",
  redirect: "redirect",
  intentToken: "intentToken",
  optIn: "optIn",
  isReload: "isReload",
  reloadAmountCents: "reloadAmountCents",
  giftcardId: "giftcardId",
  partner: "partner",
  searchType: "searchType",
  fundraiserUserId: "fundraiserUserId",
};

export const shopcloud = {
  brandModal: (id: string, locationId?: string) =>
    addQueryParams({ [queryParams.brandId]: id, [queryParams.locationId]: locationId }),
  linkModal: (id: string, promotionId?: string) =>
    addQueryParams({ [queryParams.linkId]: id, [queryParams.promotionId]: promotionId }),
  optInModal: () => addQueryParam(queryParams.optIn, "true"),
  searchModal: () => addQueryParam(queryParams.search, "true"),
  favorites: () => `${BASE_CLOUD_URL}/favorites`,
  createIntent: (params: Record<TValueOf<typeof queryParams>, string>) => {
    const currentUrl = new URL(document.location.href);
    const url = new URL(`${BASE_CLOUD_URL}/intent?${currentUrl.searchParams.toString()}`);
    url.searchParams.append(queryParams.isIntentLoader, "true");
    Object.keys(params).forEach((key) => {
      if (!url.searchParams.has(key)) url.searchParams.append(key, params[key]);
    });
    return url.toString();
  },
  storefront: ({ id = "" }) => `/storefront/${id}`,
  teambuy: () => `/storefront/${StorefrontIdAliases.teambuy}`,
  collection: ({ id = "" }) => `/collection/${id}`,
  error: (message = "") => `/error?message=${message}`,
  wallet: () => `${BASE_CLOUD_URL}/wallet`,
  funds: () => `${BASE_CLOUD_URL}/funds`,
  fundsSupporter: (id: string) => `${BASE_CLOUD_URL}/funds/roster?${queryParams.fundraiserUserId}=${id}`,
  fundsPayment: (id: string) => `${BASE_CLOUD_URL}/funds/payouts/${id}`,
  roster: () => `${BASE_CLOUD_URL}/funds/roster`,
  rosterEdit: () => `${BASE_CLOUD_URL}/roster`,
  payouts: () => `${BASE_CLOUD_URL}/funds/payouts`,
  walletGiftcard: ({ id = "" }) => `/wallet/${id}`,
  news: () => `/news`,
  checkout: {
    index: (intentToken: string) =>
      `${BASE_APP_URL}/checkout/cloud/${intentToken}${document.location.search}`,
    status: (intentToken: string) =>
      `${BASE_APP_URL}/checkout/cloud/${intentToken}/status${document.location.search}`,
  },
  teamDashboard: () => `/team`,
  profileSettings: () => `/team/profile`,
  notificationsSettings: () => `/team/notifications`,
  missingEarnings: () => `/missing`,
  missingClaim: (intentId: string) => `/missing/${intentId}/start`,
  shoppingTrips: () => `/trips`,
};
