import gql from "graphql-tag";

export const getAmountWithdrawnBreakdownQuery = gql`
  query GetAmountWithdrawnBreakdownQuery {
    Campaign {
      id
      amountWithdrawnBreakdown {
        available {
          formatted
        }
        pending {
          formatted
        }
        withdrawn {
          formatted
        }
        withdrawalAmount {
          formatted
        }
      }
    }
  }
`;
