import gql from "graphql-tag";

export const cloudFragment = gql`
  fragment CloudFragment on ShopCloud {
    id
    campaignCurrency
    currency
    state
    userCurrency
    dealColor
    primaryColor
    secondaryColor
    logoMobileUrl
    activeCampaign {
      id
      name
      currency
      amountRaised {
        formatted
        dollarsAmount
      }
      photoImage
      longitude
      latitude
    }
    activeFundraiser {
      id
    }
  }
`;
