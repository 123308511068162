import { request } from "@core-shop/services/api/request";
import {
  DefaultStorefrontData,
  DefaultStorefrontDataVariables,
  DefaultStoreFrontType,
  GetCollectionById,
  GetCollectionByIdVariables,
  GetStorefrontById,
  GetStorefrontByIdVariables,
  GetShortcuts,
} from "@core-shop/types";
import { getCollectionByIdQuery } from "./api/getCollectionById";
import { getDefaultStorefront } from "./api/getDefaultStorefront";
import { getShortcutsQuery } from "./api/getShortcuts";
import { getStorefrontByIdQuery } from "./api/getStorefrontById";

export interface ISearchVariables {
  orderBy: string;
  orderDirection: string;
  page: number;
  query: string;
}

export function getStorefrontById(id: string) {
  return request<GetStorefrontById, GetStorefrontByIdVariables>(getStorefrontByIdQuery, {
    id,
  });
}

export function getDefaultStorefrontByType(type: DefaultStoreFrontType) {
  return request<DefaultStorefrontData, DefaultStorefrontDataVariables>(getDefaultStorefront, {
    type,
  });
}

export function getCollectionById(id: string) {
  return request<GetCollectionById, GetCollectionByIdVariables>(getCollectionByIdQuery, {
    id,
  });
}

export function getShortcuts() {
  return request<GetShortcuts>(getShortcutsQuery);
}
