import gql from "graphql-tag";

export const getTeamQuery = gql`
  query GetTeamQuery {
    ShopCloud {
      id
      activeCampaign {
        id
        country
        fundsDistributionMethod
        city
        deadline
        joinedFundraisersCount
        goal {
          formatted
        }
        category {
          id
          name
        }
        amountWithdrawnBreakdown {
          available {
            formatted
          }
        }
      }
      viewer {
        id
        email
        isCaptainable
      }
    }
  }
`;
