import React, { useEffect, useState } from "react";
import { LinkModal } from "@core-shop/components";
import { useRouter } from "@shared/hooks";
import { queryParams } from "@shared/utils/routes";
import { LinkModalView } from "./LinkModalView";

interface IProps {
  onClose: () => void;
}

export const Link: React.FC<IProps> = ({ onClose }) => {
  const router = useRouter();
  const [id, setId] = useState<string>();

  useEffect(() => {
    if (!router.isReady) return;
    const linkId = router.getQuery(queryParams.linkId);
    setId(linkId);
  }, [router]);

  return <LinkModal id={id} View={LinkModalView} onClose={onClose} />;
};
