import React, { useMemo } from "react";
import { useActivityAll, useActivityFundraiser, useCloud, useCloudAuthState } from "@core-shop/hooks";
import { IntentFilterByEnum } from "@core-shop/types";
import { getPaginatedNodes } from "@core-shop/utils";
import { IActivityGroup, IActivityViewProps, TIntent } from "./IActivityViewProps";

interface IProps {
  View: React.FC<IActivityViewProps>;
  filterBy?: IntentFilterByEnum;
  fundraiserUserId?: string;
}

export const Activity: React.FC<IProps> = ({ View, fundraiserUserId, filterBy }) => {
  const { data: cloudData } = useCloud();
  const { isLoading, fgStatus } = useCloudAuthState();
  const isEnabled = !isLoading && fgStatus.hasUser;

  const activityAllQuery = useActivityAll(
    { filterBy },
    {
      enabled: isEnabled && !fundraiserUserId,
    }
  );
  const activityFundraiserQuery = useActivityFundraiser(
    { fundraiserUserId },
    {
      enabled: isEnabled && !!fundraiserUserId,
    }
  );

  const activities: TIntent[] = useMemo(() => {
    if (fundraiserUserId) {
      return (
        activityFundraiserQuery.data?.pages
          .map((page) => getPaginatedNodes(page?.Campaign?.supportedIntentsPaginated?.edges))
          .flat() ?? []
      );
    }

    return (
      activityAllQuery.data?.pages
        .map((page) => getPaginatedNodes(page?.ShopCloud?.viewer?.intentsPaginated?.edges))
        .flat() ?? []
    );
  }, [activityAllQuery.data?.pages, activityFundraiserQuery.data?.pages, fundraiserUserId]);

  const groupedActivities = useMemo(() => {
    const groupedData: IActivityGroup[] = [];
    activities?.forEach((activity) => {
      const [year, month, day] = activity.createdAt.split("T")[0].split("-");
      const date = new Date(+year, +month, +day);
      const monthName = date.toLocaleString("default", { month: "short" });
      const groupName = `${monthName} ${year}`;
      const groupIndex = groupedData.findIndex((group) => group.name === groupName);
      if (groupIndex === -1) groupedData.push({ name: groupName, activities: [activity] });
      else groupedData[groupIndex].activities.push(activity);
    });
    return groupedData;
  }, [activities]);

  if (!isEnabled) return null;

  return (
    <View
      activities={activities}
      activityAllQuery={activityAllQuery}
      activityFundraiserQuery={activityFundraiserQuery}
      currentFundraiserId={cloudData?.ShopCloud?.activeFundraiser?.id}
      groupedActivities={groupedActivities}
      isLoading={fundraiserUserId ? activityFundraiserQuery.isLoading : activityAllQuery.isLoading}
    />
  );
};
