import { getTeam, getNotificationsSettings, getProfileSettings } from "@core-shop/models";
import { day, minute } from "../constants";
import { EntityKey } from "../types";

export const teamQueries = {
  team: () => ({
    queryKey: [EntityKey.CAMPAIGN, "active"],
    queryFn: () => getTeam(),
    staleTime: day,
    cacheTime: day + 5 * minute,
  }),
  profileSettings: () => ({
    queryKey: [EntityKey.VIEWER, "profileSettings"],
    queryFn: () => getProfileSettings(),
    staleTime: day,
    cacheTime: day + 5 * minute,
  }),
  notificationsSettings: () => ({
    queryKey: [EntityKey.VIEWER, "notificationsSettings"],
    queryFn: () => getNotificationsSettings(),
    staleTime: day,
    cacheTime: day + 5 * minute,
  }),
} as const;
