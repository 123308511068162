import { useMemo } from "react";
import { routes } from "@shared/utils";
import colors from "tailwindcss/colors";
import { BrandCircle, Container } from "@shared-tailwind/atoms";
import { SearchInput } from "@shared-tailwind/molecules";
import { IBrandCircleProps } from "../types";

interface IProps {
  searchTerms: string[];
  title: string;
  logoSearchItems?: IBrandCircleProps[];
  onClickItem?: (term: string) => void;
}

const MINIMUM_LOGO_SEARCH_ITEMS_LENGTH = 4;

export const SuggestedSearch = ({ title, searchTerms = [], onClickItem, logoSearchItems = [] }: IProps) => {
  const { numberOfEmptySearchItems, shouldRenderEmptyCircles } = useMemo(() => {
    const shouldRenderEmptyCirclesValue = logoSearchItems.length < MINIMUM_LOGO_SEARCH_ITEMS_LENGTH;
    return {
      numberOfEmptySearchItems: shouldRenderEmptyCirclesValue
        ? MINIMUM_LOGO_SEARCH_ITEMS_LENGTH - logoSearchItems.length
        : 0,
      shouldRenderEmptyCircles: shouldRenderEmptyCirclesValue,
    };
  }, [logoSearchItems.length]);

  return (
    <Container className="border-b border-gray-200 mb-5 py-3">
      <p className="text-gray-900 text-lg">{title}</p>
      {!!logoSearchItems.length && (
        <div className="flex justify-center items-center my-4 px-4">
          {logoSearchItems.map((item) => (
            <BrandCircle
              href={routes.shopcloud.brandModal(item.id)}
              key={item.name}
              sizeClassName="w-20 h-20"
              {...item}
              className="mr-3"
            />
          ))}
          {shouldRenderEmptyCircles &&
            logoSearchItems.length &&
            Array.from({ length: numberOfEmptySearchItems }).map((_, index) => (
              <BrandCircle
                bgColor={colors.gray[100]}
                className="mr-3"
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                name=""
                sizeClassName="w-20 h-20"
              />
            ))}
        </div>
      )}
      {searchTerms.map((term) => (
        <button className="my-3 w-full" key={term} type="button" onClick={() => onClickItem?.(term)}>
          <SearchInput className="pointer-events-none" placeholder={term} />
        </button>
      ))}
    </Container>
  );
};
