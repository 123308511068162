import { GiftcardDetails, ILinkModalViewProps } from "@core-shop/components";
import { IFormattedLink } from "@core-shop/components/link/LinkModal/ILinkModalViewProps";
import { isGiftcardType } from "@core-shop/utils";
import { routes } from "@shared/utils";
import classNames from "classnames";
import { useToggle } from "react-use";
import { BottomActionCard, Department, ModalSection } from "@shared-tailwind/atoms";
import { BlocksCarousel, ShopModal } from "@shared-tailwind/molecules";
import { Description, useMoreTextButton } from "../components/Description";
import { modalsZIndex } from "../zIndex";
import { BottomAction } from "./components/BottomAction/BottomAction";
import { BuyGiftcardView } from "./components/BottomAction/components/giftcards/BuyGiftcardView";
import { GiveAmount } from "./components/GiveAmount";
import { Promotions } from "./components/Promotions";
import { TeambuyLabel } from "./components/TeambuyLabel";
import { Thumbnail } from "./components/Thumbnail";
import { TopDeal } from "./components/TopDeal";

interface IProps extends ILinkModalViewProps {
  onClose: () => void;
}

export const LinkModalView: React.FC<IProps> = ({ linkQuery, onClose, link, topDeal }) => {
  const { MoreButton, isOpen: hasTermsOpen } = useMoreTextButton({ text: link.terms ?? "", length: 200 });
  const [isBuyGiftcardOpen, toggleIsBuyGiftcardOpen] = useToggle(false);

  return (
    <ShopModal
      isOpen
      isLoading={linkQuery.isLoading}
      title={link.name}
      zIndex={modalsZIndex.LINK}
      onClose={onClose}
    >
      <div className="min-h-[calc(100vh-117px)]">
        <TeambuyLabel />
        <Thumbnail image={link.images?.image} isGiftCard={isGiftcardType(link.type)} name={link.name} />
        {topDeal && (
          <TopDeal
            dealCount={link.previewInfo.promotionsCount - 1}
            label={topDeal.link?.giveSentence ?? ""}
            secondaryLabel={topDeal.link?.promotionInfo.promotionSecondaryLabel ?? ""}
          />
        )}
        <ModalSection className="bg-gray-50">
          <div className="flex justify-between items-center">
            <GiveAmount
              giveSentence={link.giveSentence}
              secondaryLabel={link.promotionInfo?.promotionSecondaryLabel}
            />
            <div className="text-gray-500 w-24 text-center">
              <p className="capitalize">{link.cashbackTiming}</p>
              <p className="text-xs">after purchase</p>
            </div>
          </div>
          <Description description={link.description} />
        </ModalSection>
        {link.earnInstructions && (
          <ModalSection title="How to earn cash back">
            <div
              className="text-sm text-gray-500"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: link.earnInstructions }}
            />
          </ModalSection>
        )}
        {link.exclusions && (
          <ModalSection title="Exclusions">
            <div
              className="text-sm text-gray-500"
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{ __html: link.exclusions }}
            />
          </ModalSection>
        )}
        {link.terms && (
          <ModalSection title="Full terms & conditions">
            <div>
              <div
                className={classNames("text-sm text-gray-500", !hasTermsOpen && "line-clamp-6")}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: link.terms }}
              />
              {MoreButton}
            </div>
          </ModalSection>
        )}
        {!!link.relatedLinks?.length && (
          <ModalSection>
            <BlocksCarousel title="Departments">
              {link.relatedLinks?.map(({ shortName, images, id }) => (
                <Department
                  className="w-[136px]"
                  href={routes.shopcloud.linkModal(id)}
                  imageSrc={images.square}
                  key={`depratment-${id}`}
                  name={shortName}
                />
              ))}
            </BlocksCarousel>
          </ModalSection>
        )}
        {!!link.promotions?.length && (
          <Promotions
            bgColor={link.defaultBrand.images?.logoBackgroundColor}
            logo={link.defaultBrand.images?.invertedLogo}
            promotions={link.promotions}
          />
        )}
      </div>
      {isBuyGiftcardOpen ? (
        <BottomActionCard title="Buy gift card" onClose={toggleIsBuyGiftcardOpen}>
          <GiftcardDetails id={link.id} View={BuyGiftcardView} />
        </BottomActionCard>
      ) : (
        <BottomAction link={link as IFormattedLink} onOpenGiftcardBuy={toggleIsBuyGiftcardOpen} />
      )}
    </ShopModal>
  );
};
