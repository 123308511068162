import gql from "graphql-tag";

export const collectionFragment = gql`
  fragment CollectionFragment on Collection {
    id
    name
    style
    assets {
      title
      asset {
        small
        destination
      }
    }
    links(first: 20) {
      id
      name
      giveAmount
      giveSentence
      giftcardType
      defaultBrand {
        id
      }
      previewInfo {
        description
        promotionsCount
        topPromotionInfo {
          id
          link {
            id
            name
            promotionInfo {
              promotionSecondaryLabel
            }
            giveSentence
          }
        }
      }
      images {
        logo
        image
      }
    }
    brands {
      edges {
        cursor
        node {
          id
          name
          viewerHasFavorited
          images {
            logo
            invertedLogo
            logoBackgroundColor
          }
          maxGiveLink {
            id
            promotionInfo {
              promotionLabel
              promotionSecondaryLabel
            }
            giveSentence
          }
        }
      }
    }
  }
`;
