import { cloudMutations } from "./cloud";
import { favoritesMutations } from "./favorites";
import { fundsMutations } from "./funds";
import { linkMutations } from "./link";
import { newsMutations } from "./news";
import { teamMutations } from "./team";

export const mutations = {
  ...favoritesMutations,
  ...linkMutations,
  ...cloudMutations,
  ...fundsMutations,
  ...newsMutations,
  ...teamMutations,
};
