import { request } from "@core-shop/services/api/request";
import {
  FavoritesData,
  ReorderFavoritesNewMutation,
  ReorderFavoritesNewMutationVariables,
  ToggleFavoriteBrandMutation,
  ToggleFavoriteBrandMutationVariables,
} from "@core-shop/types";
import { getFavortiesData } from "./api/getFavorites";
import { reorderFavoritesMutation } from "./api/reorderFavorites";
import { toggleFavoriteBrandMutation } from "./api/toggleFavoriteBrandMutation";

export function getViewerFavorites() {
  return request<FavoritesData>(getFavortiesData);
}

export function toggleFavoriteBrand({ id = "" } = {}) {
  return request<ToggleFavoriteBrandMutation, ToggleFavoriteBrandMutationVariables>(
    toggleFavoriteBrandMutation,
    {
      brandId: id,
    }
  );
}

export function reorderFavorites({ brandIds = [] }: ReorderFavoritesNewMutationVariables) {
  return request<ReorderFavoritesNewMutation, ReorderFavoritesNewMutationVariables>(
    reorderFavoritesMutation,
    {
      brandIds,
    }
  );
}
