import React, { useCallback, useMemo } from "react";
import { useRouter } from "@shared/hooks";
import { queryParams } from "@shared/utils/routes";
import { Brand } from "./brand";
import { Link } from "./link";
import { OnboardingModals } from "./onboarding";
import { OptInModal } from "./optIn";
import { Search } from "./search";

interface IProps {
  children: React.ReactNode;
}

export const ShopModalsContainer: React.FC<IProps> = ({ children }) => {
  const router = useRouter<typeof queryParams>({ shouldDisableScroll: true });

  const {
    search: isSearchActive,
    brandId,
    linkId,
    optIn: isOptIn,
    isOnboardingActive,
    isIntentLoader,
  } = router.query;

  const onClose = useCallback(() => router.safeBack(), [router]);

  const ModalsComponent = useMemo(() => {
    // Order is specified in z-index param in modalsZIndex.ts
    // please, keep order here in sync

    if (isIntentLoader) return isOptIn ? <OptInModal /> : null;

    return (
      <>
        {isOnboardingActive && <OnboardingModals onClose={onClose} />}
        {isSearchActive && <Search />}
        {brandId && <Brand onClose={onClose} />}
        {linkId && <Link onClose={onClose} />}
        {isOptIn && <OptInModal />}
      </>
    );
  }, [brandId, isIntentLoader, isOnboardingActive, isOptIn, isSearchActive, linkId, onClose]);

  if (!router.isReady) return null;

  return (
    <>
      {children}
      {ModalsComponent}
    </>
  );
};
