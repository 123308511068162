import { IFormattedGiftcard } from "../utils/formatGiftcard";

export function groupByDate(cards: IFormattedGiftcard[]) {
  const groups: { cards: typeof cards; name: string }[] = [];
  cards.forEach((card) => {
    if (!card.datePurchaised) return;
    const [year, month, day] = card.datePurchaised.split("T")[0].split("-");
    const date = new Date(+year, +month, +day);
    const monthName = date.toLocaleString("default", { month: "short" });
    const groupName = `${monthName} ${year}`;
    const groupIndex = groups.findIndex(({ name }) => name === groupName);

    if (!groups[groupIndex]) groups.push({ name: groupName, cards: [card] });
    else groups[groupIndex].cards.push(card);
  });
  return groups;
}

export function groupByName(cards: IFormattedGiftcard[]) {
  const groups: { cards: typeof cards; name: string }[] = [];
  cards.forEach((card) => {
    if (!card.brandName) return;
    const groupName = card.brandName;
    const groupIndex = groups.findIndex(({ name }) => name === groupName);

    if (!groups[groupIndex]) groups.push({ name: groupName, cards: [card] });
    else groups[groupIndex].cards.push(card);
  });
  return groups;
}

export function groupByBalance(cards: IFormattedGiftcard[]) {
  const groups: { cards: typeof cards; name: string }[] = [];
  cards.forEach((card) => {
    if (!card.balance) return;
    const groupName = card.balance;
    const groupIndex = groups.findIndex(({ name }) => name === groupName);

    if (!groups[groupIndex]) groups.push({ name: groupName, cards: [card] });
    else groups[groupIndex].cards.push(card);
  });
  return groups;
}
