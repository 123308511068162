import React from "react";
import { StorefrontIdAliases } from "@core-shop/components";
import { useRouter } from "@shared/hooks";
import classNames from "classnames";

export const TeambuyLabel: React.FC = () => {
  const router = useRouter();
  const isTeambuy = router.query.id === StorefrontIdAliases.teambuy;

  if (!isTeambuy) return null;

  return (
    <div
      className={classNames(
        "w-full text-center text-sm bg-primary-200 py-2",
        "font-light leading-5 uppercase"
      )}
    >
      TEAMBUY CASH BACK IS SHARED WITH TEAM
    </div>
  );
};
