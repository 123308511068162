import gql from "graphql-tag";

export const getBaseLinkQuery = gql`
  query GetBaseLinkQuery($id: ID!) {
    Link(id: $id) {
      id
      type
      name
      description
      giveSentence
      images {
        logo
        image
      }
      defaultBrand {
        id
        name
        images {
          invertedLogo
          logo
          logoBackgroundColor
        }
      }
    }
  }
`;
