import { LinkType } from "@core-shop/types";

export function isGiftcardType(linkType?: LinkType) {
  switch (linkType) {
    case LinkType.GIFTCARDOFFER:
    case LinkType.GIFTCARDSHOP:
    case LinkType.RELOADABLEGIFTCARD:
    case LinkType.AUTORELOADGIFTCARD:
      return true;
    default:
      return false;
  }
}
