import { useMutation } from "@tanstack/react-query";
import { mutations, queries } from "@core-shop/cache";
import { queryClient } from "@core-shop/providers/QueryProvider";
import {
  FavoritesData,
  GetBrand,
  ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_Brand,
} from "@core-shop/types";

type TBrand = ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_Brand;

export function useToggleFavoriteBrand({ id = "" } = {}) {
  const { mutationFn } = mutations.toggleFavorites(id);
  return useMutation({
    mutationFn,
    onSuccess: (response) => {
      if (!response) return;
      const { ViewerToggleFavoriteBrand: brand } = response;
      if (brand.__typename === "MutationError") return;
      updateBrand(brand.id, brand);
      updateViewerFavorites(brand.id, brand);
    },
  });
}

const updateBrand = (id: string, brand: TBrand) => {
  const brandKey = queries.brand({ id }).queryKey;
  const cacheData = queryClient.getQueryCache().findAll({ queryKey: brandKey });

  // We need to have two cache instances for brand: with or without location
  const affectedKeys = cacheData.map(({ queryKey }) => queryKey);

  affectedKeys.forEach((key) => {
    const previousBrandData = queryClient.getQueryData<GetBrand>(key);
    if (!previousBrandData) return;

    const updatedBrandData = {
      ...previousBrandData,
      Brand: {
        ...previousBrandData.Brand,
        viewerHasFavorited: brand.viewerHasFavorited,
      },
    };

    queryClient.setQueryData(key, updatedBrandData);
  }, []);
};

const updateViewerFavorites = (id: string, brand: TBrand) => {
  const favoritesKey = queries.viewerFavorites().queryKey;
  const previousFavoritesData = queryClient.getQueryData<FavoritesData>(favoritesKey);
  const previousFavorites = previousFavoritesData?.Viewer?.favoriteBrands ?? [];

  let newFavorites = [];
  if (brand.viewerHasFavorited) newFavorites = [...previousFavorites, brand];
  else newFavorites = previousFavorites.filter(({ id: brandId }) => id !== brandId);

  const updatedFavoritesData = {
    ...previousFavoritesData,
    Viewer: {
      ...previousFavoritesData?.Viewer,
      favoriteBrands: newFavorites,
    },
  };

  queryClient.setQueryData(favoritesKey, updatedFavoritesData);
};
