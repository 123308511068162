import gql from "graphql-tag";
import { storefrontFragment } from "./fragments/storefrontFragment";

export const getStorefrontByIdQuery = gql`
  query GetStorefrontById($id: ID!) {
    StoreFront(id: $id) {
      id
      ...StorefrontFragment
    }
  }
  ${storefrontFragment}
`;
