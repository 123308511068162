import { IOnboardingViewProps } from "@core-shop/components";
import { ShopModal } from "@shared-tailwind/molecules";
import { modalsZIndex } from "../zIndex";

export const OnboardingModalView: React.FC<IOnboardingViewProps> = () => {
  return (
    <ShopModal isOpen zIndex={modalsZIndex.ONBOARDING}>
      Onboarding
    </ShopModal>
  );
};
