import React from "react";
import { useFavorites, useReorderFavorites, useToggleFavoriteBrand } from "@core-shop/hooks";
import { IFavoritesViewProps } from "./IFavoritesViewProps";

interface IProps {
  View: React.FC<IFavoritesViewProps>;
  isAuthenticated?: boolean;
}

export const Favorites: React.FC<IProps> = ({ View, isAuthenticated = true }) => {
  const favoritesQuery = useFavorites({ isEnabled: isAuthenticated });
  const toggleFavoriteMutaion = useToggleFavoriteBrand();
  const reorderFavoritesMutation = useReorderFavorites();

  return (
    <View
      brands={favoritesQuery.data?.Viewer.favoriteBrands ?? []}
      favoritesQuery={favoritesQuery}
      isLoading={favoritesQuery.isLoading}
      reorderFavoritesMutation={reorderFavoritesMutation}
      toggleFavoriteMutation={toggleFavoriteMutaion}
    />
  );
};
