import gql from "graphql-tag";

export const getPayoutsQuery = gql`
  query GetPayoutsQuery($first: Int, $after: String) {
    Campaign {
      id
      payments(first: $first, after: $after) {
        edges {
          cursor
          node {
            id
            processedAt
            state
            amount {
              formatted
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;
