import { useMemo } from "react";
import { useGiftcardDetails } from "@core-shop/hooks";
import { getPaginatedNodes } from "@core-shop/utils";
import { IGiftcardDetailsViewProps } from "./IGiftcardDetailsViewProps";

interface IProps {
  id: string;
  View: React.FC<IGiftcardDetailsViewProps>;
}

export const GiftcardDetails: React.FC<IProps> = ({ id, View }) => {
  const query = useGiftcardDetails({ id });
  const denominations = useMemo(
    () => getPaginatedNodes(query.data?.Link.giftcardDenominations.edges),
    [query.data?.Link.giftcardDenominations.edges]
  );
  const reloadThresholdDenominations = useMemo(
    () => query.data?.Link.reloadableGiftcardInfo.reloadThresholdDenominations,
    [query.data?.Link.reloadableGiftcardInfo.reloadThresholdDenominations]
  );

  return (
    <View
      denominations={denominations}
      giftcardDetailsQuery={query}
      reloadThresholdDenominations={reloadThresholdDenominations}
      type={query.data?.Link.type}
    />
  );
};
