import gql from "graphql-tag";

export const updateNotificationsSettingsMutation = gql`
  mutation UpdateNotifications($unsubscribedAt: DateTimeISO, $notifyUpdate: Boolean) {
    ViewerSettingsUpdate(unsubscribedAt: $unsubscribedAt, notifyUpdate: $notifyUpdate) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Viewer {
        id
        unsubscribedAt
        notifyUpdate
      }
    }
  }
`;
