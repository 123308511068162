export enum OnboardingStepName {
  CREATE_TEAM = "CREATE_TEAM",
  INVITE = "INVITE",
  JOIN = "JOIN",
  ROLE_MEMBER = "ROLE_MEMBER",
  ROSTER = "ROSTER",
  SELECT_PLAYER = "SELECT_PLAYER",
  SET_GOAL = "SET_GOAL",
}

export enum OnboardingRoles {
  CAPTAIN = "CAPTAIN",
  MEMBER = "MEMBER",
}

export enum StepsTime {
  AFTER_ACTION = "AFTER_ACTION",
  BEFORE_ACTION = "BEFORE_ACTION",
}

export interface IOnboardingStepsByTime {
  [StepsTime.BEFORE_ACTION]: OnboardingStepName[];
  [StepsTime.AFTER_ACTION]: OnboardingStepName[];
}

export interface IOnboardingStep {
  name: OnboardingStepName;
  query: $IntentionalAny;
  submitMutation: $IntentionalAny;
}
