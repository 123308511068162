import { cloudQueries } from "./cloud";
import { favoritesQueries } from "./favorites";
import { fundsQueries } from "./funds";
import { newsQueries } from "./news";
import { onboardingQueries } from "./onboarding";
import { storefrontQueries } from "./storefront";
import { teamQueries } from "./team";
import { walletQueries } from "./wallet";

export const queries = {
  ...storefrontQueries,
  ...onboardingQueries,
  ...favoritesQueries,
  ...cloudQueries,
  ...walletQueries,
  ...fundsQueries,
  ...newsQueries,
  ...teamQueries,
};
