import React, { useMemo } from "react";
import { useAmountsWithdrawnBreakdown, usePayments } from "@core-shop/hooks";
import { getPaginatedNodes } from "@core-shop/utils";
import { IPayoutsViewProps } from "./IPayoutsViewProps";

interface IProps {
  View: React.FC<IPayoutsViewProps>;
}

export const Payouts: React.FC<IProps> = ({ View }) => {
  const paymentsQuery = usePayments({ first: 10 });
  const withdrawsQuery = useAmountsWithdrawnBreakdown();

  const payments = useMemo(() => {
    return (
      paymentsQuery.data?.pages.map((page) => getPaginatedNodes(page.Campaign?.payments.edges)).flat() ?? []
    );
  }, [paymentsQuery.data?.pages]);

  const amounts = withdrawsQuery.data?.Campaign?.amountWithdrawnBreakdown;

  return (
    <View
      amounts={amounts}
      isLoading={paymentsQuery.isLoading && withdrawsQuery.isLoading}
      payments={payments}
      paymentsQuery={paymentsQuery}
      withdrawsQuery={withdrawsQuery}
    />
  );
};
