export function addQueryParam(key: string, value: string): URL {
  const url = new URL(document.location.href);
  url.searchParams.set(key, value);
  return url;
}

export function addQueryParams(params: { [key: string]: string | undefined }): URL {
  const url = new URL(document.location.href);
  const keys = Object.keys(params);
  keys.forEach((key) => {
    if (params?.[key]) url.searchParams.set(key, params[key] as string);
  });
  return url;
}
