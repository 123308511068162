import React from "react";
import classNames from "classnames";
import colors from "tailwindcss/colors";
import { Image } from "../Image/Image";
import { InternalLink } from "../InternalLink/InternalLink";

interface IProps {
  bgColor?: string | null;
  className?: string;
  href?: string | URL;
  name?: string;
  onClick?: () => void;
  sizeClassName?: string;
  src?: string | null;
}

export const BrandCircle: React.FC<IProps> = ({
  src,
  name,
  bgColor,
  href = "",
  className,
  sizeClassName = "w-[75px] h-[75px] md:w-[100px] md:h-[100px]",
  onClick,
}) => {
  return (
    <InternalLink shallow className={classNames(className, sizeClassName)} href={href} onClick={onClick}>
      <div
        className={classNames(
          "overflow-hidden p-2 rounded-full flex justify-center items-center border",
          sizeClassName
        )}
        style={{ background: bgColor || colors.white }}
      >
        {src && <Image alt={name} className="w-full h-full" src={src} />}
      </div>
    </InternalLink>
  );
};
