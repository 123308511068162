import gql from "graphql-tag";

export const giftcardFragment = gql`
  fragment GiftcardFragment on Giftcard {
    id
    uuid
    externalUrl
    updatedAt
    isExternalTarget
    isReloadable
    pin
    giftcardType
    barcode
    cardNumber
    balance {
      dollarsAmount
    }
    value {
      formatted(round: 0)
    }
    balance {
      dollarsAmount
      formatted
    }
    merchant {
      id
      name
    }
    link {
      id
      name
      images {
        image
      }
      defaultBrand {
        id
        name
      }
      giftcardType
      walletDescription
      exclusions
      giftcardInstructions
    }
    reloadInfo {
      isReloadingEnabled
      isAutoReloadable
      reloadThreshold {
        formatted
      }
    }
    transaction {
      id
      createdAt
      giftName
      giftEmail
    }
    accessLogs(first: 1000) {
      edges {
        cursor
        node {
          accessType
          accessedAt
          id
          data {
            label
            value
          }
        }
      }
    }
  }
`;
