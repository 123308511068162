import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { TSvgReactComponent } from "@root/types/utils";
import classNames from "classnames";

// == Types ================================================================

export enum DropdownPosition {
  left = "left",
  right = "right",
}

interface IProps {
  Component: React.ReactNode;
  dropdownPosition: DropdownPosition;
  title: string;
  arrowClassName?: string;
  buttonClassName?: string;
  className?: string;
  Icon?: TSvgReactComponent;
  leftPx?: number;
  topPx?: number;
}

// == Constants ============================================================

const { left, right } = DropdownPosition;

Dropdown.defaultProps = {
  className: "rounded-md",
  dropdownPosition: left,
};

// == Component ============================================================

export function Dropdown({
  Icon,
  Component,
  title,
  className,
  buttonClassName,
  arrowClassName,
  dropdownPosition,
  leftPx,
  topPx,
}: IProps) {
  return (
    <Menu as="div" className={classNames("relative text-left border", className)}>
      <div>
        <Menu.Button
          className={
            buttonClassName ??
            classNames(
              "flex justify-center w-full leading-6 items-center rounded-md",
              "px-4 py-1.5 md:py-1 bg-white text-md font-medium  hover:bg-gray-50 focus:outline-none"
            )
          }
        >
          {Icon && <Icon aria-hidden="true" className="h-5 w-5 text-gray-400 mr-2" />}
          {title}
          {Component && (
            <ChevronDownIcon
              aria-hidden="true"
              className={classNames(arrowClassName ?? "-mr-1 ml-2 h-5 w-5 text-gray-400")}
            />
          )}
        </Menu.Button>
      </div>
      {Component && (
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className={classNames(
              "origin-top-right absolute mt-2 rounded-md shadow-lg z-50 max-w-screen",
              "bg-white focus:outline-none p-2 px-4 border",
              { "right-0": dropdownPosition === left, "left-0": dropdownPosition === right }
            )}
            style={{ left: leftPx ? `${leftPx}px` : "", top: topPx ? `${topPx}px` : "" }}
          >
            {Component}
          </Menu.Items>
        </Transition>
      )}
    </Menu>
  );
}

// == Styles ===============================================================
