import { useMemo } from "react";
import { IBrandModalViewProps } from "@core-shop/components";
import { getPaginatedNodes } from "@shared/utils";
import { Link } from "../Link";
import { icons } from "./icons";

interface IProps {
  links: IBrandModalViewProps["brand"]["links"];
}

export const LinksList: React.FC<IProps> = ({ links }) => {
  const paginatedLinks = useMemo(() => getPaginatedNodes(links.edges), [links.edges]);

  return (
    <>
      {paginatedLinks.map(({ type, giveSentence, id: linkId, name: linkName, previewInfo }) => {
        const { description: linkDescription } = previewInfo ?? {};
        const linkProps: React.ComponentProps<typeof Link> = {
          description: linkDescription,
          giveAmount: giveSentence,
          title: linkName,
          id: linkId,
        };

        return <Link {...linkProps} Icon={icons[type]} key={type} />;
      })}
    </>
  );
};
