import { IBrandModalViewProps } from "@core-shop/components";
import dynamic from "next/dynamic";
import { toast } from "react-toastify";
import { Container, LikeButton, ModalSection } from "@shared-tailwind/atoms";
import { ShopModal } from "@shared-tailwind/molecules";
import { Description } from "../components/Description";
import { modalsZIndex } from "../zIndex";
import { Hero } from "./components/Hero";
import { LinksList } from "./components/LinksList/LinksList";

const LazyLocationsMap = dynamic(() =>
  import("./components/LocationsMap/LocationsMap").then((mod) => mod.LocationsMap)
);

interface IProps extends IBrandModalViewProps {
  onClose: () => void;
}

export const BrandModalView: React.FC<IProps> = ({
  onClose,
  brand,
  brandQuery,
  favoriteMutation,
  shouldShowLikeButton,
}) => {
  const {
    id,
    name,
    description,
    images,
    maxGiveLink,
    links,
    viewerHasFavorited: isLiked,
    locationsCount,
    location,
  } = brand;

  return (
    <ShopModal
      isOpen
      isLoading={brandQuery.isLoading}
      title={name}
      zIndex={modalsZIndex.BRAND}
      onClose={onClose}
    >
      <div className="bg-gray-50 min-h-[calc(100vh-53px)]">
        <div className="bg-white mb-3">
          <Hero images={images} name={name} />
          <Container className="flex space-y-4 flex-col border-b pb-4 ">
            <div className="flex justify-between items-center">
              <p className="text-lg">Gives up to {maxGiveLink?.giveAmount}</p>
              {shouldShowLikeButton && (
                <LikeButton
                  isLiked={isLiked}
                  isLoading={favoriteMutation.isLoading}
                  onClick={() =>
                    favoriteMutation.mutateAsync(id).catch((errors) => toast.error(errors?.[0]?.message))
                  }
                />
              )}
            </div>
            <Description description={description} />
          </Container>
        </div>
        {!!links?.edges?.length && (
          <ModalSection hasBorder={!!brand.location} title="Ways to earn cash back">
            <LinksList links={links} />
          </ModalSection>
        )}
        {brand.location && (
          <Container className="mb-4 bg-white">
            <p className="text-lg py-4">{locationsCount} Locations</p>
            <LazyLocationsMap location={location} />
          </Container>
        )}
      </div>
    </ShopModal>
  );
};
