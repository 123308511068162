import React, { useCallback } from "react";
import formatISO from "date-fns/formatISO";
import { useNotificationsSettings, useUpdateNotificationsSettings } from "@core-shop/hooks";
import { UpdateNotificationsVariables } from "@core-shop/types";
import { INotificationsSettingsViewProps, IOnUpdate } from "./INotificationsSettingsViewProps";

interface IProps {
  View: React.FC<INotificationsSettingsViewProps>;
}

export const NotificationsSettings: React.FC<IProps> = ({ View }) => {
  const notificationsQuery = useNotificationsSettings();
  const isSubscribedToAll = !notificationsQuery.data?.ShopCloud?.viewer?.unsubscribedAt;
  const isSubscribedToUpdates = !!notificationsQuery.data?.ShopCloud?.viewer?.notifyUpdate;

  const updateNotificationsMutation = useUpdateNotificationsSettings();

  const onUpdateNotifications = useCallback(
    (data: IOnUpdate) => {
      const variables: UpdateNotificationsVariables = {
        notifyUpdate: data.isSubscribedToUpdates,
        unsubscribedAt: data.isSubscribedToAll ? null : formatISO(new Date()),
      };
      return updateNotificationsMutation.mutateAsync(variables);
    },
    [updateNotificationsMutation]
  );

  return (
    <View
      isSubscribedToAll={isSubscribedToAll}
      isSubscribedToUpdates={isSubscribedToUpdates}
      notificationsQuery={notificationsQuery}
      updateNotificationsMutation={updateNotificationsMutation}
      onUpdateNotifications={onUpdateNotifications}
    />
  );
};
