import { useCallback, useEffect, useMemo, useState } from "react";
import { ISearchModalViewProps } from "@core-shop/components";
import { useDebounce, useRouter } from "@shared/hooks";
import { Container, Loader } from "@shared-tailwind/atoms";
import { SearchInput, ShopModal } from "@shared-tailwind/molecules";
import { modalsZIndex } from "../zIndex";
import { EmptyResult } from "./components/EmptyResults";
import { FullSearchResultsView } from "./components/FullSearchResultsView";
import { SearchResultsList } from "./components/SearchResultsList";
import { SuggestedSearchView } from "./components/SuggestedSearchView";
import { ESearchResultType } from "./types";

const MINIMUM_SEARCH_RESULTS_LENGTH = 3;

export const SearchModalView = ({
  searchResultsQuery,
  searchResults,
  autoCompleteResultsQuery,
  setSearchParams,
  brandSearchResultsQuery,
  dealsSearchResultsQuery,
  setAutoCompleteQuery,
  toggleFavoriteMutation,
  brandsSearchResults,
  dealsSearchResults,
}: ISearchModalViewProps) => {
  const router = useRouter();
  const [inputValue, setInputValue] = useState("");
  const [debouncedInputValue] = useDebounce(inputValue, 500);

  const { data: autocompleteData, isLoading: isAutocompleteLoading } = autoCompleteResultsQuery ?? {};

  const handleSearch = useCallback(
    (selectedSearchQuery: string) => {
      setSearchParams((prev) => ({ ...prev, query: selectedSearchQuery }));
    },
    [setSearchParams]
  );

  useEffect(() => {
    if (debouncedInputValue.length >= MINIMUM_SEARCH_RESULTS_LENGTH) {
      setAutoCompleteQuery(debouncedInputValue);
      return;
    }

    if (debouncedInputValue === "") {
      setAutoCompleteQuery("");
      handleSearch("");
    }
  }, [debouncedInputValue, handleSearch, setAutoCompleteQuery]);

  const dropdownOptions = useMemo(() => {
    if (isAutocompleteLoading) return [];
    const options = autocompleteData?.AutocompleteSearch ?? [];

    return options.map((option) => ({
      label: option.value,
      value: option.value,
    }));
  }, [autocompleteData?.AutocompleteSearch, isAutocompleteLoading]);

  const SearchResultComponent = useMemo(() => {
    const { isLoading, isFetching } = searchResultsQuery ?? {};
    const { brands, deals } = searchResults ?? {};

    const shouldShowEmptyResults = !isLoading && debouncedInputValue && !brands?.length && !deals?.length;

    if (shouldShowEmptyResults)
      return (
        <Container className="min-h-[300px] flex justify-center items-center">
          <EmptyResult searchTerm={inputValue ?? ""} />
        </Container>
      );

    if (isLoading && isFetching)
      return (
        <div className="flex justify-center items-center h-[400px]">
          <Loader className="h-10 w-10" />
        </div>
      );

    if (router.query.searchType)
      return (
        <FullSearchResultsView
          brandSearchResultsQuery={brandSearchResultsQuery}
          brandsSearchResults={brandsSearchResults}
          dealsSearchResults={dealsSearchResults}
          dealsSearchResultsQuery={dealsSearchResultsQuery}
          toggleFavoriteBrandMutation={toggleFavoriteMutation}
        />
      );

    if (!searchResults.brands.length && !searchResults.deals.length) {
      return (
        <div>
          <SuggestedSearchView
            handleSearch={(v) => {
              setInputValue(v);
              handleSearch(v);
            }}
          />
        </div>
      );
    }

    return (
      <>
        <div className="mb-2 border-b border-gray-200">
          {debouncedInputValue && !!brands?.length && (
            <SearchResultsList
              isGrid
              brandResults={brands}
              title="Brands"
              toggleFavoriteBrandMutation={toggleFavoriteMutation}
              type={ESearchResultType.BRAND}
            />
          )}
        </div>
        <div>
          {debouncedInputValue && !!deals?.length && (
            <SearchResultsList
              dealResults={deals}
              title="Deals"
              toggleFavoriteBrandMutation={toggleFavoriteMutation}
              type={ESearchResultType.DEAL}
            />
          )}
        </div>
      </>
    );
  }, [
    brandSearchResultsQuery,
    brandsSearchResults,
    dealsSearchResults,
    dealsSearchResultsQuery,
    debouncedInputValue,
    handleSearch,
    inputValue,
    router.query.searchType,
    searchResults,
    searchResultsQuery,
    toggleFavoriteMutation,
  ]);

  return (
    <ShopModal isOpen zIndex={modalsZIndex.SEARCH} onClose={() => router.safeBack()}>
      <div className="min-h-[70vh] py-4">
        <Container className="pb-4">
          <SearchInput
            isClearable
            options={dropdownOptions}
            value={inputValue}
            onChange={setInputValue}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleSearch(inputValue);
              }
            }}
            onSelect={(autocompletedQuery) => handleSearch(autocompletedQuery)}
          />
        </Container>
        {SearchResultComponent}
      </div>
    </ShopModal>
  );
};
