// == Types ================================================================

// == Constants ============================================================

// == Exports ==============================================================

export function removeQueryParam(key: string): URL {
  const url = new URL(document.location.href);
  url.searchParams.delete(key);
  return url;
}
