import React, { useCallback, useState } from "react";
import {
  ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_Brand,
  TToggleFavoriteMutation,
} from "@core-shop/types";
import classNames from "classnames";
import { GiveAmount } from "@shared-tailwind/organisms/ShopModals/link/components/GiveAmount";
import { ButtonSizes } from "@shared-tailwind/types";
import { Button, ButtonVariants } from "../Button/Button";
import { Image } from "../Image/Image";
import { InternalLink } from "../InternalLink/InternalLink";
import { LikeButton } from "../LikeButton/LikeButton";

type TBrand = ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_Brand;

interface IProps {
  bgColor?: string | null;
  buttonText?: string;
  buttonUrl?: string | URL;
  buttonVariant?: ButtonVariants;
  className?: string;
  giveAmount?: React.ComponentProps<typeof GiveAmount>;
  hasButton?: boolean;
  hasLikeButton?: boolean;
  hasLogo?: boolean;
  id?: string;
  imageSrc?: string | null;
  isDeal?: boolean;
  isHightLighted?: boolean;
  isLiked?: boolean;
  leftVerticalText?: string;
  onClick?: () => void;
  onClickLike?: () => void;
  onToggleFavoriteMutation?: TToggleFavoriteMutation;
  secondarySubtitle?: string;
  shouldDisableInnerLike?: boolean;
  subtitle?: string;
  title?: React.ReactNode;
  topText?: string;
  topTextBig?: string;
}

export const BaseBrandBlock: React.FC<IProps> = ({
  id,
  className,
  imageSrc,
  topText,
  secondarySubtitle,
  title,
  hasLikeButton,
  isLiked,
  subtitle,
  isDeal,
  isHightLighted,
  leftVerticalText,
  bgColor,
  buttonText,
  buttonVariant,
  topTextBig,
  giveAmount,
  buttonUrl,
  onToggleFavoriteMutation,
  onClick,
  onClickLike,
  shouldDisableInnerLike,
  hasLogo = true,
  hasButton = true,
}) => {
  const [isInnerLiked, setIsInnerLiked] = useState(isLiked);
  const [isLikeLoading, setIsLikeLoading] = useState(false);
  const onLike = useCallback(async () => {
    if (!onToggleFavoriteMutation || shouldDisableInnerLike) {
      onClickLike?.();
      return;
    }

    setIsLikeLoading(true);
    const data = await onToggleFavoriteMutation?.mutateAsync(id);
    const brand = data?.ViewerToggleFavoriteBrand as TBrand;
    if (brand) setIsInnerLiked(brand.viewerHasFavorited);
    setIsLikeLoading(false);
  }, [id, onClickLike, onToggleFavoriteMutation, shouldDisableInnerLike]);

  return (
    <div
      className={classNames(
        "flex space-x-3 items-center relative",
        isHightLighted && "bg-primary-50 px-5 py-3 rounded-md",
        className
      )}
    >
      {hasLogo && (
        <div className="w-16 aspect-square shrink-0">
          <div
            className={classNames("h-full p-1 border", isDeal ? "rounded-t-lg" : "rounded-lg")}
            style={{ background: bgColor ?? "" }}
          >
            {imageSrc ? (
              <Image
                alt={topText}
                className="w-full h-full"
                imagePositionClassName="object-contain"
                src={imageSrc}
              />
            ) : (
              <div className="w-full h-full bg-gray-100" />
            )}
          </div>
          {isDeal && (
            <div
              className={classNames(
                "w-full text-center bg-deals-500 rounded-b",
                "text-gray-900 text-[11px] h-5 leading-5 uppercase"
              )}
            >
              Deal
            </div>
          )}
          <span
            className={classNames(
              "uppercase -scale-100 text-[11px] absolute text-gray-400",
              "left-1 top-0 leading-none h-full text-center"
            )}
            style={{ writingMode: "vertical-lr" }}
          >
            {leftVerticalText}
          </span>
        </div>
      )}

      <div className="text-gray-900 mt-1 leading-5 text-left flex-1">
        <p className="w-full">{topTextBig}</p>
        {/* TODO: fix this. In my use-case, the content is coming as html */}
        {/* eslint-disable-next-line react/no-danger */}
        <p className="w-full text-gray-500 text-xs" dangerouslySetInnerHTML={{ __html: topText ?? "" }} />
        {title && <p className="w-full">{title}</p>}
        {subtitle && <p className="w-full text-xs">{subtitle}</p>}
        {secondarySubtitle && <p className="w-full text-xs text-gray-500">{secondarySubtitle}</p>}
        {giveAmount && <GiveAmount className="mt-1" {...giveAmount} />}
      </div>
      <div className="right-0 flex h-7 space-x-2">
        {hasLikeButton && <LikeButton isLiked={!!isInnerLiked} isLoading={isLikeLoading} onClick={onLike} />}
        {buttonText && hasButton && (
          <InternalLink shallow href={buttonUrl ?? ""}>
            <Button
              className="justify-self-end"
              size={ButtonSizes.xs}
              variant={buttonVariant}
              onClick={onClick}
            >
              {buttonText}
            </Button>
          </InternalLink>
        )}
      </div>
    </div>
  );
};
