import React from "react";
import { XIcon } from "@heroicons/react/outline";
import { Button, ButtonVariants } from "../Button/Button";

interface IProps {
  onClick?: () => void;
}

export const XButton: React.FC<IProps> = ({ onClick }) => {
  return (
    <Button
      resetSizeClasses
      className="-mt-3"
      Icon={XIcon}
      iconClassName="w-6 h-6"
      variant={ButtonVariants.link}
      onClick={onClick}
    />
  );
};
