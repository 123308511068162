import React from "react";
import { IOnboardingViewProps } from "./IOnboardingViewProps";

/**
 * It's a WIP for future implementation of onboarding
 */

interface IProps {
  View: React.FC<IOnboardingViewProps>;
}

export const Onboarding: React.FC<IProps> = ({ View }) => {
  return <View />;
};
