import { request } from "@core-shop/services/api/request";
import {
  GetBrand,
  GetBrandVariables,
  LinkType,
  LocationSearch,
  LocationSearchVariables,
} from "@core-shop/types";
import { getBrandQuery } from "./api/getBrandQuery";
import { locationSearchQuery } from "./api/locationSearchQuery";

const linkTypes = [
  LinkType.BRAND,
  LinkType.GIFTCARDOFFER,
  LinkType.AUTORELOADGIFTCARD,
  LinkType.EMPYR,
  LinkType.TRAVELSHOP,
  LinkType.BOUNTYSHOP,
  LinkType.GIFTCARDSHOP,
  LinkType.VOUCHER,
];

export function getBrand({ id = "", locationId = "" }) {
  return request<GetBrand, GetBrandVariables>(getBrandQuery, {
    id,
    locationId,
    linkTypes,
    includeLocation: !!locationId,
  });
}

export function locationSearch(vars: LocationSearchVariables) {
  return request<LocationSearch, LocationSearchVariables>(locationSearchQuery, vars);
}
