import { colors } from "@shared/styles";
import "./Loader.css";

// == Types ================================================================

interface IProps {
  className: string;
  color: string;
  size: number;
  stroke: number;
}

// == Constants ============================================================

Loader.defaultProps = {
  size: 25,
  color: colors.gray,
  stroke: 5,
  className: "",
};

// == Component ============================================================

export function Loader({ size, color, stroke, className }: IProps) {
  return (
    <svg className={`spinner ${className}`} height={size} viewBox="0 0 50 50" width={size}>
      <circle className="path" cx="25" cy="25" fill="none" r="20" stroke={color} strokeWidth={stroke} />
    </svg>
  );
}

// == Styles ===============================================================
