/**
 * We are migrating to imgix image loader from next image loader.
 * Current documented way of setting the loader: https://nextjs.org/docs/api-reference/next/image#loader-configuration
 * But this way sets the loader for all next/image components, and fires a lot of errors which are breaking pages.
 *
 * Implementation below uses imgixLoader function from next.js package:
 * https://github.com/vercel/next.js/blob/5ea3f6c4ab22cfd55d078664ed8a5eb573807be3/packages/next/client/image.tsx#L960
 *
 * This implementation should be abandoned and deleted when all image components use imgix urls.
 * Because in this case, we will set the loader in the next.config file instead.
 *
 * Migration plan:
 * 1. use imgix loader in Image components if src url origin is flipgive.imgix.net --- YOU ARE HERE
 * 2. move all images from /public folders to s3
 * 3. set config to next.config, including path field
 * 4. replace imgix urls from full path to relative one
 * 5. get rid of <img/> tags in favor of image component
 */

import { useMemo } from "react";
import { IS_CLOUD, IS_PRODUCTION, TEST_ENV } from "@shared/config/environment";
import { IMGIX, IMGIX_STAGING } from "./constants";

// == Types ================================================================

export type TImageLoaderProps = {
  src: string;
  width: number;
  quality?: number;
};

type TStaticImageData = {
  height: number;
  src: string;
  width: number;
  blurDataURL?: string;
};

interface IStaticRequire {
  default: TStaticImageData;
}

interface ILoaderOpts {
  shouldForceCloud?: boolean;
}

type TStaticImport = IStaticRequire | TStaticImageData;

type TLoader = ({ src, width, quality }: TImageLoaderProps) => string;

type TLoaderMaker = (src: string | TStaticImport, opts?: ILoaderOpts) => TLoader | undefined;

// == Constants ============================================================

// == Functions ============================================================

export const getImageLoader: TLoaderMaker = (src, { shouldForceCloud } = {}) => {
  if (typeof src !== "string") return undefined;
  if (IS_CLOUD || shouldForceCloud) return cloudImagesLoader;

  if (src.includes(IMGIX) || src.includes(IMGIX_STAGING)) return imgixLoader;

  // BE also uses cloudfront, don't throw error in this case
  if (!IS_PRODUCTION && !src.includes("cloudfront") && !TEST_ENV)
    // eslint-disable-next-line no-console
    console.error(`PLEASE USE IMGIX. Image src: ${src} should be served from imgix server.`);

  return undefined;
};

export const useImageLoader: TLoaderMaker = (src, opts = {}) =>
  useMemo(() => getImageLoader(src, opts), [opts, src]);

// Imgix loader from next/image
// https://github.com/vercel/next.js/blob/5ea3f6c4ab22cfd55d078664ed8a5eb573807be3/packages/next/client/image.tsx#L960

function normalizeSrc(src: string): string {
  return src[0] === "/" ? src.slice(1) : src;
}

function imgixLoader({ src, width, quality }: TImageLoaderProps): string {
  // Demo: https://static.imgix.net/daisy.png?auto=format&fit=max&w=300
  const url = new URL(normalizeSrc(src));
  const params = url.searchParams;

  params.set("auto", params.get("auto") || "compress,format");
  params.set("fit", params.get("fit") || "max");
  params.set("w", params.get("w") || width.toString());

  if (quality) {
    params.set("q", quality.toString());
  }

  return url.href;
}

function cloudImagesLoader({ src }: TImageLoaderProps): string {
  return src.split("?")[0];
}
