import { getNewsQuery } from "@core-shop/models";
import { minute } from "../constants";
import { EntityKey } from "../types";

export const newsQueries = {
  news: () => ({
    queryKey: [EntityKey.NEWS],
    queryFn: getNewsQuery,
    staleTime: 5 * minute,
    cacheTime: 6 * minute,
  }),
} as const;
