import React from "react";
import { useCloud, useFundraisers } from "@core-shop/hooks";
import { getPaginatedNodes } from "@core-shop/utils";
import { IRosterListViewProps } from "./IRosterListViewProps";

interface IProps {
  View: React.FC<IRosterListViewProps>;
}

export const RosterList: React.FC<IProps> = ({ View }) => {
  const fundraisersQuery = useFundraisers();
  const fundraisers =
    fundraisersQuery.data?.pages
      .map((page) => getPaginatedNodes(page.ShopCloud?.activeCampaign?.teamMembers.all.edges))
      .flat() ?? [];

  const { data: cloudData } = useCloud();
  const activeFundraiserId = cloudData?.ShopCloud?.activeFundraiser?.id;

  return (
    <View
      activeFundraiserId={activeFundraiserId}
      fundraisers={fundraisers}
      fundraisersQuery={fundraisersQuery}
    />
  );
};
