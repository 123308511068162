import React from "react";
import classNames from "classnames";
import { Image } from "@shared-tailwind/atoms";

interface IProps {
  image?: string | null;
  isGiftCard?: boolean;
  name?: string | null;
}

export const Thumbnail: React.FC<IProps> = ({ isGiftCard, image, name }) => {
  return (
    <div className={classNames("w-full aspect-video", isGiftCard && "p-4")}>
      <Image
        alt={name ?? ""}
        className={classNames(
          "w-full",
          isGiftCard ? "rounded-lg shadow-md overflow-hidden aspect-[12/8]" : "aspect-[12/9]"
        )}
        imagePositionClassName="object-cover object-center"
        imageProps={{
          className: classNames("w-full"),
        }}
        src={image ?? ""}
      />
    </div>
  );
};
