export const TOKEN_KEY = "CLOUD_TOKEN";

export function setToken(token: string) {
  localStorage.setItem(TOKEN_KEY, token);
}

export function getToken() {
  return localStorage.getItem(TOKEN_KEY);
}

export function deleteToken() {
  const isTokenExists = getToken();
  if (!isTokenExists) return;
  localStorage.removeItem(TOKEN_KEY);
}
