import React from "react";
import classNames from "classnames";
import { Button, ButtonVariants, HorizontalScroll, InternalLink } from "@shared-tailwind/atoms";
import { HeadingSection } from "../HeadingSection/HeadingSection";

interface IProps {
  buttonTitle?: string;
  buttonUrl?: string | URL;
  children?: React.ReactNode;
  className?: string;
  justify?: React.ComponentProps<typeof HorizontalScroll>["justify"];
  rows?: number;
  scrollContainerClassName?: string;
  title?: string;
}

export const BlocksCarousel: React.FC<IProps> = ({
  buttonTitle,
  buttonUrl = "",
  title,
  children,
  rows = 1,
  className,
  scrollContainerClassName,
  justify,
}) => {
  return (
    <div className={classNames("w-full overflow-x-hidden", className)}>
      {title && (
        <HeadingSection
          hasNoBorder
          shouldForceOneLine
          Buttons={
            buttonTitle ? (
              <InternalLink href={buttonUrl}>
                <Button className="text-primary-500 -mt-3" hasRing={false} variant={ButtonVariants.link}>
                  {buttonTitle}
                </Button>
              </InternalLink>
            ) : undefined
          }
          className="-mb-4"
        >
          {title}
        </HeadingSection>
      )}

      <HorizontalScroll className={scrollContainerClassName} justify={justify} rows={rows}>
        {children}
      </HorizontalScroll>
    </div>
  );
};
