import classNames from "classnames";
import { InternalLink } from "@shared-tailwind/atoms";

interface IProps {
  className?: string;
  description?: string;
  href?: string;
  Icon?: React.FC<{ className?: string }>;
  isDisabled?: boolean;
  title?: string;
  value?: string;
}

export const MenuCard: React.FC<IProps> = ({
  href = "",
  Icon,
  description,
  value,
  title,
  className,
  isDisabled,
}) => {
  return (
    <InternalLink className={classNames(className, isDisabled && "pointer-events-none")} href={href}>
      <div
        className={classNames(
          "flex items-center space-x-3 relative border rounded-md p-3",
          !isDisabled && "bg-white"
        )}
      >
        {Icon && (
          <div
            className={classNames(
              "rounded-full w-14 h-14 p-4 flex justify-center items-center shrink-0",
              isDisabled ? "bg-gray-500" : "bg-primary-500"
            )}
          >
            <Icon className="text-white" />
          </div>
        )}
        <div className="flex flex-col flex-space-1">
          <p className="text-sm">{title}</p>
          <p className="font-medium text-lg">{value}</p>
          <p className="text-xs text-gray-500">{description}</p>
        </div>
      </div>
    </InternalLink>
  );
};
