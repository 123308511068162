/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HelpModalQuery
// ====================================================

export interface HelpModalQuery_Viewer_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaign's country
   */
  readonly country: Country | null;
  /**
   * Campaign's join code
   */
  readonly accessCode: string;
}

export interface HelpModalQuery_Viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: HelpModalQuery_Viewer_activeCampaign | null;
}

export interface HelpModalQuery {
  /**
   * Retrieve details about the logged in user
   */
  readonly Viewer: HelpModalQuery_Viewer;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SendInviteEmailQuery
// ====================================================

export interface SendInviteEmailQuery_Viewer_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
}

export interface SendInviteEmailQuery_Viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: SendInviteEmailQuery_Viewer_activeCampaign | null;
}

export interface SendInviteEmailQuery_InviteTemplate {
  readonly __typename: "InviteTemplate";
  /**
   * Template ID
   */
  readonly id: string;
  /**
   * Template name
   */
  readonly title: string;
  /**
   * Template subject
   */
  readonly subject: string;
  /**
   * Template HTML body
   */
  readonly bodyHTML: string;
  /**
   * Template plain body
   */
  readonly bodyPlain: string;
  /**
   * Template mailTo link
   */
  readonly mailTo: string;
}

export interface SendInviteEmailQuery {
  /**
   * Retrieve details about the logged in user
   */
  readonly Viewer: SendInviteEmailQuery_Viewer;
  /**
   * Find an invite template
   */
  readonly InviteTemplate: SendInviteEmailQuery_InviteTemplate | null;
}

export interface SendInviteEmailQueryVariables {
  readonly template?: InviteTemplateType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: InviteOptionsSetInvitedSupporters
// ====================================================

export interface InviteOptionsSetInvitedSupporters_FundraiserSetInvitedSupporters_MutationError {
  readonly __typename: "MutationError";
}

export interface InviteOptionsSetInvitedSupporters_FundraiserSetInvitedSupporters_Fundraiser_campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
}

export interface InviteOptionsSetInvitedSupporters_FundraiserSetInvitedSupporters_Fundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Has invited supporters
   */
  readonly hasInvitedSupporters: boolean;
  /**
   * Fundraiser's campaign
   */
  readonly campaign: InviteOptionsSetInvitedSupporters_FundraiserSetInvitedSupporters_Fundraiser_campaign | null;
}

export type InviteOptionsSetInvitedSupporters_FundraiserSetInvitedSupporters =
  | InviteOptionsSetInvitedSupporters_FundraiserSetInvitedSupporters_MutationError
  | InviteOptionsSetInvitedSupporters_FundraiserSetInvitedSupporters_Fundraiser;

export interface InviteOptionsSetInvitedSupporters {
  /**
   * Record that viewer fundraiser has invited supporters
   */
  readonly FundraiserSetInvitedSupporters: InviteOptionsSetInvitedSupporters_FundraiserSetInvitedSupporters;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MarkNotificationReadMutation
// ====================================================

export interface MarkNotificationReadMutation_ActivityMarkNotificationsRead_User {
  readonly __typename: "User";
}

export interface MarkNotificationReadMutation_ActivityMarkNotificationsRead_Viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
}

export interface MarkNotificationReadMutation_ActivityMarkNotificationsRead_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface MarkNotificationReadMutation_ActivityMarkNotificationsRead_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<MarkNotificationReadMutation_ActivityMarkNotificationsRead_MutationError_errors>;
}

export type MarkNotificationReadMutation_ActivityMarkNotificationsRead =
  | MarkNotificationReadMutation_ActivityMarkNotificationsRead_User
  | MarkNotificationReadMutation_ActivityMarkNotificationsRead_Viewer
  | MarkNotificationReadMutation_ActivityMarkNotificationsRead_MutationError;

export interface MarkNotificationReadMutation {
  /**
   * Mark notifications as read
   */
  readonly ActivityMarkNotificationsRead: MarkNotificationReadMutation_ActivityMarkNotificationsRead;
}

export interface MarkNotificationReadMutationVariables {
  readonly activityIds: ReadonlyArray<string>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteTeamRosterMutation
// ====================================================

export interface DeleteTeamRosterMutation_DeleteTeamRoster_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface DeleteTeamRosterMutation_DeleteTeamRoster_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<DeleteTeamRosterMutation_DeleteTeamRoster_MutationError_errors>;
}

export interface DeleteTeamRosterMutation_DeleteTeamRoster_DeleteTeamRosterPayload_campaign_activeGroups_goal {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface DeleteTeamRosterMutation_DeleteTeamRoster_DeleteTeamRosterPayload_campaign_activeGroups {
  readonly __typename: "Group";
  /**
   * Group's ID
   */
  readonly id: string;
  /**
   * Group's name
   */
  readonly name: string;
  /**
   * Group's total amount raised
   */
  readonly goal: DeleteTeamRosterMutation_DeleteTeamRoster_DeleteTeamRosterPayload_campaign_activeGroups_goal;
  /**
   * DateTime when a player was deleted
   */
  readonly deletedAt: DateTimeISO | null;
  /**
   * A count of the group's fundraisers
   */
  readonly fundraisersCount: number;
}

export interface DeleteTeamRosterMutation_DeleteTeamRoster_DeleteTeamRosterPayload_campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Does this campaign use the budget app
   */
  readonly hasBudget: boolean;
  /**
   * A list of the campaign's groups which are not deleted
   */
  readonly activeGroups: ReadonlyArray<DeleteTeamRosterMutation_DeleteTeamRoster_DeleteTeamRosterPayload_campaign_activeGroups>;
}

export interface DeleteTeamRosterMutation_DeleteTeamRoster_DeleteTeamRosterPayload {
  readonly __typename: "DeleteTeamRosterPayload";
  /**
   * The team.
   */
  readonly campaign: DeleteTeamRosterMutation_DeleteTeamRoster_DeleteTeamRosterPayload_campaign;
}

export type DeleteTeamRosterMutation_DeleteTeamRoster =
  | DeleteTeamRosterMutation_DeleteTeamRoster_MutationError
  | DeleteTeamRosterMutation_DeleteTeamRoster_DeleteTeamRosterPayload;

export interface DeleteTeamRosterMutation {
  /**
   * Delete players from a team
   */
  readonly DeleteTeamRoster: DeleteTeamRosterMutation_DeleteTeamRoster;
}

export interface DeleteTeamRosterMutationVariables {
  readonly input: DeleteTeamRosterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EditTeamRosterQuery
// ====================================================

export interface EditTeamRosterQuery_Viewer_activeCampaign_viewerFundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Is Captain
   */
  readonly isCaptain: boolean | null;
  /**
   * Is Captainable
   */
  readonly isCaptainable: boolean | null;
}

export interface EditTeamRosterQuery_Viewer_activeCampaign_goal {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface EditTeamRosterQuery_Viewer_activeCampaign_activeGroups_goal {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface EditTeamRosterQuery_Viewer_activeCampaign_activeGroups {
  readonly __typename: "Group";
  /**
   * Group's ID
   */
  readonly id: string;
  /**
   * Group's name
   */
  readonly name: string;
  /**
   * Group's total amount raised
   */
  readonly goal: EditTeamRosterQuery_Viewer_activeCampaign_activeGroups_goal;
  /**
   * DateTime when a player was deleted
   */
  readonly deletedAt: DateTimeISO | null;
  /**
   * A count of the group's fundraisers
   */
  readonly fundraisersCount: number;
}

export interface EditTeamRosterQuery_Viewer_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Does this campaign use the budget app
   */
  readonly hasBudget: boolean;
  /**
   * Campaign's url on the web
   */
  readonly url: string;
  /**
   * The team's player count estimate
   */
  readonly playersCountEstimate: number | null;
  /**
   * How campaign plans to distribute funds
   */
  readonly fundsDistributionMethod: FundsDistributionMethod | null;
  /**
   * Current viewer's fundraiser on campaign
   */
  readonly viewerFundraiser: EditTeamRosterQuery_Viewer_activeCampaign_viewerFundraiser | null;
  /**
   * Campaigns's goal amount
   */
  readonly goal: EditTeamRosterQuery_Viewer_activeCampaign_goal | null;
  /**
   * A list of the campaign's groups which are not deleted
   */
  readonly activeGroups: ReadonlyArray<EditTeamRosterQuery_Viewer_activeCampaign_activeGroups>;
}

export interface EditTeamRosterQuery_Viewer_teamOnboardingInfo {
  readonly __typename: "TeamOnboardingInfo";
  /**
   * Is the user's onboarding state active?
   */
  readonly isActive: boolean;
}

export interface EditTeamRosterQuery_Viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: EditTeamRosterQuery_Viewer_activeCampaign | null;
  /**
   * User's team onboarding info
   */
  readonly teamOnboardingInfo: EditTeamRosterQuery_Viewer_teamOnboardingInfo;
}

export interface EditTeamRosterQuery {
  /**
   * Retrieve details about the logged in user
   */
  readonly Viewer: EditTeamRosterQuery_Viewer;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditTeamRosterMutation
// ====================================================

export interface EditTeamRosterMutation_EditTeamRoster_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface EditTeamRosterMutation_EditTeamRoster_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<EditTeamRosterMutation_EditTeamRoster_MutationError_errors>;
}

export interface EditTeamRosterMutation_EditTeamRoster_EditTeamRosterPayload_campaign_activeGroups_goal {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface EditTeamRosterMutation_EditTeamRoster_EditTeamRosterPayload_campaign_activeGroups {
  readonly __typename: "Group";
  /**
   * Group's ID
   */
  readonly id: string;
  /**
   * Group's name
   */
  readonly name: string;
  /**
   * Group's total amount raised
   */
  readonly goal: EditTeamRosterMutation_EditTeamRoster_EditTeamRosterPayload_campaign_activeGroups_goal;
  /**
   * DateTime when a player was deleted
   */
  readonly deletedAt: DateTimeISO | null;
  /**
   * A count of the group's fundraisers
   */
  readonly fundraisersCount: number;
}

export interface EditTeamRosterMutation_EditTeamRoster_EditTeamRosterPayload_campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Does this campaign use the budget app
   */
  readonly hasBudget: boolean;
  /**
   * A list of the campaign's groups which are not deleted
   */
  readonly activeGroups: ReadonlyArray<EditTeamRosterMutation_EditTeamRoster_EditTeamRosterPayload_campaign_activeGroups>;
}

export interface EditTeamRosterMutation_EditTeamRoster_EditTeamRosterPayload_viewer_teamOnboardingInfo_onboardingSteps {
  readonly __typename: "TeamOnboardingStepInfo";
  /**
   * The step's ID
   */
  readonly id: string;
  /**
   * The step's name
   */
  readonly name: string;
}

export interface EditTeamRosterMutation_EditTeamRoster_EditTeamRosterPayload_viewer_teamOnboardingInfo {
  readonly __typename: "TeamOnboardingInfo";
  /**
   * Is the user's onboarding state active?
   */
  readonly isOnboardingActive: boolean;
  /**
   * The type of onboarding
   */
  readonly type: TeamOnboardingType | null;
  /**
   * The user's current onboarding step
   */
  readonly currentStep: TeamOnboardingStep | null;
  /**
   * The user's current checklist item to complete
   */
  readonly currentChecklistItem: TeamOnboardingChecklistItem | null;
  /**
   * The user's list of onboarding step
   */
  readonly onboardingSteps: ReadonlyArray<EditTeamRosterMutation_EditTeamRoster_EditTeamRosterPayload_viewer_teamOnboardingInfo_onboardingSteps>;
}

export interface EditTeamRosterMutation_EditTeamRoster_EditTeamRosterPayload_viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * User's team onboarding info
   */
  readonly teamOnboardingInfo: EditTeamRosterMutation_EditTeamRoster_EditTeamRosterPayload_viewer_teamOnboardingInfo;
}

export interface EditTeamRosterMutation_EditTeamRoster_EditTeamRosterPayload {
  readonly __typename: "EditTeamRosterPayload";
  /**
   * The team.
   */
  readonly campaign: EditTeamRosterMutation_EditTeamRoster_EditTeamRosterPayload_campaign;
  /**
   * The viewer.
   */
  readonly viewer: EditTeamRosterMutation_EditTeamRoster_EditTeamRosterPayload_viewer;
}

export type EditTeamRosterMutation_EditTeamRoster =
  | EditTeamRosterMutation_EditTeamRoster_MutationError
  | EditTeamRosterMutation_EditTeamRoster_EditTeamRosterPayload;

export interface EditTeamRosterMutation {
  /**
   * Edit team roster of players for a team
   */
  readonly EditTeamRoster: EditTeamRosterMutation_EditTeamRoster;
}

export interface EditTeamRosterMutationVariables {
  readonly input: EditTeamRosterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InviteFormQuery
// ====================================================

export interface InviteFormQuery_Viewer_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * Campaign's url on the web
   */
  readonly url: string;
  /**
   * Campaign's join code
   */
  readonly accessCode: string;
  /**
   * URL to invite people with join the team.
   */
  readonly joinUrl: string;
}

export interface InviteFormQuery_Viewer_teamOnboardingInfo {
  readonly __typename: "TeamOnboardingInfo";
  /**
   * Is the user's onboarding state active?
   */
  readonly isActive: boolean;
}

export interface InviteFormQuery_Viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * User's first name
   */
  readonly firstName: string;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: InviteFormQuery_Viewer_activeCampaign | null;
  /**
   * User's team onboarding info
   */
  readonly teamOnboardingInfo: InviteFormQuery_Viewer_teamOnboardingInfo;
}

export interface InviteFormQuery_InviteTemplate {
  readonly __typename: "InviteTemplate";
  /**
   * Template ID
   */
  readonly id: string;
  /**
   * Template name
   */
  readonly title: string;
  /**
   * Template subject
   */
  readonly subject: string;
  /**
   * Template HTML body
   */
  readonly bodyHTML: string;
  /**
   * Template plain body
   */
  readonly bodyPlain: string;
  /**
   * Template mailTo link
   */
  readonly mailTo: string;
}

export interface InviteFormQuery {
  /**
   * Retrieve details about the logged in user
   */
  readonly Viewer: InviteFormQuery_Viewer;
  /**
   * Find an invite template
   */
  readonly InviteTemplate: InviteFormQuery_InviteTemplate | null;
}

export interface InviteFormQueryVariables {
  readonly template?: InviteTemplateType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FundraiserSetInvitedSupporters
// ====================================================

export interface FundraiserSetInvitedSupporters_FundraiserSetInvitedSupporters_MutationError {
  readonly __typename: "MutationError";
}

export interface FundraiserSetInvitedSupporters_FundraiserSetInvitedSupporters_Fundraiser_campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
}

export interface FundraiserSetInvitedSupporters_FundraiserSetInvitedSupporters_Fundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Has invited supporters
   */
  readonly hasInvitedSupporters: boolean;
  /**
   * Fundraiser's campaign
   */
  readonly campaign: FundraiserSetInvitedSupporters_FundraiserSetInvitedSupporters_Fundraiser_campaign | null;
}

export type FundraiserSetInvitedSupporters_FundraiserSetInvitedSupporters =
  | FundraiserSetInvitedSupporters_FundraiserSetInvitedSupporters_MutationError
  | FundraiserSetInvitedSupporters_FundraiserSetInvitedSupporters_Fundraiser;

export interface FundraiserSetInvitedSupporters {
  /**
   * Record that viewer fundraiser has invited supporters
   */
  readonly FundraiserSetInvitedSupporters: FundraiserSetInvitedSupporters_FundraiserSetInvitedSupporters;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NewTeamFormSiteQuery
// ====================================================

export interface NewTeamFormSiteQuery_Site_categories_parent {
  readonly __typename: "CampaignCategory";
  /**
   * Category's ID
   */
  readonly id: string;
  /**
   * Category's name
   */
  readonly name: string;
}

export interface NewTeamFormSiteQuery_Site_categories {
  readonly __typename: "CampaignCategory";
  /**
   * Category's ID
   */
  readonly id: string;
  /**
   * Category's name
   */
  readonly name: string;
  /**
   * If category is featured
   */
  readonly isFeatured: boolean;
  /**
   * Category's parent
   */
  readonly parent: NewTeamFormSiteQuery_Site_categories_parent | null;
}

export interface NewTeamFormSiteQuery_Site_teamRoles {
  readonly __typename: "TeamRole";
  /**
   * The role's ID
   */
  readonly id: string;
  /**
   * The role's name
   */
  readonly name: string;
}

export interface NewTeamFormSiteQuery_Site {
  readonly __typename: "Site";
  /**
   * Site's ID
   */
  readonly id: string;
  /**
   * Site's list of campaign categories available
   */
  readonly categories: ReadonlyArray<NewTeamFormSiteQuery_Site_categories>;
  /**
   * Site's list of team roles available
   */
  readonly teamRoles: ReadonlyArray<NewTeamFormSiteQuery_Site_teamRoles>;
}

export interface NewTeamFormSiteQuery {
  /**
   * Find a Site by ID
   */
  readonly Site: NewTeamFormSiteQuery_Site | null;
}

export interface NewTeamFormSiteQueryVariables {
  readonly site_id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: NewTeamFormMutation
// ====================================================

export interface NewTeamFormMutation_TeamCreate_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface NewTeamFormMutation_TeamCreate_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<NewTeamFormMutation_TeamCreate_MutationError_errors>;
}

export interface NewTeamFormMutation_TeamCreate_TeamCreatePayload_campaign_viewerFundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Is Captain
   */
  readonly isCaptain: boolean | null;
}

export interface NewTeamFormMutation_TeamCreate_TeamCreatePayload_campaign_category {
  readonly __typename: "CampaignCategory";
  /**
   * Category's ID
   */
  readonly id: string;
  /**
   * Category's name
   */
  readonly name: string;
}

export interface NewTeamFormMutation_TeamCreate_TeamCreatePayload_campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Current viewer's fundraiser on campaign
   */
  readonly viewerFundraiser: NewTeamFormMutation_TeamCreate_TeamCreatePayload_campaign_viewerFundraiser | null;
  /**
   * Campaign's category
   */
  readonly category: NewTeamFormMutation_TeamCreate_TeamCreatePayload_campaign_category;
}

export interface NewTeamFormMutation_TeamCreate_TeamCreatePayload {
  readonly __typename: "TeamCreatePayload";
  /**
   * The newly created team.
   */
  readonly campaign: NewTeamFormMutation_TeamCreate_TeamCreatePayload_campaign;
}

export type NewTeamFormMutation_TeamCreate =
  | NewTeamFormMutation_TeamCreate_MutationError
  | NewTeamFormMutation_TeamCreate_TeamCreatePayload;

export interface NewTeamFormMutation {
  /**
   * Create a new team.
   */
  readonly TeamCreate: NewTeamFormMutation_TeamCreate;
}

export interface NewTeamFormMutationVariables {
  readonly input: TeamCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SetGoalMutation
// ====================================================

export interface SetGoalMutation_CampaignUpdate_Campaign {
  readonly __typename: "Campaign";
}

export interface SetGoalMutation_CampaignUpdate_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface SetGoalMutation_CampaignUpdate_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<SetGoalMutation_CampaignUpdate_MutationError_errors>;
}

export type SetGoalMutation_CampaignUpdate =
  | SetGoalMutation_CampaignUpdate_Campaign
  | SetGoalMutation_CampaignUpdate_MutationError;

export interface SetGoalMutation {
  /**
   * Update a campaign's details.
   */
  readonly CampaignUpdate: SetGoalMutation_CampaignUpdate;
}

export interface SetGoalMutationVariables {
  readonly goal?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: BudgetFundraiserUpdateRolesMutation
// ====================================================

export interface BudgetFundraiserUpdateRolesMutation_BudgetFundraiserUpdateRoles_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface BudgetFundraiserUpdateRolesMutation_BudgetFundraiserUpdateRoles_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<BudgetFundraiserUpdateRolesMutation_BudgetFundraiserUpdateRoles_MutationError_errors>;
}

export interface BudgetFundraiserUpdateRolesMutation_BudgetFundraiserUpdateRoles_BudgetFundraiserUpdateRolesPayload_fundraiser {
  readonly __typename: "BudgetFundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraiser roles
   */
  readonly roles: ReadonlyArray<FundraiserRole>;
}

export interface BudgetFundraiserUpdateRolesMutation_BudgetFundraiserUpdateRoles_BudgetFundraiserUpdateRolesPayload {
  readonly __typename: "BudgetFundraiserUpdateRolesPayload";
  /**
   * Budget Team Member
   */
  readonly fundraiser: BudgetFundraiserUpdateRolesMutation_BudgetFundraiserUpdateRoles_BudgetFundraiserUpdateRolesPayload_fundraiser;
}

export type BudgetFundraiserUpdateRolesMutation_BudgetFundraiserUpdateRoles =
  | BudgetFundraiserUpdateRolesMutation_BudgetFundraiserUpdateRoles_MutationError
  | BudgetFundraiserUpdateRolesMutation_BudgetFundraiserUpdateRoles_BudgetFundraiserUpdateRolesPayload;

export interface BudgetFundraiserUpdateRolesMutation {
  /**
   * Update fundraiser roles.
   */
  readonly BudgetFundraiserUpdateRoles: BudgetFundraiserUpdateRolesMutation_BudgetFundraiserUpdateRoles;
}

export interface BudgetFundraiserUpdateRolesMutationVariables {
  readonly input: BudgetFundraiserUpdateRolesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FundraiserUpdateRolesMutation
// ====================================================

export interface FundraiserUpdateRolesMutation_FundraiserUpdateRoles_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface FundraiserUpdateRolesMutation_FundraiserUpdateRoles_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<FundraiserUpdateRolesMutation_FundraiserUpdateRoles_MutationError_errors>;
}

export interface FundraiserUpdateRolesMutation_FundraiserUpdateRoles_FundraiserUpdateRolesPayload_campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
}

export interface FundraiserUpdateRolesMutation_FundraiserUpdateRoles_FundraiserUpdateRolesPayload_fundraiser_teamRoles {
  readonly __typename: "TeamRole";
  /**
   * The role's ID
   */
  readonly id: string;
  /**
   * The role's name
   */
  readonly name: string;
}

export interface FundraiserUpdateRolesMutation_FundraiserUpdateRoles_FundraiserUpdateRolesPayload_fundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * The team member's roles
   */
  readonly teamRoles: ReadonlyArray<FundraiserUpdateRolesMutation_FundraiserUpdateRoles_FundraiserUpdateRolesPayload_fundraiser_teamRoles>;
}

export interface FundraiserUpdateRolesMutation_FundraiserUpdateRoles_FundraiserUpdateRolesPayload {
  readonly __typename: "FundraiserUpdateRolesPayload";
  /**
   * The fundraiser's team.
   */
  readonly campaign: FundraiserUpdateRolesMutation_FundraiserUpdateRoles_FundraiserUpdateRolesPayload_campaign;
  /**
   * The updated fundraiser.
   */
  readonly fundraiser: FundraiserUpdateRolesMutation_FundraiserUpdateRoles_FundraiserUpdateRolesPayload_fundraiser;
}

export type FundraiserUpdateRolesMutation_FundraiserUpdateRoles =
  | FundraiserUpdateRolesMutation_FundraiserUpdateRoles_MutationError
  | FundraiserUpdateRolesMutation_FundraiserUpdateRoles_FundraiserUpdateRolesPayload;

export interface FundraiserUpdateRolesMutation {
  /**
   * Update the team roles for a fundraiser.
   */
  readonly FundraiserUpdateRoles: FundraiserUpdateRolesMutation_FundraiserUpdateRoles;
}

export interface FundraiserUpdateRolesMutationVariables {
  readonly input: FundraiserUpdateRolesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CampaignUpdateCaptainMutation
// ====================================================

export interface CampaignUpdateCaptainMutation_CampaignUpdateCaptain_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface CampaignUpdateCaptainMutation_CampaignUpdateCaptain_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<CampaignUpdateCaptainMutation_CampaignUpdateCaptain_MutationError_errors>;
}

export interface CampaignUpdateCaptainMutation_CampaignUpdateCaptain_Campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
}

export type CampaignUpdateCaptainMutation_CampaignUpdateCaptain =
  | CampaignUpdateCaptainMutation_CampaignUpdateCaptain_MutationError
  | CampaignUpdateCaptainMutation_CampaignUpdateCaptain_Campaign;

export interface CampaignUpdateCaptainMutation {
  /**
   * Change the captain of a team
   */
  readonly CampaignUpdateCaptain: CampaignUpdateCaptainMutation_CampaignUpdateCaptain;
}

export interface CampaignUpdateCaptainMutationVariables {
  readonly input: CampaignUpdateCaptainInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PlayersAssignQuery
// ====================================================

export interface PlayersAssignQuery_Viewer_activeCampaign_filteredGroups {
  readonly __typename: "Group";
  /**
   * Group's ID
   */
  readonly id: string;
  /**
   * Group's name
   */
  readonly name: string;
}

export interface PlayersAssignQuery_Viewer_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * A list of the campaign's groups based on arguments, user
   */
  readonly filteredGroups: ReadonlyArray<PlayersAssignQuery_Viewer_activeCampaign_filteredGroups> | null;
}

export interface PlayersAssignQuery_Viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: PlayersAssignQuery_Viewer_activeCampaign | null;
}

export interface PlayersAssignQuery {
  /**
   * Retrieve details about the logged in user
   */
  readonly Viewer: PlayersAssignQuery_Viewer;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OnboardingReassignMemberMutation
// ====================================================

export interface OnboardingReassignMemberMutation_FundraiserSetGroup_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface OnboardingReassignMemberMutation_FundraiserSetGroup_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<OnboardingReassignMemberMutation_FundraiserSetGroup_MutationError_errors>;
}

export interface OnboardingReassignMemberMutation_FundraiserSetGroup_Fundraiser_player {
  readonly __typename: "TeamPlayer";
  /**
   * Player's ID
   */
  readonly id: string;
  /**
   * Player's name
   */
  readonly name: string;
}

export interface OnboardingReassignMemberMutation_FundraiserSetGroup_Fundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Team member's assigned player
   */
  readonly player: OnboardingReassignMemberMutation_FundraiserSetGroup_Fundraiser_player | null;
}

export type OnboardingReassignMemberMutation_FundraiserSetGroup =
  | OnboardingReassignMemberMutation_FundraiserSetGroup_MutationError
  | OnboardingReassignMemberMutation_FundraiserSetGroup_Fundraiser;

export interface OnboardingReassignMemberMutation {
  /**
   * Set the group for a fundraiser.
   */
  readonly FundraiserSetGroup: OnboardingReassignMemberMutation_FundraiserSetGroup;
}

export interface OnboardingReassignMemberMutationVariables {
  readonly id: string;
  readonly groupId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RemoveMemberModalMutation
// ====================================================

export interface RemoveMemberModalMutation_CampaignLeave_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface RemoveMemberModalMutation_CampaignLeave_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<RemoveMemberModalMutation_CampaignLeave_MutationError_errors>;
}

export interface RemoveMemberModalMutation_CampaignLeave_CampaignLeavePayload_campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
}

export interface RemoveMemberModalMutation_CampaignLeave_CampaignLeavePayload {
  readonly __typename: "CampaignLeavePayload";
  /**
   * The campaign the user left.
   */
  readonly campaign: RemoveMemberModalMutation_CampaignLeave_CampaignLeavePayload_campaign;
}

export type RemoveMemberModalMutation_CampaignLeave =
  | RemoveMemberModalMutation_CampaignLeave_MutationError
  | RemoveMemberModalMutation_CampaignLeave_CampaignLeavePayload;

export interface RemoveMemberModalMutation {
  /**
   * A fundraiser wants to leave a team
   */
  readonly CampaignLeave: RemoveMemberModalMutation_CampaignLeave;
}

export interface RemoveMemberModalMutationVariables {
  readonly campaignId: string;
  readonly userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeletePlayerMutation
// ====================================================

export interface DeletePlayerMutation_DeleteTeamRoster_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface DeletePlayerMutation_DeleteTeamRoster_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<DeletePlayerMutation_DeleteTeamRoster_MutationError_errors>;
}

export interface DeletePlayerMutation_DeleteTeamRoster_DeleteTeamRosterPayload_campaign_filteredGroups_goal {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface DeletePlayerMutation_DeleteTeamRoster_DeleteTeamRosterPayload_campaign_filteredGroups {
  readonly __typename: "Group";
  /**
   * Group's ID
   */
  readonly id: string;
  /**
   * Group's name
   */
  readonly name: string;
  /**
   * Group's total amount raised
   */
  readonly goal: DeletePlayerMutation_DeleteTeamRoster_DeleteTeamRosterPayload_campaign_filteredGroups_goal;
  /**
   * DateTime when a player was deleted
   */
  readonly deletedAt: DateTimeISO | null;
  /**
   * A count of the group's fundraisers
   */
  readonly fundraisersCount: number;
}

export interface DeletePlayerMutation_DeleteTeamRoster_DeleteTeamRosterPayload_campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Does this campaign use the budget app
   */
  readonly hasBudget: boolean;
  /**
   * A list of the campaign's groups based on arguments, user
   */
  readonly filteredGroups: ReadonlyArray<DeletePlayerMutation_DeleteTeamRoster_DeleteTeamRosterPayload_campaign_filteredGroups> | null;
}

export interface DeletePlayerMutation_DeleteTeamRoster_DeleteTeamRosterPayload {
  readonly __typename: "DeleteTeamRosterPayload";
  /**
   * The team.
   */
  readonly campaign: DeletePlayerMutation_DeleteTeamRoster_DeleteTeamRosterPayload_campaign;
}

export type DeletePlayerMutation_DeleteTeamRoster =
  | DeletePlayerMutation_DeleteTeamRoster_MutationError
  | DeletePlayerMutation_DeleteTeamRoster_DeleteTeamRosterPayload;

export interface DeletePlayerMutation {
  /**
   * Delete players from a team
   */
  readonly DeleteTeamRoster: DeletePlayerMutation_DeleteTeamRoster;
}

export interface DeletePlayerMutationVariables {
  readonly input: DeleteTeamRosterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TeamRosterListQuery
// ====================================================

export interface TeamRosterListQuery_Site_teamRoles {
  readonly __typename: "TeamRole";
  /**
   * The role's ID
   */
  readonly id: string;
  /**
   * The role's name
   */
  readonly name: string;
}

export interface TeamRosterListQuery_Site {
  readonly __typename: "Site";
  /**
   * Site's ID
   */
  readonly id: string;
  /**
   * Site's list of team roles available
   */
  readonly teamRoles: ReadonlyArray<TeamRosterListQuery_Site_teamRoles>;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_viewerFundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Is Captain
   */
  readonly isCaptain: boolean | null;
  /**
   * Is Captainable
   */
  readonly isCaptainable: boolean | null;
  /**
   * Is Org Admin
   */
  readonly isOrgAdmin: boolean | null;
  /**
   * Is Captain
   */
  readonly isDivisionManager: boolean | null;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_goal {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_noGroupFundraisers_paginatedFundraisers_edges_node_teamRoles {
  readonly __typename: "TeamRole";
  /**
   * The role's ID
   */
  readonly id: string;
  /**
   * The role's name
   */
  readonly name: string;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_noGroupFundraisers_paginatedFundraisers_edges_node_group {
  readonly __typename: "Group";
  /**
   * Group's ID
   */
  readonly id: string;
  /**
   * Group's name
   */
  readonly name: string;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_noGroupFundraisers_paginatedFundraisers_edges_node {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's full name
   */
  readonly name: string;
  /**
   * Fundraisers's user ID
   */
  readonly userId: string | null;
  /**
   * Fundraiser's joined at timestamp
   */
  readonly joinedAt: DateTimeISO | null;
  /**
   * Is Captain
   */
  readonly isCaptain: boolean | null;
  /**
   * Is Captainable
   */
  readonly isCaptainable: boolean | null;
  /**
   * Is Org Admin
   */
  readonly isOrgAdmin: boolean | null;
  /**
   * Is Captain
   */
  readonly isDivisionManager: boolean | null;
  /**
   * Fundraisers's avatar image URL
   */
  readonly avatarImage: string | null;
  /**
   * The team member's roles
   */
  readonly teamRoles: ReadonlyArray<TeamRosterListQuery_Viewer_activeCampaign_noGroupFundraisers_paginatedFundraisers_edges_node_teamRoles>;
  /**
   * Fundraiser's deactivated at timestamp
   */
  readonly deactivatedAt: DateTimeISO | null;
  /**
   * Fundraisers's group
   */
  readonly group: TeamRosterListQuery_Viewer_activeCampaign_noGroupFundraisers_paginatedFundraisers_edges_node_group | null;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_noGroupFundraisers_paginatedFundraisers_edges {
  readonly __typename: "FundraiserEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: TeamRosterListQuery_Viewer_activeCampaign_noGroupFundraisers_paginatedFundraisers_edges_node | null;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_noGroupFundraisers_paginatedFundraisers {
  readonly __typename: "FundraiserConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<TeamRosterListQuery_Viewer_activeCampaign_noGroupFundraisers_paginatedFundraisers_edges | null> | null;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_noGroupFundraisers {
  readonly __typename: "CampaignNoGroupFundraisers";
  /**
   * No Groups ID
   */
  readonly id: string;
  /**
   * The groups name
   */
  readonly name: string;
  /**
   * A list of the team members that don't belong to a group
   */
  readonly paginatedFundraisers: TeamRosterListQuery_Viewer_activeCampaign_noGroupFundraisers_paginatedFundraisers;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_filteredGroups_goal {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_filteredGroups_paginatedFundraisers_edges_node_teamRoles {
  readonly __typename: "TeamRole";
  /**
   * The role's ID
   */
  readonly id: string;
  /**
   * The role's name
   */
  readonly name: string;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_filteredGroups_paginatedFundraisers_edges_node_group {
  readonly __typename: "Group";
  /**
   * Group's ID
   */
  readonly id: string;
  /**
   * Group's name
   */
  readonly name: string;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_filteredGroups_paginatedFundraisers_edges_node {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's full name
   */
  readonly name: string;
  /**
   * Fundraisers's user ID
   */
  readonly userId: string | null;
  /**
   * Fundraiser's joined at timestamp
   */
  readonly joinedAt: DateTimeISO | null;
  /**
   * Is Captain
   */
  readonly isCaptain: boolean | null;
  /**
   * Is Captainable
   */
  readonly isCaptainable: boolean | null;
  /**
   * Is Org Admin
   */
  readonly isOrgAdmin: boolean | null;
  /**
   * Is Captain
   */
  readonly isDivisionManager: boolean | null;
  /**
   * Fundraisers's avatar image URL
   */
  readonly avatarImage: string | null;
  /**
   * The team member's roles
   */
  readonly teamRoles: ReadonlyArray<TeamRosterListQuery_Viewer_activeCampaign_filteredGroups_paginatedFundraisers_edges_node_teamRoles>;
  /**
   * Fundraiser's deactivated at timestamp
   */
  readonly deactivatedAt: DateTimeISO | null;
  /**
   * Fundraisers's group
   */
  readonly group: TeamRosterListQuery_Viewer_activeCampaign_filteredGroups_paginatedFundraisers_edges_node_group | null;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_filteredGroups_paginatedFundraisers_edges {
  readonly __typename: "FundraiserEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: TeamRosterListQuery_Viewer_activeCampaign_filteredGroups_paginatedFundraisers_edges_node | null;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_filteredGroups_paginatedFundraisers {
  readonly __typename: "FundraiserConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<TeamRosterListQuery_Viewer_activeCampaign_filteredGroups_paginatedFundraisers_edges | null> | null;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_filteredGroups {
  readonly __typename: "Group";
  /**
   * Group's ID
   */
  readonly id: string;
  /**
   * Group's name
   */
  readonly name: string;
  /**
   * Group's total amount raised
   */
  readonly goal: TeamRosterListQuery_Viewer_activeCampaign_filteredGroups_goal;
  /**
   * Supported intents, filtered optionally by user or fundraiser.
   */
  readonly paginatedFundraisers: TeamRosterListQuery_Viewer_activeCampaign_filteredGroups_paginatedFundraisers;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_deletedGroups_goal {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_deletedGroups_paginatedFundraisers_edges_node_teamRoles {
  readonly __typename: "TeamRole";
  /**
   * The role's ID
   */
  readonly id: string;
  /**
   * The role's name
   */
  readonly name: string;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_deletedGroups_paginatedFundraisers_edges_node_group {
  readonly __typename: "Group";
  /**
   * Group's ID
   */
  readonly id: string;
  /**
   * Group's name
   */
  readonly name: string;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_deletedGroups_paginatedFundraisers_edges_node {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's full name
   */
  readonly name: string;
  /**
   * Fundraisers's user ID
   */
  readonly userId: string | null;
  /**
   * Fundraiser's joined at timestamp
   */
  readonly joinedAt: DateTimeISO | null;
  /**
   * Is Captain
   */
  readonly isCaptain: boolean | null;
  /**
   * Is Captainable
   */
  readonly isCaptainable: boolean | null;
  /**
   * Is Org Admin
   */
  readonly isOrgAdmin: boolean | null;
  /**
   * Is Captain
   */
  readonly isDivisionManager: boolean | null;
  /**
   * Fundraisers's avatar image URL
   */
  readonly avatarImage: string | null;
  /**
   * The team member's roles
   */
  readonly teamRoles: ReadonlyArray<TeamRosterListQuery_Viewer_activeCampaign_deletedGroups_paginatedFundraisers_edges_node_teamRoles>;
  /**
   * Fundraiser's deactivated at timestamp
   */
  readonly deactivatedAt: DateTimeISO | null;
  /**
   * Fundraisers's group
   */
  readonly group: TeamRosterListQuery_Viewer_activeCampaign_deletedGroups_paginatedFundraisers_edges_node_group | null;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_deletedGroups_paginatedFundraisers_edges {
  readonly __typename: "FundraiserEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: TeamRosterListQuery_Viewer_activeCampaign_deletedGroups_paginatedFundraisers_edges_node | null;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_deletedGroups_paginatedFundraisers {
  readonly __typename: "FundraiserConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<TeamRosterListQuery_Viewer_activeCampaign_deletedGroups_paginatedFundraisers_edges | null> | null;
}

export interface TeamRosterListQuery_Viewer_activeCampaign_deletedGroups {
  readonly __typename: "Group";
  /**
   * Group's ID
   */
  readonly id: string;
  /**
   * Group's name
   */
  readonly name: string;
  /**
   * Group's total amount raised
   */
  readonly goal: TeamRosterListQuery_Viewer_activeCampaign_deletedGroups_goal;
  /**
   * Supported intents, filtered optionally by user or fundraiser.
   */
  readonly paginatedFundraisers: TeamRosterListQuery_Viewer_activeCampaign_deletedGroups_paginatedFundraisers;
}

export interface TeamRosterListQuery_Viewer_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * The team's player count estimate
   */
  readonly playersCountEstimate: number | null;
  /**
   * Current viewer's fundraiser on campaign
   */
  readonly viewerFundraiser: TeamRosterListQuery_Viewer_activeCampaign_viewerFundraiser | null;
  /**
   * How campaign plans to distribute funds
   */
  readonly fundsDistributionMethod: FundsDistributionMethod | null;
  /**
   * Campaigns's goal amount
   */
  readonly goal: TeamRosterListQuery_Viewer_activeCampaign_goal | null;
  /**
   * Campaign's url on the web
   */
  readonly url: string;
  /**
   * The campaign's fundraisers that don't belong to a group
   */
  readonly noGroupFundraisers: TeamRosterListQuery_Viewer_activeCampaign_noGroupFundraisers;
  /**
   * A list of the campaign's groups based on arguments, user
   */
  readonly filteredGroups: ReadonlyArray<TeamRosterListQuery_Viewer_activeCampaign_filteredGroups> | null;
  /**
   * A list of the campaign's groups which are deleted
   */
  readonly deletedGroups: ReadonlyArray<TeamRosterListQuery_Viewer_activeCampaign_deletedGroups>;
  /**
   * Does this campaign use the budget app
   */
  readonly hasBudget: boolean;
}

export interface TeamRosterListQuery_Viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: TeamRosterListQuery_Viewer_activeCampaign | null;
}

export interface TeamRosterListQuery {
  /**
   * Find a Site by ID
   */
  readonly Site: TeamRosterListQuery_Site | null;
  /**
   * Retrieve details about the logged in user
   */
  readonly Viewer: TeamRosterListQuery_Viewer;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EditPlayerQuery
// ====================================================

export interface EditPlayerQuery_Viewer_activeCampaign_viewerFundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Is Captain
   */
  readonly isCaptain: boolean | null;
}

export interface EditPlayerQuery_Viewer_activeCampaign_goal {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface EditPlayerQuery_Viewer_activeCampaign_filteredGroups_goal {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface EditPlayerQuery_Viewer_activeCampaign_filteredGroups {
  readonly __typename: "Group";
  /**
   * Group's ID
   */
  readonly id: string;
  /**
   * Group's name
   */
  readonly name: string;
  /**
   * Group's total amount raised
   */
  readonly goal: EditPlayerQuery_Viewer_activeCampaign_filteredGroups_goal;
  /**
   * DateTime when a player was deleted
   */
  readonly deletedAt: DateTimeISO | null;
  /**
   * A count of the group's fundraisers
   */
  readonly fundraisersCount: number;
}

export interface EditPlayerQuery_Viewer_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Does this campaign use the budget app
   */
  readonly hasBudget: boolean;
  /**
   * Campaign's url on the web
   */
  readonly url: string;
  /**
   * The team's player count estimate
   */
  readonly playersCountEstimate: number | null;
  /**
   * How campaign plans to distribute funds
   */
  readonly fundsDistributionMethod: FundsDistributionMethod | null;
  /**
   * Current viewer's fundraiser on campaign
   */
  readonly viewerFundraiser: EditPlayerQuery_Viewer_activeCampaign_viewerFundraiser | null;
  /**
   * Campaign's currency
   */
  readonly currency: Currency;
  /**
   * Campaigns's goal amount
   */
  readonly goal: EditPlayerQuery_Viewer_activeCampaign_goal | null;
  /**
   * A list of the campaign's groups based on arguments, user
   */
  readonly filteredGroups: ReadonlyArray<EditPlayerQuery_Viewer_activeCampaign_filteredGroups> | null;
}

export interface EditPlayerQuery_Viewer_teamOnboardingInfo {
  readonly __typename: "TeamOnboardingInfo";
  /**
   * Is the user's onboarding state active?
   */
  readonly isActive: boolean;
}

export interface EditPlayerQuery_Viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: EditPlayerQuery_Viewer_activeCampaign | null;
  /**
   * User's team onboarding info
   */
  readonly teamOnboardingInfo: EditPlayerQuery_Viewer_teamOnboardingInfo;
}

export interface EditPlayerQuery {
  /**
   * Retrieve details about the logged in user
   */
  readonly Viewer: EditPlayerQuery_Viewer;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditPlayerMutation
// ====================================================

export interface EditPlayerMutation_EditTeamRoster_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface EditPlayerMutation_EditTeamRoster_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<EditPlayerMutation_EditTeamRoster_MutationError_errors>;
}

export interface EditPlayerMutation_EditTeamRoster_EditTeamRosterPayload_campaign_filteredGroups_goal {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface EditPlayerMutation_EditTeamRoster_EditTeamRosterPayload_campaign_filteredGroups {
  readonly __typename: "Group";
  /**
   * Group's ID
   */
  readonly id: string;
  /**
   * Group's name
   */
  readonly name: string;
  /**
   * Group's total amount raised
   */
  readonly goal: EditPlayerMutation_EditTeamRoster_EditTeamRosterPayload_campaign_filteredGroups_goal;
  /**
   * DateTime when a player was deleted
   */
  readonly deletedAt: DateTimeISO | null;
  /**
   * A count of the group's fundraisers
   */
  readonly fundraisersCount: number;
}

export interface EditPlayerMutation_EditTeamRoster_EditTeamRosterPayload_campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Does this campaign use the budget app
   */
  readonly hasBudget: boolean;
  /**
   * A list of the campaign's groups based on arguments, user
   */
  readonly filteredGroups: ReadonlyArray<EditPlayerMutation_EditTeamRoster_EditTeamRosterPayload_campaign_filteredGroups> | null;
}

export interface EditPlayerMutation_EditTeamRoster_EditTeamRosterPayload_viewer_teamOnboardingInfo_onboardingSteps {
  readonly __typename: "TeamOnboardingStepInfo";
  /**
   * The step's ID
   */
  readonly id: string;
  /**
   * The step's name
   */
  readonly name: string;
}

export interface EditPlayerMutation_EditTeamRoster_EditTeamRosterPayload_viewer_teamOnboardingInfo {
  readonly __typename: "TeamOnboardingInfo";
  /**
   * Is the user's onboarding state active?
   */
  readonly isOnboardingActive: boolean;
  /**
   * The type of onboarding
   */
  readonly type: TeamOnboardingType | null;
  /**
   * The user's current onboarding step
   */
  readonly currentStep: TeamOnboardingStep | null;
  /**
   * The user's current checklist item to complete
   */
  readonly currentChecklistItem: TeamOnboardingChecklistItem | null;
  /**
   * The user's list of onboarding step
   */
  readonly onboardingSteps: ReadonlyArray<EditPlayerMutation_EditTeamRoster_EditTeamRosterPayload_viewer_teamOnboardingInfo_onboardingSteps>;
}

export interface EditPlayerMutation_EditTeamRoster_EditTeamRosterPayload_viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * User's team onboarding info
   */
  readonly teamOnboardingInfo: EditPlayerMutation_EditTeamRoster_EditTeamRosterPayload_viewer_teamOnboardingInfo;
}

export interface EditPlayerMutation_EditTeamRoster_EditTeamRosterPayload {
  readonly __typename: "EditTeamRosterPayload";
  /**
   * The team.
   */
  readonly campaign: EditPlayerMutation_EditTeamRoster_EditTeamRosterPayload_campaign;
  /**
   * The viewer.
   */
  readonly viewer: EditPlayerMutation_EditTeamRoster_EditTeamRosterPayload_viewer;
}

export type EditPlayerMutation_EditTeamRoster =
  | EditPlayerMutation_EditTeamRoster_MutationError
  | EditPlayerMutation_EditTeamRoster_EditTeamRosterPayload;

export interface EditPlayerMutation {
  /**
   * Edit team roster of players for a team
   */
  readonly EditTeamRoster: EditPlayerMutation_EditTeamRoster;
}

export interface EditPlayerMutationVariables {
  readonly input: EditTeamRosterInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DefaultApolloProviderCampaignIDQuery
// ====================================================

export interface DefaultApolloProviderCampaignIDQuery_Viewer_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
}

export interface DefaultApolloProviderCampaignIDQuery_Viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: DefaultApolloProviderCampaignIDQuery_Viewer_activeCampaign | null;
}

export interface DefaultApolloProviderCampaignIDQuery {
  /**
   * Retrieve details about the logged in user
   */
  readonly Viewer: DefaultApolloProviderCampaignIDQuery_Viewer;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CampaignBeneficiaryQuery
// ====================================================

export interface CampaignBeneficiaryQuery_Campaign_division {
  readonly __typename: "Organization";
  /**
   * Organization's ID
   */
  readonly id: string;
  /**
   * Organization's name
   */
  readonly name: string;
}

export interface CampaignBeneficiaryQuery_Campaign_payments_edges_node_amount {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface CampaignBeneficiaryQuery_Campaign_payments_edges_node {
  readonly __typename: "Payment";
  /**
   * Payment's ID
   */
  readonly id: string;
  /**
   * Payment state
   */
  readonly state: string;
  /**
   * Payment amount
   */
  readonly amount: CampaignBeneficiaryQuery_Campaign_payments_edges_node_amount;
}

export interface CampaignBeneficiaryQuery_Campaign_payments_edges {
  readonly __typename: "PaymentEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: CampaignBeneficiaryQuery_Campaign_payments_edges_node | null;
}

export interface CampaignBeneficiaryQuery_Campaign_payments {
  readonly __typename: "PaymentConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<CampaignBeneficiaryQuery_Campaign_payments_edges | null> | null;
}

export interface CampaignBeneficiaryQuery_Campaign_category {
  readonly __typename: "CampaignCategory";
  /**
   * Category's ID
   */
  readonly id: string;
}

export interface CampaignBeneficiaryQuery_Campaign_beneficiary {
  readonly __typename: "Beneficiary";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * City
   */
  readonly city: string | null;
  /**
   * The full name of the person directly responsible for the management of the team/funds.
   */
  readonly contactName: string | null;
  /**
   * The registered date of birth of the beneficiary for team type INDIVIDUAL
   */
  readonly dateOfBirth: string | null;
  /**
   * Email address of the person directly responsible for the management of the team/funds.
   */
  readonly email: string | null;
  /**
   * The registered first name of the beneficiary for team type INDIVIDUAL
   */
  readonly firstName: string | null;
  /**
   * Hyperwallet user token
   */
  readonly hwUserToken: string | null;
  /**
   * Hyperwallet user status
   */
  readonly hwUserStatus: string | null;
  /**
   * Hyperwallet user verification status
   */
  readonly hwUserVerificationStatus: string | null;
  /**
   * Hyperwallet account information
   */
  readonly hwAccountInfo: string | null;
  /**
   * Hyperwallet account token
   */
  readonly hwAccountToken: string | null;
  /**
   * The registered last name of the beneficiary for team type INDIVIDUAL
   */
  readonly lastName: string | null;
  /**
   * The registered legal name of the beneficiary. The name that will appear on the Cheque.
   */
  readonly legalName: string | null;
  /**
   * Payment type that will be used.
   */
  readonly paymentType: PaymentType | null;
  /**
   * Phone number of the person directly responsible for the management of the team/funds.
   */
  readonly phoneNumber: string | null;
  /**
   * Zip/Postal Code
   */
  readonly postalCode: string | null;
  /**
   * State/Province
   */
  readonly province: string | null;
  /**
   * Street number and street name of the beneficiary.
   */
  readonly street1: string | null;
  /**
   * Apt/Unit/Suite number of the beneficiary.
   */
  readonly street2: string | null;
  /**
   * EIN number or Charitable Registration Number.
   */
  readonly taxId: string | null;
  /**
   * The official/real name of the team.
   */
  readonly realTeamName: string | null;
  /**
   * The unofficial/common name of the team.
   */
  readonly teamName: string | null;
  /**
   * A priliminary understanding of the team, used to understand the intended destination of the funds.
   */
  readonly teamType: BeneficiaryTeamType | null;
  /**
   * The official website of the beneficiary.
   */
  readonly website: string | null;
  /**
   * Plaid account id
   */
  readonly plaidAccountId: string | null;
  /**
   * Plaid account information
   */
  readonly plaidAccountInfo: string | null;
  /**
   * Google places id
   */
  readonly googlePlacesId: string | null;
  /**
   * Whether or not the beneficiary has been approved.
   */
  readonly approved: boolean | null;
}

export interface CampaignBeneficiaryQuery_Campaign_organization {
  readonly __typename: "Organization";
  /**
   * Organization's ID
   */
  readonly id: string;
  /**
   * Organization's name
   */
  readonly name: string;
  /**
   * City
   */
  readonly city: string;
  /**
   * The country in which the organization is located.
   */
  readonly country: Country;
  /**
   * The legal name of the organization.
   */
  readonly legalName: string;
  /**
   * Zip/Postal Code
   */
  readonly postalCode: string;
  /**
   * State/Province
   */
  readonly province: string;
  /**
   * Street number and street name of the organization.
   */
  readonly street1: string;
}

export interface CampaignBeneficiaryQuery_Campaign_amountWithdrawnBreakdown_available {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Cents integer value, example: 10023
   */
  readonly centsAmount: number;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface CampaignBeneficiaryQuery_Campaign_amountWithdrawnBreakdown_withdrawn {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Cents integer value, example: 10023
   */
  readonly centsAmount: number;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface CampaignBeneficiaryQuery_Campaign_amountWithdrawnBreakdown_pending {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Cents integer value, example: 10023
   */
  readonly centsAmount: number;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface CampaignBeneficiaryQuery_Campaign_amountWithdrawnBreakdown_fees {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Cents integer value, example: 10023
   */
  readonly centsAmount: number;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface CampaignBeneficiaryQuery_Campaign_amountWithdrawnBreakdown {
  readonly __typename: "AmountWithdrawnBreakdown";
  /**
   * Money available
   */
  readonly available: CampaignBeneficiaryQuery_Campaign_amountWithdrawnBreakdown_available;
  /**
   * Money withdrawn
   */
  readonly withdrawn: CampaignBeneficiaryQuery_Campaign_amountWithdrawnBreakdown_withdrawn;
  /**
   * Money held
   */
  readonly pending: CampaignBeneficiaryQuery_Campaign_amountWithdrawnBreakdown_pending;
  /**
   * Fees accrued
   */
  readonly fees: CampaignBeneficiaryQuery_Campaign_amountWithdrawnBreakdown_fees;
}

export interface CampaignBeneficiaryQuery_Campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * Campaign's currency
   */
  readonly currency: Currency;
  /**
   * Beneficiary approval status
   */
  readonly status: BeneficiaryStatus | null;
  /**
   * The campaign's review comment
   */
  readonly reviewComment: string | null;
  /**
   * Plaid link token
   */
  readonly plaidLinkToken: string | null;
  /**
   * Division's revenue split
   */
  readonly divisionRevSplit: number | null;
  /**
   * Campaign's category
   */
  readonly division: CampaignBeneficiaryQuery_Campaign_division | null;
  /**
   * See payments made to this campaign
   */
  readonly payments: CampaignBeneficiaryQuery_Campaign_payments;
  /**
   * Campaign's category
   */
  readonly category: CampaignBeneficiaryQuery_Campaign_category;
  /**
   * Does the campaign handle it's money?
   */
  readonly handlesMoney: boolean;
  /**
   * Campaign's country
   */
  readonly country: Country | null;
  /**
   * Campaign's beneficiary
   */
  readonly beneficiary: CampaignBeneficiaryQuery_Campaign_beneficiary | null;
  /**
   * Campaign's category
   */
  readonly organization: CampaignBeneficiaryQuery_Campaign_organization | null;
  /**
   * a breakdown of the funds withdrawn by this campaign
   */
  readonly amountWithdrawnBreakdown: CampaignBeneficiaryQuery_Campaign_amountWithdrawnBreakdown;
}

export interface CampaignBeneficiaryQuery {
  /**
   * Find a Campaign by ID
   */
  readonly Campaign: CampaignBeneficiaryQuery_Campaign | null;
}

export interface CampaignBeneficiaryQueryVariables {
  readonly id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CampaignBeneficiaryCreateMutation
// ====================================================

export interface CampaignBeneficiaryCreateMutation_BeneficiaryCreate_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface CampaignBeneficiaryCreateMutation_BeneficiaryCreate_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<CampaignBeneficiaryCreateMutation_BeneficiaryCreate_MutationError_errors>;
}

export interface CampaignBeneficiaryCreateMutation_BeneficiaryCreate_Beneficiary {
  readonly __typename: "Beneficiary";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Hyperwallet user token
   */
  readonly hwUserToken: string | null;
  /**
   * Hyperwallet user status
   */
  readonly hwUserStatus: string | null;
  /**
   * Hyperwallet user verification status
   */
  readonly hwUserVerificationStatus: string | null;
  /**
   * Plaid account information
   */
  readonly plaidAccountInfo: string | null;
  /**
   * Payment type that will be used.
   */
  readonly paymentType: PaymentType | null;
}

export type CampaignBeneficiaryCreateMutation_BeneficiaryCreate =
  | CampaignBeneficiaryCreateMutation_BeneficiaryCreate_MutationError
  | CampaignBeneficiaryCreateMutation_BeneficiaryCreate_Beneficiary;

export interface CampaignBeneficiaryCreateMutation {
  /**
   * Create a new beneficiary.
   */
  readonly BeneficiaryCreate: CampaignBeneficiaryCreateMutation_BeneficiaryCreate;
}

export interface CampaignBeneficiaryCreateMutationVariables {
  readonly input: BeneficiaryCreateInput;
  readonly organization?: BeneficiaryOrganizationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CampaignBeneficiaryUpdateMutation
// ====================================================

export interface CampaignBeneficiaryUpdateMutation_BeneficiaryUpdate_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface CampaignBeneficiaryUpdateMutation_BeneficiaryUpdate_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<CampaignBeneficiaryUpdateMutation_BeneficiaryUpdate_MutationError_errors>;
}

export interface CampaignBeneficiaryUpdateMutation_BeneficiaryUpdate_Beneficiary {
  readonly __typename: "Beneficiary";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Hyperwallet user token
   */
  readonly hwUserToken: string | null;
  /**
   * Hyperwallet user status
   */
  readonly hwUserStatus: string | null;
  /**
   * Hyperwallet user verification status
   */
  readonly hwUserVerificationStatus: string | null;
  /**
   * Plaid account information
   */
  readonly plaidAccountInfo: string | null;
  /**
   * Payment type that will be used.
   */
  readonly paymentType: PaymentType | null;
  /**
   * The registered legal name of the beneficiary. The name that will appear on the Cheque.
   */
  readonly legalName: string | null;
}

export type CampaignBeneficiaryUpdateMutation_BeneficiaryUpdate =
  | CampaignBeneficiaryUpdateMutation_BeneficiaryUpdate_MutationError
  | CampaignBeneficiaryUpdateMutation_BeneficiaryUpdate_Beneficiary;

export interface CampaignBeneficiaryUpdateMutation {
  /**
   * Update a beneficiary.
   */
  readonly BeneficiaryUpdate: CampaignBeneficiaryUpdateMutation_BeneficiaryUpdate;
}

export interface CampaignBeneficiaryUpdateMutationVariables {
  readonly input: BeneficiaryUpdateInputType;
  readonly organization?: BeneficiaryOrganizationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CampaignPaymentWithdrawalMutation
// ====================================================

export interface CampaignPaymentWithdrawalMutation_CampaignPaymentWithdraw_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface CampaignPaymentWithdrawalMutation_CampaignPaymentWithdraw_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<CampaignPaymentWithdrawalMutation_CampaignPaymentWithdraw_MutationError_errors>;
}

export interface CampaignPaymentWithdrawalMutation_CampaignPaymentWithdraw_CampaignPaymentWithdrawPayload {
  readonly __typename: "CampaignPaymentWithdrawPayload";
  /**
   * Withdraw request successful?
   */
  readonly success: boolean;
}

export type CampaignPaymentWithdrawalMutation_CampaignPaymentWithdraw =
  | CampaignPaymentWithdrawalMutation_CampaignPaymentWithdraw_MutationError
  | CampaignPaymentWithdrawalMutation_CampaignPaymentWithdraw_CampaignPaymentWithdrawPayload;

export interface CampaignPaymentWithdrawalMutation {
  /**
   * Withdraw campaign funds.
   */
  readonly CampaignPaymentWithdraw: CampaignPaymentWithdrawalMutation_CampaignPaymentWithdraw;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgSlugQuery
// ====================================================

export interface OrgSlugQuery_Organization {
  readonly __typename: "Organization";
  /**
   * Organization's ID
   */
  readonly id: string;
  /**
   * Organization's unique slug
   */
  readonly slug: string;
}

export interface OrgSlugQuery {
  /**
   * Find an Organization by ID or Slug
   */
  readonly Organization: OrgSlugQuery_Organization | null;
}

export interface OrgSlugQueryVariables {
  readonly joinCode?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllBrandsPDFCampaignQuery
// ====================================================

export interface AllBrandsPDFCampaignQuery_Campaign_category {
  readonly __typename: "CampaignCategory";
  /**
   * Category's ID
   */
  readonly id: string;
  /**
   * Category's name
   */
  readonly name: string;
}

export interface AllBrandsPDFCampaignQuery_Campaign_teamPicks_edges_node_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
}

export interface AllBrandsPDFCampaignQuery_Campaign_teamPicks_edges_node {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's images
   */
  readonly images: AllBrandsPDFCampaignQuery_Campaign_teamPicks_edges_node_images;
}

export interface AllBrandsPDFCampaignQuery_Campaign_teamPicks_edges {
  readonly __typename: "BrandEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: AllBrandsPDFCampaignQuery_Campaign_teamPicks_edges_node | null;
}

export interface AllBrandsPDFCampaignQuery_Campaign_teamPicks {
  readonly __typename: "BrandConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<AllBrandsPDFCampaignQuery_Campaign_teamPicks_edges | null> | null;
}

export interface AllBrandsPDFCampaignQuery_Campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * Campaign's category
   */
  readonly category: AllBrandsPDFCampaignQuery_Campaign_category;
  /**
   * Team's selected favorite brands
   */
  readonly teamPicks: AllBrandsPDFCampaignQuery_Campaign_teamPicks;
}

export interface AllBrandsPDFCampaignQuery_Collection_brands_edges_node_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
}

export interface AllBrandsPDFCampaignQuery_Collection_brands_edges_node_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
}

export interface AllBrandsPDFCampaignQuery_Collection_brands_edges_node {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Brand's images
   */
  readonly images: AllBrandsPDFCampaignQuery_Collection_brands_edges_node_images;
  readonly maxGiveLink: AllBrandsPDFCampaignQuery_Collection_brands_edges_node_maxGiveLink | null;
}

export interface AllBrandsPDFCampaignQuery_Collection_brands_edges {
  readonly __typename: "BrandEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: AllBrandsPDFCampaignQuery_Collection_brands_edges_node | null;
}

export interface AllBrandsPDFCampaignQuery_Collection_brands {
  readonly __typename: "BrandConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<AllBrandsPDFCampaignQuery_Collection_brands_edges | null> | null;
}

export interface AllBrandsPDFCampaignQuery_Collection {
  readonly __typename: "Collection";
  /**
   * Collection's ID
   */
  readonly id: string;
  /**
   * A paginated list of the collection's brands
   */
  readonly brands: AllBrandsPDFCampaignQuery_Collection_brands;
}

export interface AllBrandsPDFCampaignQuery {
  /**
   * Find a Campaign by ID
   */
  readonly Campaign: AllBrandsPDFCampaignQuery_Campaign | null;
  /**
   * Find a Collection by ID
   */
  readonly Collection: AllBrandsPDFCampaignQuery_Collection | null;
}

export interface AllBrandsPDFCampaignQueryVariables {
  readonly collectionId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllBrandsPDFBrandsQuery
// ====================================================

export interface AllBrandsPDFBrandsQuery_BrandDirectory_pageInfo {
  readonly __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  readonly hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  readonly endCursor: string | null;
}

export interface AllBrandsPDFBrandsQuery_BrandDirectory_edges_node_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
}

export interface AllBrandsPDFBrandsQuery_BrandDirectory_edges_node_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
}

export interface AllBrandsPDFBrandsQuery_BrandDirectory_edges_node {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Brand's images
   */
  readonly images: AllBrandsPDFBrandsQuery_BrandDirectory_edges_node_images;
  readonly maxGiveLink: AllBrandsPDFBrandsQuery_BrandDirectory_edges_node_maxGiveLink | null;
}

export interface AllBrandsPDFBrandsQuery_BrandDirectory_edges {
  readonly __typename: "BrandEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: AllBrandsPDFBrandsQuery_BrandDirectory_edges_node | null;
}

export interface AllBrandsPDFBrandsQuery_BrandDirectory {
  readonly __typename: "BrandConnection";
  /**
   * Information to aid in pagination.
   */
  readonly pageInfo: AllBrandsPDFBrandsQuery_BrandDirectory_pageInfo;
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<AllBrandsPDFBrandsQuery_BrandDirectory_edges | null> | null;
}

export interface AllBrandsPDFBrandsQuery {
  /**
   * Find brands in a directory style listing
   */
  readonly BrandDirectory: AllBrandsPDFBrandsQuery_BrandDirectory;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FAQPDFQuery
// ====================================================

export interface FAQPDFQuery_Campaign_teamPicks_edges_node {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
}

export interface FAQPDFQuery_Campaign_teamPicks_edges {
  readonly __typename: "BrandEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: FAQPDFQuery_Campaign_teamPicks_edges_node | null;
}

export interface FAQPDFQuery_Campaign_teamPicks {
  readonly __typename: "BrandConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<FAQPDFQuery_Campaign_teamPicks_edges | null> | null;
}

export interface FAQPDFQuery_Campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * Team's selected favorite brands
   */
  readonly teamPicks: FAQPDFQuery_Campaign_teamPicks;
}

export interface FAQPDFQuery {
  /**
   * Find a Campaign by ID
   */
  readonly Campaign: FAQPDFQuery_Campaign | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HandoutPDFQuery
// ====================================================

export interface HandoutPDFQuery_Campaign_category {
  readonly __typename: "CampaignCategory";
  /**
   * Category's ID
   */
  readonly id: string;
  /**
   * Category's name
   */
  readonly name: string;
}

export interface HandoutPDFQuery_Campaign_goal {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface HandoutPDFQuery_Campaign_defaultPlayerGoal {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface HandoutPDFQuery_Campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * Campaign's currency
   */
  readonly currency: Currency;
  /**
   * Campaign's category
   */
  readonly category: HandoutPDFQuery_Campaign_category;
  /**
   * Campaigns's description
   */
  readonly description: string | null;
  /**
   * Campaigns's goal amount
   */
  readonly goal: HandoutPDFQuery_Campaign_goal | null;
  /**
   * The team's default player goal amount.
   */
  readonly defaultPlayerGoal: HandoutPDFQuery_Campaign_defaultPlayerGoal | null;
  /**
   * How campaign plans to distribute funds
   */
  readonly fundsDistributionMethod: FundsDistributionMethod | null;
  /**
   * Campaign's photo image URL
   */
  readonly photoImage: string | null;
}

export interface HandoutPDFQuery {
  /**
   * Find a Campaign by ID
   */
  readonly Campaign: HandoutPDFQuery_Campaign | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TailwindAvatarFragment
// ====================================================

export interface TailwindAvatarFragment_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaign's photo image URL
   */
  readonly photoImage: string | null;
}

export interface TailwindAvatarFragment {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * The url of the user's avatar
   */
  readonly avatarImage: string | null;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: TailwindAvatarFragment_activeCampaign | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AvatarGroupFragment
// ====================================================

export interface AvatarGroupFragment_activeCampaign_amountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface AvatarGroupFragment_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * Campaign's photo image URL
   */
  readonly photoImage: string | null;
  /**
   * Campaigns amount raised
   */
  readonly amountRaised: AvatarGroupFragment_activeCampaign_amountRaised;
  /**
   * Days or hours left for a campaign
   */
  readonly daysOrHoursLeftLabel: string | null;
}

export interface AvatarGroupFragment {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * The url of the user's avatar
   */
  readonly avatarImage: string | null;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: AvatarGroupFragment_activeCampaign | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BrandCircleFragment
// ====================================================

export interface BrandCircleFragment_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface BrandCircleFragment {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Brand's images
   */
  readonly images: BrandCircleFragment_images;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: BrandTileFragment
// ====================================================

export interface BrandTileFragment_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Link has a positive give back
   */
  readonly positiveGiveAmount: boolean;
  /**
   * Link's baseline give amount when promo is present
   */
  readonly baselineGiveAmount: string;
}

export interface BrandTileFragment_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface BrandTileFragment {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  readonly maxGiveLink: BrandTileFragment_maxGiveLink | null;
  /**
   * Brand's images
   */
  readonly images: BrandTileFragment_images;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CampaignHeroFragment
// ====================================================

export interface CampaignHeroFragment_amountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface CampaignHeroFragment_goal {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface CampaignHeroFragment_category {
  readonly __typename: "CampaignCategory";
  /**
   * Category's ID
   */
  readonly id: string;
  /**
   * Category's name
   */
  readonly name: string;
}

export interface CampaignHeroFragment_joinedFundraisers {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's full name
   */
  readonly name: string;
  /**
   * Is Captain
   */
  readonly isCaptain: boolean | null;
  /**
   * Is Captainable
   */
  readonly isCaptainable: boolean | null;
}

export interface CampaignHeroFragment_viewerFundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's avatar image URL
   */
  readonly avatarImage: string | null;
}

export interface CampaignHeroFragment_organization {
  readonly __typename: "Organization";
  /**
   * Organization's ID
   */
  readonly id: string;
  /**
   * Organization's name
   */
  readonly name: string;
}

export interface CampaignHeroFragment {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * Campaign's join code
   */
  readonly accessCode: string;
  /**
   * Campaigns amount raised
   */
  readonly amountRaised: CampaignHeroFragment_amountRaised;
  /**
   * Campaigns's goal amount
   */
  readonly goal: CampaignHeroFragment_goal | null;
  /**
   * Days or hours left for a campaign
   */
  readonly daysOrHoursLeftLabel: string | null;
  /**
   * Campaigns's description
   */
  readonly description: string | null;
  /**
   * Campaign's city
   */
  readonly city: string | null;
  /**
   * Campaign's country
   */
  readonly country: Country | null;
  /**
   * Campaign's category
   */
  readonly category: CampaignHeroFragment_category;
  /**
   * A list of just the joined fundraisers
   */
  readonly joinedFundraisers: ReadonlyArray<CampaignHeroFragment_joinedFundraisers>;
  /**
   * Campaign's photo image URL
   */
  readonly photoImage: string | null;
  /**
   * Current viewer's fundraiser on campaign
   */
  readonly viewerFundraiser: CampaignHeroFragment_viewerFundraiser | null;
  /**
   * Count of joined fundraisers
   */
  readonly joinedFundraisersCount: number;
  /**
   * Campaign's category
   */
  readonly organization: CampaignHeroFragment_organization | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CampaignListItemFragment
// ====================================================

export interface CampaignListItemFragment_amountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface CampaignListItemFragment {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * Campaign's photo image URL
   */
  readonly photoImage: string | null;
  /**
   * Campaigns amount raised
   */
  readonly amountRaised: CampaignListItemFragment_amountRaised;
  /**
   * Days or hours left for a campaign
   */
  readonly daysOrHoursLeftLabel: string | null;
  /**
   * Campaign's url on the web
   */
  readonly url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ContinuityReasonFragment
// ====================================================

export interface ContinuityReasonFragment_continuityPrevCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's name
   */
  readonly name: string;
}

export interface ContinuityReasonFragment {
  readonly __typename: "PersonalAccount";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Reason the viewer is in continuity
   */
  readonly continuityReason: ContinuityReasonEnum | null;
  /**
   * The campaign the viewer was on prior to entering continuity
   */
  readonly continuityPrevCampaign: ContinuityReasonFragment_continuityPrevCampaign | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HeaderFragment
// ====================================================

export interface HeaderFragment_totalAmountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface HeaderFragment_activeCampaign_viewerFundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Is Captain
   */
  readonly isCaptain: boolean | null;
  /**
   * Is Captainable
   */
  readonly isCaptainable: boolean | null;
}

export interface HeaderFragment_activeCampaign_amountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface HeaderFragment_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaign's url on the web
   */
  readonly url: string;
  /**
   * Current viewer's fundraiser on campaign
   */
  readonly viewerFundraiser: HeaderFragment_activeCampaign_viewerFundraiser | null;
  /**
   * Does this campaign use the budget app
   */
  readonly hasBudget: boolean;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * Campaign's photo image URL
   */
  readonly photoImage: string | null;
  /**
   * Campaigns amount raised
   */
  readonly amountRaised: HeaderFragment_activeCampaign_amountRaised;
  /**
   * Days or hours left for a campaign
   */
  readonly daysOrHoursLeftLabel: string | null;
}

export interface HeaderFragment_personalAccount_transferrableFunds_availableAmount {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface HeaderFragment_personalAccount_transferrableFunds {
  readonly __typename: "TransferrableFunds";
  /**
   * Available amount
   */
  readonly availableAmount: HeaderFragment_personalAccount_transferrableFunds_availableAmount;
}

export interface HeaderFragment_personalAccount {
  readonly __typename: "PersonalAccount";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Reason the viewer is in continuity
   */
  readonly continuityReason: ContinuityReasonEnum | null;
  /**
   * Transferrable funds
   */
  readonly transferrableFunds: HeaderFragment_personalAccount_transferrableFunds;
}

export interface HeaderFragment {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Number of unseen notifications for this user
   */
  readonly unseenNotificationCount: number;
  /**
   * The url of the user's avatar
   */
  readonly avatarImage: string | null;
  /**
   * viewer total amount raised
   */
  readonly totalAmountRaised: HeaderFragment_totalAmountRaised;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: HeaderFragment_activeCampaign | null;
  /**
   * User's full name
   */
  readonly name: string | null;
  /**
   * Is the user an admin?
   */
  readonly isAdmin: boolean;
  /**
   * viewer merchant admin?
   */
  readonly isMerchantAdmin: boolean;
  /**
   * viewer organization admin?
   */
  readonly isOrgAdmin: boolean;
  /**
   * viewer site admin?
   */
  readonly isSiteAdmin: boolean;
  /**
   * User created date
   */
  readonly joinedAt: DateTimeISO;
  /**
   * Total shopping trips a viewer has made
   */
  readonly shoppingTripsCount: number;
  /**
   * Total teams the viewer has been a part of
   */
  readonly teamsCount: number;
  /**
   * Information for the viewer's personal account
   */
  readonly personalAccount: HeaderFragment_personalAccount;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ResourceLibraryModalFragment
// ====================================================

export interface ResourceLibraryModalFragment_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaign's join code
   */
  readonly accessCode: string;
  /**
   * Campaign's country
   */
  readonly country: Country | null;
}

export interface ResourceLibraryModalFragment {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: ResourceLibraryModalFragment_activeCampaign | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: InviteOptionsFragment
// ====================================================

export interface InviteOptionsFragment_activeCampaign_viewerFundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Is Captain
   */
  readonly isCaptain: boolean | null;
}

export interface InviteOptionsFragment_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Current viewer's fundraiser on campaign
   */
  readonly viewerFundraiser: InviteOptionsFragment_activeCampaign_viewerFundraiser | null;
  /**
   * URL to invite people with join the team.
   */
  readonly joinUrl: string;
  /**
   * Campaign's join code
   */
  readonly accessCode: string;
  /**
   * Campaign's country
   */
  readonly country: Country | null;
}

export interface InviteOptionsFragment {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: InviteOptionsFragment_activeCampaign | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NotificationItemFragment
// ====================================================

export interface NotificationItemFragment_activities_actor {
  readonly __typename: "ActivityActor";
  /**
   * Actor's ID
   */
  readonly id: string | null;
  /**
   * Actor's name
   */
  readonly name: string | null;
  /**
   * Actor's avatar image URL
   */
  readonly avatarImage: string | null;
}

export interface NotificationItemFragment_activities_campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * Campaign's url on the web
   */
  readonly url: string;
}

export interface NotificationItemFragment_activities {
  readonly __typename: "Activity";
  /**
   * Activity's ID
   */
  readonly id: string;
  /**
   * Activity's time
   */
  readonly time: DateTimeISO;
  /**
   * Activity's destination Activity ID
   */
  readonly destinationActivityId: number;
  /**
   * Activity's actor
   */
  readonly actor: NotificationItemFragment_activities_actor;
  /**
   * Activity's campaign if it exists
   */
  readonly campaign: NotificationItemFragment_activities_campaign | null;
}

export interface NotificationItemFragment {
  readonly __typename: "Notification";
  /**
   * Notification's unique ID
   */
  readonly id: string;
  /**
   * Is the notification read?
   */
  readonly isRead: boolean;
  /**
   * If notification is for user's personal team
   */
  readonly isPersonal: boolean;
  /**
   * Notification's time
   */
  readonly time: DateTimeISO;
  /**
   * Notification's title
   */
  readonly title: string;
  /**
   * Notification's description
   */
  readonly description: string | null;
  /**
   * Campaign IDs targeted by this notification
   */
  readonly campaignIds: ReadonlyArray<string>;
  /**
   * A list of the notification's activities
   */
  readonly activities: ReadonlyArray<NotificationItemFragment_activities>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PersonalAccountHeroFragment
// ====================================================

export interface PersonalAccountHeroFragment_personalAccount_transferrableFunds_availableAmount {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface PersonalAccountHeroFragment_personalAccount_transferrableFunds {
  readonly __typename: "TransferrableFunds";
  /**
   * Available amount
   */
  readonly availableAmount: PersonalAccountHeroFragment_personalAccount_transferrableFunds_availableAmount;
}

export interface PersonalAccountHeroFragment_personalAccount {
  readonly __typename: "PersonalAccount";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Transferrable funds
   */
  readonly transferrableFunds: PersonalAccountHeroFragment_personalAccount_transferrableFunds;
}

export interface PersonalAccountHeroFragment {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * User's full name
   */
  readonly name: string | null;
  /**
   * Information for the viewer's personal account
   */
  readonly personalAccount: PersonalAccountHeroFragment_personalAccount;
  /**
   * The url of the user's avatar
   */
  readonly avatarImage: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ResourceLibraryFragment
// ====================================================

export interface ResourceLibraryFragment_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaign's join code
   */
  readonly accessCode: string;
  /**
   * Campaign's country
   */
  readonly country: Country | null;
}

export interface ResourceLibraryFragment {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: ResourceLibraryFragment_activeCampaign | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SmallBrandsListFragment
// ====================================================

export interface SmallBrandsListFragment_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface SmallBrandsListFragment {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Brand's images
   */
  readonly images: SmallBrandsListFragment_images;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllBrandsResourceCampaignFragment
// ====================================================

export interface AllBrandsResourceCampaignFragment_category {
  readonly __typename: "CampaignCategory";
  /**
   * Category's ID
   */
  readonly id: string;
  /**
   * Category's name
   */
  readonly name: string;
}

export interface AllBrandsResourceCampaignFragment_teamPicks_edges_node_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
}

export interface AllBrandsResourceCampaignFragment_teamPicks_edges_node {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's images
   */
  readonly images: AllBrandsResourceCampaignFragment_teamPicks_edges_node_images;
}

export interface AllBrandsResourceCampaignFragment_teamPicks_edges {
  readonly __typename: "BrandEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: AllBrandsResourceCampaignFragment_teamPicks_edges_node | null;
}

export interface AllBrandsResourceCampaignFragment_teamPicks {
  readonly __typename: "BrandConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<AllBrandsResourceCampaignFragment_teamPicks_edges | null> | null;
}

export interface AllBrandsResourceCampaignFragment {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaign's category
   */
  readonly category: AllBrandsResourceCampaignFragment_category;
  /**
   * Team's selected favorite brands
   */
  readonly teamPicks: AllBrandsResourceCampaignFragment_teamPicks;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: AllBrandsResourceBrandFragment
// ====================================================

export interface AllBrandsResourceBrandFragment_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
}

export interface AllBrandsResourceBrandFragment_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
}

export interface AllBrandsResourceBrandFragment {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Brand's images
   */
  readonly images: AllBrandsResourceBrandFragment_images;
  readonly maxGiveLink: AllBrandsResourceBrandFragment_maxGiveLink | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FaqResourceFragment
// ====================================================

export interface FaqResourceFragment_teamPicks_edges_node {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
}

export interface FaqResourceFragment_teamPicks_edges {
  readonly __typename: "BrandEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: FaqResourceFragment_teamPicks_edges_node | null;
}

export interface FaqResourceFragment_teamPicks {
  readonly __typename: "BrandConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<FaqResourceFragment_teamPicks_edges | null> | null;
}

export interface FaqResourceFragment {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Team's selected favorite brands
   */
  readonly teamPicks: FaqResourceFragment_teamPicks;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: HandoutResourceFragment
// ====================================================

export interface HandoutResourceFragment_category {
  readonly __typename: "CampaignCategory";
  /**
   * Category's ID
   */
  readonly id: string;
  /**
   * Category's name
   */
  readonly name: string;
}

export interface HandoutResourceFragment_goal {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface HandoutResourceFragment_defaultPlayerGoal {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface HandoutResourceFragment {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaign's currency
   */
  readonly currency: Currency;
  /**
   * Campaign's category
   */
  readonly category: HandoutResourceFragment_category;
  /**
   * Campaigns's description
   */
  readonly description: string | null;
  /**
   * Campaigns's goal amount
   */
  readonly goal: HandoutResourceFragment_goal | null;
  /**
   * The team's default player goal amount.
   */
  readonly defaultPlayerGoal: HandoutResourceFragment_defaultPlayerGoal | null;
  /**
   * How campaign plans to distribute funds
   */
  readonly fundsDistributionMethod: FundsDistributionMethod | null;
  /**
   * Campaign's photo image URL
   */
  readonly photoImage: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TeamCategoryInputFragment
// ====================================================

export interface TeamCategoryInputFragment_categories_parent {
  readonly __typename: "CampaignCategory";
  /**
   * Category's ID
   */
  readonly id: string;
  /**
   * Category's name
   */
  readonly name: string;
}

export interface TeamCategoryInputFragment_categories {
  readonly __typename: "CampaignCategory";
  /**
   * Category's ID
   */
  readonly id: string;
  /**
   * Category's name
   */
  readonly name: string;
  /**
   * If category is featured
   */
  readonly isFeatured: boolean;
  /**
   * Category's parent
   */
  readonly parent: TeamCategoryInputFragment_categories_parent | null;
}

export interface TeamCategoryInputFragment {
  readonly __typename: "Site";
  /**
   * Site's ID
   */
  readonly id: string;
  /**
   * Site's list of campaign categories available
   */
  readonly categories: ReadonlyArray<TeamCategoryInputFragment_categories>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TeamOwnerRolesInputFragment
// ====================================================

export interface TeamOwnerRolesInputFragment_teamRoles {
  readonly __typename: "TeamRole";
  /**
   * The role's ID
   */
  readonly id: string;
  /**
   * The role's name
   */
  readonly name: string;
}

export interface TeamOwnerRolesInputFragment {
  readonly __typename: "Site";
  /**
   * Site's ID
   */
  readonly id: string;
  /**
   * Site's list of team roles available
   */
  readonly teamRoles: ReadonlyArray<TeamOwnerRolesInputFragment_teamRoles>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: EditRolesModalFragment
// ====================================================

export interface EditRolesModalFragment_teamRoles {
  readonly __typename: "TeamRole";
  /**
   * The role's ID
   */
  readonly id: string;
  /**
   * The role's name
   */
  readonly name: string;
}

export interface EditRolesModalFragment {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's full name
   */
  readonly name: string;
  /**
   * The team member's roles
   */
  readonly teamRoles: ReadonlyArray<EditRolesModalFragment_teamRoles>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: TeamRosterFundraiserFragment
// ====================================================

export interface TeamRosterFundraiserFragment_teamRoles {
  readonly __typename: "TeamRole";
  /**
   * The role's ID
   */
  readonly id: string;
  /**
   * The role's name
   */
  readonly name: string;
}

export interface TeamRosterFundraiserFragment_group {
  readonly __typename: "Group";
  /**
   * Group's ID
   */
  readonly id: string;
  /**
   * Group's name
   */
  readonly name: string;
}

export interface TeamRosterFundraiserFragment {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's user ID
   */
  readonly userId: string | null;
  /**
   * Fundraiser's joined at timestamp
   */
  readonly joinedAt: DateTimeISO | null;
  /**
   * Is Captain
   */
  readonly isCaptain: boolean | null;
  /**
   * Is Captainable
   */
  readonly isCaptainable: boolean | null;
  /**
   * Is Org Admin
   */
  readonly isOrgAdmin: boolean | null;
  /**
   * Is Captain
   */
  readonly isDivisionManager: boolean | null;
  /**
   * Fundraisers's avatar image URL
   */
  readonly avatarImage: string | null;
  /**
   * The team member's roles
   */
  readonly teamRoles: ReadonlyArray<TeamRosterFundraiserFragment_teamRoles>;
  /**
   * Fundraiser's deactivated at timestamp
   */
  readonly deactivatedAt: DateTimeISO | null;
  /**
   * Fundraisers's full name
   */
  readonly name: string;
  /**
   * Fundraisers's group
   */
  readonly group: TeamRosterFundraiserFragment_group | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ApplessHeaderFragment
// ====================================================

export interface ApplessHeaderFragment_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaign's photo image URL
   */
  readonly photoImage: string | null;
}

export interface ApplessHeaderFragment_totalAmountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface ApplessHeaderFragment {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * The url of the user's avatar
   */
  readonly avatarImage: string | null;
  /**
   * User's full name
   */
  readonly name: string | null;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: ApplessHeaderFragment_activeCampaign | null;
  /**
   * Is the user an admin?
   */
  readonly isAdmin: boolean;
  /**
   * viewer merchant admin?
   */
  readonly isMerchantAdmin: boolean;
  /**
   * viewer organization admin?
   */
  readonly isOrgAdmin: boolean;
  /**
   * viewer site admin?
   */
  readonly isSiteAdmin: boolean;
  /**
   * User created date
   */
  readonly joinedAt: DateTimeISO;
  /**
   * viewer total amount raised
   */
  readonly totalAmountRaised: ApplessHeaderFragment_totalAmountRaised;
  /**
   * Total shopping trips a viewer has made
   */
  readonly shoppingTripsCount: number;
  /**
   * Total teams the viewer has been a part of
   */
  readonly teamsCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewHeaderAppSwitcherFragment
// ====================================================

export interface NewHeaderAppSwitcherFragment_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaign's url on the web
   */
  readonly url: string;
}

export interface NewHeaderAppSwitcherFragment {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * User's full name
   */
  readonly name: string | null;
  /**
   * The url of the user's avatar
   */
  readonly avatarImage: string | null;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: NewHeaderAppSwitcherFragment_activeCampaign | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewNavigationFragment
// ====================================================

export interface NewNavigationFragment_totalAmountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface NewNavigationFragment_activeCampaign_amountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface NewNavigationFragment_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaign's photo image URL
   */
  readonly photoImage: string | null;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * Campaigns amount raised
   */
  readonly amountRaised: NewNavigationFragment_activeCampaign_amountRaised;
  /**
   * Days or hours left for a campaign
   */
  readonly daysOrHoursLeftLabel: string | null;
  /**
   * Campaign's url on the web
   */
  readonly url: string;
}

export interface NewNavigationFragment {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * User's full name
   */
  readonly name: string | null;
  /**
   * The url of the user's avatar
   */
  readonly avatarImage: string | null;
  /**
   * viewer total amount raised
   */
  readonly totalAmountRaised: NewNavigationFragment_totalAmountRaised;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: NewNavigationFragment_activeCampaign | null;
  /**
   * Is the user an admin?
   */
  readonly isAdmin: boolean;
  /**
   * viewer merchant admin?
   */
  readonly isMerchantAdmin: boolean;
  /**
   * viewer organization admin?
   */
  readonly isOrgAdmin: boolean;
  /**
   * viewer site admin?
   */
  readonly isSiteAdmin: boolean;
  /**
   * User created date
   */
  readonly joinedAt: DateTimeISO;
  /**
   * Total shopping trips a viewer has made
   */
  readonly shoppingTripsCount: number;
  /**
   * Total teams the viewer has been a part of
   */
  readonly teamsCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserTeamHeaderLinksFragment
// ====================================================

export interface UserTeamHeaderLinksFragment_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaign's url on the web
   */
  readonly url: string;
  /**
   * Does this campaign use the budget app
   */
  readonly hasBudget: boolean;
  /**
   * Is current viewer owner of campaign
   */
  readonly isViewerOwner: boolean;
}

export interface UserTeamHeaderLinksFragment {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: UserTeamHeaderLinksFragment_activeCampaign | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: NewHeaderFragment
// ====================================================

export interface NewHeaderFragment_totalAmountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface NewHeaderFragment_activeCampaign_amountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface NewHeaderFragment_activeCampaign_goal {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface NewHeaderFragment_activeCampaign_category {
  readonly __typename: "CampaignCategory";
  /**
   * Category's ID
   */
  readonly id: string;
  /**
   * Category's name
   */
  readonly name: string;
}

export interface NewHeaderFragment_activeCampaign_joinedFundraisers {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's full name
   */
  readonly name: string;
  /**
   * Is Captain
   */
  readonly isCaptain: boolean | null;
  /**
   * Is Captainable
   */
  readonly isCaptainable: boolean | null;
}

export interface NewHeaderFragment_activeCampaign_viewerFundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's avatar image URL
   */
  readonly avatarImage: string | null;
  /**
   * Is Captain
   */
  readonly isCaptain: boolean | null;
  /**
   * Is Captainable
   */
  readonly isCaptainable: boolean | null;
}

export interface NewHeaderFragment_activeCampaign_organization {
  readonly __typename: "Organization";
  /**
   * Organization's ID
   */
  readonly id: string;
  /**
   * Organization's name
   */
  readonly name: string;
}

export interface NewHeaderFragment_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * Campaign's join code
   */
  readonly accessCode: string;
  /**
   * Campaigns amount raised
   */
  readonly amountRaised: NewHeaderFragment_activeCampaign_amountRaised;
  /**
   * Campaigns's goal amount
   */
  readonly goal: NewHeaderFragment_activeCampaign_goal | null;
  /**
   * Days or hours left for a campaign
   */
  readonly daysOrHoursLeftLabel: string | null;
  /**
   * Campaigns's description
   */
  readonly description: string | null;
  /**
   * Campaign's city
   */
  readonly city: string | null;
  /**
   * Campaign's country
   */
  readonly country: Country | null;
  /**
   * Campaign's category
   */
  readonly category: NewHeaderFragment_activeCampaign_category;
  /**
   * A list of just the joined fundraisers
   */
  readonly joinedFundraisers: ReadonlyArray<NewHeaderFragment_activeCampaign_joinedFundraisers>;
  /**
   * Campaign's photo image URL
   */
  readonly photoImage: string | null;
  /**
   * Current viewer's fundraiser on campaign
   */
  readonly viewerFundraiser: NewHeaderFragment_activeCampaign_viewerFundraiser | null;
  /**
   * Count of joined fundraisers
   */
  readonly joinedFundraisersCount: number;
  /**
   * Campaign's category
   */
  readonly organization: NewHeaderFragment_activeCampaign_organization | null;
  /**
   * Campaign's url on the web
   */
  readonly url: string;
  /**
   * Does this campaign use the budget app
   */
  readonly hasBudget: boolean;
}

export interface NewHeaderFragment_personalAccount_transferrableFunds_availableAmount {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface NewHeaderFragment_personalAccount_transferrableFunds {
  readonly __typename: "TransferrableFunds";
  /**
   * Available amount
   */
  readonly availableAmount: NewHeaderFragment_personalAccount_transferrableFunds_availableAmount;
}

export interface NewHeaderFragment_personalAccount {
  readonly __typename: "PersonalAccount";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Reason the viewer is in continuity
   */
  readonly continuityReason: ContinuityReasonEnum | null;
  /**
   * Transferrable funds
   */
  readonly transferrableFunds: NewHeaderFragment_personalAccount_transferrableFunds;
}

export interface NewHeaderFragment {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Number of unseen notifications for this user
   */
  readonly unseenNotificationCount: number;
  /**
   * The url of the user's avatar
   */
  readonly avatarImage: string | null;
  /**
   * User's full name
   */
  readonly name: string | null;
  /**
   * Is the user an admin?
   */
  readonly isAdmin: boolean;
  /**
   * viewer merchant admin?
   */
  readonly isMerchantAdmin: boolean;
  /**
   * viewer organization admin?
   */
  readonly isOrgAdmin: boolean;
  /**
   * viewer site admin?
   */
  readonly isSiteAdmin: boolean;
  /**
   * User created date
   */
  readonly joinedAt: DateTimeISO;
  /**
   * The active fundraising status of the viewer
   */
  readonly activeStatus: ActiveStatusEnum | null;
  /**
   * viewer total amount raised
   */
  readonly totalAmountRaised: NewHeaderFragment_totalAmountRaised;
  /**
   * Total shopping trips a viewer has made
   */
  readonly shoppingTripsCount: number;
  /**
   * Total teams the viewer has been a part of
   */
  readonly teamsCount: number;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: NewHeaderFragment_activeCampaign | null;
  /**
   * Information for the viewer's personal account
   */
  readonly personalAccount: NewHeaderFragment_personalAccount;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserAccountModalFragment
// ====================================================

export interface UserAccountModalFragment_totalAmountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface UserAccountModalFragment {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * The url of the user's avatar
   */
  readonly avatarImage: string | null;
  /**
   * User's full name
   */
  readonly name: string | null;
  /**
   * Is the user an admin?
   */
  readonly isAdmin: boolean;
  /**
   * viewer merchant admin?
   */
  readonly isMerchantAdmin: boolean;
  /**
   * viewer organization admin?
   */
  readonly isOrgAdmin: boolean;
  /**
   * viewer site admin?
   */
  readonly isSiteAdmin: boolean;
  /**
   * User created date
   */
  readonly joinedAt: DateTimeISO;
  /**
   * viewer total amount raised
   */
  readonly totalAmountRaised: UserAccountModalFragment_totalAmountRaised;
  /**
   * Total shopping trips a viewer has made
   */
  readonly shoppingTripsCount: number;
  /**
   * Total teams the viewer has been a part of
   */
  readonly teamsCount: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: SiteCookieFragment
// ====================================================

export interface SiteCookieFragment {
  readonly __typename: "Site";
  /**
   * Site's ID
   */
  readonly id: string;
  /**
   * Site's url
   */
  readonly url: string;
  /**
   * Site's logo
   */
  readonly logoImage: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Describes the current fundraising status of the user
 */
export enum ActiveStatusEnum {
  PERSONAL = "PERSONAL",
  TEAM = "TEAM",
  UNKNOWN = "UNKNOWN",
}

/**
 * Describes the beneficiary approval status
 */
export enum BeneficiaryStatus {
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  PENDING = "PENDING",
  UNKNOWN = "UNKNOWN",
  VERIFIED = "VERIFIED",
}

/**
 * Describes the team type for a beneficiary
 */
export enum BeneficiaryTeamType {
  CHARITY = "CHARITY",
  INDIVIDUAL = "INDIVIDUAL",
  ORGANIZATION = "ORGANIZATION",
  UNCATEGORIZED = "UNCATEGORIZED",
}

/**
 * Describes the reason the user is in continuity
 */
export enum ContinuityReasonEnum {
  CHOICE = "CHOICE",
  CLOSED = "CLOSED",
  LEFT = "LEFT",
  NEW_USER = "NEW_USER",
  REMOVED = "REMOVED",
}

/**
 * What is the source of these coordinates?
 */
export enum CoordinatesSource {
  CAMPAIGN = "CAMPAIGN",
  CURRENT = "CURRENT",
  SEARCH = "SEARCH",
}

/**
 * Available countries.
 */
export enum Country {
  CANADA = "CANADA",
  USA = "USA",
}

/**
 * The list of currencies available.
 */
export enum Currency {
  CAD = "CAD",
  USD = "USD",
}

/**
 * Describes which external partner
 */
export enum ExternalPartnerType {
  TEAMSNAP = "TEAMSNAP",
}

/**
 * Describes the roles of fundraiser
 */
export enum FundraiserRole {
  BUDGET_ADMIN = "BUDGET_ADMIN",
  BUDGET_TEAM_MEMBER = "BUDGET_TEAM_MEMBER",
  CO_CAPTAIN = "CO_CAPTAIN",
  OWNER = "OWNER",
  PLAYER_CONTACT = "PLAYER_CONTACT",
}

/**
 * How funds are to be distributed
 */
export enum FundsDistributionMethod {
  PLAYER = "PLAYER",
  TEAM = "TEAM",
  UNDECIDED = "UNDECIDED",
}

/**
 * Describes the invite template you wish to see
 */
export enum InviteTemplateType {
  PARENT = "PARENT",
  REMINDER = "REMINDER",
  SUPPORTER = "SUPPORTER",
}

/**
 * Describes the payment types that can be used for beneficiary
 */
export enum PaymentType {
  EMAIL = "EMAIL",
  HW_BANK_TRANSFER = "HW_BANK_TRANSFER",
  HW_CHECK = "HW_CHECK",
  POSTAGE = "POSTAGE",
}

/**
 * Describes a checklist item the user needs to complete after signup.
 */
export enum TeamOnboardingChecklistItem {
  CAPTAIN_INVITE_TEAM = "CAPTAIN_INVITE_TEAM",
  CAPTAIN_SET_ROSTER = "CAPTAIN_SET_ROSTER",
  CAPTAIN_SHOP = "CAPTAIN_SHOP",
  CAPTAIN_SUBMIT_PAYMENT_INFO = "CAPTAIN_SUBMIT_PAYMENT_INFO",
  TEAM_MEMBER_INVITE_SUPPORTERS = "TEAM_MEMBER_INVITE_SUPPORTERS",
  TEAM_MEMBER_SHOP = "TEAM_MEMBER_SHOP",
}

/**
 * Describes the current step that the user is in during onboarding.
 */
export enum TeamOnboardingStep {
  CAPTAIN_DOWNLOAD_RESOURCES = "CAPTAIN_DOWNLOAD_RESOURCES",
  CAPTAIN_INVITE_TEAM = "CAPTAIN_INVITE_TEAM",
  CAPTAIN_SET_GOAL = "CAPTAIN_SET_GOAL",
  CAPTAIN_SET_OBJECTIVE = "CAPTAIN_SET_OBJECTIVE",
  CAPTAIN_SET_ROSTER = "CAPTAIN_SET_ROSTER",
  CAPTAIN_SET_TEAM_PICKS = "CAPTAIN_SET_TEAM_PICKS",
  TEAM_MEMBER_ESTIMATE_EARNINGS = "TEAM_MEMBER_ESTIMATE_EARNINGS",
  TEAM_MEMBER_SET_FAVORITES = "TEAM_MEMBER_SET_FAVORITES",
  TEAM_MEMBER_SET_PLAYER = "TEAM_MEMBER_SET_PLAYER",
  TEAM_MEMBER_SET_ROLE = "TEAM_MEMBER_SET_ROLE",
}

/**
 * Describes the type of onboarding the user is in.
 */
export enum TeamOnboardingType {
  CAPTAIN = "CAPTAIN",
  TEAM_MEMBER = "TEAM_MEMBER",
}

/**
 * Attributes for creating a beneficiary for a campaign
 */
export interface BeneficiaryCreateInput {
  readonly campaignId: string;
  readonly teamType: BeneficiaryTeamType;
  readonly legalName?: string | null;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly dateOfBirth?: string | null;
  readonly realTeamName: string;
  readonly teamName?: string | null;
  readonly website?: string | null;
  readonly contactName?: string | null;
  readonly phoneNumber: string;
  readonly email: string;
  readonly taxId?: string | null;
  readonly street1: string;
  readonly street2?: string | null;
  readonly city: string;
  readonly province: string;
  readonly postalCode: string;
  readonly country: Country;
  readonly paymentType: PaymentType;
  readonly plaidPublicToken?: string | null;
  readonly plaidAccountId?: string | null;
  readonly googlePlacesId?: string | null;
  readonly plaidInstitutionName?: string | null;
  readonly campaignAutoWithdrawAfterApproval?: boolean | null;
}

/**
 * Attributes for creating an organization via beneficiary submission
 */
export interface BeneficiaryOrganizationInput {
  readonly name: string;
  readonly legalName: string;
  readonly street1: string;
  readonly city: string;
  readonly province: string;
  readonly postalCode: string;
  readonly country: Country;
  readonly categoryId: string;
}

/**
 * Attributes for updating a beneficiary for a campaign
 */
export interface BeneficiaryUpdateInputType {
  readonly id: string;
  readonly teamType?: BeneficiaryTeamType | null;
  readonly legalName?: string | null;
  readonly firstName?: string | null;
  readonly lastName?: string | null;
  readonly dateOfBirth?: string | null;
  readonly realTeamName?: string | null;
  readonly teamName?: string | null;
  readonly website?: string | null;
  readonly contactName?: string | null;
  readonly phoneNumber?: string | null;
  readonly email?: string | null;
  readonly taxId?: string | null;
  readonly street1?: string | null;
  readonly street2?: string | null;
  readonly city?: string | null;
  readonly province?: string | null;
  readonly postalCode?: string | null;
  readonly country?: Country | null;
  readonly paymentType?: PaymentType | null;
  readonly plaidPublicToken?: string | null;
  readonly plaidAccountId?: string | null;
  readonly googlePlacesId?: string | null;
  readonly plaidAccessToken?: string | null;
  readonly plaidInstitutionName?: string | null;
  readonly campaignAutoWithdrawAfterApproval?: boolean | null;
}

/**
 * Attributes for updating a fundraiser roles
 */
export interface BudgetFundraiserUpdateRolesInput {
  readonly id: string;
  readonly roles: ReadonlyArray<FundraiserRole>;
}

/**
 * Attributes for updating the captain of a team
 */
export interface CampaignUpdateCaptainInput {
  readonly teamMemberId: string;
}

/**
 * UTM attributes for a campaign on creation.
 */
export interface CampaignUtmDataInput {
  readonly utmType?: string | null;
  readonly utmSource?: string | null;
  readonly utmMedium?: string | null;
  readonly utmCampaign?: string | null;
  readonly utmTerm?: string | null;
  readonly utmContent?: string | null;
  readonly utmChannel?: string | null;
  readonly branchLinkId?: string | null;
  readonly appsFlyerId?: string | null;
}

/**
 * Coordinates attributes
 */
export interface CoordinatesInput {
  readonly latitude: number;
  readonly longitude: number;
  readonly source?: CoordinatesSource | null;
}

/**
 * Attributes for deleting a player
 */
export interface DeleteTeamRosterInput {
  readonly playerId?: string | null;
}

/**
 * Attributes for setting a team's player roster
 */
export interface EditTeamRosterInput {
  readonly onboarding?: boolean | null;
  readonly players: ReadonlyArray<EditTeamRosterPlayerInput>;
}

/**
 * Attributes for creating/editing a player on a team's player roster
 */
export interface EditTeamRosterPlayerInput {
  readonly id?: string | null;
  readonly name: string;
  readonly goal: number;
}

/**
 * Attributes for associating a campaign with an external partner
 */
export interface ExternalPartnerInput {
  readonly id: string;
  readonly type: ExternalPartnerType;
}

/**
 * Attributes for updating a fundraiser's roles
 */
export interface FundraiserUpdateRolesInput {
  readonly id: string;
  readonly teamRoles: ReadonlyArray<string>;
}

/**
 * Attributes for creating a team
 */
export interface TeamCreateInput {
  readonly name: string;
  readonly categoryID: string;
  readonly brandId?: string | null;
  readonly partnerId?: string | null;
  readonly recaptchaToken?: string | null;
  readonly country?: Country | null;
  readonly externalPartner?: ExternalPartnerInput | null;
  readonly coordinates?: CoordinatesInput | null;
  readonly postalCode?: string | null;
  readonly playersCountEstimate?: number | null;
  readonly teamOwnerRoles?: ReadonlyArray<string> | null;
  readonly referralCode?: string | null;
  readonly utmData?: CampaignUtmDataInput | null;
  readonly logo?: Upload | null;
  readonly organizationID?: string | null;
  readonly divisionID?: string | null;
  readonly shopInitialized?: DateISO | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
