// == Types ================================================================

// == Constants ============================================================

// == Exports ==============================================================

export const fundingPrimary = "#48B1BF";
export const budgetPrimary = "#9B58E9";
export const fundingLogoBg = "#12AAA0";

export const primary = "#48B1BF";
export const danger = "#D9534F";
export const income = "#047b48";
export const success = "#28A745";
export const notification = "#FFB82E";
export const notificationBgColor = "rgba(247,181,0,0.2)";

export const black = "#000";
export const white = "#FFF";
export const gray = "#CCC";
export const text = "#666";

export const grayScale = {
  0: `#000`,
  1: `#111`,
  2: `#222`,
  3: `#333`,
  4: `#444`,
  5: `#555`,
  6: `#666`,
  7: `#777`,
  8: `#888`,
  9: `#999`,
  A: `#AAA`,
  B: `#BBB`,
  C: `#CCC`,
  D: `#DDD`,
  E: `#EEE`,
  F: `#FFF`,

  EF: `#EFEFEF`,
  E6: `#E6E6E6`,
  F0: `#F0F0F0`,
  F3: `#F3F3F3`,
  F6: `#F6F6F6`,
  FA: `#FAFAFA`,

  white,
  black,
  gray,
  grey: gray,

  muted: `#F6F6F6`,
  sliderBackground: `#F0F0EF`,
};

export const primaryScale = {
  primary,
  light: `#F8FDFD`,
  lightBackground: `#E7F9F8`,
  dark: `#27B7BA`,
  darkBackground: `#E4F3F5`,
  slider: `#25BCBC`,
};

export const budgetScale = {
  primary: budgetPrimary,
  secondary: "#E9F6FC",
  bgLight: "#FBFBFE",
  bgDefault: "#FAF6FE",
  bgDark: "#E1CDF9",
  bgDarker: "#9B58E9",
};

// confused about the primary color so heres funding color
export const fundingScale = {
  bgLight: "#FAFBFC",
  bgDefault: "#F5FDFC",
  bgDark: "#E7F9F8",
  bgDarker: "#36B5BD",
};

export const bankingScale = {
  bgLight: "#EDF7FE",
  bgDefault: "#C5E4FB",
  bgDarker: "#00A3FA",
};

export const successScale = {
  success,
  background: `#7CDF93`,
};

export const blueScale = {
  secondary: "#30C",
  lightBackground: "#EDF7F8",
};
