import { ComponentProps, useMemo } from "react";
import { ISearchModalViewProps } from "@core-shop/components";
import classNames from "classnames";
// eslint-disable-next-line import/no-duplicates
import format from "date-fns/format";
// eslint-disable-next-line import/no-duplicates
import parseISO from "date-fns/parseISO";
import { TToggleFavoriteMutation } from "@shared-tailwind/../@core-shop/types";
import { routes } from "@shared-tailwind/../@shared/utils";
import { BrandBlock, Container, DealBlock, HorizontalScroll } from "@shared-tailwind/atoms";
import { SearchListHeader } from "./SearchListHeader";

export interface IBrandResultItem extends ComponentProps<typeof BrandBlock> {
  id: string;
}
export interface IDealResultItem extends ComponentProps<typeof DealBlock> {
  id?: string;
}

interface IProps {
  toggleFavoriteBrandMutation: TToggleFavoriteMutation;
  brandResults?: ISearchModalViewProps["searchResults"]["brands"];
  dealResults?: ISearchModalViewProps["searchResults"]["deals"];
  favoriteBrandsIds?: string[];
  isGrid?: boolean;
  onLikeBrand?: (id: string) => void;
  title?: string;
  type?: string;
}

export const SearchResultsList = ({
  title,
  brandResults = [],
  dealResults = [],
  isGrid,
  onLikeBrand,
  favoriteBrandsIds,
  type,
  toggleFavoriteBrandMutation,
}: IProps) => {
  const WrapperComponent = isGrid ? HorizontalScroll : "div";

  const rowsCount = useMemo(() => {
    if (brandResults?.length < 3) return brandResults?.length;
    return 3;
  }, [brandResults?.length]);

  return (
    <Container className="my-4">
      <SearchListHeader title={title ?? ""} type={type ?? ""} />
      <WrapperComponent className="gap-y-4" justify="start" rows={rowsCount}>
        {brandResults?.map(({ id, images, maxGiveLink, name }) => {
          return (
            <div className={classNames(!isGrid && "my-3")} key={`brand-${id}`}>
              <BrandBlock
                hasLikeButton
                bgColor={images?.logoBackgroundColor}
                buttonText="Shop"
                buttonUrl={routes.shopcloud.brandModal(id)}
                givesSubtitle={maxGiveLink?.promotionInfo?.promotionLabel ?? ""}
                givesTitle={maxGiveLink?.giveSentence ?? ""}
                id={id}
                imageSrc={images?.invertedLogo || images?.logo}
                isLiked={favoriteBrandsIds?.includes(id)}
                name={name}
                onClickLike={() => onLikeBrand?.(id)}
                onToggleFavoriteMutation={toggleFavoriteBrandMutation}
              />
            </div>
          );
        })}
        {dealResults?.map(({ description, endsAt, id = "", link }, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div className={classNames(!isGrid && "my-3")} key={`${id}-${i}`}>
              <DealBlock
                expires={endsAt ? `Exp. ${format(parseISO(endsAt), "MM/dd/yyyy")}` : ""}
                hasButton={false}
                href={routes.shopcloud.linkModal(link?.id ?? "", id).toString()}
                imageSrc={link?.images.logo ?? ""}
                name={description}
              />
            </div>
          );
        })}
      </WrapperComponent>
    </Container>
  );
};
