import React from "react";
import { Button, ButtonVariants, Label } from "@shared-tailwind/atoms";

interface IProps {
  onChange: (value: boolean) => void;
  isGift?: boolean;
}

export const Recepient: React.FC<IProps> = ({ isGift, onChange }) => {
  return (
    <div className="mb-3">
      <Label>Recepient</Label>
      <div className="flex space-x-2">
        <Button
          variant={isGift ? ButtonVariants.toggleNotSelected : ButtonVariants.toggle}
          onClick={() => onChange(false)}
        >
          For myself
        </Button>
        <Button
          variant={isGift ? ButtonVariants.toggle : ButtonVariants.toggleNotSelected}
          onClick={() => onChange(true)}
        >
          Send as a gift
        </Button>
      </div>
    </div>
  );
};
