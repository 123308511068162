import { useInfiniteQuery } from "@tanstack/react-query";
import { queries } from "@core-shop/cache";
import { GetPayoutsQueryVariables } from "@core-shop/types";

export function usePayments(vars: GetPayoutsQueryVariables) {
  return useInfiniteQuery({
    ...queries.payouts(vars),
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      return (
        lastPage.Campaign?.payments.pageInfo.hasNextPage && lastPage.Campaign?.payments.pageInfo.endCursor
      );
    },
  });
}
