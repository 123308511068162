import { IS_FLIPGIVE_DOMAIN } from "@shared/config/environment";
import { ErrorService } from "./Sentry";

// == Types ================================================================

// == Constants ============================================================

// == Exports ==============================================================

export function getDomainForCookie(host?: string | string[]) {
  try {
    if (typeof host !== "string") return undefined;
    if (host.includes("localhost")) return undefined;
    if (!IS_FLIPGIVE_DOMAIN(host)) return undefined;

    const domain = host.split(".").slice(-2).join(".");
    return `.${domain}`;
  } catch (error) {
    ErrorService.error(error);
    return undefined;
  }
}
