import classNames from "classnames";

// == Types ================================================================

interface IProps {
  checked: boolean;
  className?: string;
  description?: string;
  id?: string;
  isDisabled?: boolean;
  isError?: boolean;
  label?: string;
  labelClassName?: string;
  onChange?: (value: boolean) => void;
}

// == Constants ============================================================

// == Component ============================================================

export function Checkbox({
  label,
  description,
  checked,
  onChange,
  className,
  isError,
  id,
  labelClassName,
  isDisabled,
}: IProps) {
  return (
    <div className={classNames("relative flex items-start", className)}>
      <div className="flex items-center h-5">
        <input
          aria-describedby="comments-description"
          checked={checked}
          className={classNames(
            "h-4 w-4 text-primary-600 rounded",
            isError ? "focus:ring-red-500 border-red-300" : "focus:ring-primary-500 border-gray-300"
          )}
          disabled={isDisabled}
          id={id}
          name={id}
          type="checkbox"
          onChange={({ target }) => onChange?.(target.checked)}
        />
      </div>
      {(label || description) && (
        <div className="ml-3 text-sm">
          {label && (
            <label className={classNames("font-medium text-gray-700", labelClassName)} htmlFor={id}>
              {label}
            </label>
          )}
          {description && (
            <p className="text-gray-500" id="comments-description">
              {description}
            </p>
          )}
        </div>
      )}
    </div>
  );
}

// == Styles ===============================================================
