import gql from "graphql-tag";
import { giftcardFragment } from "./fragments/giftcardFragment";

export const getGiftcards = gql`
  query GiftCardListQuery(
    $after: String
    $sortBy: GiftcardSortEnum
    $nameContains: String
    $withBalance: Boolean
    $giftcardTypes: [GiftcardType!]
    $ids: [ID!]
  ) {
    Viewer {
      id
      giftcardsPaginated(
        first: 10
        after: $after
        sortBy: $sortBy
        nameContains: $nameContains
        withBalance: $withBalance
        giftcardTypes: $giftcardTypes
        ids: $ids
      ) {
        edges {
          cursor
          node {
            id
            ...GiftcardFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${giftcardFragment}
`;
