import gql from "graphql-tag";

export const onLikeNewsMutation = gql`
  mutation OnLikeNewsMutation($activityId: ID!, $reactionType: ReactionType!) {
    ActivityReactionCreate(activityId: $activityId, reactionType: $reactionType) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Activity {
        id
        likesCount
        viewerHasLiked
      }
    }
  }
`;
