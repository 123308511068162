import { useMemo } from "react";
import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { queries } from "@core-shop/cache";
import {
  CloudData_ShopCloud_activeCampaign,
  GetTeamQuery,
  GetTeamQuery_ShopCloud_activeCampaign,
} from "@core-shop/types";

export type TUseTeamResult = UseQueryResult<GetTeamQuery> & {
  data?: {
    ShopCloud?: {
      activeCampaign?: GetTeamQuery_ShopCloud_activeCampaign & CloudData_ShopCloud_activeCampaign;
    };
  };
};

export function useTeam() {
  const teamQuery = useQuery(queries.team());
  const cloudQuery = useQuery(queries.cloud());

  const mergedData = useMemo(() => {
    if (teamQuery.isLoading || cloudQuery.isLoading) return undefined;
    if (!teamQuery.data?.ShopCloud?.activeCampaign) return undefined;

    const activeCampaign = {
      ...teamQuery.data.ShopCloud.activeCampaign,
      ...cloudQuery.data?.ShopCloud?.activeCampaign,
    };
    const data = {
      ...teamQuery.data,
      ShopCloud: { ...teamQuery.data?.ShopCloud, activeCampaign },
    };
    return data;
  }, [cloudQuery.data?.ShopCloud?.activeCampaign, cloudQuery.isLoading, teamQuery.data, teamQuery.isLoading]);

  return { ...teamQuery, data: mergedData } as TUseTeamResult;
}
