import { routes } from "@shared/utils";
import { MenuCard } from "@shared-tailwind/molecules";

interface IProps {
  id: string;
  description?: string;
  giveAmount?: string;
  Icon?: React.FC<{ className?: string }>;
  title?: string;
}

export const Link: React.FC<IProps> = ({ Icon, description, giveAmount, title, id }) => {
  return (
    <MenuCard
      description={description}
      href={routes.shopcloud.linkModal(id).toString()}
      Icon={Icon}
      title={title}
      value={giveAmount}
    />
  );
};
