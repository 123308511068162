import { ComponentProps } from "react";
import { BrandCircle } from "@shared-tailwind/atoms";

export enum ESearchResultType {
  BRAND = "BRAND",
  DEAL = "DEAL",
}

export interface IBrandCircleProps extends ComponentProps<typeof BrandCircle> {
  id: string;
}
