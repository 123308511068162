import classNames from "classnames";

// == Types ================================================================

interface IProps {
  Buttons?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  description?: string;
  hasNoBorder?: boolean;
  shouldForceOneLine?: boolean;
}

// == Constants ============================================================

// == Component ============================================================

export function HeadingSection({
  children,
  description,
  Buttons,
  className,
  hasNoBorder,
  shouldForceOneLine,
}: IProps) {
  return (
    <div
      className={classNames("pb-5", className, {
        "border-b border-gray-200": !hasNoBorder,
      })}
    >
      <div
        className={classNames(
          shouldForceOneLine
            ? "flex items-center justify-between"
            : "sm:flex sm:items-center sm:justify-between"
        )}
      >
        <h3 className="text-lg leading-6 font-medium text-gray-900">{children}</h3>
        {Buttons && (
          <div className={classNames("mt-3 flex", !shouldForceOneLine && "sm:mt-0 sm:ml-4")}>{Buttons}</div>
        )}
      </div>
      {description && <p className="mt-2 max-w-4xl text-sm text-gray-500 pt-2 sm:pt-0">{description}</p>}
    </div>
  );
}
