import { CloudColorNames, TColors } from "./types";

const { deals, primary, secondary } = CloudColorNames;

// Duplicated main shades of colors to be suitable with
// using for shop tailwind theme
export const createStylesStr = (colors: TColors) => `
html {
  --primary: ${colors[primary]};
  --primary-50: ${colors[secondary]};
  --primary-100: ${colors[secondary]};
  --primary-200: ${colors[secondary]};
  --primary-500: ${colors[primary]};
  --primary-600: ${colors[primary]};
  --primary-700: ${colors[primary]};
  --secondary: ${colors[secondary]};
  --secondary-500: ${colors[secondary]};
  --primary-50: ${colors[secondary]};
  --primary-100: ${colors[secondary]};
  --deals: ${colors[deals]};
}
`;
