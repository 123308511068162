import { useToggle } from "@shared/hooks";
import classNames from "classnames";
import { Button, ButtonVariants } from "@shared-tailwind/atoms";
import { ButtonSizes } from "@shared-tailwind/types";

interface IProps {
  description?: string | null;
}

const MAX_CHARS = 150;

export const Description: React.FC<IProps> = ({ description }) => {
  const { isOpen, MoreButton } = useMoreTextButton({ text: description ?? "", length: MAX_CHARS });

  if (!description) return null;

  return (
    <div className="text-sm text-gray-500">
      <span className={classNames(!isOpen && "after:content-['...']")}>
        {isOpen ? description : description.slice(0, MAX_CHARS)}
      </span>
      {MoreButton}
    </div>
  );
};

export function useMoreTextButton({ text = "", length = MAX_CHARS } = {}) {
  const [isOpen, toggleisOpen] = useToggle(!!text && text?.length < length);
  return {
    isOpen,
    MoreButton: isOpen ? null : (
      <Button
        resetSizeClasses
        className=" text-primary-500 text-sm"
        size={ButtonSizes.sm}
        variant={ButtonVariants.link}
        onClick={toggleisOpen}
      >
        More
      </Button>
    ),
  };
}
