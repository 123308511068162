import gql from "graphql-tag";

export const locationSearchQuery = gql`
  query LocationSearch($coordinates: CoordinatesInput, $currency: Currency, $first: Int, $distance: Int) {
    LocationSearch(coordinates: $coordinates, first: $first, distance: $distance, currency: $currency) {
      edges {
        cursor
        node {
          id
          sponsored
          brand {
            id
            name
            location {
              id
            }
            images {
              hero
              invertedLogo
              logo
              logoBackgroundColor
            }
          }
        }
      }
    }
  }
`;
