import React from "react";
import { IGiftcardDetailsViewProps } from "@core-shop/components";
import { formatMoney } from "@shared/utils";
import { Button, ButtonVariants, Label } from "@shared-tailwind/atoms";

interface IProps {
  onChange: (centsAmount: number) => void;
  reloadThresholdCents: number;
  reloadThresholdDenominations: IGiftcardDetailsViewProps["reloadThresholdDenominations"];
  seletedDenom: IGiftcardDetailsViewProps["denominations"][0];
}

export const ReloadThreshold: React.FC<IProps> = ({
  onChange,
  reloadThresholdDenominations,
  reloadThresholdCents,
  seletedDenom,
}) => {
  return (
    <div className="mb-3">
      <Label>Reload when balance drops below</Label>
      <div className="grid gap-2 grid-cols-5">
        {reloadThresholdDenominations
          ?.filter(({ dollarsAmount }) => seletedDenom?.value.dollarsAmount >= dollarsAmount)
          .map(({ formatted, centsAmount }) => (
            <Button
              key={`denom-${centsAmount}`}
              variant={
                centsAmount === reloadThresholdCents
                  ? ButtonVariants.toggle
                  : ButtonVariants.toggleNotSelected
              }
              onClick={() => onChange(centsAmount)}
            >
              {formatted}
            </Button>
          ))}
      </div>

      <p className="text-gray-500 text-sm mt-2 italic">
        Reload to {seletedDenom.value.formatted} when the balance drops below{" "}
        {formatMoney(reloadThresholdCents / 100, 0)}
      </p>
    </div>
  );
};
