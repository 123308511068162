import { onboardingQueries } from "@core-shop/cache/queries/onboarding";
import { queryClient } from "@core-shop/providers/QueryProvider";
import { OnboardingInfoData_Viewer } from "@core-shop/types";
import { newToOldStepsMapper } from "./constants";
import { steps } from "./initOnboardingSteps";
import { OnboardingRoles, OnboardingStepName, StepsTime } from "./types";

interface IGetSteps {
  time: StepsTime;
}

function getRole(viewer: OnboardingInfoData_Viewer) {
  if (viewer.isCaptain) return OnboardingRoles.CAPTAIN;
  if (viewer.activeCampaign?.id) return OnboardingRoles.MEMBER;
  return null;
}

export async function getSteps({ time }: IGetSteps) {
  const { Viewer } = await queryClient.fetchQuery(onboardingQueries.onboardingInfo());
  const currentOnboardingStep = Viewer.teamOnboardingInfo.currentStep;
  const role = getRole(Viewer);

  if (!currentOnboardingStep || !role) return [];

  const onboardingSteps = steps[time][role];
  let currentStep = Object.keys(newToOldStepsMapper).find((oldSteps) =>
    oldSteps.includes(currentOnboardingStep)
  ) as unknown as OnboardingStepName;

  if (!currentStep)
    currentStep = role === OnboardingRoles.CAPTAIN ? OnboardingStepName.CREATE_TEAM : OnboardingStepName.JOIN;

  const currentStepIndex = onboardingSteps.findIndex((stepName) => currentStep === stepName);
  const remainingSteps = onboardingSteps.slice(currentStepIndex - 1);

  return remainingSteps;
}
