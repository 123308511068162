import React, { ReactNode } from "react";
import { BackTopNav } from "../BackTopNav/BackTopNav";
import { Modal, ModalSizes } from "../Modal/Modal";

interface IProps {
  children: ReactNode;
  isOpen: boolean;
  iconClassName?: string;
  IconRight?: React.FC<{ className?: string; onClick?: () => void }>;
  id?: string;
  isLoading?: boolean;
  onClickIcon?: () => void;
  onClose?: () => void;
  subtitle?: string;
  title?: string;
  zIndex?: number;
}

export const ShopModal: React.FC<IProps> = ({
  onClose,
  children,
  isOpen,
  title,
  isLoading,
  IconRight,
  iconClassName,
  onClickIcon,
  id,
  zIndex,
  subtitle,
}) => {
  return (
    <Modal
      isFullScreen
      isInnerContainerDisabled
      contentWrapperClassNames="overflow-y-scroll sm:mx-auto sm:max-w-2xl bg-white md:shadow-lg min-h-screen"
      CustomHeader={
        !isLoading && (
          <BackTopNav
            className="border-b"
            hasBackText={false}
            hasBottomSpace={false}
            iconClassName={iconClassName}
            IconRight={IconRight}
            subtitle={subtitle}
            title={title}
            onClick={onClose}
            onClickIcon={onClickIcon}
          />
        )
      }
      id={id}
      isLoading={isLoading}
      isOpen={isOpen}
      overlayClassName="bg-transparent"
      size={ModalSizes.full}
      zIndex={zIndex}
      onClose={onClose}
    >
      {children}
    </Modal>
  );
};
