import gql from "graphql-tag";

export const getBrandQuery = gql`
  query GetBrand($id: ID!, $linkTypes: [LinkType!]!, $locationId: ID, $includeLocation: Boolean!) {
    Brand(id: $id) {
      id
      name
      viewerHasFavorited
      description
      locationsCount
      location(id: $locationId) @include(if: $includeLocation) {
        id
        latitude
        longitude
        googlePlacesId
      }
      images {
        hero
        invertedLogo
        logo
        logoBackgroundColor
      }
      maxGiveLink {
        id
        giveAmount
      }
      links(linkTypes: $linkTypes) {
        edges {
          cursor
          node {
            id
            name
            type
            giftcardType
            giveSentence
            labelPromotionId
            commissionPromotionId
            previewInfo {
              icon
              description
              sectionName
              promotionsCount
            }
          }
        }
      }
    }
  }
`;
