import React from "react";
import { useBrand, useCloudAuthState, useToggleFavoriteBrand } from "@core-shop/hooks";
import { IBrandModalViewProps } from "./IBrandModalViewProps";

export interface IProps {
  onClose: $IntentionalAny;
  View: React.FC<IBrandModalViewProps>;
  id?: string;
  locationId?: string;
}

export const BrandModal: React.FC<IProps> = ({ View, id, locationId, onClose }) => {
  const brandQuery = useBrand({ id, locationId });
  const favoriteMutation = useToggleFavoriteBrand({ id });
  const { isLoading, fgStatus } = useCloudAuthState();
  const shouldShowLikeButton = !isLoading && fgStatus.hasUser;

  if (!id) return null;
  return (
    <View
      brand={brandQuery.data?.Brand ?? {}}
      brandQuery={brandQuery}
      favoriteMutation={favoriteMutation}
      shouldShowLikeButton={shouldShowLikeButton}
      onClose={onClose}
    />
  );
};
