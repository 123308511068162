/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBrand
// ====================================================

export interface GetBrand_Brand_location {
  readonly __typename: "Location";
  /**
   * Location's ID
   */
  readonly id: string;
  /**
   * Latitude
   */
  readonly latitude: number;
  /**
   * Longitude
   */
  readonly longitude: number;
  /**
   * Location's Google Places ID
   */
  readonly googlePlacesId: string | null;
}

export interface GetBrand_Brand_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's hero image URL
   */
  readonly hero: string | null;
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface GetBrand_Brand_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's give amount
   */
  readonly giveAmount: string;
}

export interface GetBrand_Brand_links_edges_node_previewInfo {
  readonly __typename: "LinkPreviewInfo";
  /**
   * The link's preview text to show on the brand modal
   */
  readonly icon: string;
  /**
   * The link's preview text to show on the brand modal
   */
  readonly description: string;
  /**
   * What section in the brand modal this link belongs to
   */
  readonly sectionName: string;
  /**
   * The link's promotion count
   */
  readonly promotionsCount: number;
}

export interface GetBrand_Brand_links_edges_node {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * Link's type
   */
  readonly type: LinkType;
  /**
   * Type of gift card
   */
  readonly giftcardType: string | null;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Link's label promotion ID
   */
  readonly labelPromotionId: string | null;
  /**
   * Link's commission promotion ID
   */
  readonly commissionPromotionId: string | null;
  /**
   * The link's preview text to show on the brand modal
   */
  readonly previewInfo: GetBrand_Brand_links_edges_node_previewInfo;
}

export interface GetBrand_Brand_links_edges {
  readonly __typename: "LinkEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: GetBrand_Brand_links_edges_node | null;
}

export interface GetBrand_Brand_links {
  readonly __typename: "LinkConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<GetBrand_Brand_links_edges | null> | null;
}

export interface GetBrand_Brand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Whether the current viewer has favourited this brand
   */
  readonly viewerHasFavorited: boolean;
  /**
   * Brand's Description
   */
  readonly description: string | null;
  /**
   * Find locations based on coordinates
   */
  readonly locationsCount: number;
  /**
   * Get a specific location
   */
  readonly location: GetBrand_Brand_location | null;
  /**
   * Brand's images
   */
  readonly images: GetBrand_Brand_images;
  readonly maxGiveLink: GetBrand_Brand_maxGiveLink | null;
  /**
   * A list of the brand's links
   */
  readonly links: GetBrand_Brand_links;
}

export interface GetBrand {
  /**
   * Find a Brand by ID
   */
  readonly Brand: GetBrand_Brand | null;
}

export interface GetBrandVariables {
  readonly id: string;
  readonly linkTypes: ReadonlyArray<LinkType>;
  readonly locationId?: string | null;
  readonly includeLocation: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LocationSearch
// ====================================================

export interface LocationSearch_LocationSearch_edges_node_brand_location {
  readonly __typename: "Location";
  /**
   * Location's ID
   */
  readonly id: string;
}

export interface LocationSearch_LocationSearch_edges_node_brand_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's hero image URL
   */
  readonly hero: string | null;
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface LocationSearch_LocationSearch_edges_node_brand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Get a specific location
   */
  readonly location: LocationSearch_LocationSearch_edges_node_brand_location | null;
  /**
   * Brand's images
   */
  readonly images: LocationSearch_LocationSearch_edges_node_brand_images;
}

export interface LocationSearch_LocationSearch_edges_node {
  readonly __typename: "Location";
  /**
   * Location's ID
   */
  readonly id: string;
  /**
   * Is this a sponsored location for nearby results
   */
  readonly sponsored: boolean;
  /**
   * Brand for this location
   */
  readonly brand: LocationSearch_LocationSearch_edges_node_brand;
}

export interface LocationSearch_LocationSearch_edges {
  readonly __typename: "LocationEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: LocationSearch_LocationSearch_edges_node | null;
}

export interface LocationSearch_LocationSearch {
  readonly __typename: "LocationConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<LocationSearch_LocationSearch_edges | null> | null;
}

export interface LocationSearch {
  /**
   * Find locations based on coordinates
   */
  readonly LocationSearch: LocationSearch_LocationSearch;
}

export interface LocationSearchVariables {
  readonly coordinates?: CoordinatesInput | null;
  readonly currency?: Currency | null;
  readonly first?: number | null;
  readonly distance?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CloudData
// ====================================================

export interface CloudData_ShopCloud_activeCampaign_amountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface CloudData_ShopCloud_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * Campaign's currency
   */
  readonly currency: Currency;
  /**
   * Campaigns amount raised
   */
  readonly amountRaised: CloudData_ShopCloud_activeCampaign_amountRaised;
  /**
   * Campaign's photo image URL
   */
  readonly photoImage: string | null;
  /**
   * Campaign's longitude
   */
  readonly longitude: number | null;
  /**
   * Campaign's latitude
   */
  readonly latitude: number | null;
}

export interface CloudData_ShopCloud_activeFundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
}

export interface CloudData_ShopCloud {
  readonly __typename: "ShopCloud";
  /**
   * ShopCloud's ID
   */
  readonly id: string;
  /**
   * Campaign Currency
   */
  readonly campaignCurrency: string | null;
  /**
   * Currency
   */
  readonly currency: string | null;
  /**
   * Token state
   */
  readonly state: string;
  /**
   * User Currency
   */
  readonly userCurrency: string | null;
  /**
   * ShopCloud's Deal Color
   */
  readonly dealColor: string | null;
  /**
   * ShopCloud's Primary Color
   */
  readonly primaryColor: string | null;
  /**
   * ShopCloud's Link Color
   */
  readonly secondaryColor: string | null;
  /**
   * Logo url
   */
  readonly logoMobileUrl: string | null;
  /**
   * Active campaign on the shop cloud partner
   */
  readonly activeCampaign: CloudData_ShopCloud_activeCampaign | null;
  /**
   * Active campaign's fundraiser on the shop cloud partner
   */
  readonly activeFundraiser: CloudData_ShopCloud_activeFundraiser | null;
}

export interface CloudData {
  /**
   * Query the Authenticated shop cloud
   */
  readonly ShopCloud: CloudData_ShopCloud | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CloudViewer
// ====================================================

export interface CloudViewer_ShopCloud_viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * User's full name
   */
  readonly name: string | null;
  /**
   * The country for this user
   */
  readonly country: Country;
  /**
   * User's email address
   */
  readonly email: string;
}

export interface CloudViewer_ShopCloud {
  readonly __typename: "ShopCloud";
  /**
   * ShopCloud's ID
   */
  readonly id: string;
  /**
   * Viwer on the shop cloud partner
   */
  readonly viewer: CloudViewer_ShopCloud_viewer | null;
}

export interface CloudViewer {
  /**
   * Query the Authenticated shop cloud
   */
  readonly ShopCloud: CloudViewer_ShopCloud | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OptInMutation
// ====================================================

export interface OptInMutation_OptIn_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface OptInMutation_OptIn_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<OptInMutation_OptIn_MutationError_errors>;
}

export interface OptInMutation_OptIn_ShopCloud_activeCampaign_amountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface OptInMutation_OptIn_ShopCloud_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * Campaign's currency
   */
  readonly currency: Currency;
  /**
   * Campaigns amount raised
   */
  readonly amountRaised: OptInMutation_OptIn_ShopCloud_activeCampaign_amountRaised;
  /**
   * Campaign's photo image URL
   */
  readonly photoImage: string | null;
  /**
   * Campaign's longitude
   */
  readonly longitude: number | null;
  /**
   * Campaign's latitude
   */
  readonly latitude: number | null;
}

export interface OptInMutation_OptIn_ShopCloud_activeFundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
}

export interface OptInMutation_OptIn_ShopCloud {
  readonly __typename: "ShopCloud";
  /**
   * ShopCloud's ID
   */
  readonly id: string;
  /**
   * Campaign Currency
   */
  readonly campaignCurrency: string | null;
  /**
   * Currency
   */
  readonly currency: string | null;
  /**
   * Token state
   */
  readonly state: string;
  /**
   * User Currency
   */
  readonly userCurrency: string | null;
  /**
   * ShopCloud's Deal Color
   */
  readonly dealColor: string | null;
  /**
   * ShopCloud's Primary Color
   */
  readonly primaryColor: string | null;
  /**
   * ShopCloud's Link Color
   */
  readonly secondaryColor: string | null;
  /**
   * Logo url
   */
  readonly logoMobileUrl: string | null;
  /**
   * Active campaign on the shop cloud partner
   */
  readonly activeCampaign: OptInMutation_OptIn_ShopCloud_activeCampaign | null;
  /**
   * Active campaign's fundraiser on the shop cloud partner
   */
  readonly activeFundraiser: OptInMutation_OptIn_ShopCloud_activeFundraiser | null;
}

export type OptInMutation_OptIn = OptInMutation_OptIn_MutationError | OptInMutation_OptIn_ShopCloud;

export interface OptInMutation {
  /**
   * Registers a user that opts in to shop cloud
   */
  readonly OptIn: OptInMutation_OptIn;
}

export interface OptInMutationVariables {
  readonly agreedToTos: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FavoritesData
// ====================================================

export interface FavoritesData_Viewer_favoriteBrands_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface FavoritesData_Viewer_favoriteBrands_maxGiveLink_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
  /**
   * Link's promo ends at
   */
  readonly promotionEndsAt: DateTimeISO | null;
  /**
   * The Link's most recent promotion label
   */
  readonly promotionLabel: string | null;
}

export interface FavoritesData_Viewer_favoriteBrands_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Link's give amount
   */
  readonly giveAmount: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: FavoritesData_Viewer_favoriteBrands_maxGiveLink_promotionInfo;
}

export interface FavoritesData_Viewer_favoriteBrands {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Whether the current viewer has favourited this brand
   */
  readonly viewerHasFavorited: boolean;
  /**
   * Brand's images
   */
  readonly images: FavoritesData_Viewer_favoriteBrands_images;
  readonly maxGiveLink: FavoritesData_Viewer_favoriteBrands_maxGiveLink | null;
}

export interface FavoritesData_Viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Favorite brands for this user
   */
  readonly favoriteBrands: ReadonlyArray<FavoritesData_Viewer_favoriteBrands>;
}

export interface FavoritesData {
  /**
   * Retrieve details about the logged in user
   */
  readonly Viewer: FavoritesData_Viewer;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ReorderFavoritesNewMutation
// ====================================================

export interface ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_User {
  readonly __typename: "User";
}

export interface ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_MutationError_errors>;
}

export interface ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_Viewer_favoriteBrands_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_Viewer_favoriteBrands_maxGiveLink_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
  /**
   * Link's promo ends at
   */
  readonly promotionEndsAt: DateTimeISO | null;
  /**
   * The Link's most recent promotion label
   */
  readonly promotionLabel: string | null;
}

export interface ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_Viewer_favoriteBrands_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Link's give amount
   */
  readonly giveAmount: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_Viewer_favoriteBrands_maxGiveLink_promotionInfo;
}

export interface ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_Viewer_favoriteBrands {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Whether the current viewer has favourited this brand
   */
  readonly viewerHasFavorited: boolean;
  /**
   * Brand's images
   */
  readonly images: ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_Viewer_favoriteBrands_images;
  readonly maxGiveLink: ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_Viewer_favoriteBrands_maxGiveLink | null;
}

export interface ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_Viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Favorite brands for this user
   */
  readonly favoriteBrands: ReadonlyArray<ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_Viewer_favoriteBrands>;
}

export type ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands =
  | ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_User
  | ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_MutationError
  | ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_Viewer;

export interface ReorderFavoritesNewMutation {
  /**
   * Reorder the user's favorite brands
   */
  readonly ViewerReorderFavoriteBrands: ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands;
}

export interface ReorderFavoritesNewMutationVariables {
  readonly brandIds: ReadonlyArray<string>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleFavoriteBrandMutation
// ====================================================

export interface ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_MutationError_errors>;
}

export interface ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_Brand_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_Brand_maxGiveLink_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
  /**
   * Link's promo ends at
   */
  readonly promotionEndsAt: DateTimeISO | null;
  /**
   * The Link's most recent promotion label
   */
  readonly promotionLabel: string | null;
}

export interface ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_Brand_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Link's give amount
   */
  readonly giveAmount: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_Brand_maxGiveLink_promotionInfo;
}

export interface ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_Brand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Whether the current viewer has favourited this brand
   */
  readonly viewerHasFavorited: boolean;
  /**
   * Brand's images
   */
  readonly images: ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_Brand_images;
  readonly maxGiveLink: ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_Brand_maxGiveLink | null;
}

export type ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand =
  | ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_MutationError
  | ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand_Brand;

export interface ToggleFavoriteBrandMutation {
  /**
   * Toggle a favorite brand for this user
   */
  readonly ViewerToggleFavoriteBrand: ToggleFavoriteBrandMutation_ViewerToggleFavoriteBrand;
}

export interface ToggleFavoriteBrandMutationVariables {
  readonly brandId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ActivityQueryAll
// ====================================================

export interface ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges_node_link_defaultBrand_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges_node_link_defaultBrand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Brand's images
   */
  readonly images: ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges_node_link_defaultBrand_images;
}

export interface ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges_node_link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's type
   */
  readonly type: LinkType;
  /**
   * Link's default brand
   */
  readonly defaultBrand: ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges_node_link_defaultBrand;
}

export interface ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges_node_fundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's first name
   */
  readonly firstName: string;
  /**
   * Fundraisers's last name
   */
  readonly lastName: string | null;
  /**
   * Fundraisers's avatar image URL
   */
  readonly avatarImage: string | null;
}

export interface ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges_node_campaignAmount {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges_node_missingClaim {
  readonly __typename: "MissingClaim";
  /**
   * Missing Claim's ID
   */
  readonly id: string;
  readonly status: MissingClaimStatusEnum;
  /**
   * Missing claim's denied reason
   */
  readonly denialReason: string | null;
}

export interface ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges_node {
  readonly __typename: "Intent";
  /**
   * Intent's ID
   */
  readonly id: string;
  /**
   * When the intent was created
   */
  readonly createdAt: DateTimeISO;
  /**
   * Intent's reference ID
   */
  readonly reference: string;
  /**
   * is the intent transferrable? it is not transferrable if its transfer share has been paid out
   */
  readonly isTransferrable: boolean;
  /**
   * The intent's buyer name
   */
  readonly buyer_name: string | null;
  /**
   * Intent's type
   */
  readonly intentType: IntentTypeEnum;
  /**
   * Can the user submit a missing claim
   */
  readonly canClaimMissing: boolean;
  /**
   * When the intent was supported
   */
  readonly supportedAt: DateTimeISO | null;
  /**
   * When you can expect earnings if purchase has been made
   */
  readonly expectedEarnedOn: DateISO | null;
  /**
   * Intent's token
   */
  readonly token: string;
  /**
   * The intent's link
   */
  readonly link: ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges_node_link | null;
  /**
   * The fundraiser of the intent
   */
  readonly fundraiser: ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges_node_fundraiser | null;
  /**
   * How much was raised for the campaign
   */
  readonly campaignAmount: ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges_node_campaignAmount | null;
  /**
   * Intent's missing claim
   */
  readonly missingClaim: ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges_node_missingClaim | null;
}

export interface ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges {
  readonly __typename: "IntentEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges_node | null;
}

export interface ActivityQueryAll_ShopCloud_viewer_intentsPaginated_pageInfo {
  readonly __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  readonly hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  readonly endCursor: string | null;
}

export interface ActivityQueryAll_ShopCloud_viewer_intentsPaginated {
  readonly __typename: "IntentConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<ActivityQueryAll_ShopCloud_viewer_intentsPaginated_edges | null> | null;
  /**
   * Information to aid in pagination.
   */
  readonly pageInfo: ActivityQueryAll_ShopCloud_viewer_intentsPaginated_pageInfo;
}

export interface ActivityQueryAll_ShopCloud_viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * The viewer's intents
   */
  readonly intentsPaginated: ActivityQueryAll_ShopCloud_viewer_intentsPaginated;
}

export interface ActivityQueryAll_ShopCloud {
  readonly __typename: "ShopCloud";
  /**
   * ShopCloud's ID
   */
  readonly id: string;
  /**
   * Viwer on the shop cloud partner
   */
  readonly viewer: ActivityQueryAll_ShopCloud_viewer | null;
}

export interface ActivityQueryAll {
  /**
   * Query the Authenticated shop cloud
   */
  readonly ShopCloud: ActivityQueryAll_ShopCloud | null;
}

export interface ActivityQueryAllVariables {
  readonly after?: string | null;
  readonly first?: number | null;
  readonly filterBy?: IntentFilterByEnum | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ActivityQueryFundraiser
// ====================================================

export interface ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges_node_link_defaultBrand_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges_node_link_defaultBrand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Brand's images
   */
  readonly images: ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges_node_link_defaultBrand_images;
}

export interface ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges_node_link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's type
   */
  readonly type: LinkType;
  /**
   * Link's default brand
   */
  readonly defaultBrand: ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges_node_link_defaultBrand;
}

export interface ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges_node_fundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's first name
   */
  readonly firstName: string;
  /**
   * Fundraisers's last name
   */
  readonly lastName: string | null;
  /**
   * Fundraisers's avatar image URL
   */
  readonly avatarImage: string | null;
}

export interface ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges_node_campaignAmount {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges_node_missingClaim {
  readonly __typename: "MissingClaim";
  /**
   * Missing Claim's ID
   */
  readonly id: string;
  readonly status: MissingClaimStatusEnum;
  /**
   * Missing claim's denied reason
   */
  readonly denialReason: string | null;
}

export interface ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges_node {
  readonly __typename: "Intent";
  /**
   * Intent's ID
   */
  readonly id: string;
  /**
   * When the intent was created
   */
  readonly createdAt: DateTimeISO;
  /**
   * Intent's reference ID
   */
  readonly reference: string;
  /**
   * is the intent transferrable? it is not transferrable if its transfer share has been paid out
   */
  readonly isTransferrable: boolean;
  /**
   * The intent's buyer name
   */
  readonly buyer_name: string | null;
  /**
   * Intent's type
   */
  readonly intentType: IntentTypeEnum;
  /**
   * Can the user submit a missing claim
   */
  readonly canClaimMissing: boolean;
  /**
   * When the intent was supported
   */
  readonly supportedAt: DateTimeISO | null;
  /**
   * When you can expect earnings if purchase has been made
   */
  readonly expectedEarnedOn: DateISO | null;
  /**
   * Intent's token
   */
  readonly token: string;
  /**
   * The intent's link
   */
  readonly link: ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges_node_link | null;
  /**
   * The fundraiser of the intent
   */
  readonly fundraiser: ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges_node_fundraiser | null;
  /**
   * How much was raised for the campaign
   */
  readonly campaignAmount: ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges_node_campaignAmount | null;
  /**
   * Intent's missing claim
   */
  readonly missingClaim: ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges_node_missingClaim | null;
}

export interface ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges {
  readonly __typename: "IntentEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges_node | null;
}

export interface ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_pageInfo {
  readonly __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  readonly hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  readonly endCursor: string | null;
}

export interface ActivityQueryFundraiser_Campaign_supportedIntentsPaginated {
  readonly __typename: "IntentConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_edges | null> | null;
  /**
   * Information to aid in pagination.
   */
  readonly pageInfo: ActivityQueryFundraiser_Campaign_supportedIntentsPaginated_pageInfo;
}

export interface ActivityQueryFundraiser_Campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Supported intents, filtered optionally by user or fundraiser.
   */
  readonly supportedIntentsPaginated: ActivityQueryFundraiser_Campaign_supportedIntentsPaginated;
}

export interface ActivityQueryFundraiser {
  /**
   * Find a Campaign by ID
   */
  readonly Campaign: ActivityQueryFundraiser_Campaign | null;
}

export interface ActivityQueryFundraiserVariables {
  readonly after?: string | null;
  readonly first?: number | null;
  readonly fundraiserUserId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AmountsBreakdownQuery
// ====================================================

export interface AmountsBreakdownQuery_ShopCloud_activeCampaign_goal {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface AmountsBreakdownQuery_ShopCloud_activeCampaign_amountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface AmountsBreakdownQuery_ShopCloud_activeCampaign_amountRaisedBreakdown_shopping {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface AmountsBreakdownQuery_ShopCloud_activeCampaign_amountRaisedBreakdown_bonuses {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface AmountsBreakdownQuery_ShopCloud_activeCampaign_amountRaisedBreakdown_donations {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface AmountsBreakdownQuery_ShopCloud_activeCampaign_amountRaisedBreakdown_team {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface AmountsBreakdownQuery_ShopCloud_activeCampaign_amountRaisedBreakdown_transfers {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface AmountsBreakdownQuery_ShopCloud_activeCampaign_amountRaisedBreakdown {
  readonly __typename: "AmountRaisedBreakdown";
  /**
   * Money earned via shopping
   */
  readonly shopping: AmountsBreakdownQuery_ShopCloud_activeCampaign_amountRaisedBreakdown_shopping;
  /**
   * Money earned via bonuses
   */
  readonly bonuses: AmountsBreakdownQuery_ShopCloud_activeCampaign_amountRaisedBreakdown_bonuses;
  /**
   * Money earned via donations
   */
  readonly donations: AmountsBreakdownQuery_ShopCloud_activeCampaign_amountRaisedBreakdown_donations;
  /**
   * Money earned for team
   */
  readonly team: AmountsBreakdownQuery_ShopCloud_activeCampaign_amountRaisedBreakdown_team;
  /**
   * Money earned via transfers
   */
  readonly transfers: AmountsBreakdownQuery_ShopCloud_activeCampaign_amountRaisedBreakdown_transfers;
}

export interface AmountsBreakdownQuery_ShopCloud_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's goal amount
   */
  readonly goal: AmountsBreakdownQuery_ShopCloud_activeCampaign_goal | null;
  /**
   * Campaigns amount raised
   */
  readonly amountRaised: AmountsBreakdownQuery_ShopCloud_activeCampaign_amountRaised;
  /**
   * a breakdown of the amount raised by this campaign
   */
  readonly amountRaisedBreakdown: AmountsBreakdownQuery_ShopCloud_activeCampaign_amountRaisedBreakdown;
}

export interface AmountsBreakdownQuery_ShopCloud {
  readonly __typename: "ShopCloud";
  /**
   * ShopCloud's ID
   */
  readonly id: string;
  /**
   * Active campaign on the shop cloud partner
   */
  readonly activeCampaign: AmountsBreakdownQuery_ShopCloud_activeCampaign | null;
}

export interface AmountsBreakdownQuery {
  /**
   * Query the Authenticated shop cloud
   */
  readonly ShopCloud: AmountsBreakdownQuery_ShopCloud | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAmountWithdrawnBreakdownQuery
// ====================================================

export interface GetAmountWithdrawnBreakdownQuery_Campaign_amountWithdrawnBreakdown_available {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GetAmountWithdrawnBreakdownQuery_Campaign_amountWithdrawnBreakdown_pending {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GetAmountWithdrawnBreakdownQuery_Campaign_amountWithdrawnBreakdown_withdrawn {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GetAmountWithdrawnBreakdownQuery_Campaign_amountWithdrawnBreakdown_withdrawalAmount {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GetAmountWithdrawnBreakdownQuery_Campaign_amountWithdrawnBreakdown {
  readonly __typename: "AmountWithdrawnBreakdown";
  /**
   * Money available
   */
  readonly available: GetAmountWithdrawnBreakdownQuery_Campaign_amountWithdrawnBreakdown_available;
  /**
   * Money held
   */
  readonly pending: GetAmountWithdrawnBreakdownQuery_Campaign_amountWithdrawnBreakdown_pending;
  /**
   * Money withdrawn
   */
  readonly withdrawn: GetAmountWithdrawnBreakdownQuery_Campaign_amountWithdrawnBreakdown_withdrawn;
  /**
   * Money available for withdraw
   */
  readonly withdrawalAmount: GetAmountWithdrawnBreakdownQuery_Campaign_amountWithdrawnBreakdown_withdrawalAmount;
}

export interface GetAmountWithdrawnBreakdownQuery_Campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * a breakdown of the funds withdrawn by this campaign
   */
  readonly amountWithdrawnBreakdown: GetAmountWithdrawnBreakdownQuery_Campaign_amountWithdrawnBreakdown;
}

export interface GetAmountWithdrawnBreakdownQuery {
  /**
   * Find a Campaign by ID
   */
  readonly Campaign: GetAmountWithdrawnBreakdownQuery_Campaign | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFundraisersList
// ====================================================

export interface GetFundraisersList_ShopCloud_activeCampaign_teamMembers_all_pageInfo {
  readonly __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  readonly hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  readonly endCursor: string | null;
}

export interface GetFundraisersList_ShopCloud_activeCampaign_teamMembers_all_edges_node_amountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GetFundraisersList_ShopCloud_activeCampaign_teamMembers_all_edges_node {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's user ID
   */
  readonly userId: string | null;
  /**
   * Fundraisers's avatar image URL
   */
  readonly avatarImage: string | null;
  /**
   * Fundraisers's first name
   */
  readonly firstName: string;
  /**
   * Fundraisers's last name
   */
  readonly lastName: string | null;
  /**
   * Fundraisers's amount raised
   */
  readonly amountRaised: GetFundraisersList_ShopCloud_activeCampaign_teamMembers_all_edges_node_amountRaised;
}

export interface GetFundraisersList_ShopCloud_activeCampaign_teamMembers_all_edges {
  readonly __typename: "FundraiserEdge";
  /**
   * The item at the end of the edge.
   */
  readonly node: GetFundraisersList_ShopCloud_activeCampaign_teamMembers_all_edges_node | null;
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
}

export interface GetFundraisersList_ShopCloud_activeCampaign_teamMembers_all {
  readonly __typename: "FundraiserConnection";
  /**
   * Information to aid in pagination.
   */
  readonly pageInfo: GetFundraisersList_ShopCloud_activeCampaign_teamMembers_all_pageInfo;
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<GetFundraisersList_ShopCloud_activeCampaign_teamMembers_all_edges | null> | null;
}

export interface GetFundraisersList_ShopCloud_activeCampaign_teamMembers {
  readonly __typename: "TeamMembersInfo";
  /**
   * All of a team's team members
   */
  readonly all: GetFundraisersList_ShopCloud_activeCampaign_teamMembers_all;
}

export interface GetFundraisersList_ShopCloud_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Team's team members info
   */
  readonly teamMembers: GetFundraisersList_ShopCloud_activeCampaign_teamMembers;
}

export interface GetFundraisersList_ShopCloud {
  readonly __typename: "ShopCloud";
  /**
   * ShopCloud's ID
   */
  readonly id: string;
  /**
   * Active campaign on the shop cloud partner
   */
  readonly activeCampaign: GetFundraisersList_ShopCloud_activeCampaign | null;
}

export interface GetFundraisersList {
  /**
   * Query the Authenticated shop cloud
   */
  readonly ShopCloud: GetFundraisersList_ShopCloud | null;
}

export interface GetFundraisersListVariables {
  readonly first?: number | null;
  readonly after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetIntentQuery
// ====================================================

export interface GetIntentQuery_Intent_link_defaultBrand_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface GetIntentQuery_Intent_link_defaultBrand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Brand's images
   */
  readonly images: GetIntentQuery_Intent_link_defaultBrand_images;
}

export interface GetIntentQuery_Intent_link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's type
   */
  readonly type: LinkType;
  /**
   * Link's default brand
   */
  readonly defaultBrand: GetIntentQuery_Intent_link_defaultBrand;
}

export interface GetIntentQuery_Intent_fundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's first name
   */
  readonly firstName: string;
  /**
   * Fundraisers's last name
   */
  readonly lastName: string | null;
  /**
   * Fundraisers's avatar image URL
   */
  readonly avatarImage: string | null;
}

export interface GetIntentQuery_Intent_campaignAmount {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GetIntentQuery_Intent_missingClaim {
  readonly __typename: "MissingClaim";
  /**
   * Missing Claim's ID
   */
  readonly id: string;
  readonly status: MissingClaimStatusEnum;
  /**
   * Missing claim's denied reason
   */
  readonly denialReason: string | null;
}

export interface GetIntentQuery_Intent {
  readonly __typename: "Intent";
  /**
   * Intent's ID
   */
  readonly id: string;
  /**
   * When the intent was created
   */
  readonly createdAt: DateTimeISO;
  /**
   * Intent's reference ID
   */
  readonly reference: string;
  /**
   * is the intent transferrable? it is not transferrable if its transfer share has been paid out
   */
  readonly isTransferrable: boolean;
  /**
   * The intent's buyer name
   */
  readonly buyer_name: string | null;
  /**
   * Intent's type
   */
  readonly intentType: IntentTypeEnum;
  /**
   * Can the user submit a missing claim
   */
  readonly canClaimMissing: boolean;
  /**
   * When the intent was supported
   */
  readonly supportedAt: DateTimeISO | null;
  /**
   * When you can expect earnings if purchase has been made
   */
  readonly expectedEarnedOn: DateISO | null;
  /**
   * Intent's token
   */
  readonly token: string;
  /**
   * The intent's link
   */
  readonly link: GetIntentQuery_Intent_link | null;
  /**
   * The fundraiser of the intent
   */
  readonly fundraiser: GetIntentQuery_Intent_fundraiser | null;
  /**
   * How much was raised for the campaign
   */
  readonly campaignAmount: GetIntentQuery_Intent_campaignAmount | null;
  /**
   * Intent's missing claim
   */
  readonly missingClaim: GetIntentQuery_Intent_missingClaim | null;
}

export interface GetIntentQuery {
  /**
   * Find an Intent by an ID
   */
  readonly Intent: GetIntentQuery_Intent | null;
}

export interface GetIntentQueryVariables {
  readonly id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPayoutDetailsQuery
// ====================================================

export interface GetPayoutDetailsQuery_Campaign_payment_amount {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GetPayoutDetailsQuery_Campaign_payment_recipients_edges_node_amount {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GetPayoutDetailsQuery_Campaign_payment_recipients_edges_node_fundraiser_teamRoles {
  readonly __typename: "TeamRole";
  /**
   * The role's ID
   */
  readonly id: string;
  /**
   * The role's name
   */
  readonly name: string;
}

export interface GetPayoutDetailsQuery_Campaign_payment_recipients_edges_node_fundraiser_amountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GetPayoutDetailsQuery_Campaign_payment_recipients_edges_node_fundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's avatar image URL
   */
  readonly avatarImage: string | null;
  /**
   * Fundraisers's full name
   */
  readonly name: string;
  /**
   * The team member's roles
   */
  readonly teamRoles: ReadonlyArray<GetPayoutDetailsQuery_Campaign_payment_recipients_edges_node_fundraiser_teamRoles>;
  /**
   * Fundraisers's amount raised
   */
  readonly amountRaised: GetPayoutDetailsQuery_Campaign_payment_recipients_edges_node_fundraiser_amountRaised;
}

export interface GetPayoutDetailsQuery_Campaign_payment_recipients_edges_node {
  readonly __typename: "PaymentRecipient";
  /**
   * ID for Payment Recipient
   */
  readonly id: string;
  /**
   * Amount to this recipient for a payment
   */
  readonly amount: GetPayoutDetailsQuery_Campaign_payment_recipients_edges_node_amount;
  /**
   * Fundraiser
   */
  readonly fundraiser: GetPayoutDetailsQuery_Campaign_payment_recipients_edges_node_fundraiser | null;
}

export interface GetPayoutDetailsQuery_Campaign_payment_recipients_edges {
  readonly __typename: "PaymentRecipientEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: GetPayoutDetailsQuery_Campaign_payment_recipients_edges_node | null;
}

export interface GetPayoutDetailsQuery_Campaign_payment_recipients_pageInfo {
  readonly __typename: "PageInfo";
  /**
   * When paginating forwards, the cursor to continue.
   */
  readonly endCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  readonly hasNextPage: boolean;
}

export interface GetPayoutDetailsQuery_Campaign_payment_recipients {
  readonly __typename: "PaymentRecipientConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<GetPayoutDetailsQuery_Campaign_payment_recipients_edges | null> | null;
  /**
   * Information to aid in pagination.
   */
  readonly pageInfo: GetPayoutDetailsQuery_Campaign_payment_recipients_pageInfo;
}

export interface GetPayoutDetailsQuery_Campaign_payment {
  readonly __typename: "Payment";
  /**
   * Payment's ID
   */
  readonly id: string;
  /**
   * Payment state
   */
  readonly state: string;
  /**
   * When the payment was processed
   */
  readonly processedAt: DateTimeISO | null;
  /**
   * Payment amount
   */
  readonly amount: GetPayoutDetailsQuery_Campaign_payment_amount;
  /**
   * Recipients who have earned funds through this payment
   */
  readonly recipients: GetPayoutDetailsQuery_Campaign_payment_recipients;
}

export interface GetPayoutDetailsQuery_Campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Find a specific payment for this campaign
   */
  readonly payment: GetPayoutDetailsQuery_Campaign_payment | null;
}

export interface GetPayoutDetailsQuery {
  /**
   * Find a Campaign by ID
   */
  readonly Campaign: GetPayoutDetailsQuery_Campaign | null;
}

export interface GetPayoutDetailsQueryVariables {
  readonly first?: number | null;
  readonly after?: string | null;
  readonly id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetPayoutsQuery
// ====================================================

export interface GetPayoutsQuery_Campaign_payments_edges_node_amount {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GetPayoutsQuery_Campaign_payments_edges_node {
  readonly __typename: "Payment";
  /**
   * Payment's ID
   */
  readonly id: string;
  /**
   * When the payment was processed
   */
  readonly processedAt: DateTimeISO | null;
  /**
   * Payment state
   */
  readonly state: string;
  /**
   * Payment amount
   */
  readonly amount: GetPayoutsQuery_Campaign_payments_edges_node_amount;
}

export interface GetPayoutsQuery_Campaign_payments_edges {
  readonly __typename: "PaymentEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: GetPayoutsQuery_Campaign_payments_edges_node | null;
}

export interface GetPayoutsQuery_Campaign_payments_pageInfo {
  readonly __typename: "PageInfo";
  /**
   * When paginating forwards, the cursor to continue.
   */
  readonly endCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  readonly hasNextPage: boolean;
}

export interface GetPayoutsQuery_Campaign_payments {
  readonly __typename: "PaymentConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<GetPayoutsQuery_Campaign_payments_edges | null> | null;
  /**
   * Information to aid in pagination.
   */
  readonly pageInfo: GetPayoutsQuery_Campaign_payments_pageInfo;
}

export interface GetPayoutsQuery_Campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * See payments made to this campaign
   */
  readonly payments: GetPayoutsQuery_Campaign_payments;
}

export interface GetPayoutsQuery {
  /**
   * Find a Campaign by ID
   */
  readonly Campaign: GetPayoutsQuery_Campaign | null;
}

export interface GetPayoutsQueryVariables {
  readonly first?: number | null;
  readonly after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: JoinedFundraisersQuery
// ====================================================

export interface JoinedFundraisersQuery_ShopCloud_activeCampaign_teamMembers_joined_edges_node {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's full name
   */
  readonly name: string;
  /**
   * Fundraisers's avatar image URL
   */
  readonly avatarImage: string | null;
}

export interface JoinedFundraisersQuery_ShopCloud_activeCampaign_teamMembers_joined_edges {
  readonly __typename: "FundraiserEdge";
  /**
   * The item at the end of the edge.
   */
  readonly node: JoinedFundraisersQuery_ShopCloud_activeCampaign_teamMembers_joined_edges_node | null;
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
}

export interface JoinedFundraisersQuery_ShopCloud_activeCampaign_teamMembers_joined {
  readonly __typename: "FundraiserConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<JoinedFundraisersQuery_ShopCloud_activeCampaign_teamMembers_joined_edges | null> | null;
}

export interface JoinedFundraisersQuery_ShopCloud_activeCampaign_teamMembers {
  readonly __typename: "TeamMembersInfo";
  /**
   * Team members that have joined the team
   */
  readonly joined: JoinedFundraisersQuery_ShopCloud_activeCampaign_teamMembers_joined;
}

export interface JoinedFundraisersQuery_ShopCloud_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Team's team members info
   */
  readonly teamMembers: JoinedFundraisersQuery_ShopCloud_activeCampaign_teamMembers;
}

export interface JoinedFundraisersQuery_ShopCloud {
  readonly __typename: "ShopCloud";
  /**
   * ShopCloud's ID
   */
  readonly id: string;
  /**
   * Active campaign on the shop cloud partner
   */
  readonly activeCampaign: JoinedFundraisersQuery_ShopCloud_activeCampaign | null;
}

export interface JoinedFundraisersQuery {
  /**
   * Query the Authenticated shop cloud
   */
  readonly ShopCloud: JoinedFundraisersQuery_ShopCloud | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: TransferFundsMutation
// ====================================================

export interface TransferFundsMutation_TransferIntent_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface TransferFundsMutation_TransferIntent_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<TransferFundsMutation_TransferIntent_MutationError_errors>;
}

export interface TransferFundsMutation_TransferIntent_Campaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
}

export type TransferFundsMutation_TransferIntent =
  | TransferFundsMutation_TransferIntent_MutationError
  | TransferFundsMutation_TransferIntent_Campaign;

export interface TransferFundsMutation {
  /**
   * Transfer intents to another team member
   */
  readonly TransferIntent: TransferFundsMutation_TransferIntent;
}

export interface TransferFundsMutationVariables {
  readonly fundraiserId?: string | null;
  readonly intentId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateIntentGiftcardMutation
// ====================================================

export interface CreateIntentGiftcardMutation_GiftcardIntentCreate_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface CreateIntentGiftcardMutation_GiftcardIntentCreate_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<CreateIntentGiftcardMutation_GiftcardIntentCreate_MutationError_errors>;
}

export interface CreateIntentGiftcardMutation_GiftcardIntentCreate_GiftcardIntent {
  readonly __typename: "GiftcardIntent";
  /**
   * ID of the Intent
   */
  readonly id: string;
  /**
   * Intent token
   */
  readonly token: string;
}

export type CreateIntentGiftcardMutation_GiftcardIntentCreate =
  | CreateIntentGiftcardMutation_GiftcardIntentCreate_MutationError
  | CreateIntentGiftcardMutation_GiftcardIntentCreate_GiftcardIntent;

export interface CreateIntentGiftcardMutation {
  /**
   * Begin a giftcard purchase for a user.
   */
  readonly GiftcardIntentCreate: CreateIntentGiftcardMutation_GiftcardIntentCreate;
}

export interface CreateIntentGiftcardMutationVariables {
  readonly input: GiftcardIntentCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateIntentGiftcardReloadMutation
// ====================================================

export interface CreateIntentGiftcardReloadMutation_ReloadIntentCreate_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface CreateIntentGiftcardReloadMutation_ReloadIntentCreate_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<CreateIntentGiftcardReloadMutation_ReloadIntentCreate_MutationError_errors>;
}

export interface CreateIntentGiftcardReloadMutation_ReloadIntentCreate_GiftcardIntent {
  readonly __typename: "GiftcardIntent";
  /**
   * ID of the Intent
   */
  readonly id: string;
  /**
   * Intent token
   */
  readonly token: string;
}

export type CreateIntentGiftcardReloadMutation_ReloadIntentCreate =
  | CreateIntentGiftcardReloadMutation_ReloadIntentCreate_MutationError
  | CreateIntentGiftcardReloadMutation_ReloadIntentCreate_GiftcardIntent;

export interface CreateIntentGiftcardReloadMutation {
  /**
   * Begin a giftcard reload for a user.
   */
  readonly ReloadIntentCreate: CreateIntentGiftcardReloadMutation_ReloadIntentCreate;
}

export interface CreateIntentGiftcardReloadMutationVariables {
  readonly input: ReloadIntentCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateShopMutation
// ====================================================

export interface CreateShopMutation_IntentCreate_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface CreateShopMutation_IntentCreate_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<CreateShopMutation_IntentCreate_MutationError_errors>;
}

export interface CreateShopMutation_IntentCreate_Intent {
  readonly __typename: "Intent";
  /**
   * Intent's ID
   */
  readonly id: string;
  /**
   * Intent's token
   */
  readonly token: string;
  /**
   * The intent's url
   */
  readonly url: string | null;
  /**
   * The intent's interstitial URL on flipgive.com
   */
  readonly interstitialUrl: string | null;
}

export type CreateShopMutation_IntentCreate =
  | CreateShopMutation_IntentCreate_MutationError
  | CreateShopMutation_IntentCreate_Intent;

export interface CreateShopMutation {
  /**
   * Create an intent for a user.
   */
  readonly IntentCreate: CreateShopMutation_IntentCreate;
}

export interface CreateShopMutationVariables {
  readonly input: IntentCreateInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetBaseLinkQuery
// ====================================================

export interface GetBaseLinkQuery_Link_images {
  readonly __typename: "LinkImages";
  /**
   * Link's logo image URL
   */
  readonly logo: string | null;
  /**
   * Link's image URL
   */
  readonly image: string | null;
}

export interface GetBaseLinkQuery_Link_defaultBrand_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface GetBaseLinkQuery_Link_defaultBrand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Brand's images
   */
  readonly images: GetBaseLinkQuery_Link_defaultBrand_images;
}

export interface GetBaseLinkQuery_Link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's type
   */
  readonly type: LinkType;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * Link's description
   */
  readonly description: string | null;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Link's images
   */
  readonly images: GetBaseLinkQuery_Link_images;
  /**
   * Link's default brand
   */
  readonly defaultBrand: GetBaseLinkQuery_Link_defaultBrand;
}

export interface GetBaseLinkQuery {
  /**
   * Find a Link by an ID
   */
  readonly Link: GetBaseLinkQuery_Link;
}

export interface GetBaseLinkQueryVariables {
  readonly id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetGiftcardDetailsQuery
// ====================================================

export interface GetGiftcardDetailsQuery_Link_giftcardDenominations_edges_node_value {
  readonly __typename: "DollarFormats";
  /**
   * Cents integer value, example: 10023
   */
  readonly centsAmount: number;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GetGiftcardDetailsQuery_Link_giftcardDenominations_edges_node {
  readonly __typename: "GiftcardDenomination";
  /**
   * Giftcard Denomination's ID
   */
  readonly id: string;
  /**
   * Is this the default denomination?
   */
  readonly default: boolean;
  /**
   * How denomination will be delivered
   */
  readonly deliveryMethod: GiftcardDenominationDeliveryMethod;
  /**
   * How soon will the card be delivered
   */
  readonly deliveryEstimate: string;
  /**
   * Does this card (if physical) have free shipping?
   */
  readonly freeShipping: boolean;
  /**
   * The minimum quantity of cards that can be ordered
   */
  readonly minimumCards: number;
  /**
   * The maximum quantity of cards that can be ordered
   */
  readonly maximumCards: number;
  /**
   * Value of denomination
   */
  readonly value: GetGiftcardDetailsQuery_Link_giftcardDenominations_edges_node_value;
}

export interface GetGiftcardDetailsQuery_Link_giftcardDenominations_edges {
  readonly __typename: "GiftcardDenominationEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: GetGiftcardDetailsQuery_Link_giftcardDenominations_edges_node | null;
}

export interface GetGiftcardDetailsQuery_Link_giftcardDenominations {
  readonly __typename: "GiftcardDenominationConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<GetGiftcardDetailsQuery_Link_giftcardDenominations_edges | null> | null;
}

export interface GetGiftcardDetailsQuery_Link_reloadableGiftcardInfo_reloadThresholdDenominations {
  readonly __typename: "DollarFormats";
  /**
   * Cents integer value, example: 10023
   */
  readonly centsAmount: number;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GetGiftcardDetailsQuery_Link_reloadableGiftcardInfo {
  readonly __typename: "LinkReloadableGiftcardInfo";
  /**
   * The link's available reloadable threshold denominations in dollars
   */
  readonly reloadThresholdDenominations: ReadonlyArray<GetGiftcardDetailsQuery_Link_reloadableGiftcardInfo_reloadThresholdDenominations>;
}

export interface GetGiftcardDetailsQuery_Link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's type
   */
  readonly type: LinkType;
  /**
   * Link's available denominations
   */
  readonly availableDenominations: string | null;
  /**
   * Giftcard denominations available for this link
   */
  readonly giftcardDenominations: GetGiftcardDetailsQuery_Link_giftcardDenominations;
  readonly reloadableGiftcardInfo: GetGiftcardDetailsQuery_Link_reloadableGiftcardInfo;
}

export interface GetGiftcardDetailsQuery {
  /**
   * Find a Link by an ID
   */
  readonly Link: GetGiftcardDetailsQuery_Link;
}

export interface GetGiftcardDetailsQueryVariables {
  readonly id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLinkQuery
// ====================================================

export interface GetLinkQuery_Link_images {
  readonly __typename: "LinkImages";
  /**
   * Link's logo image URL
   */
  readonly logo: string | null;
  /**
   * Link's image URL
   */
  readonly image: string | null;
}

export interface GetLinkQuery_Link_previewInfo_topPromotionInfo_link_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
}

export interface GetLinkQuery_Link_previewInfo_topPromotionInfo_link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: GetLinkQuery_Link_previewInfo_topPromotionInfo_link_promotionInfo;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
}

export interface GetLinkQuery_Link_previewInfo_topPromotionInfo {
  readonly __typename: "LinkPromotion";
  /**
   * Promotion's ID
   */
  readonly id: string;
  /**
   * Promotion's Link
   */
  readonly link: GetLinkQuery_Link_previewInfo_topPromotionInfo_link | null;
}

export interface GetLinkQuery_Link_previewInfo {
  readonly __typename: "LinkPreviewInfo";
  /**
   * The link's preview text to show on the brand modal
   */
  readonly description: string;
  /**
   * The link's promotion count
   */
  readonly promotionsCount: number;
  /**
   * The link's top promotion
   */
  readonly topPromotionInfo: GetLinkQuery_Link_previewInfo_topPromotionInfo | null;
}

export interface GetLinkQuery_Link_promotionInfo_promotion {
  readonly __typename: "LinkPromotion";
  /**
   * Promotion's ID
   */
  readonly id: string;
  /**
   * Promotion's coupon code
   */
  readonly couponCode: string | null;
  /**
   * Promotion's description
   */
  readonly description: string;
  /**
   * Promotion's end date
   */
  readonly endsAt: DateTimeISO | null;
}

export interface GetLinkQuery_Link_promotionInfo_promotions_edges_node_link_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
  /**
   * Link's promo ends at
   */
  readonly promotionEndsAt: DateTimeISO | null;
  /**
   * The Link's most recent promotion label
   */
  readonly promotionLabel: string | null;
}

export interface GetLinkQuery_Link_promotionInfo_promotions_edges_node_link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: GetLinkQuery_Link_promotionInfo_promotions_edges_node_link_promotionInfo;
  /**
   * Link's give amount
   */
  readonly giveAmount: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
}

export interface GetLinkQuery_Link_promotionInfo_promotions_edges_node {
  readonly __typename: "LinkPromotion";
  /**
   * Promotion's ID
   */
  readonly id: string;
  /**
   * Promotion's coupon code
   */
  readonly couponCode: string | null;
  /**
   * Promotion's description
   */
  readonly description: string;
  /**
   * Promotion's end date
   */
  readonly endsAt: DateTimeISO | null;
  /**
   * Promotion's Link
   */
  readonly link: GetLinkQuery_Link_promotionInfo_promotions_edges_node_link | null;
}

export interface GetLinkQuery_Link_promotionInfo_promotions_edges {
  readonly __typename: "LinkPromotionEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: GetLinkQuery_Link_promotionInfo_promotions_edges_node | null;
}

export interface GetLinkQuery_Link_promotionInfo_promotions {
  readonly __typename: "LinkPromotionConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<GetLinkQuery_Link_promotionInfo_promotions_edges | null> | null;
}

export interface GetLinkQuery_Link_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
  /**
   * Link's promo ends at
   */
  readonly promotionEndsAt: DateTimeISO | null;
  /**
   * The Link's most recent promotion label
   */
  readonly promotionLabel: string | null;
  /**
   * A single promotion for a link
   */
  readonly promotion: GetLinkQuery_Link_promotionInfo_promotion | null;
  /**
   * A list of promotions for the link
   */
  readonly promotions: GetLinkQuery_Link_promotionInfo_promotions | null;
}

export interface GetLinkQuery_Link_defaultBrand_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
}

export interface GetLinkQuery_Link_defaultBrand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Brand's images
   */
  readonly images: GetLinkQuery_Link_defaultBrand_images;
}

export interface GetLinkQuery_Link_merchant {
  readonly __typename: "Merchant";
  /**
   * Merchant's ID
   */
  readonly id: string;
  /**
   * Merchant's name
   */
  readonly name: string;
  /**
   * Merchant's Website
   */
  readonly website: string;
}

export interface GetLinkQuery_Link_relatedLinks_edges_node_images {
  readonly __typename: "LinkImages";
  /**
   * Link's square image URL
   */
  readonly square: string | null;
}

export interface GetLinkQuery_Link_relatedLinks_edges_node_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
  /**
   * Link's promo ends at
   */
  readonly promotionEndsAt: DateTimeISO | null;
  /**
   * The Link's most recent promotion label
   */
  readonly promotionLabel: string | null;
}

export interface GetLinkQuery_Link_relatedLinks_edges_node {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's type
   */
  readonly type: LinkType;
  /**
   * Link's images
   */
  readonly images: GetLinkQuery_Link_relatedLinks_edges_node_images;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * Link's short name
   */
  readonly shortName: string | null;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: GetLinkQuery_Link_relatedLinks_edges_node_promotionInfo;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Link's give amount
   */
  readonly giveAmount: string;
  /**
   * Is the link a bulk shopping link?
   */
  readonly isBulkShop: boolean;
}

export interface GetLinkQuery_Link_relatedLinks_edges {
  readonly __typename: "LinkEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: GetLinkQuery_Link_relatedLinks_edges_node | null;
}

export interface GetLinkQuery_Link_relatedLinks {
  readonly __typename: "LinkConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<GetLinkQuery_Link_relatedLinks_edges | null> | null;
}

export interface GetLinkQuery_Link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's type
   */
  readonly type: LinkType;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * Link's 'how to earn' instructions
   */
  readonly earnInstructions: string | null;
  /**
   * Is the link a bulk shopping link?
   */
  readonly isBulkShop: boolean;
  /**
   * Link's buy button CTA
   */
  readonly buttonCTA: string;
  /**
   * Link's description
   */
  readonly description: string | null;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Approximate timing for when the link should give cashback
   */
  readonly cashbackTiming: string | null;
  /**
   * Link's exclusions
   */
  readonly exclusions: string | null;
  /**
   * Gift cards are not eligible for cash back.
   */
  readonly exclusionGiftCardNotEligible: boolean;
  /**
   * Purchases made with a gift card are not eligible for cash back.
   */
  readonly exclusionPurchaseWithGiftCardNotEligible: boolean;
  /**
   * Link's variable rates description
   */
  readonly variableRates: string | null;
  /**
   * Link's terms and conditions
   */
  readonly terms: string | null;
  /**
   * Link's terms summary
   */
  readonly termsSummary: string | null;
  /**
   * Link's images
   */
  readonly images: GetLinkQuery_Link_images;
  /**
   * The link's preview text to show on the brand modal
   */
  readonly previewInfo: GetLinkQuery_Link_previewInfo;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: GetLinkQuery_Link_promotionInfo;
  /**
   * Link's default brand
   */
  readonly defaultBrand: GetLinkQuery_Link_defaultBrand;
  /**
   * Link's merchant
   */
  readonly merchant: GetLinkQuery_Link_merchant | null;
  /**
   * A list of the link's related links
   */
  readonly relatedLinks: GetLinkQuery_Link_relatedLinks;
}

export interface GetLinkQuery {
  /**
   * Find a Link by an ID
   */
  readonly Link: GetLinkQuery_Link;
}

export interface GetLinkQueryVariables {
  readonly id: string;
  readonly promotionId: string;
  readonly hasPromotionId: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NewsQuery
// ====================================================

export interface NewsQuery_Activities_edges_node_details {
  readonly __typename: "ActivityDetail";
  /**
   * Detail's CTA text
   */
  readonly ctaText: string | null;
  /**
   * Detail's CTA URL
   */
  readonly ctaUrl: string | null;
  /**
   * Detail's description
   */
  readonly description: string | null;
  /**
   * Detail's destination for mobile
   */
  readonly destination: string | null;
  /**
   * Detail's photo image URL
   */
  readonly photoImage: string | null;
  /**
   * Detail's quote
   */
  readonly quote: string | null;
  /**
   * Detail's youtube ID
   */
  readonly youtubeId: string | null;
}

export interface NewsQuery_Activities_edges_node_activityObject_Post {
  readonly __typename: "Post" | "WeeklySummary" | "Intent";
}

export interface NewsQuery_Activities_edges_node_activityObject_Broadcast {
  readonly __typename: "Broadcast";
  /**
   * Broadcast's description
   */
  readonly description: string | null;
  /**
   * Broadcast's ID
   */
  readonly id: string;
  /**
   * Broadcast's photo image URL
   */
  readonly photoImage: string | null;
}

export type NewsQuery_Activities_edges_node_activityObject =
  | NewsQuery_Activities_edges_node_activityObject_Post
  | NewsQuery_Activities_edges_node_activityObject_Broadcast;

export interface NewsQuery_Activities_edges_node {
  readonly __typename: "Activity";
  /**
   * Activity's ID
   */
  readonly id: string;
  /**
   * Whether the viewer has liked the activity
   */
  readonly viewerHasLiked: boolean;
  /**
   * Activity's total likes count
   */
  readonly likesCount: number;
  /**
   * Activity's time
   */
  readonly time: DateTimeISO;
  /**
   * Activity's more details
   */
  readonly details: NewsQuery_Activities_edges_node_details;
  /**
   * Activity's object
   */
  readonly activityObject: NewsQuery_Activities_edges_node_activityObject | null;
}

export interface NewsQuery_Activities_edges {
  readonly __typename: "ActivityEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: NewsQuery_Activities_edges_node | null;
}

export interface NewsQuery_Activities {
  readonly __typename: "ActivityConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<NewsQuery_Activities_edges | null> | null;
}

export interface NewsQuery {
  /**
   * A paginated list of activities.
   */
  readonly Activities: NewsQuery_Activities;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OnLikeNewsMutation
// ====================================================

export interface OnLikeNewsMutation_ActivityReactionCreate_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface OnLikeNewsMutation_ActivityReactionCreate_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<OnLikeNewsMutation_ActivityReactionCreate_MutationError_errors>;
}

export interface OnLikeNewsMutation_ActivityReactionCreate_Activity {
  readonly __typename: "Activity";
  /**
   * Activity's ID
   */
  readonly id: string;
  /**
   * Activity's total likes count
   */
  readonly likesCount: number;
  /**
   * Whether the viewer has liked the activity
   */
  readonly viewerHasLiked: boolean;
}

export type OnLikeNewsMutation_ActivityReactionCreate =
  | OnLikeNewsMutation_ActivityReactionCreate_MutationError
  | OnLikeNewsMutation_ActivityReactionCreate_Activity;

export interface OnLikeNewsMutation {
  /**
   * Add a reaction for an activity of type like or dislike.
   */
  readonly ActivityReactionCreate: OnLikeNewsMutation_ActivityReactionCreate;
}

export interface OnLikeNewsMutationVariables {
  readonly activityId: string;
  readonly reactionType: ReactionType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OnboardingInfoData
// ====================================================

export interface OnboardingInfoData_Viewer_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * The date the shop app was initialized
   */
  readonly shopInitialized: DateISO | null;
}

export interface OnboardingInfoData_Viewer_teamOnboardingInfo_onboardingSteps {
  readonly __typename: "TeamOnboardingStepInfo";
  /**
   * The step's ID
   */
  readonly id: string;
  /**
   * The step's name
   */
  readonly name: string;
  /**
   * Does this step needs to be visible?
   */
  readonly isHidden: boolean;
}

export interface OnboardingInfoData_Viewer_teamOnboardingInfo {
  readonly __typename: "TeamOnboardingInfo";
  /**
   * The user's current onboarding step
   */
  readonly currentStep: TeamOnboardingStep | null;
  /**
   * Is the user's onboarding state active?
   */
  readonly isOnboardingActive: boolean;
  /**
   * The user's list of onboarding step
   */
  readonly onboardingSteps: ReadonlyArray<OnboardingInfoData_Viewer_teamOnboardingInfo_onboardingSteps>;
}

export interface OnboardingInfoData_Viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Is Captain
   */
  readonly isCaptain: boolean | null;
  /**
   * Active campaign on FlipGive or partners
   */
  readonly activeCampaign: OnboardingInfoData_Viewer_activeCampaign | null;
  /**
   * User's team onboarding info
   */
  readonly teamOnboardingInfo: OnboardingInfoData_Viewer_teamOnboardingInfo;
}

export interface OnboardingInfoData {
  /**
   * Retrieve details about the logged in user
   */
  readonly Viewer: OnboardingInfoData_Viewer;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchQuery
// ====================================================

export interface SearchQuery_LabelPromotionSearch_merchant {
  readonly __typename: "Merchant";
  /**
   * Merchant's ID
   */
  readonly id: string;
  /**
   * Merchant's name
   */
  readonly name: string;
}

export interface SearchQuery_LabelPromotionSearch_link_images {
  readonly __typename: "LinkImages";
  /**
   * Link's logo image URL
   */
  readonly logo: string | null;
  /**
   * Link's square image URL
   */
  readonly square: string | null;
}

export interface SearchQuery_LabelPromotionSearch_link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Link's images
   */
  readonly images: SearchQuery_LabelPromotionSearch_link_images;
}

export interface SearchQuery_LabelPromotionSearch {
  readonly __typename: "LabelPromotion";
  /**
   * Promotion's ID
   */
  readonly id: string;
  /**
   * Promotion's url
   */
  readonly url: string | null;
  /**
   * Promotion's label
   */
  readonly label: string;
  /**
   * Promotion's description
   */
  readonly description: string;
  /**
   * Promotion's merchant
   */
  readonly merchant: SearchQuery_LabelPromotionSearch_merchant | null;
  /**
   * Promotion's end date
   */
  readonly endsAt: DateTimeISO | null;
  /**
   * Promotion's Link
   */
  readonly link: SearchQuery_LabelPromotionSearch_link | null;
}

export interface SearchQuery_AdvancedBrandSearch_edges_node_maxGiveLink_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * The Link's most recent promotion label
   */
  readonly promotionLabel: string | null;
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
}

export interface SearchQuery_AdvancedBrandSearch_edges_node_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: SearchQuery_AdvancedBrandSearch_edges_node_maxGiveLink_promotionInfo;
}

export interface SearchQuery_AdvancedBrandSearch_edges_node_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface SearchQuery_AdvancedBrandSearch_edges_node {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Brand's Website
   */
  readonly website: string | null;
  /**
   * Brand's Description
   */
  readonly description: string | null;
  /**
   * Brand's Slug
   */
  readonly slug: string;
  /**
   * Whether the current viewer has favourited this brand
   */
  readonly viewerHasFavorited: boolean;
  readonly maxGiveLink: SearchQuery_AdvancedBrandSearch_edges_node_maxGiveLink | null;
  /**
   * Brand's images
   */
  readonly images: SearchQuery_AdvancedBrandSearch_edges_node_images;
}

export interface SearchQuery_AdvancedBrandSearch_edges {
  readonly __typename: "BrandEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: SearchQuery_AdvancedBrandSearch_edges_node | null;
}

export interface SearchQuery_AdvancedBrandSearch {
  readonly __typename: "BrandConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<SearchQuery_AdvancedBrandSearch_edges | null> | null;
}

export interface SearchQuery {
  /**
   * Find a link by string, max: 30
   */
  readonly LabelPromotionSearch: ReadonlyArray<SearchQuery_LabelPromotionSearch>;
  /**
   * Find brands based on search
   */
  readonly AdvancedBrandSearch: SearchQuery_AdvancedBrandSearch;
}

export interface SearchQueryVariables {
  readonly query: string;
  readonly page?: number | null;
  readonly orderBy?: string | null;
  readonly orderDirection?: string | null;
  readonly first?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LabelPromotionSearchQueryType
// ====================================================

export interface LabelPromotionSearchQueryType_LabelPromotionSearch_merchant {
  readonly __typename: "Merchant";
  /**
   * Merchant's ID
   */
  readonly id: string;
  /**
   * Merchant's name
   */
  readonly name: string;
}

export interface LabelPromotionSearchQueryType_LabelPromotionSearch_link_images {
  readonly __typename: "LinkImages";
  /**
   * Link's logo image URL
   */
  readonly logo: string | null;
  /**
   * Link's square image URL
   */
  readonly square: string | null;
}

export interface LabelPromotionSearchQueryType_LabelPromotionSearch_link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Link's images
   */
  readonly images: LabelPromotionSearchQueryType_LabelPromotionSearch_link_images;
}

export interface LabelPromotionSearchQueryType_LabelPromotionSearch {
  readonly __typename: "LabelPromotion";
  /**
   * Promotion's ID
   */
  readonly id: string;
  /**
   * Promotion's url
   */
  readonly url: string | null;
  /**
   * Promotion's label
   */
  readonly label: string;
  /**
   * Promotion's description
   */
  readonly description: string;
  /**
   * Promotion's merchant
   */
  readonly merchant: LabelPromotionSearchQueryType_LabelPromotionSearch_merchant | null;
  /**
   * Promotion's end date
   */
  readonly endsAt: DateTimeISO | null;
  /**
   * Promotion's Link
   */
  readonly link: LabelPromotionSearchQueryType_LabelPromotionSearch_link | null;
}

export interface LabelPromotionSearchQueryType {
  /**
   * Find a link by string, max: 30
   */
  readonly LabelPromotionSearch: ReadonlyArray<LabelPromotionSearchQueryType_LabelPromotionSearch>;
}

export interface LabelPromotionSearchQueryTypeVariables {
  readonly query: string;
  readonly page?: number | null;
  readonly orderBy?: string | null;
  readonly orderDirection?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AdvancedBrandSearchQueryType
// ====================================================

export interface AdvancedBrandSearchQueryType_AdvancedBrandSearch_pageInfo {
  readonly __typename: "PageInfo";
  /**
   * When paginating forwards, the cursor to continue.
   */
  readonly endCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  readonly hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  readonly hasPreviousPage: boolean;
  /**
   * When paginating backwards, the cursor to continue.
   */
  readonly startCursor: string | null;
}

export interface AdvancedBrandSearchQueryType_AdvancedBrandSearch_edges_node_maxGiveLink_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * The Link's most recent promotion label
   */
  readonly promotionLabel: string | null;
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
}

export interface AdvancedBrandSearchQueryType_AdvancedBrandSearch_edges_node_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: AdvancedBrandSearchQueryType_AdvancedBrandSearch_edges_node_maxGiveLink_promotionInfo;
}

export interface AdvancedBrandSearchQueryType_AdvancedBrandSearch_edges_node_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface AdvancedBrandSearchQueryType_AdvancedBrandSearch_edges_node {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Brand's Website
   */
  readonly website: string | null;
  /**
   * Brand's Description
   */
  readonly description: string | null;
  /**
   * Brand's Slug
   */
  readonly slug: string;
  /**
   * Whether the current viewer has favourited this brand
   */
  readonly viewerHasFavorited: boolean;
  readonly maxGiveLink: AdvancedBrandSearchQueryType_AdvancedBrandSearch_edges_node_maxGiveLink | null;
  /**
   * Brand's images
   */
  readonly images: AdvancedBrandSearchQueryType_AdvancedBrandSearch_edges_node_images;
}

export interface AdvancedBrandSearchQueryType_AdvancedBrandSearch_edges {
  readonly __typename: "BrandEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: AdvancedBrandSearchQueryType_AdvancedBrandSearch_edges_node | null;
}

export interface AdvancedBrandSearchQueryType_AdvancedBrandSearch {
  readonly __typename: "BrandConnection";
  /**
   * Information to aid in pagination.
   */
  readonly pageInfo: AdvancedBrandSearchQueryType_AdvancedBrandSearch_pageInfo;
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<AdvancedBrandSearchQueryType_AdvancedBrandSearch_edges | null> | null;
}

export interface AdvancedBrandSearchQueryType {
  /**
   * Find brands based on search
   */
  readonly AdvancedBrandSearch: AdvancedBrandSearchQueryType_AdvancedBrandSearch;
}

export interface AdvancedBrandSearchQueryTypeVariables {
  readonly query: string;
  readonly page?: number | null;
  readonly orderBy?: string | null;
  readonly orderDirection?: string | null;
  readonly first?: number | null;
  readonly before?: string | null;
  readonly after?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AutocompleteSearchQuery
// ====================================================

export interface AutocompleteSearchQuery_AutocompleteSearch {
  readonly __typename: "AutocompleteSearch";
  /**
   * autocomplete search value
   */
  readonly value: string;
}

export interface AutocompleteSearchQuery {
  /**
   * autocomplete search
   */
  readonly AutocompleteSearch: ReadonlyArray<AutocompleteSearchQuery_AutocompleteSearch>;
}

export interface AutocompleteSearchQueryVariables {
  readonly query: string;
  readonly first: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecentBrandQuery
// ====================================================

export interface RecentBrandQuery_RecentBrand_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface RecentBrandQuery_RecentBrand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Brand's images
   */
  readonly images: RecentBrandQuery_RecentBrand_images;
}

export interface RecentBrandQuery {
  /**
   * recently interacted items
   */
  readonly RecentBrand: ReadonlyArray<RecentBrandQuery_RecentBrand>;
}

export interface RecentBrandQueryVariables {
  readonly currency: Currency;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RecentSearchQuery
// ====================================================

export interface RecentSearchQuery_RecentSearch {
  readonly __typename: "RecentSearch";
  /**
   * recent search value
   */
  readonly term: string;
}

export interface RecentSearchQuery {
  /**
   * recently searched items
   */
  readonly RecentSearch: ReadonlyArray<RecentSearchQuery_RecentSearch>;
}

export interface RecentSearchQueryVariables {
  readonly currency: Currency;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BrandSearchSuggestionsQuery
// ====================================================

export interface BrandSearchSuggestionsQuery_BrandSearchSuggestion_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface BrandSearchSuggestionsQuery_BrandSearchSuggestion {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Brand's images
   */
  readonly images: BrandSearchSuggestionsQuery_BrandSearchSuggestion_images;
}

export interface BrandSearchSuggestionsQuery {
  /**
   * brand search recommendation
   */
  readonly BrandSearchSuggestion: ReadonlyArray<BrandSearchSuggestionsQuery_BrandSearchSuggestion>;
}

export interface BrandSearchSuggestionsQueryVariables {
  readonly currency: Currency;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SearchTermSuggestionsQuery
// ====================================================

export interface SearchTermSuggestionsQuery_SearchTermSuggestion {
  readonly __typename: "SearchTermSuggestionType";
  /**
   * recommended search value
   */
  readonly recommendation: string;
}

export interface SearchTermSuggestionsQuery {
  /**
   * search term recommendations, set by merchandise team
   */
  readonly SearchTermSuggestion: ReadonlyArray<SearchTermSuggestionsQuery_SearchTermSuggestion>;
}

export interface SearchTermSuggestionsQueryVariables {
  readonly currency: Currency;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCollectionById
// ====================================================

export interface GetCollectionById_Collection_assets_asset {
  readonly __typename: "Image";
  /**
   * Small version of asset, usually used for mobile screens
   */
  readonly small: string | null;
  /**
   * Where the image should link to.
   */
  readonly destination: string | null;
}

export interface GetCollectionById_Collection_assets {
  readonly __typename: "CollectionAsset";
  /**
   * Asset's Title
   */
  readonly title: string | null;
  /**
   * Asset's Images
   */
  readonly asset: GetCollectionById_Collection_assets_asset | null;
}

export interface GetCollectionById_Collection_links_defaultBrand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
}

export interface GetCollectionById_Collection_links_previewInfo_topPromotionInfo_link_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
}

export interface GetCollectionById_Collection_links_previewInfo_topPromotionInfo_link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: GetCollectionById_Collection_links_previewInfo_topPromotionInfo_link_promotionInfo;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
}

export interface GetCollectionById_Collection_links_previewInfo_topPromotionInfo {
  readonly __typename: "LinkPromotion";
  /**
   * Promotion's ID
   */
  readonly id: string;
  /**
   * Promotion's Link
   */
  readonly link: GetCollectionById_Collection_links_previewInfo_topPromotionInfo_link | null;
}

export interface GetCollectionById_Collection_links_previewInfo {
  readonly __typename: "LinkPreviewInfo";
  /**
   * The link's preview text to show on the brand modal
   */
  readonly description: string;
  /**
   * The link's promotion count
   */
  readonly promotionsCount: number;
  /**
   * The link's top promotion
   */
  readonly topPromotionInfo: GetCollectionById_Collection_links_previewInfo_topPromotionInfo | null;
}

export interface GetCollectionById_Collection_links_images {
  readonly __typename: "LinkImages";
  /**
   * Link's logo image URL
   */
  readonly logo: string | null;
  /**
   * Link's image URL
   */
  readonly image: string | null;
}

export interface GetCollectionById_Collection_links {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * Link's give amount
   */
  readonly giveAmount: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Type of gift card
   */
  readonly giftcardType: string | null;
  /**
   * Link's default brand
   */
  readonly defaultBrand: GetCollectionById_Collection_links_defaultBrand;
  /**
   * The link's preview text to show on the brand modal
   */
  readonly previewInfo: GetCollectionById_Collection_links_previewInfo;
  /**
   * Link's images
   */
  readonly images: GetCollectionById_Collection_links_images;
}

export interface GetCollectionById_Collection_brands_edges_node_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface GetCollectionById_Collection_brands_edges_node_maxGiveLink_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * The Link's most recent promotion label
   */
  readonly promotionLabel: string | null;
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
}

export interface GetCollectionById_Collection_brands_edges_node_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: GetCollectionById_Collection_brands_edges_node_maxGiveLink_promotionInfo;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
}

export interface GetCollectionById_Collection_brands_edges_node {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Whether the current viewer has favourited this brand
   */
  readonly viewerHasFavorited: boolean;
  /**
   * Brand's images
   */
  readonly images: GetCollectionById_Collection_brands_edges_node_images;
  readonly maxGiveLink: GetCollectionById_Collection_brands_edges_node_maxGiveLink | null;
}

export interface GetCollectionById_Collection_brands_edges {
  readonly __typename: "BrandEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: GetCollectionById_Collection_brands_edges_node | null;
}

export interface GetCollectionById_Collection_brands {
  readonly __typename: "BrandConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<GetCollectionById_Collection_brands_edges | null> | null;
}

export interface GetCollectionById_Collection {
  readonly __typename: "Collection";
  /**
   * Collection's ID
   */
  readonly id: string;
  /**
   * Collection's name
   */
  readonly name: string | null;
  /**
   * The collection's style type
   */
  readonly style: CollectionStyle | null;
  /**
   * A list of a collection's images
   */
  readonly assets: ReadonlyArray<GetCollectionById_Collection_assets>;
  /**
   * An unpaginated list of the collection's links, max: 100
   */
  readonly links: ReadonlyArray<GetCollectionById_Collection_links>;
  /**
   * A paginated list of the collection's brands
   */
  readonly brands: GetCollectionById_Collection_brands;
}

export interface GetCollectionById {
  /**
   * Find a Collection by ID
   */
  readonly Collection: GetCollectionById_Collection | null;
}

export interface GetCollectionByIdVariables {
  readonly id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DefaultStorefrontData
// ====================================================

export interface DefaultStorefrontData_DefaultStoreFront_collections_assets_asset {
  readonly __typename: "Image";
  /**
   * Small version of asset, usually used for mobile screens
   */
  readonly small: string | null;
  /**
   * Where the image should link to.
   */
  readonly destination: string | null;
}

export interface DefaultStorefrontData_DefaultStoreFront_collections_assets {
  readonly __typename: "CollectionAsset";
  /**
   * Asset's Title
   */
  readonly title: string | null;
  /**
   * Asset's Images
   */
  readonly asset: DefaultStorefrontData_DefaultStoreFront_collections_assets_asset | null;
}

export interface DefaultStorefrontData_DefaultStoreFront_collections_links_defaultBrand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
}

export interface DefaultStorefrontData_DefaultStoreFront_collections_links_previewInfo_topPromotionInfo_link_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
}

export interface DefaultStorefrontData_DefaultStoreFront_collections_links_previewInfo_topPromotionInfo_link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: DefaultStorefrontData_DefaultStoreFront_collections_links_previewInfo_topPromotionInfo_link_promotionInfo;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
}

export interface DefaultStorefrontData_DefaultStoreFront_collections_links_previewInfo_topPromotionInfo {
  readonly __typename: "LinkPromotion";
  /**
   * Promotion's ID
   */
  readonly id: string;
  /**
   * Promotion's Link
   */
  readonly link: DefaultStorefrontData_DefaultStoreFront_collections_links_previewInfo_topPromotionInfo_link | null;
}

export interface DefaultStorefrontData_DefaultStoreFront_collections_links_previewInfo {
  readonly __typename: "LinkPreviewInfo";
  /**
   * The link's preview text to show on the brand modal
   */
  readonly description: string;
  /**
   * The link's promotion count
   */
  readonly promotionsCount: number;
  /**
   * The link's top promotion
   */
  readonly topPromotionInfo: DefaultStorefrontData_DefaultStoreFront_collections_links_previewInfo_topPromotionInfo | null;
}

export interface DefaultStorefrontData_DefaultStoreFront_collections_links_images {
  readonly __typename: "LinkImages";
  /**
   * Link's logo image URL
   */
  readonly logo: string | null;
  /**
   * Link's image URL
   */
  readonly image: string | null;
}

export interface DefaultStorefrontData_DefaultStoreFront_collections_links {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * Link's give amount
   */
  readonly giveAmount: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Type of gift card
   */
  readonly giftcardType: string | null;
  /**
   * Link's default brand
   */
  readonly defaultBrand: DefaultStorefrontData_DefaultStoreFront_collections_links_defaultBrand;
  /**
   * The link's preview text to show on the brand modal
   */
  readonly previewInfo: DefaultStorefrontData_DefaultStoreFront_collections_links_previewInfo;
  /**
   * Link's images
   */
  readonly images: DefaultStorefrontData_DefaultStoreFront_collections_links_images;
}

export interface DefaultStorefrontData_DefaultStoreFront_collections_brands_edges_node_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface DefaultStorefrontData_DefaultStoreFront_collections_brands_edges_node_maxGiveLink_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * The Link's most recent promotion label
   */
  readonly promotionLabel: string | null;
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
}

export interface DefaultStorefrontData_DefaultStoreFront_collections_brands_edges_node_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: DefaultStorefrontData_DefaultStoreFront_collections_brands_edges_node_maxGiveLink_promotionInfo;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
}

export interface DefaultStorefrontData_DefaultStoreFront_collections_brands_edges_node {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Whether the current viewer has favourited this brand
   */
  readonly viewerHasFavorited: boolean;
  /**
   * Brand's images
   */
  readonly images: DefaultStorefrontData_DefaultStoreFront_collections_brands_edges_node_images;
  readonly maxGiveLink: DefaultStorefrontData_DefaultStoreFront_collections_brands_edges_node_maxGiveLink | null;
}

export interface DefaultStorefrontData_DefaultStoreFront_collections_brands_edges {
  readonly __typename: "BrandEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: DefaultStorefrontData_DefaultStoreFront_collections_brands_edges_node | null;
}

export interface DefaultStorefrontData_DefaultStoreFront_collections_brands {
  readonly __typename: "BrandConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<DefaultStorefrontData_DefaultStoreFront_collections_brands_edges | null> | null;
}

export interface DefaultStorefrontData_DefaultStoreFront_collections {
  readonly __typename: "Collection";
  /**
   * Collection's ID
   */
  readonly id: string;
  /**
   * Collection's name
   */
  readonly name: string | null;
  /**
   * The collection's style type
   */
  readonly style: CollectionStyle | null;
  /**
   * A list of a collection's images
   */
  readonly assets: ReadonlyArray<DefaultStorefrontData_DefaultStoreFront_collections_assets>;
  /**
   * An unpaginated list of the collection's links, max: 100
   */
  readonly links: ReadonlyArray<DefaultStorefrontData_DefaultStoreFront_collections_links>;
  /**
   * A paginated list of the collection's brands
   */
  readonly brands: DefaultStorefrontData_DefaultStoreFront_collections_brands;
}

export interface DefaultStorefrontData_DefaultStoreFront {
  readonly __typename: "StoreFront";
  /**
   * StoreFront's ID
   */
  readonly id: string;
  /**
   * StoreFront's name
   */
  readonly name: string;
  /**
   * A list of the storefront's collections
   */
  readonly collections: ReadonlyArray<DefaultStorefrontData_DefaultStoreFront_collections>;
}

export interface DefaultStorefrontData {
  /**
   * Find a StoreFront by default type
   */
  readonly DefaultStoreFront: DefaultStorefrontData_DefaultStoreFront | null;
}

export interface DefaultStorefrontDataVariables {
  readonly type: DefaultStoreFrontType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetShortcuts
// ====================================================

export interface GetShortcuts_ShopCloud_shortcuts_links {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
}

export interface GetShortcuts_ShopCloud_shortcuts {
  readonly __typename: "Collection";
  /**
   * Collection's ID
   */
  readonly id: string;
  /**
   * An unpaginated list of the collection's links, max: 100
   */
  readonly links: ReadonlyArray<GetShortcuts_ShopCloud_shortcuts_links>;
}

export interface GetShortcuts_ShopCloud {
  readonly __typename: "ShopCloud";
  /**
   * ShopCloud's ID
   */
  readonly id: string;
  /**
   * Viwer on the shop cloud partner
   */
  readonly shortcuts: GetShortcuts_ShopCloud_shortcuts | null;
}

export interface GetShortcuts {
  /**
   * Query the Authenticated shop cloud
   */
  readonly ShopCloud: GetShortcuts_ShopCloud | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetStorefrontById
// ====================================================

export interface GetStorefrontById_StoreFront_collections_assets_asset {
  readonly __typename: "Image";
  /**
   * Small version of asset, usually used for mobile screens
   */
  readonly small: string | null;
  /**
   * Where the image should link to.
   */
  readonly destination: string | null;
}

export interface GetStorefrontById_StoreFront_collections_assets {
  readonly __typename: "CollectionAsset";
  /**
   * Asset's Title
   */
  readonly title: string | null;
  /**
   * Asset's Images
   */
  readonly asset: GetStorefrontById_StoreFront_collections_assets_asset | null;
}

export interface GetStorefrontById_StoreFront_collections_links_defaultBrand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
}

export interface GetStorefrontById_StoreFront_collections_links_previewInfo_topPromotionInfo_link_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
}

export interface GetStorefrontById_StoreFront_collections_links_previewInfo_topPromotionInfo_link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: GetStorefrontById_StoreFront_collections_links_previewInfo_topPromotionInfo_link_promotionInfo;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
}

export interface GetStorefrontById_StoreFront_collections_links_previewInfo_topPromotionInfo {
  readonly __typename: "LinkPromotion";
  /**
   * Promotion's ID
   */
  readonly id: string;
  /**
   * Promotion's Link
   */
  readonly link: GetStorefrontById_StoreFront_collections_links_previewInfo_topPromotionInfo_link | null;
}

export interface GetStorefrontById_StoreFront_collections_links_previewInfo {
  readonly __typename: "LinkPreviewInfo";
  /**
   * The link's preview text to show on the brand modal
   */
  readonly description: string;
  /**
   * The link's promotion count
   */
  readonly promotionsCount: number;
  /**
   * The link's top promotion
   */
  readonly topPromotionInfo: GetStorefrontById_StoreFront_collections_links_previewInfo_topPromotionInfo | null;
}

export interface GetStorefrontById_StoreFront_collections_links_images {
  readonly __typename: "LinkImages";
  /**
   * Link's logo image URL
   */
  readonly logo: string | null;
  /**
   * Link's image URL
   */
  readonly image: string | null;
}

export interface GetStorefrontById_StoreFront_collections_links {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * Link's give amount
   */
  readonly giveAmount: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Type of gift card
   */
  readonly giftcardType: string | null;
  /**
   * Link's default brand
   */
  readonly defaultBrand: GetStorefrontById_StoreFront_collections_links_defaultBrand;
  /**
   * The link's preview text to show on the brand modal
   */
  readonly previewInfo: GetStorefrontById_StoreFront_collections_links_previewInfo;
  /**
   * Link's images
   */
  readonly images: GetStorefrontById_StoreFront_collections_links_images;
}

export interface GetStorefrontById_StoreFront_collections_brands_edges_node_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface GetStorefrontById_StoreFront_collections_brands_edges_node_maxGiveLink_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * The Link's most recent promotion label
   */
  readonly promotionLabel: string | null;
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
}

export interface GetStorefrontById_StoreFront_collections_brands_edges_node_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: GetStorefrontById_StoreFront_collections_brands_edges_node_maxGiveLink_promotionInfo;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
}

export interface GetStorefrontById_StoreFront_collections_brands_edges_node {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Whether the current viewer has favourited this brand
   */
  readonly viewerHasFavorited: boolean;
  /**
   * Brand's images
   */
  readonly images: GetStorefrontById_StoreFront_collections_brands_edges_node_images;
  readonly maxGiveLink: GetStorefrontById_StoreFront_collections_brands_edges_node_maxGiveLink | null;
}

export interface GetStorefrontById_StoreFront_collections_brands_edges {
  readonly __typename: "BrandEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: GetStorefrontById_StoreFront_collections_brands_edges_node | null;
}

export interface GetStorefrontById_StoreFront_collections_brands {
  readonly __typename: "BrandConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<GetStorefrontById_StoreFront_collections_brands_edges | null> | null;
}

export interface GetStorefrontById_StoreFront_collections {
  readonly __typename: "Collection";
  /**
   * Collection's ID
   */
  readonly id: string;
  /**
   * Collection's name
   */
  readonly name: string | null;
  /**
   * The collection's style type
   */
  readonly style: CollectionStyle | null;
  /**
   * A list of a collection's images
   */
  readonly assets: ReadonlyArray<GetStorefrontById_StoreFront_collections_assets>;
  /**
   * An unpaginated list of the collection's links, max: 100
   */
  readonly links: ReadonlyArray<GetStorefrontById_StoreFront_collections_links>;
  /**
   * A paginated list of the collection's brands
   */
  readonly brands: GetStorefrontById_StoreFront_collections_brands;
}

export interface GetStorefrontById_StoreFront {
  readonly __typename: "StoreFront";
  /**
   * StoreFront's ID
   */
  readonly id: string;
  /**
   * StoreFront's name
   */
  readonly name: string;
  /**
   * A list of the storefront's collections
   */
  readonly collections: ReadonlyArray<GetStorefrontById_StoreFront_collections>;
}

export interface GetStorefrontById {
  /**
   * Find a StoreFront by ID
   */
  readonly StoreFront: GetStorefrontById_StoreFront | null;
}

export interface GetStorefrontByIdVariables {
  readonly id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetNotificationsSettings
// ====================================================

export interface GetNotificationsSettings_ShopCloud_viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Notify user of updates?
   */
  readonly notifyUpdate: boolean;
  /**
   * Unsubscribed from marketing?
   */
  readonly unsubscribedAt: DateTimeISO | null;
}

export interface GetNotificationsSettings_ShopCloud {
  readonly __typename: "ShopCloud";
  /**
   * ShopCloud's ID
   */
  readonly id: string;
  /**
   * Viwer on the shop cloud partner
   */
  readonly viewer: GetNotificationsSettings_ShopCloud_viewer | null;
}

export interface GetNotificationsSettings {
  /**
   * Query the Authenticated shop cloud
   */
  readonly ShopCloud: GetNotificationsSettings_ShopCloud | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetProfileSettings
// ====================================================

export interface GetProfileSettings_ShopCloud_viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Hide user's shopping activity.
   */
  readonly hidePurchaseActivity: boolean;
  /**
   * Viewer's privacy level
   */
  readonly privacyLevel: PrivacyLevel | null;
}

export interface GetProfileSettings_ShopCloud {
  readonly __typename: "ShopCloud";
  /**
   * ShopCloud's ID
   */
  readonly id: string;
  /**
   * Viwer on the shop cloud partner
   */
  readonly viewer: GetProfileSettings_ShopCloud_viewer | null;
}

export interface GetProfileSettings {
  /**
   * Query the Authenticated shop cloud
   */
  readonly ShopCloud: GetProfileSettings_ShopCloud | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTeamQuery
// ====================================================

export interface GetTeamQuery_ShopCloud_activeCampaign_goal {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GetTeamQuery_ShopCloud_activeCampaign_category {
  readonly __typename: "CampaignCategory";
  /**
   * Category's ID
   */
  readonly id: string;
  /**
   * Category's name
   */
  readonly name: string;
}

export interface GetTeamQuery_ShopCloud_activeCampaign_amountWithdrawnBreakdown_available {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GetTeamQuery_ShopCloud_activeCampaign_amountWithdrawnBreakdown {
  readonly __typename: "AmountWithdrawnBreakdown";
  /**
   * Money available
   */
  readonly available: GetTeamQuery_ShopCloud_activeCampaign_amountWithdrawnBreakdown_available;
}

export interface GetTeamQuery_ShopCloud_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaign's country
   */
  readonly country: Country | null;
  /**
   * How campaign plans to distribute funds
   */
  readonly fundsDistributionMethod: FundsDistributionMethod | null;
  /**
   * Campaign's city
   */
  readonly city: string | null;
  /**
   * The deadline of a campaign
   */
  readonly deadline: DateISO | null;
  /**
   * Count of joined fundraisers
   */
  readonly joinedFundraisersCount: number;
  /**
   * Campaigns's goal amount
   */
  readonly goal: GetTeamQuery_ShopCloud_activeCampaign_goal | null;
  /**
   * Campaign's category
   */
  readonly category: GetTeamQuery_ShopCloud_activeCampaign_category;
  /**
   * a breakdown of the funds withdrawn by this campaign
   */
  readonly amountWithdrawnBreakdown: GetTeamQuery_ShopCloud_activeCampaign_amountWithdrawnBreakdown;
}

export interface GetTeamQuery_ShopCloud_viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * User's email address
   */
  readonly email: string;
  /**
   * Is Captainable
   */
  readonly isCaptainable: boolean | null;
}

export interface GetTeamQuery_ShopCloud {
  readonly __typename: "ShopCloud";
  /**
   * ShopCloud's ID
   */
  readonly id: string;
  /**
   * Active campaign on the shop cloud partner
   */
  readonly activeCampaign: GetTeamQuery_ShopCloud_activeCampaign | null;
  /**
   * Viwer on the shop cloud partner
   */
  readonly viewer: GetTeamQuery_ShopCloud_viewer | null;
}

export interface GetTeamQuery {
  /**
   * Query the Authenticated shop cloud
   */
  readonly ShopCloud: GetTeamQuery_ShopCloud | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateNotifications
// ====================================================

export interface UpdateNotifications_ViewerSettingsUpdate_User {
  readonly __typename: "User";
}

export interface UpdateNotifications_ViewerSettingsUpdate_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface UpdateNotifications_ViewerSettingsUpdate_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<UpdateNotifications_ViewerSettingsUpdate_MutationError_errors>;
}

export interface UpdateNotifications_ViewerSettingsUpdate_Viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Unsubscribed from marketing?
   */
  readonly unsubscribedAt: DateTimeISO | null;
  /**
   * Notify user of updates?
   */
  readonly notifyUpdate: boolean;
}

export type UpdateNotifications_ViewerSettingsUpdate =
  | UpdateNotifications_ViewerSettingsUpdate_User
  | UpdateNotifications_ViewerSettingsUpdate_MutationError
  | UpdateNotifications_ViewerSettingsUpdate_Viewer;

export interface UpdateNotifications {
  /**
   * Update a user's settings.
   */
  readonly ViewerSettingsUpdate: UpdateNotifications_ViewerSettingsUpdate;
}

export interface UpdateNotificationsVariables {
  readonly unsubscribedAt?: DateTimeISO | null;
  readonly notifyUpdate?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateProfile
// ====================================================

export interface UpdateProfile_ViewerSettingsUpdate_User {
  readonly __typename: "User";
}

export interface UpdateProfile_ViewerSettingsUpdate_MutationError_errors {
  readonly __typename: "Error";
  readonly input: string | null;
  readonly inputErrors: ReadonlyArray<string>;
}

export interface UpdateProfile_ViewerSettingsUpdate_MutationError {
  readonly __typename: "MutationError";
  readonly errors: ReadonlyArray<UpdateProfile_ViewerSettingsUpdate_MutationError_errors>;
}

export interface UpdateProfile_ViewerSettingsUpdate_Viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * Hide user's shopping activity.
   */
  readonly hidePurchaseActivity: boolean;
  /**
   * Viewer's privacy level
   */
  readonly privacyLevel: PrivacyLevel | null;
}

export type UpdateProfile_ViewerSettingsUpdate =
  | UpdateProfile_ViewerSettingsUpdate_User
  | UpdateProfile_ViewerSettingsUpdate_MutationError
  | UpdateProfile_ViewerSettingsUpdate_Viewer;

export interface UpdateProfile {
  /**
   * Update a user's settings.
   */
  readonly ViewerSettingsUpdate: UpdateProfile_ViewerSettingsUpdate;
}

export interface UpdateProfileVariables {
  readonly hidePurchaseActivity?: boolean | null;
  readonly privacyLevel?: PrivacyLevel | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GiftCardListQuery
// ====================================================

export interface GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_balance {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_value {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_merchant {
  readonly __typename: "Merchant";
  /**
   * Merchant's ID
   */
  readonly id: string;
  /**
   * Merchant's name
   */
  readonly name: string;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_link_images {
  readonly __typename: "LinkImages";
  /**
   * Link's image URL
   */
  readonly image: string | null;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_link_defaultBrand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * Link's images
   */
  readonly images: GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_link_images;
  /**
   * Link's default brand
   */
  readonly defaultBrand: GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_link_defaultBrand;
  /**
   * Type of gift card
   */
  readonly giftcardType: string | null;
  /**
   * Link's gift card wallet description
   */
  readonly walletDescription: string | null;
  /**
   * Link's exclusions
   */
  readonly exclusions: string | null;
  /**
   * Link's giftcard instructions
   */
  readonly giftcardInstructions: string | null;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_reloadInfo_reloadThreshold {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_reloadInfo {
  readonly __typename: "GiftcardReloadableInfo";
  /**
   * Whether or not the reload functionality for a giftcard is enabled
   */
  readonly isReloadingEnabled: boolean;
  /**
   * Is giftcard auto reloadable?
   */
  readonly isAutoReloadable: boolean | null;
  /**
   * Giftcard's initial value
   */
  readonly reloadThreshold: GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_reloadInfo_reloadThreshold | null;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_transaction {
  readonly __typename: "Transaction";
  /**
   * Transaction ID
   */
  readonly id: string;
  /**
   * When transaction was created
   */
  readonly createdAt: DateTimeISO;
  /**
   * Name of giftee
   */
  readonly giftName: string | null;
  /**
   * Email of giftee
   */
  readonly giftEmail: string | null;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_accessLogs_edges_node_data {
  readonly __typename: "AccessLogsData";
  /**
   * Data label
   */
  readonly label: string;
  /**
   * Data value
   */
  readonly value: string | null;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_accessLogs_edges_node {
  readonly __typename: "AccessLogs";
  /**
   * Access type of log entry
   */
  readonly accessType: GiftcardAccessType;
  /**
   * Time of log entry
   */
  readonly accessedAt: DateTimeISO;
  /**
   * ID of log entry
   */
  readonly id: string;
  /**
   * Metadata of log entry
   */
  readonly data: ReadonlyArray<GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_accessLogs_edges_node_data>;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_accessLogs_edges {
  readonly __typename: "AccessLogsEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_accessLogs_edges_node | null;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_accessLogs {
  readonly __typename: "AccessLogsConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_accessLogs_edges | null> | null;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated_edges_node {
  readonly __typename: "Giftcard";
  /**
   * Giftcard's ID
   */
  readonly id: string;
  /**
   * Giftcards unique id
   */
  readonly uuid: string;
  /**
   * Giftcard's external URL
   */
  readonly externalUrl: string | null;
  /**
   * Giftcard's last updated at
   */
  readonly updatedAt: string | null;
  /**
   * Whether to open the giftcard in webview or browser
   */
  readonly isExternalTarget: boolean;
  /**
   * Whether or not a giftcard is reloadable
   */
  readonly isReloadable: boolean;
  /**
   * Giftcard's PIN
   */
  readonly pin: string | null;
  /**
   * Giftcard's type
   */
  readonly giftcardType: GiftcardType;
  /**
   * Giftcard's barcode
   */
  readonly barcode: string | null;
  /**
   * Giftcard's card number
   */
  readonly cardNumber: string | null;
  /**
   * Giftcard's balance
   */
  readonly balance: GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_balance | null;
  /**
   * Giftcard's initial value
   */
  readonly value: GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_value;
  /**
   * Giftcard's merchant
   */
  readonly merchant: GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_merchant | null;
  /**
   * Giftcard's link
   */
  readonly link: GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_link;
  /**
   * The giftcard's reloadable info
   */
  readonly reloadInfo: GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_reloadInfo | null;
  /**
   * Giftcard's transaction
   */
  readonly transaction: GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_transaction | null;
  /**
   * paginated access logs for giftcard
   */
  readonly accessLogs: GiftCardListQuery_Viewer_giftcardsPaginated_edges_node_accessLogs | null;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated_edges {
  readonly __typename: "GiftcardEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: GiftCardListQuery_Viewer_giftcardsPaginated_edges_node | null;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated_pageInfo {
  readonly __typename: "PageInfo";
  /**
   * When paginating forwards, are there more items?
   */
  readonly hasNextPage: boolean;
  /**
   * When paginating forwards, the cursor to continue.
   */
  readonly endCursor: string | null;
}

export interface GiftCardListQuery_Viewer_giftcardsPaginated {
  readonly __typename: "GiftcardConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<GiftCardListQuery_Viewer_giftcardsPaginated_edges | null> | null;
  /**
   * Information to aid in pagination.
   */
  readonly pageInfo: GiftCardListQuery_Viewer_giftcardsPaginated_pageInfo;
}

export interface GiftCardListQuery_Viewer {
  readonly __typename: "Viewer";
  /**
   * User's ID
   */
  readonly id: string;
  /**
   * A list of the user's giftcards
   */
  readonly giftcardsPaginated: GiftCardListQuery_Viewer_giftcardsPaginated;
}

export interface GiftCardListQuery {
  /**
   * Retrieve details about the logged in user
   */
  readonly Viewer: GiftCardListQuery_Viewer;
}

export interface GiftCardListQueryVariables {
  readonly after?: string | null;
  readonly sortBy?: GiftcardSortEnum | null;
  readonly nameContains?: string | null;
  readonly withBalance?: boolean | null;
  readonly giftcardTypes?: ReadonlyArray<GiftcardType> | null;
  readonly ids?: ReadonlyArray<string> | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CloudFragment
// ====================================================

export interface CloudFragment_activeCampaign_amountRaised {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
}

export interface CloudFragment_activeCampaign {
  readonly __typename: "Campaign";
  /**
   * Campaigns's ID
   */
  readonly id: string;
  /**
   * Campaigns's name
   */
  readonly name: string;
  /**
   * Campaign's currency
   */
  readonly currency: Currency;
  /**
   * Campaigns amount raised
   */
  readonly amountRaised: CloudFragment_activeCampaign_amountRaised;
  /**
   * Campaign's photo image URL
   */
  readonly photoImage: string | null;
  /**
   * Campaign's longitude
   */
  readonly longitude: number | null;
  /**
   * Campaign's latitude
   */
  readonly latitude: number | null;
}

export interface CloudFragment_activeFundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
}

export interface CloudFragment {
  readonly __typename: "ShopCloud";
  /**
   * ShopCloud's ID
   */
  readonly id: string;
  /**
   * Campaign Currency
   */
  readonly campaignCurrency: string | null;
  /**
   * Currency
   */
  readonly currency: string | null;
  /**
   * Token state
   */
  readonly state: string;
  /**
   * User Currency
   */
  readonly userCurrency: string | null;
  /**
   * ShopCloud's Deal Color
   */
  readonly dealColor: string | null;
  /**
   * ShopCloud's Primary Color
   */
  readonly primaryColor: string | null;
  /**
   * ShopCloud's Link Color
   */
  readonly secondaryColor: string | null;
  /**
   * Logo url
   */
  readonly logoMobileUrl: string | null;
  /**
   * Active campaign on the shop cloud partner
   */
  readonly activeCampaign: CloudFragment_activeCampaign | null;
  /**
   * Active campaign's fundraiser on the shop cloud partner
   */
  readonly activeFundraiser: CloudFragment_activeFundraiser | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: FavoriteBrandFragment
// ====================================================

export interface FavoriteBrandFragment_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface FavoriteBrandFragment_maxGiveLink_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
  /**
   * Link's promo ends at
   */
  readonly promotionEndsAt: DateTimeISO | null;
  /**
   * The Link's most recent promotion label
   */
  readonly promotionLabel: string | null;
}

export interface FavoriteBrandFragment_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Link's give amount
   */
  readonly giveAmount: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: FavoriteBrandFragment_maxGiveLink_promotionInfo;
}

export interface FavoriteBrandFragment {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Whether the current viewer has favourited this brand
   */
  readonly viewerHasFavorited: boolean;
  /**
   * Brand's images
   */
  readonly images: FavoriteBrandFragment_images;
  readonly maxGiveLink: FavoriteBrandFragment_maxGiveLink | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: IntentFragment
// ====================================================

export interface IntentFragment_link_defaultBrand_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface IntentFragment_link_defaultBrand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Brand's images
   */
  readonly images: IntentFragment_link_defaultBrand_images;
}

export interface IntentFragment_link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's type
   */
  readonly type: LinkType;
  /**
   * Link's default brand
   */
  readonly defaultBrand: IntentFragment_link_defaultBrand;
}

export interface IntentFragment_fundraiser {
  readonly __typename: "Fundraiser";
  /**
   * Fundraisers's ID
   */
  readonly id: string;
  /**
   * Fundraisers's first name
   */
  readonly firstName: string;
  /**
   * Fundraisers's last name
   */
  readonly lastName: string | null;
  /**
   * Fundraisers's avatar image URL
   */
  readonly avatarImage: string | null;
}

export interface IntentFragment_campaignAmount {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface IntentFragment_missingClaim {
  readonly __typename: "MissingClaim";
  /**
   * Missing Claim's ID
   */
  readonly id: string;
  readonly status: MissingClaimStatusEnum;
  /**
   * Missing claim's denied reason
   */
  readonly denialReason: string | null;
}

export interface IntentFragment {
  readonly __typename: "Intent";
  /**
   * Intent's ID
   */
  readonly id: string;
  /**
   * When the intent was created
   */
  readonly createdAt: DateTimeISO;
  /**
   * Intent's reference ID
   */
  readonly reference: string;
  /**
   * is the intent transferrable? it is not transferrable if its transfer share has been paid out
   */
  readonly isTransferrable: boolean;
  /**
   * The intent's buyer name
   */
  readonly buyer_name: string | null;
  /**
   * Intent's type
   */
  readonly intentType: IntentTypeEnum;
  /**
   * Can the user submit a missing claim
   */
  readonly canClaimMissing: boolean;
  /**
   * When the intent was supported
   */
  readonly supportedAt: DateTimeISO | null;
  /**
   * When you can expect earnings if purchase has been made
   */
  readonly expectedEarnedOn: DateISO | null;
  /**
   * Intent's token
   */
  readonly token: string;
  /**
   * The intent's link
   */
  readonly link: IntentFragment_link | null;
  /**
   * The fundraiser of the intent
   */
  readonly fundraiser: IntentFragment_fundraiser | null;
  /**
   * How much was raised for the campaign
   */
  readonly campaignAmount: IntentFragment_campaignAmount | null;
  /**
   * Intent's missing claim
   */
  readonly missingClaim: IntentFragment_missingClaim | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PageInfoFragment
// ====================================================

export interface PageInfoFragment {
  readonly __typename: "PageInfo";
  /**
   * When paginating forwards, the cursor to continue.
   */
  readonly endCursor: string | null;
  /**
   * When paginating forwards, are there more items?
   */
  readonly hasNextPage: boolean;
  /**
   * When paginating backwards, are there more items?
   */
  readonly hasPreviousPage: boolean;
  /**
   * When paginating backwards, the cursor to continue.
   */
  readonly startCursor: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: CollectionFragment
// ====================================================

export interface CollectionFragment_assets_asset {
  readonly __typename: "Image";
  /**
   * Small version of asset, usually used for mobile screens
   */
  readonly small: string | null;
  /**
   * Where the image should link to.
   */
  readonly destination: string | null;
}

export interface CollectionFragment_assets {
  readonly __typename: "CollectionAsset";
  /**
   * Asset's Title
   */
  readonly title: string | null;
  /**
   * Asset's Images
   */
  readonly asset: CollectionFragment_assets_asset | null;
}

export interface CollectionFragment_links_defaultBrand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
}

export interface CollectionFragment_links_previewInfo_topPromotionInfo_link_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
}

export interface CollectionFragment_links_previewInfo_topPromotionInfo_link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: CollectionFragment_links_previewInfo_topPromotionInfo_link_promotionInfo;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
}

export interface CollectionFragment_links_previewInfo_topPromotionInfo {
  readonly __typename: "LinkPromotion";
  /**
   * Promotion's ID
   */
  readonly id: string;
  /**
   * Promotion's Link
   */
  readonly link: CollectionFragment_links_previewInfo_topPromotionInfo_link | null;
}

export interface CollectionFragment_links_previewInfo {
  readonly __typename: "LinkPreviewInfo";
  /**
   * The link's preview text to show on the brand modal
   */
  readonly description: string;
  /**
   * The link's promotion count
   */
  readonly promotionsCount: number;
  /**
   * The link's top promotion
   */
  readonly topPromotionInfo: CollectionFragment_links_previewInfo_topPromotionInfo | null;
}

export interface CollectionFragment_links_images {
  readonly __typename: "LinkImages";
  /**
   * Link's logo image URL
   */
  readonly logo: string | null;
  /**
   * Link's image URL
   */
  readonly image: string | null;
}

export interface CollectionFragment_links {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * Link's give amount
   */
  readonly giveAmount: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Type of gift card
   */
  readonly giftcardType: string | null;
  /**
   * Link's default brand
   */
  readonly defaultBrand: CollectionFragment_links_defaultBrand;
  /**
   * The link's preview text to show on the brand modal
   */
  readonly previewInfo: CollectionFragment_links_previewInfo;
  /**
   * Link's images
   */
  readonly images: CollectionFragment_links_images;
}

export interface CollectionFragment_brands_edges_node_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface CollectionFragment_brands_edges_node_maxGiveLink_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * The Link's most recent promotion label
   */
  readonly promotionLabel: string | null;
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
}

export interface CollectionFragment_brands_edges_node_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: CollectionFragment_brands_edges_node_maxGiveLink_promotionInfo;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
}

export interface CollectionFragment_brands_edges_node {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Whether the current viewer has favourited this brand
   */
  readonly viewerHasFavorited: boolean;
  /**
   * Brand's images
   */
  readonly images: CollectionFragment_brands_edges_node_images;
  readonly maxGiveLink: CollectionFragment_brands_edges_node_maxGiveLink | null;
}

export interface CollectionFragment_brands_edges {
  readonly __typename: "BrandEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: CollectionFragment_brands_edges_node | null;
}

export interface CollectionFragment_brands {
  readonly __typename: "BrandConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<CollectionFragment_brands_edges | null> | null;
}

export interface CollectionFragment {
  readonly __typename: "Collection";
  /**
   * Collection's ID
   */
  readonly id: string;
  /**
   * Collection's name
   */
  readonly name: string | null;
  /**
   * The collection's style type
   */
  readonly style: CollectionStyle | null;
  /**
   * A list of a collection's images
   */
  readonly assets: ReadonlyArray<CollectionFragment_assets>;
  /**
   * An unpaginated list of the collection's links, max: 100
   */
  readonly links: ReadonlyArray<CollectionFragment_links>;
  /**
   * A paginated list of the collection's brands
   */
  readonly brands: CollectionFragment_brands;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: StorefrontFragment
// ====================================================

export interface StorefrontFragment_collections_assets_asset {
  readonly __typename: "Image";
  /**
   * Small version of asset, usually used for mobile screens
   */
  readonly small: string | null;
  /**
   * Where the image should link to.
   */
  readonly destination: string | null;
}

export interface StorefrontFragment_collections_assets {
  readonly __typename: "CollectionAsset";
  /**
   * Asset's Title
   */
  readonly title: string | null;
  /**
   * Asset's Images
   */
  readonly asset: StorefrontFragment_collections_assets_asset | null;
}

export interface StorefrontFragment_collections_links_defaultBrand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
}

export interface StorefrontFragment_collections_links_previewInfo_topPromotionInfo_link_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
}

export interface StorefrontFragment_collections_links_previewInfo_topPromotionInfo_link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: StorefrontFragment_collections_links_previewInfo_topPromotionInfo_link_promotionInfo;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
}

export interface StorefrontFragment_collections_links_previewInfo_topPromotionInfo {
  readonly __typename: "LinkPromotion";
  /**
   * Promotion's ID
   */
  readonly id: string;
  /**
   * Promotion's Link
   */
  readonly link: StorefrontFragment_collections_links_previewInfo_topPromotionInfo_link | null;
}

export interface StorefrontFragment_collections_links_previewInfo {
  readonly __typename: "LinkPreviewInfo";
  /**
   * The link's preview text to show on the brand modal
   */
  readonly description: string;
  /**
   * The link's promotion count
   */
  readonly promotionsCount: number;
  /**
   * The link's top promotion
   */
  readonly topPromotionInfo: StorefrontFragment_collections_links_previewInfo_topPromotionInfo | null;
}

export interface StorefrontFragment_collections_links_images {
  readonly __typename: "LinkImages";
  /**
   * Link's logo image URL
   */
  readonly logo: string | null;
  /**
   * Link's image URL
   */
  readonly image: string | null;
}

export interface StorefrontFragment_collections_links {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * Link's give amount
   */
  readonly giveAmount: string;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
  /**
   * Type of gift card
   */
  readonly giftcardType: string | null;
  /**
   * Link's default brand
   */
  readonly defaultBrand: StorefrontFragment_collections_links_defaultBrand;
  /**
   * The link's preview text to show on the brand modal
   */
  readonly previewInfo: StorefrontFragment_collections_links_previewInfo;
  /**
   * Link's images
   */
  readonly images: StorefrontFragment_collections_links_images;
}

export interface StorefrontFragment_collections_brands_edges_node_images {
  readonly __typename: "BrandImages";
  /**
   * Brand's logo image URL
   */
  readonly logo: string | null;
  /**
   * Brand's inverted logo image URL
   */
  readonly invertedLogo: string | null;
  /**
   * Brand's Logo Background Color
   */
  readonly logoBackgroundColor: string | null;
}

export interface StorefrontFragment_collections_brands_edges_node_maxGiveLink_promotionInfo {
  readonly __typename: "LinkPromotionInfo";
  /**
   * The Link's most recent promotion label
   */
  readonly promotionLabel: string | null;
  /**
   * Link's small promotion label
   */
  readonly promotionSecondaryLabel: string | null;
}

export interface StorefrontFragment_collections_brands_edges_node_maxGiveLink {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * The link's promotion info
   */
  readonly promotionInfo: StorefrontFragment_collections_brands_edges_node_maxGiveLink_promotionInfo;
  /**
   * Link's give sentence
   */
  readonly giveSentence: string;
}

export interface StorefrontFragment_collections_brands_edges_node {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
  /**
   * Whether the current viewer has favourited this brand
   */
  readonly viewerHasFavorited: boolean;
  /**
   * Brand's images
   */
  readonly images: StorefrontFragment_collections_brands_edges_node_images;
  readonly maxGiveLink: StorefrontFragment_collections_brands_edges_node_maxGiveLink | null;
}

export interface StorefrontFragment_collections_brands_edges {
  readonly __typename: "BrandEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: StorefrontFragment_collections_brands_edges_node | null;
}

export interface StorefrontFragment_collections_brands {
  readonly __typename: "BrandConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<StorefrontFragment_collections_brands_edges | null> | null;
}

export interface StorefrontFragment_collections {
  readonly __typename: "Collection";
  /**
   * Collection's ID
   */
  readonly id: string;
  /**
   * Collection's name
   */
  readonly name: string | null;
  /**
   * The collection's style type
   */
  readonly style: CollectionStyle | null;
  /**
   * A list of a collection's images
   */
  readonly assets: ReadonlyArray<StorefrontFragment_collections_assets>;
  /**
   * An unpaginated list of the collection's links, max: 100
   */
  readonly links: ReadonlyArray<StorefrontFragment_collections_links>;
  /**
   * A paginated list of the collection's brands
   */
  readonly brands: StorefrontFragment_collections_brands;
}

export interface StorefrontFragment {
  readonly __typename: "StoreFront";
  /**
   * StoreFront's ID
   */
  readonly id: string;
  /**
   * StoreFront's name
   */
  readonly name: string;
  /**
   * A list of the storefront's collections
   */
  readonly collections: ReadonlyArray<StorefrontFragment_collections>;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: GiftcardFragment
// ====================================================

export interface GiftcardFragment_balance {
  readonly __typename: "DollarFormats";
  /**
   * Dollar integer value, example: 100.23
   */
  readonly dollarsAmount: number;
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GiftcardFragment_value {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GiftcardFragment_merchant {
  readonly __typename: "Merchant";
  /**
   * Merchant's ID
   */
  readonly id: string;
  /**
   * Merchant's name
   */
  readonly name: string;
}

export interface GiftcardFragment_link_images {
  readonly __typename: "LinkImages";
  /**
   * Link's image URL
   */
  readonly image: string | null;
}

export interface GiftcardFragment_link_defaultBrand {
  readonly __typename: "Brand";
  /**
   * Brand's ID
   */
  readonly id: string;
  /**
   * Brand's Name
   */
  readonly name: string;
}

export interface GiftcardFragment_link {
  readonly __typename: "Link";
  /**
   * Link's ID
   */
  readonly id: string;
  /**
   * Link's name
   */
  readonly name: string;
  /**
   * Link's images
   */
  readonly images: GiftcardFragment_link_images;
  /**
   * Link's default brand
   */
  readonly defaultBrand: GiftcardFragment_link_defaultBrand;
  /**
   * Type of gift card
   */
  readonly giftcardType: string | null;
  /**
   * Link's gift card wallet description
   */
  readonly walletDescription: string | null;
  /**
   * Link's exclusions
   */
  readonly exclusions: string | null;
  /**
   * Link's giftcard instructions
   */
  readonly giftcardInstructions: string | null;
}

export interface GiftcardFragment_reloadInfo_reloadThreshold {
  readonly __typename: "DollarFormats";
  /**
   * Formatted as a string, example: $100.23
   */
  readonly formatted: string;
}

export interface GiftcardFragment_reloadInfo {
  readonly __typename: "GiftcardReloadableInfo";
  /**
   * Whether or not the reload functionality for a giftcard is enabled
   */
  readonly isReloadingEnabled: boolean;
  /**
   * Is giftcard auto reloadable?
   */
  readonly isAutoReloadable: boolean | null;
  /**
   * Giftcard's initial value
   */
  readonly reloadThreshold: GiftcardFragment_reloadInfo_reloadThreshold | null;
}

export interface GiftcardFragment_transaction {
  readonly __typename: "Transaction";
  /**
   * Transaction ID
   */
  readonly id: string;
  /**
   * When transaction was created
   */
  readonly createdAt: DateTimeISO;
  /**
   * Name of giftee
   */
  readonly giftName: string | null;
  /**
   * Email of giftee
   */
  readonly giftEmail: string | null;
}

export interface GiftcardFragment_accessLogs_edges_node_data {
  readonly __typename: "AccessLogsData";
  /**
   * Data label
   */
  readonly label: string;
  /**
   * Data value
   */
  readonly value: string | null;
}

export interface GiftcardFragment_accessLogs_edges_node {
  readonly __typename: "AccessLogs";
  /**
   * Access type of log entry
   */
  readonly accessType: GiftcardAccessType;
  /**
   * Time of log entry
   */
  readonly accessedAt: DateTimeISO;
  /**
   * ID of log entry
   */
  readonly id: string;
  /**
   * Metadata of log entry
   */
  readonly data: ReadonlyArray<GiftcardFragment_accessLogs_edges_node_data>;
}

export interface GiftcardFragment_accessLogs_edges {
  readonly __typename: "AccessLogsEdge";
  /**
   * A cursor for use in pagination.
   */
  readonly cursor: string;
  /**
   * The item at the end of the edge.
   */
  readonly node: GiftcardFragment_accessLogs_edges_node | null;
}

export interface GiftcardFragment_accessLogs {
  readonly __typename: "AccessLogsConnection";
  /**
   * A list of edges.
   */
  readonly edges: ReadonlyArray<GiftcardFragment_accessLogs_edges | null> | null;
}

export interface GiftcardFragment {
  readonly __typename: "Giftcard";
  /**
   * Giftcard's ID
   */
  readonly id: string;
  /**
   * Giftcards unique id
   */
  readonly uuid: string;
  /**
   * Giftcard's external URL
   */
  readonly externalUrl: string | null;
  /**
   * Giftcard's last updated at
   */
  readonly updatedAt: string | null;
  /**
   * Whether to open the giftcard in webview or browser
   */
  readonly isExternalTarget: boolean;
  /**
   * Whether or not a giftcard is reloadable
   */
  readonly isReloadable: boolean;
  /**
   * Giftcard's PIN
   */
  readonly pin: string | null;
  /**
   * Giftcard's type
   */
  readonly giftcardType: GiftcardType;
  /**
   * Giftcard's barcode
   */
  readonly barcode: string | null;
  /**
   * Giftcard's card number
   */
  readonly cardNumber: string | null;
  /**
   * Giftcard's balance
   */
  readonly balance: GiftcardFragment_balance | null;
  /**
   * Giftcard's initial value
   */
  readonly value: GiftcardFragment_value;
  /**
   * Giftcard's merchant
   */
  readonly merchant: GiftcardFragment_merchant | null;
  /**
   * Giftcard's link
   */
  readonly link: GiftcardFragment_link;
  /**
   * The giftcard's reloadable info
   */
  readonly reloadInfo: GiftcardFragment_reloadInfo | null;
  /**
   * Giftcard's transaction
   */
  readonly transaction: GiftcardFragment_transaction | null;
  /**
   * paginated access logs for giftcard
   */
  readonly accessLogs: GiftcardFragment_accessLogs | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Describes the style of a collection.
 */
export enum CollectionStyle {
  BANNER = "BANNER",
  BANNER_CAROUSEL = "BANNER_CAROUSEL",
  BRAND_CAROUSEL = "BRAND_CAROUSEL",
  CAROUSEL = "CAROUSEL",
  COLLAGE = "COLLAGE",
  CONTENT = "CONTENT",
  DEPARTMENT_GRID = "DEPARTMENT_GRID",
  FEATURED = "FEATURED",
  FOUR_UP = "FOUR_UP",
  GRID = "GRID",
  GRID_HALF_BANNER = "GRID_HALF_BANNER",
  GRID_QUARTER_BANNER = "GRID_QUARTER_BANNER",
  LINK_PROMOTIONS = "LINK_PROMOTIONS",
  NEARBY_BRANDS = "NEARBY_BRANDS",
  PLAYLIST = "PLAYLIST",
  SINGLE = "SINGLE",
  SPORTS_BRANDS = "SPORTS_BRANDS",
  STORE_FRONT = "STORE_FRONT",
  THREE_UP = "THREE_UP",
  TWO_UP = "TWO_UP",
}

/**
 * What is the source of these coordinates?
 */
export enum CoordinatesSource {
  CAMPAIGN = "CAMPAIGN",
  CURRENT = "CURRENT",
  SEARCH = "SEARCH",
}

/**
 * Available countries.
 */
export enum Country {
  CANADA = "CANADA",
  USA = "USA",
}

/**
 * The list of currencies available.
 */
export enum Currency {
  CAD = "CAD",
  USD = "USD",
}

/**
 * Describes the type of a StoreFront, used specifically for querying the different default StoreFronts.
 */
export enum DefaultStoreFrontType {
  DEALS_DEFAULT = "DEALS_DEFAULT",
  FIRST_SHOP = "FIRST_SHOP",
  GIFT_CARDS_DEFAULT = "GIFT_CARDS_DEFAULT",
  HOME_DEFAULT = "HOME_DEFAULT",
  ONBOARDING_DEFAULT = "ONBOARDING_DEFAULT",
  STORE_DEFAULT = "STORE_DEFAULT",
  TEAM_STORE_DEFAULT = "TEAM_STORE_DEFAULT",
}

/**
 * How funds are to be distributed
 */
export enum FundsDistributionMethod {
  PLAYER = "PLAYER",
  TEAM = "TEAM",
  UNDECIDED = "UNDECIDED",
}

/**
 * Giftcard access type
 */
export enum GiftcardAccessType {
  ADMIN_VIEW = "ADMIN_VIEW",
  AUTO_RELOAD = "AUTO_RELOAD",
  DISABLE_AUTO_RELOAD = "DISABLE_AUTO_RELOAD",
  ENABLE_AUTO_RELOAD = "ENABLE_AUTO_RELOAD",
  MANUAL_RELOAD = "MANUAL_RELOAD",
  PRINT = "PRINT",
  PURCHASE = "PURCHASE",
  UPDATE_BALANCE = "UPDATE_BALANCE",
  VIEW = "VIEW",
}

/**
 * How this denomination will be deliveried
 */
export enum GiftcardDenominationDeliveryMethod {
  DIGITAL = "DIGITAL",
  PHYSICAL = "PHYSICAL",
}

/**
 * Describes the available sort options for a giftcard
 */
export enum GiftcardSortEnum {
  BALANCE = "BALANCE",
  DATE = "DATE",
  NAME = "NAME",
}

/**
 * The type of a giftcard
 */
export enum GiftcardType {
  GIFTCARD = "GIFTCARD",
  RELOADABLE = "RELOADABLE",
  VOUCHER = "VOUCHER",
}

/**
 * Describes the type of filters available for intent.
 */
export enum IntentFilterByEnum {
  PENDING = "PENDING",
  SUPPORTED = "SUPPORTED",
}

/**
 * Describes the browser that was used to view an intent on mobile.
 */
export enum IntentMobileBrowserTypeEnum {
  APP_BUTTON = "APP_BUTTON",
  APP_CHROME = "APP_CHROME",
  APP_CHROME_ESCAPE = "APP_CHROME_ESCAPE",
  APP_FLIPGIVE_WEBVIEW = "APP_FLIPGIVE_WEBVIEW",
  APP_SAFARI = "APP_SAFARI",
  APP_SAFARI_ESCAPE = "APP_SAFARI_ESCAPE",
}

/**
 * Describes the type of an intent.
 */
export enum IntentTypeEnum {
  BUY = "BUY",
  DONATION = "DONATION",
  FLIPGIVECREDIT = "FLIPGIVECREDIT",
  FLIPGIVEINACTIVITYFEE = "FLIPGIVEINACTIVITYFEE",
  LINKEDCARDSALE = "LINKEDCARDSALE",
  RELOAD = "RELOAD",
  SHOP = "SHOP",
  TRANSFER = "TRANSFER",
}

/**
 * Describes the type of store link.
 */
export enum LinkType {
  AUTORELOADGIFTCARD = "AUTORELOADGIFTCARD",
  BOUNTYSHOP = "BOUNTYSHOP",
  BRAND = "BRAND",
  DEPARTMENT = "DEPARTMENT",
  EMPYR = "EMPYR",
  GIFTCARDOFFER = "GIFTCARDOFFER",
  GIFTCARDSHOP = "GIFTCARDSHOP",
  PRODUCT = "PRODUCT",
  RELOADABLEGIFTCARD = "RELOADABLEGIFTCARD",
  SHORTCUT = "SHORTCUT",
  TRAVELSHOP = "TRAVELSHOP",
  VOUCHER = "VOUCHER",
}

/**
 * Describes the status of missing claim.
 */
export enum MissingClaimStatusEnum {
  APPROVED = "APPROVED",
  AUTO_CLOSED = "AUTO_CLOSED",
  DENIED = "DENIED",
  PENDING = "PENDING",
}

/**
 * Describes the platform of a device.
 */
export enum PlatformEnum {
  ANDROID = "ANDROID",
  IOS = "IOS",
  WEB = "WEB",
  WEB_ANDROID = "WEB_ANDROID",
  WEB_EXTENSION = "WEB_EXTENSION",
  WEB_IOS = "WEB_IOS",
}

/**
 * The privacy level that describes how much information about an object is shared.
 */
export enum PrivacyLevel {
  ANONYMOUS = "ANONYMOUS",
  MASKED = "MASKED",
  NONE = "NONE",
}

/**
 * Describes the type of reaction for an activity.
 */
export enum ReactionType {
  DISLIKE = "DISLIKE",
  LIKE = "LIKE",
}

/**
 * Describes the current step that the user is in during onboarding.
 */
export enum TeamOnboardingStep {
  CAPTAIN_DOWNLOAD_RESOURCES = "CAPTAIN_DOWNLOAD_RESOURCES",
  CAPTAIN_INVITE_TEAM = "CAPTAIN_INVITE_TEAM",
  CAPTAIN_SET_GOAL = "CAPTAIN_SET_GOAL",
  CAPTAIN_SET_OBJECTIVE = "CAPTAIN_SET_OBJECTIVE",
  CAPTAIN_SET_ROSTER = "CAPTAIN_SET_ROSTER",
  CAPTAIN_SET_TEAM_PICKS = "CAPTAIN_SET_TEAM_PICKS",
  TEAM_MEMBER_ESTIMATE_EARNINGS = "TEAM_MEMBER_ESTIMATE_EARNINGS",
  TEAM_MEMBER_SET_FAVORITES = "TEAM_MEMBER_SET_FAVORITES",
  TEAM_MEMBER_SET_PLAYER = "TEAM_MEMBER_SET_PLAYER",
  TEAM_MEMBER_SET_ROLE = "TEAM_MEMBER_SET_ROLE",
}

/**
 * UTM attributes for a campaign on creation.
 */
export interface CampaignUtmDataInput {
  readonly utmType?: string | null;
  readonly utmSource?: string | null;
  readonly utmMedium?: string | null;
  readonly utmCampaign?: string | null;
  readonly utmTerm?: string | null;
  readonly utmContent?: string | null;
  readonly utmChannel?: string | null;
  readonly branchLinkId?: string | null;
  readonly appsFlyerId?: string | null;
}

/**
 * Coordinates attributes
 */
export interface CoordinatesInput {
  readonly latitude: number;
  readonly longitude: number;
  readonly source?: CoordinatesSource | null;
}

/**
 * Attributes for creating a giftcard intent
 */
export interface GiftcardIntentCreateInput {
  readonly linkId: string;
  readonly brandId?: string | null;
  readonly collectionId?: string | null;
  readonly storefrontId?: string | null;
  readonly labelPromotionId?: string | null;
  readonly commissionPromotionId?: string | null;
  readonly utmData?: CampaignUtmDataInput | null;
  readonly isGift: boolean;
  readonly isPhysical: boolean;
  readonly amountCents: number;
  readonly quantity: number;
  readonly fundraiserId?: string | null;
  readonly fundraiserIds?: ReadonlyArray<string> | null;
  readonly promotionId?: string | null;
  readonly reloadThresholdCents?: number | null;
  readonly deviceAttributes: IntentDeviceAttributesInput;
  readonly recaptchaToken?: string | null;
}

/**
 * Attributes for creating an Intent
 */
export interface IntentCreateInput {
  readonly linkId?: string | null;
  readonly brandId?: string | null;
  readonly intentType?: string | null;
  readonly buyerName?: string | null;
  readonly buyerEmail?: string | null;
  readonly totalAmountCents?: number | null;
  readonly isAnonymous?: boolean | null;
  readonly collectionId?: string | null;
  readonly storefrontId?: string | null;
  readonly labelPromotionId?: string | null;
  readonly commissionPromotionId?: string | null;
  readonly utmData?: CampaignUtmDataInput | null;
  readonly fundraiserId?: string | null;
  readonly fundraiserIds?: ReadonlyArray<string> | null;
  readonly locationId?: string | null;
  readonly promotionId?: string | null;
  readonly deviceAttributes?: IntentDeviceAttributesInput | null;
  readonly devicePlatform?: PlatformEnum | null;
  readonly deviceType?: string | null;
  readonly deviceVersion?: string | null;
  readonly deviceIpAddress?: string | null;
  readonly deviceUserAgent?: string | null;
}

/**
 * Information about the device making the intent
 */
export interface IntentDeviceAttributesInput {
  readonly devicePlatform: PlatformEnum;
  readonly deviceType?: string | null;
  readonly deviceVersion?: string | null;
  readonly ipAddress?: string | null;
  readonly userAgent?: string | null;
  readonly mobileBrowserType?: IntentMobileBrowserTypeEnum | null;
}

/**
 * Attributes for creating a reload intent
 */
export interface ReloadIntentCreateInput {
  readonly giftcardId: string;
  readonly campaignId: string;
  readonly reloadAmountCents: number;
  readonly recaptchaToken?: string | null;
  readonly devicePlatform: PlatformEnum;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
