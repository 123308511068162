import { AnchorHTMLAttributes, DetailedHTMLProps } from "react";
import { BASE_MAIN_URL } from "@shared/config/environment";
import NextLink, { LinkProps as NextLinkProps } from "next/link";

// == Constants ============================================================

InternalLink.defaultProps = {
  replace: false,
  scroll: false,
  shallow: false,
};

// == Component ============================================================

export function InternalLink({
  href = "",
  as,
  replace,
  scroll,
  shallow,
  children,
  ...linkProps
}: NextLinkProps &
  Omit<DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>, "href">) {
  if (href.toString().includes(BASE_MAIN_URL)) {
    return (
      <a href={href.toString()} {...linkProps}>
        {children}
      </a>
    );
  }

  return (
    <NextLink
      passHref
      as={as}
      href={href}
      prefetch={false}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
    >
      <a {...linkProps}>{children}</a>
    </NextLink>
  );
}
