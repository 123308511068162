import { CaptureContext, SeverityLevel, User } from "@sentry/types";

// == Types ================================================================

// == Constants ============================================================

// == Functions ==============================================================

const capture = async (
  error: $IntentionalAny,
  level: CaptureContext | SeverityLevel | undefined,
  context = "",
  details: $IntentionalAny = null
) => {
  const Sentry = await import("@sentry/nextjs");
  if (level && context) {
    return Sentry.setContext("extras", {
      level,
      context,
    });
  }
  if (details) {
    Sentry.setContext("details", details);
  }
  if (level === "error" && error.message) {
    return Sentry.captureException(error);
  }
  if (level === "error") {
    return Sentry.captureException(new Error(error));
  }
  if (error.message) {
    return Sentry.captureMessage(error.message, level);
  }
  return Sentry.captureMessage(JSON.stringify(error), level);
};

const info = (error: $IntentionalAny, context = "", details: $IntentionalAny = null) => {
  void capture(error, "info", context, details);
};

const warn = (error: $IntentionalAny, context = "", details: $IntentionalAny = null) => {
  void capture(error, "warning", context, details);
};

const error = (err: $IntentionalAny, context = "", details: $IntentionalAny = null) => {
  void capture(err, "error", context, details);
};

// == Exports ==============================================================

export const ErrorService = {
  info,
  warn,
  error,
};

export const sentrySetUser = async (user: User) => {
  const Sentry = await import("@sentry/nextjs");
  return () => Sentry.setUser(user);
};
