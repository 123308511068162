import { getOnboardingInfo } from "@core-shop/models/onboarding/api";
import { minute } from "../constants";
import { EntityKey } from "../types";

export const onboardingQueries = {
  onboardingInfo: () => ({
    queryKey: [EntityKey.ONBOARDING, "info"],
    queryFn: () => getOnboardingInfo(),
    staleTime: 5 * minute,
    cacheTime: 5.5 * minute,
  }),
} as const;
