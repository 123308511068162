import { useMemo } from "react";
import { ISearchModalViewProps } from "@core-shop/components";
import { TToggleFavoriteMutation } from "@core-shop/types";
import { useRouter } from "@shared/hooks";
import { queryParams } from "@shared/utils/routes";
import { InfiniteScrollWrapper } from "@shared-tailwind/atoms";
import { ESearchResultType } from "../types";
import { SearchResultsList } from "./SearchResultsList";

interface IProps {
  brandSearchResultsQuery: ISearchModalViewProps["brandSearchResultsQuery"];
  brandsSearchResults: ISearchModalViewProps["brandsSearchResults"];
  dealsSearchResults: ISearchModalViewProps["dealsSearchResults"];
  dealsSearchResultsQuery: ISearchModalViewProps["dealsSearchResultsQuery"];
  toggleFavoriteBrandMutation: TToggleFavoriteMutation;
}

export const FullSearchResultsView = ({
  brandSearchResultsQuery,
  dealsSearchResultsQuery,
  brandsSearchResults,
  toggleFavoriteBrandMutation,
  dealsSearchResults,
}: IProps) => {
  const router = useRouter();

  const resultType = useMemo(() => {
    const searchType = router.getQuery(queryParams.searchType) ?? {};
    return searchType as ESearchResultType;
  }, [router]);

  const isBrandSearch = useMemo(() => resultType === ESearchResultType.BRAND, [resultType]);

  const { fetchNextPage: fetchNextDeals, hasNextPage: hasNextDealsPage } = dealsSearchResultsQuery || {};
  const { fetchNextPage: fetchNextBrands, hasNextPage: hasNextBrandsPage } = brandSearchResultsQuery || {};

  const hasNextPage = useMemo(
    () => (isBrandSearch ? hasNextBrandsPage : hasNextDealsPage),
    [isBrandSearch, hasNextBrandsPage, hasNextDealsPage]
  );

  const handleGetNextPage = () => {
    if (!hasNextPage) return;
    if (isBrandSearch) {
      fetchNextBrands?.();
    } else {
      fetchNextDeals?.();
    }
  };

  return (
    <div className="flex flex-col w-full h-full">
      {/* <div>
        <HorizontalScroll>
          <Button className="w-max" variant={ButtonVariants.toggle}>
            Closest match
          </Button>
          <Button className="w-max" variant={ButtonVariants.toggleNotSelected}>
            Name
          </Button>
          <Button className="w-max" variant={ButtonVariants.toggleNotSelected}>
            Highest give
          </Button>
          <Button className="w-max" variant={ButtonVariants.toggleNotSelected}>
            Brand
          </Button>
        </HorizontalScroll>
      </div> */}
      <InfiniteScrollWrapper
        hasMore={!!hasNextPage}
        isLoading={brandSearchResultsQuery?.isFetchingNextPage || dealsSearchResultsQuery?.isFetchingNextPage}
        onReachBottom={handleGetNextPage}
      >
        <SearchResultsList
          brandResults={isBrandSearch ? brandsSearchResults : []}
          dealResults={!isBrandSearch ? dealsSearchResults : []}
          title={isBrandSearch ? "Brands" : "Deals"}
          toggleFavoriteBrandMutation={toggleFavoriteBrandMutation}
        />
      </InfiniteScrollWrapper>
    </div>
  );
};
