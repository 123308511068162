import { useMutation } from "@tanstack/react-query";
import { mutations } from "@core-shop/cache";

const { mutationFn: createGiftcardIntent } = mutations.createGiftcardIntent();
const { mutationFn: createShopIntent } = mutations.createShopIntent();
const { mutationFn: reloadGiftcardIntent } = mutations.reloadGiftcardIntent();

export function useCreateIntent() {
  return {
    giftcard: useMutation(createGiftcardIntent),
    reloadGiftcard: useMutation(reloadGiftcardIntent),
    shop: useMutation(createShopIntent),
  };
}
