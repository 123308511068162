import React from "react";
import { BaseBrandBlock } from "../BaseBrandBlock/BaseBrandBlock";
import { InternalLink } from "../InternalLink/InternalLink";

interface IProps {
  imageSrc: string | null;
  name: string;
  bgColor?: string | null;
  className?: string;
  expires?: string | null;
  gives?: string;
  hasButton?: boolean;
  href?: string;
}

export const DealBlock: React.FC<IProps> = ({
  gives,
  imageSrc,
  name,
  expires,
  bgColor,
  hasButton = true,
  href = "",
  className,
}) => {
  return (
    <InternalLink className={className} href={href}>
      <BaseBrandBlock
        isDeal
        bgColor={bgColor ?? ""}
        buttonText={hasButton ? "Shop" : undefined}
        imageSrc={imageSrc ?? ""}
        subtitle={expires ?? ""}
        title={gives ?? ""}
        topText={name}
      />
    </InternalLink>
  );
};
