// == Types ================================================================

interface IOptions {
  showCount?: boolean;
  suffix?: string;
  zeroValueFallback?: string;
}

// == Constants ============================================================

// == Exports ==============================================================

export function pluralize(
  noun: string,
  count: number,
  {
    showCount = true,
    suffix = "s",
    zeroValueFallback = `${showCount ? 0 : ""}${noun}${suffix}`,
  }: IOptions = {}
) {
  if (!count) return zeroValueFallback;
  if (!showCount) return `${noun}${count !== 1 ? suffix : ""}`;
  return `${showCount ? `${count.toLocaleString()} ` : ""}${noun}${count !== 1 ? suffix : ""}`;
}
