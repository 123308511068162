import React from "react";
import { useCloud } from "@core-shop/hooks";
import { useLocationSearch } from "@core-shop/hooks/brand/useLocationSearch";
import { getPaginatedNodes } from "@core-shop/utils";
import { INearbyWidgetViewProps } from "./INearbyWidgetViewProps";

interface IProps {
  View: React.FC<INearbyWidgetViewProps>;
}

const DEFAULT_LOCATION = {
  latitude: 43.6532,
  longitude: -79.3832,
};

const DISTANCE = 50;
const MAX_COUNT = 20;

export const NearbyWidget: React.FC<IProps> = ({ View }) => {
  const { data } = useCloud();
  const { latitude, longitude, currency } = data?.ShopCloud?.activeCampaign ?? {};

  const locationSearchQuery = useLocationSearch({
    coordinates: {
      latitude: latitude || DEFAULT_LOCATION.latitude,
      longitude: longitude || DEFAULT_LOCATION.longitude,
    },
    currency,
    distance: DISTANCE,
    first: MAX_COUNT,
  });

  const locations = getPaginatedNodes(locationSearchQuery.data?.LocationSearch.edges);

  const addedIds: string[] = [];
  const formattedBrands = locations
    .map(({ id, brand }) => ({ ...brand, location: { __typename: "Location", id } }))
    .filter((brand) => {
      const locationBrandId = brand?.id;
      if (locationBrandId && !addedIds.includes(locationBrandId)) {
        addedIds.push(locationBrandId);
        return true;
      }
      return false;
    }) as INearbyWidgetViewProps["formattedBrands"];

  return <View formattedBrands={formattedBrands} locationSearchQuery={locationSearchQuery} />;
};
