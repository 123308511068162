import gql from "graphql-tag";

export const getPayoutDetailsQuery = gql`
  query GetPayoutDetailsQuery($first: Int, $after: String, $id: ID!) {
    Campaign {
      id
      payment(id: $id) {
        id
        state
        processedAt
        amount {
          formatted
        }
        recipients(first: $first, after: $after) {
          edges {
            cursor
            node {
              id
              amount {
                formatted
              }
              fundraiser {
                id
                avatarImage
                name
                teamRoles {
                  id
                  name
                }
                amountRaised {
                  formatted
                }
              }
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
  }
`;
