import { request } from "@core-shop/services/api/request";
import {
  GetNotificationsSettings,
  GetProfileSettings,
  GetTeamQuery,
  UpdateNotifications,
  UpdateNotificationsVariables,
  UpdateProfile,
  UpdateProfileVariables,
} from "@core-shop/types";
import { getNotificationsSettingsQuery } from "./api/getNotificationsSettings";
import { getProfileSettingsQuery } from "./api/getProfileSettings";
import { getTeamQuery } from "./api/getTeam";
import { updateNotificationsSettingsMutation } from "./api/updateNotificationsSettings";
import { updateProfileSettingsMutation } from "./api/updateProfileSettings";

export function getTeam() {
  return request<GetTeamQuery>(getTeamQuery);
}
export function getNotificationsSettings() {
  return request<GetNotificationsSettings>(getNotificationsSettingsQuery);
}
export function updateNotificationsSettings(variables: UpdateNotificationsVariables) {
  return request<UpdateNotifications, UpdateNotificationsVariables>(
    updateNotificationsSettingsMutation,
    variables
  );
}
export function getProfileSettings() {
  return request<GetProfileSettings>(getProfileSettingsQuery);
}
export function updateProfileSettings(variables: UpdateProfileVariables) {
  return request<UpdateProfile, UpdateProfileVariables>(updateProfileSettingsMutation, variables);
}
