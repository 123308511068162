import { AppName } from "../config/environment";
import * as routes from "./routes";
import { spelling } from "./spelling";

// == Types ================================================================

// == Constants ============================================================

// == Exports ==============================================================

export const POSTAL_ZIP_REGEX = RegExp(
  "([a-zA-Z][0-9][a-zA-Z][ ]?[0-9][a-zA-Z][0-9])|([0-9]{5})(?:[-s]*([0-9]{4}))?$"
);
export const ZIP_REGEX = RegExp("([0-9]{5})(?:[-s]*([0-9]{4}))?$");
export const POSTAL_CODE_REGEX = RegExp("^([A-Za-z][0-9][A-Za-z][ ]?[0-9][A-Za-z][0-9])$");
export const GOOGLE_API_KEY = process.env.GOOGLE_API_KEY as string;
export const GOOGLE_MAPS_KEY = process.env.GOOGLE_MAPS_KEY as string;
export const FACEBOOK_APP_ID = process.env.FACEBOOK_APP_ID as string;
export const IP_INFO_TOKEN = process.env.IP_INFO_TOKEN as string;
export const IP_INFO_URL = "https://ipinfo.io/json";
export const GOOGLE_MAP_API_URL = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places&callback=placesScriptReady`;
export const STATES = [
  ["AL", "Alabama"],
  ["AK", "Alaska"],
  ["AZ", "Arizona"],
  ["AR", "Arkansas"],
  ["CA", "California"],
  ["CO", "Colorado"],
  ["CT", "Connecticut"],
  ["DE", "Delaware"],
  ["FL", "Florida"],
  ["GA", "Georgia"],
  ["HI", "Hawaii"],
  ["ID", "Idaho"],
  ["IL", "Illinois"],
  ["IN", "Indiana"],
  ["IA", "Iowa"],
  ["KS", "Kansas"],
  ["KY", "Kentucky"],
  ["LA", "Louisiana"],
  ["ME", "Maine"],
  ["MD", "Maryland"],
  ["MA", "Massachusetts"],
  ["MI", "Michigan"],
  ["MN", "Minnesota"],
  ["MS", "Mississippi"],
  ["MO", "Missouri"],
  ["MT", "Montana"],
  ["NE", "Nebraska"],
  ["NV", "Nevada"],
  ["NH", "New Hampshire"],
  ["NJ", "New Jersey"],
  ["NM", "New Mexico"],
  ["NY", "New York"],
  ["NC", "North Carolina"],
  ["ND", "North Dakota"],
  ["OH", "Ohio"],
  ["OK", "Oklahoma"],
  ["OR", "Oregon"],
  ["PA", "Pennsylvania"],
  ["RI", "Rhode Island"],
  ["SC", "South Carolina"],
  ["SD", "South Dakota"],
  ["TN", "Tennessee"],
  ["TX", "Texas"],
  ["UT", "Utah"],
  ["VT", "Vermont"],
  ["VA", "Virginia"],
  ["WA", "Washington"],
  ["WV", "West Virginia"],
  ["WI", "Wisconsin"],
  ["WY", "Wyoming"],
];

export const PROVINCES = [
  ["AB", "Alberta"],
  ["BC", "British Columbia"],
  ["MB", "Manitoba"],
  ["NB", "New Brunswick"],
  ["NL", "Newfoundland and Labrador"],
  ["NS", "Nova Scotia"],
  ["ON", "Ontario"],
  ["PE", "Prince Edward Island"],
  ["QC", "Quebec"],
  ["SK", "Saskatchewan"],
  ["NT", "Northwest Territories"],
  ["NU", "Nunavut"],
  ["YT", "Yukon"],
];

export const PAYMENT_METHOD = (paymentMethod: string | null) => {
  if (paymentMethod === "HW_BANK_TRANSFER") {
    return {
      label: "Bank transfer (3-5 business days)",
      description: "Sent within 3-5 business days",
      switchText: "Switch to bank transfer (3-5 business days)",
    };
  }
  return {
    label: `Physical ${spelling("check", { capitalize: false })} (10-15 business days)`,
    description: "Sent to mailing address within 10-15 business days",
  };
};

export const DISPLAY_DATE_FORMAT = "MMM dd, yyyy";
export const API_DATE_FORMAT = "yyyy-MM-dd";

export const PLACEHOLDER_AVATAR = "https://d2wkegjj8g6j4.cloudfront.net/images/uploads/square_100.png";
export const INACTIVE_AVATAR = "https://static.thenounproject.com/png/115194-200.png";

export const DEFAULT_LOGO_WHITE =
  "https://flipgive.imgix.net/images/press/flipgive_logo_white.png?auto=compress,format";

export const DEFAULT_LOGO =
  "https://flipgive.imgix.net/images/sites/site_logo_campaigns/000/000/031/original/flipgive_logo_black.png?auto=compress,format";

// ONLY USE BUDGET or FUNDING logo if unable to add text underneath default logo
export const BUDGET_LOGO =
  "https://flipgive.imgix.net/budget/header/budgetLogo.png?ch=Width%2CDPR%2CSave-Data&auto=format%2Ccompress&dpr=2&w=60";

export const FUNDING_LOGO =
  "https://flipgive.imgix.net/images/sites/site_logo_campaigns/000/000/031/original/flipgive_logo_black.png?ch=Width%2CDPR%2CSave-Data&auto=format%2Ccompress&dpr=2&w=75";

export const FULL_LOGO =
  "https://flipgive.imgix.net/images/press/flipgive_logo_black.png?ch=Width%2CDPR%2CSave-Data&auto=format%2Ccompress&dpr=2&w=100";

export const ALPHA_NUMBER_ARRAY = "#abcdefghijklmnopqrstuvwxyz1234567890".toUpperCase().split("");

export const WHITE_ARROW_LOGO = "https://flipgive.imgix.net/images/header/fg_logo.svg?auto=compress,format";

export const DEFAULT_ORG_IMAGE_URL =
  "https://flipgive.imgix.net/images/site/icons/hero/baseball-coin-cart-dog.png?auto=compress,format";

export const MAX_DOG_IMAGE = "https://flipgive.imgix.net/images/dog.png?auto=compress,format";

export const NO_AVATAR_COLORS = [
  "#1abc9c",
  "#2ecc71",
  "#3498db",
  "#9b59b6",
  "#34495e",
  "#16a085",
  "#27ae60",
  "#2980b9",
  "#8e44ad",
  "#2c3e50",
  "#f1c40f",
  "#e67e22",
  "#e74c3c",
  "#c9d2d6",
  "#95a5a6",
  "#f39c12",
  "#d35400",
  "#c0392b",
  "#bdc3c7",
  "#7f8c8d",
];

export const FUNDRAISER_ROLES = [
  { value: "coach", label: "Coach" },
  { value: "treasurer", label: "Treasurer" },
  { value: "manager", label: "Team Manager" },
  { value: "fundraiser_coordinator", label: "Fundraising Coordinator" },
  { value: "social_coordinator", label: "Social/Events Coordinator" },
  { value: "parent_or_player", label: "Parent/Guardian or Player" },
  { value: "supporter", label: "Supporter" },
];

export const COUNTRY_NAMES = {
  CANADA: "Canada",
  USA: "U.S.",
};

const { BANK, BUDGET, FUNDING } = AppName;

export const APPS = [
  {
    name: FUNDING,
    title: "Shop",
    subtext: "An easy-to-use cash back app for teams.",
    icon: "https://flipgive.imgix.net/images/site/icons/app-three-panel/Cart.png?auto=compress,format",
    link: (shopUrl?: string) => shopUrl ?? "",
    shouldShow: () => true,
    twThemeColor: "shop",
  },
  {
    name: BUDGET,
    title: "Budget",
    subtext: "A free team budgeting and payment solution.",
    icon: "https://flipgive.imgix.net/images/site/icons/app-three-panel/Bar-Graph.png?auto=compress,format",
    link: () => routes.budget.root(),
    shouldShow: () => true,
    twThemeColor: "budget",
  },
  {
    name: BANK,
    title: "Banking",
    subtext: "A banking app and cash back card for teams.",
    icon: "https://flipgive.imgix.net/images/site/icons/app-three-panel/Credit-Card.png?auto=compress,format",
    link: () => routes.external.bankDashboard(),
    shouldShow: () => true,
    twThemeColor: "bank",
  },
];

// Both urls are used for staging env, so not related to env, just constants
export const IMGIX = "https://flipgive.imgix.net";
export const IMGIX_STAGING = "https://almostflip.imgix.net";

// Types

export interface IHeaderLink {
  href: string;
  label: string;
  active?: boolean;
}

export enum Currencies {
  CAD = "CAD",
  USD = "USD",
}

export const MOBILE_WIDTH = 768;

export const DONATION_PROCESSING_PERCENTAGE = 0.025;
