export const GiveAmount: React.FC<{
  giveSentence: React.ReactNode;
  className?: string;
  secondaryLabel?: string | null;
}> = ({ giveSentence, secondaryLabel, className = "" }) => (
  <div className={className}>
    <p className="font-medium text-lg">{giveSentence}</p>
    {secondaryLabel && (
      <p
        className="text-xs line-clamp-1"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{ __html: secondaryLabel ?? "" }}
      />
    )}
  </div>
);
