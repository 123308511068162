import { ReactNode } from "react";
import classNames from "classnames";

// == Types ================================================================

interface IProps {
  children: ReactNode;
  className?: string;
  htmlFor?: string;
}

// == Constants ============================================================

// == Component ============================================================

export function Label({ children, className, htmlFor }: IProps) {
  return (
    <label
      className={classNames("block text-sm font-medium text-gray-700 mb-1", className)}
      htmlFor={htmlFor}
    >
      {children}
    </label>
  );
}

// == Styles ===============================================================
