import { request } from "@core-shop/services/api/request";
import {
  ActivityQueryAll,
  ActivityQueryAllVariables,
  ActivityQueryFundraiser,
  ActivityQueryFundraiserVariables,
  AmountsBreakdownQuery,
  GetAmountWithdrawnBreakdownQuery,
  GetFundraisersList,
  GetFundraisersListVariables,
  GetIntentQuery,
  GetIntentQueryVariables,
  GetPayoutDetailsQuery,
  GetPayoutDetailsQueryVariables,
  GetPayoutsQuery,
  GetPayoutsQueryVariables,
  JoinedFundraisersQuery,
  TransferFundsMutation,
  TransferFundsMutationVariables,
} from "@core-shop/types";
import { activityQueryAll, activityQueryFundraiser } from "./api/activity";
import { getAmountWithdrawnBreakdownQuery } from "./api/amountWithdrawnBreakdown";
import { amountsBreakdownQuery } from "./api/amountsBreakdown";
import { fundraisersListQuery } from "./api/fundraisersList";
import { getIntentQuery } from "./api/intent";
import { getPayoutDetailsQuery } from "./api/payoutDetails";
import { getPayoutsQuery } from "./api/payouts";
import { joinedFundraisersQuery, transferFundsMutation } from "./api/transfer";

export function getAmountsBreakdown() {
  return request<AmountsBreakdownQuery>(amountsBreakdownQuery);
}

export function getActivityAll(vars: ActivityQueryAllVariables) {
  return request<ActivityQueryAll, ActivityQueryAllVariables>(activityQueryAll, vars);
}

export function getActivityFundraiser(vars: ActivityQueryFundraiserVariables) {
  return request<ActivityQueryFundraiser, ActivityQueryFundraiserVariables>(activityQueryFundraiser, vars);
}

export function getJoinedFundraisers() {
  return request<JoinedFundraisersQuery>(joinedFundraisersQuery);
}

export function transferFunds(vars: TransferFundsMutationVariables) {
  return request<TransferFundsMutation, TransferFundsMutationVariables>(transferFundsMutation, vars);
}

export function getFundraisersList(vars: GetFundraisersListVariables) {
  return request<GetFundraisersList, GetFundraisersListVariables>(fundraisersListQuery, vars);
}

export function getPayouts(vars: GetPayoutsQueryVariables) {
  return request<GetPayoutsQuery, GetPayoutsQueryVariables>(getPayoutsQuery, vars);
}

export function getPayoutDetails(vars: GetPayoutDetailsQueryVariables) {
  return request<GetPayoutDetailsQuery, GetPayoutDetailsQueryVariables>(getPayoutDetailsQuery, vars);
}

export function getAmountsWithdrawnBreakdown() {
  return request<GetAmountWithdrawnBreakdownQuery>(getAmountWithdrawnBreakdownQuery);
}

export function getIntent(vars: GetIntentQueryVariables) {
  return request<GetIntentQuery, GetIntentQueryVariables>(getIntentQuery, vars);
}
