import React, { useMemo } from "react";
import { LinkType } from "@core-shop/types";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { useRouter } from "@shared/hooks";
import { queryParams } from "@shared/utils/routes";
import { Button, Container } from "@shared-tailwind/atoms";
import { icons } from "@shared-tailwind/organisms/ShopModals/brand/components/LinksList/icons";
import { GiveAmount } from "../../GiveAmount";

interface IProps {
  giveSentence: string;
  secondaryLabel: string;
  type: LinkType;
  buttonCTA?: string;
  isLoading?: boolean;
  onClick?: () => void;
}

export const ActionPreview: React.FC<IProps> = ({
  giveSentence,
  onClick,
  secondaryLabel,
  type,
  buttonCTA,
  isLoading,
}) => {
  const BottomButtonIcon = useMemo(() => {
    if (!type) return () => null;
    const Component: React.FC<{ className: string }> = icons[type];
    return () => <Component className="text-white" />;
  }, [type]);

  const router = useRouter();

  const isPromotionActive = router.getQuery(queryParams.promotionId);

  return (
    <div className="w-full sticky bottom-0 -mt-1">
      <Container className="bg-gray-50 flex items-center space-x-2 justify-between py-3 border-t shadow-md">
        <GiveAmount giveSentence={giveSentence} secondaryLabel={secondaryLabel} />
        <div className="relative">
          <Button className="h-10 w-[150px]" Icon={BottomButtonIcon} isLoading={isLoading} onClick={onClick}>
            {buttonCTA}
          </Button>
          {isPromotionActive && (
            <CheckCircleIcon className="text-amber-400 bg-white rounded-full w-5 h-5 absolute -right-2 -top-2" />
          )}
        </div>
      </Container>
    </div>
  );
};
