import React from "react";
import { useCloud } from "@core-shop/hooks";
import { encryptStatus } from "@core-shop/services";
import { IAuthenticatedHeaderViewProps } from "./IAuthenticatedHeaderViewProps";

interface IProps {
  isAuthorized: boolean;
  View: React.FC<IAuthenticatedHeaderViewProps>;
}

const defaultViewProps: IAuthenticatedHeaderViewProps = {
  logo: "https://flipgive.imgix.net/images/links/images/000/177/501/original/Gap_400_x_300.jpg",
  amountRaised: "",
  name: "Get started with Footeam",
  isAuthorized: false,
};

export const AuthenticatedHeader: React.FC<IProps> = ({ View, isAuthorized }) => {
  const { data: cloudData, isLoading } = useCloud();
  const { hasUser } = encryptStatus(cloudData?.ShopCloud?.state ?? "").fgStatus;

  if (!isAuthorized) return <View {...defaultViewProps} isLoading={isAuthorized && isLoading} />;

  return (
    <View
      amountRaised={cloudData?.ShopCloud?.activeCampaign?.amountRaised.formatted ?? ""}
      hasUser={!!hasUser}
      isAuthorized={isAuthorized}
      isLoading={isLoading}
      logo={cloudData?.ShopCloud?.activeCampaign?.photoImage ?? ""}
      name={cloudData?.ShopCloud?.activeCampaign?.name ?? ""}
    />
  );
};
