import gql from "graphql-tag";
import { favoriteBrandFragment } from "./fragments/favoritesFragment";

export const toggleFavoriteBrandMutation = gql`
  mutation ToggleFavoriteBrandMutation($brandId: ID!) {
    ViewerToggleFavoriteBrand(brandId: $brandId) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Brand {
        id
        ...FavoriteBrandFragment
      }
    }
  }
  ${favoriteBrandFragment}
`;
