import gql from "graphql-tag";
import { collectionFragment } from "./fragments/collectionFragment";

export const getCollectionByIdQuery = gql`
  query GetCollectionById($id: ID!) {
    Collection(id: $id) {
      id
      ...CollectionFragment
    }
  }
  ${collectionFragment}
`;
