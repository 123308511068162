import gql from "graphql-tag";

const giftcard = gql`
  mutation CreateIntentGiftcardMutation($input: GiftcardIntentCreateInput!) {
    GiftcardIntentCreate(input: $input) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on GiftcardIntent {
        id
        token
      }
    }
  }
`;

const reloadGiftcard = gql`
  mutation CreateIntentGiftcardReloadMutation($input: ReloadIntentCreateInput!) {
    ReloadIntentCreate(input: $input) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on GiftcardIntent {
        id
        token
      }
    }
  }
`;

const shop = gql`
  mutation CreateShopMutation($input: IntentCreateInput!) {
    IntentCreate(input: $input) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Intent {
        id
        token
        url
        interstitialUrl
      }
    }
  }
`;

export const createIntentMutation = { giftcard, reloadGiftcard, shop };
