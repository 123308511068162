import React, { useCallback, useState } from "react";
import { IOptInViewProps } from "@core-shop/components";
import { useRouter } from "@shared/hooks";
import { OptInMutation_OptIn_MutationError } from "@shared-tailwind/../@core-shop/types";
import { removeQueryParam } from "@shared-tailwind/../@shared/utils";
import { queryParams } from "@shared-tailwind/../@shared/utils/routes";
import { Button, Checkbox, Container } from "@shared-tailwind/atoms";
import { ShopModal } from "@shared-tailwind/molecules";
import { modalsZIndex } from "../zIndex";

export const OptInModalView: React.FC<IOptInViewProps> = ({ optInMutation }) => {
  const [hasAgreed, setHasAgreed] = useState(true);
  const [errorText, setErrorText] = useState("");
  const router = useRouter();
  const onContinue = useCallback(async () => {
    const { OptIn } = await optInMutation.mutateAsync({ agreedToTos: hasAgreed });
    if ((OptIn as OptInMutation_OptIn_MutationError)?.errors) {
      setErrorText((OptIn as OptInMutation_OptIn_MutationError)?.errors?.[0]?.inputErrors?.[0]);

      return;
    }
    void router.replace(removeQueryParam(queryParams.optIn));
    router.reload();
  }, [hasAgreed, optInMutation, router]);

  return (
    <ShopModal isOpen title="Shop & Earn with Footeam" zIndex={modalsZIndex.OPTIN} onClose={router.safeBack}>
      <Container className="flex flex-col space-y-5 my-5">
        <p className="text-lg">
          Get started and earn cashback for your team! We&apos;ll create an account to track cash back across
          all of your earnings.
        </p>
        <Checkbox
          checked={hasAgreed}
          id="tos"
          label="I would like a weekly digest with a report on team earnings and top cashback offers"
          onChange={setHasAgreed}
        />
        {errorText && <p className="text-sm text-red-500">{errorText}</p>}
        <Button isLoading={optInMutation.isLoading} onClick={onContinue}>
          Continue
        </Button>
      </Container>
    </ShopModal>
  );
};
