import React from "react";
import classNames from "classnames";

// == Types ================================================================

interface IProps {
  children?: React.ReactNode;
  className?: string;
  id?: string;
  isFullWidth?: boolean;
}

// == Constants ============================================================

// == Component ============================================================

export const Container: React.FC<IProps> = ({ children, className, isFullWidth, id }) => {
  return (
    <div
      className={classNames("mx-auto px-4 sm:px-6 lg:px-8 w-full", className, {
        "max-w-7xl": !isFullWidth,
      })}
      id={id}
    >
      {children}
    </div>
  );
};

// == Styles ===============================================================
