import { useInfiniteQuery } from "@tanstack/react-query";
import { queries } from "@core-shop/cache";
import { GiftCardListQueryVariables } from "@core-shop/types";

export function useGiftcards(variables: GiftCardListQueryVariables) {
  return useInfiniteQuery({
    ...queries.giftcards(variables),
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      return lastPage?.Viewer.giftcardsPaginated.pageInfo.hasNextPage
        ? lastPage?.Viewer.giftcardsPaginated.pageInfo.endCursor
        : undefined;
    },
  });
}
