import gql from "graphql-tag";
import { favoriteBrandFragment } from "./fragments/favoritesFragment";

export const getFavortiesData = gql`
  query FavoritesData {
    Viewer {
      id
      favoriteBrands(first: 50) {
        id
        ...FavoriteBrandFragment
      }
    }
  }
  ${favoriteBrandFragment}
`;
