import { useQuery } from "@tanstack/react-query";
import { queries } from "@core-shop/cache";

interface IStorefrontOptions {
  id: string;
  enabled?: boolean;
}

export function useStorefront({ id, enabled = true }: IStorefrontOptions) {
  return useQuery({ ...queries.storefrontById(id), enabled });
}
