import gql from "graphql-tag";

export const getShortcutsQuery = gql`
  query GetShortcuts {
    ShopCloud {
      id
      shortcuts {
        id
        links(first: 20) {
          id
          name
          # shortcutUrl
        }
      }
    }
  }
`;
