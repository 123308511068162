import gql from "graphql-tag";

export const getGiftcardDetailsQuery = gql`
  query GetGiftcardDetailsQuery($id: ID!) {
    Link(id: $id) {
      id
      type
      availableDenominations
      giftcardDenominations {
        edges {
          cursor
          node {
            id
            default
            deliveryMethod
            deliveryEstimate
            freeShipping
            minimumCards
            maximumCards
            value {
              centsAmount
              dollarsAmount
              formatted(round: 0)
            }
          }
        }
      }
      reloadableGiftcardInfo {
        reloadThresholdDenominations {
          centsAmount
          dollarsAmount
          formatted(round: 0)
        }
      }
    }
  }
`;
