import React, { Fragment, useState, useCallback, useEffect, useRef } from "react";
import { Transition } from "@headlessui/react";
import { ChevronDownIcon, XIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import uniqueId from "lodash/uniqueId";
import { Input } from "@shared-tailwind/atoms";
import { IconPosition } from "@shared-tailwind/types";

// == Types ================================================================

export interface ISelectOption<TData = void> {
  label: string;
  value: string;
  CustomItem?: React.FC;
  data?: TData;
  subLabel?: string;
}

export interface ISelectProps<TData> {
  inputValue: string;
  options: ISelectOption<TData>[];
  className?: string;
  CustomItem?: React.FC<ISelectOption<TData>>;
  errorText?: string;
  hasMinDropdown?: boolean;
  hasReadonlyInput?: boolean;
  inputOnChange?: (value: string) => void;
  inputProps?: React.ComponentProps<typeof Input>;
  isClearable?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  label?: string;
  onChange?: (value: string, option: ISelectOption<TData>) => void;
  shouldOpenToTop?: boolean;
  withArrow?: boolean;
}

// == Constants ============================================================

Select.defaultProps = {
  options: [],
  alwaysOpen: false,
};

// == Component ============================================================

export function Select<TOptionData>({
  inputValue,
  inputOnChange,
  options,
  onChange,
  label,
  className,
  errorText,
  withArrow,
  isLoading,
  inputProps,
  CustomItem,
  hasReadonlyInput,
  isClearable,
  hasMinDropdown,
  shouldOpenToTop,
  isDisabled,
}: ISelectProps<TOptionData>) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [currentItemIndex, setCurrentItemIndex] = useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const onSelect = useCallback(
    (option: ISelectOption<TOptionData>) => {
      const { value } = option;
      onChange?.(value, option);
      if (inputOnChange) inputOnChange(value.toString());
      setIsOpen(false);
      inputRef.current?.blur();
    },
    [inputOnChange, onChange]
  );

  useEffect(() => {
    setCurrentItemIndex(null);
  }, [inputValue]);

  return (
    <div className={classNames("relative w-full", className)}>
      <Input
        autoComplete="nope" // chrome overrides 'off', but doesn't override a random string
        errorText={errorText}
        Icon={withArrow ? ChevronDownIcon : undefined}
        iconPosition={IconPosition.right}
        isDisabled={isDisabled}
        isLoading={isLoading}
        label={label}
        ref={inputRef}
        value={inputValue?.toString()}
        onBlur={() => {
          // Delay is needed to be able to register onClick event before the list was hidden
          setTimeout(() => {
            setIsOpen(false);
          }, 500);
        }}
        onChangeValue={inputOnChange}
        onClickIcon={() => setIsOpen((isDown) => !isDown)}
        onFocus={() => setIsOpen(true)}
        onKeyDown={({ code }) => {
          switch (code) {
            case "ArrowDown": {
              if (currentItemIndex === null) setCurrentItemIndex(0);
              else setCurrentItemIndex((prev) => (prev === options.length - 1 ? 0 : Number(prev) + 1));
              return;
            }
            case "ArrowUp": {
              if (currentItemIndex === null) setCurrentItemIndex(options.length - 1);
              else setCurrentItemIndex((prev) => (prev === 0 ? options.length - 1 : Number(prev) - 1));
              break;
            }
            case "Enter": {
              if (!currentItemIndex) return;
              onSelect(options[currentItemIndex]);
              break;
            }
            default: {
              break;
            }
          }
        }}
        {...inputProps}
        className={classNames(inputProps?.className, hasReadonlyInput && "cursor-pointer")}
        readOnly={hasReadonlyInput}
        style={{ paddingRight: withArrow || isClearable ? 40 : undefined }}
      />
      {isClearable && (
        <XIcon
          aria-hidden="true"
          className={classNames(
            "h-5 w-5 text-gray-400 absolute right-3 top-2 cursor-pointer",
            isLoading && "hidden"
          )}
          onClick={() => inputOnChange?.("")}
        />
      )}
      <Transition
        as={Fragment}
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        show={isOpen && options.length > 0}
      >
        <div
          className={classNames(
            "shadow-sm border rounded-md mt-1 max-h-52 overflow-auto absolute w-full bg-white z-10",
            hasMinDropdown ? "max-h-28" : " max-h-52",
            shouldOpenToTop && "bottom-10"
          )}
        >
          {options.map((option, i) => {
            const { label: optionLabel, subLabel, CustomItem: CustomItemOption } = option;
            if (CustomItem) return <CustomItem key={uniqueId()} {...option} />;
            if (CustomItemOption) return <CustomItemOption key={uniqueId()} />;
            return (
              <div
                className={classNames(
                  "text-gray-900",
                  "cursor-default select-none relative py-2 pl-3 pr-9 flex",
                  "hover:bg-gray-50 cursor-pointer",
                  currentItemIndex === i && "bg-gray-50"
                )}
                key={uniqueId()}
                role="button"
                tabIndex={-1}
                onClick={() => onSelect(option)}
                onKeyDown={() => onSelect(option)}
              >
                <span className={classNames("font-normal", "truncate")}>{optionLabel}</span>
                {subLabel && <span className={classNames("text-gray-500", "ml-2 truncate")}>{subLabel}</span>}
              </div>
            );
          })}
        </div>
      </Transition>
    </div>
  );
}
