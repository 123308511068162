import React, { useMemo } from "react";
import { Loader } from "@shared-tailwind/atoms";
import { AuthContext } from "./authContext";
import { useAuthenticate } from "./useAuthenticate";

interface IProps {
  children?: React.ReactNode;
  isDisabled?: boolean;
}

export const AuthProvider: React.FC<IProps> = ({ children, isDisabled }) => {
  const { isAuthCheckInProgress, isAuthorized, state, refreshAuth } = useAuthenticate({ isDisabled });

  const shouldOptIn = useMemo(
    () => !!(state?.isValid && !state.fgStatus?.hasUser && state.tokenStatus?.hasUser),
    [state?.fgStatus?.hasUser, state?.isValid, state?.tokenStatus?.hasUser]
  );

  const contextValue = useMemo(() => {
    return { isAuthorized, isAuthCheckInProgress, shouldOptIn, refreshAuth };
  }, [isAuthorized, isAuthCheckInProgress, shouldOptIn, refreshAuth]);

  if (isDisabled && children) return <div>{children}</div>;

  if (isAuthCheckInProgress)
    return (
      <div className="w-full h-screen flex items-center justify-center">
        <Loader size={96} />
      </div>
    );

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
