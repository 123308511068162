import React, { ReactNode } from "react";
import { ChevronLeftIcon } from "@heroicons/react/outline";
import classNames from "classnames";
import { Button, ButtonVariants, Container } from "@shared-tailwind/atoms";

// == Types ================================================================

interface IProps {
  Buttons?: ReactNode;
  className?: string;
  hasBackText?: boolean;
  hasBottomSpace?: boolean;
  hasShadow?: boolean;
  iconClassName?: string;
  IconRight?: React.FC<{ className?: string; onClick?: () => void }>;
  onClick?: () => void;
  onClickIcon?: () => void;
  style?: React.CSSProperties;
  subtitle?: string;
  title?: string;
}

// == Component ============================================================

export function BackTopNav({
  onClick,
  className,
  style,
  IconRight,
  Buttons,
  hasShadow = true,
  title,
  hasBottomSpace = true,
  onClickIcon,
  iconClassName,
  hasBackText = true,
  subtitle,
}: IProps) {
  return (
    <div
      className={classNames("bg-white", hasShadow && "shadow-sm", hasBottomSpace && "mb-10", className)}
      style={style}
    >
      <Container className="p-2.5 flex justify-between items-center">
        <div>
          <Button
            resetSizeClasses
            className="pl-0"
            hasRing={false}
            Icon={() => <ChevronLeftIcon className="text-primary-500 h-8 w-6" />}
            variant={ButtonVariants.link}
            onClick={onClick}
          >
            {hasBackText && "Back"}
          </Button>
        </div>
        {title && (
          <div className="w-full text-center -ml-6">
            <div>{title}</div>
            <div className="text-gray-500 text-xs leading-0">{subtitle}</div>
          </div>
        )}
        {Buttons}
        {IconRight && (
          <IconRight
            className={classNames("w-6 h-6 text-primary-500", iconClassName)}
            onClick={onClickIcon}
          />
        )}
      </Container>
    </div>
  );
}
