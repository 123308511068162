import { useQuery } from "@tanstack/react-query";
import { queries } from "@core-shop/cache";
import { getPaginatedNodes } from "@core-shop/utils";

export function useJoinedFundraisers() {
  return useQuery({
    ...queries.joinedFundraisers(),
    select: (data) => getPaginatedNodes(data.ShopCloud?.activeCampaign?.teamMembers.joined.edges) || [],
  });
}
