import { reorderFavorites, toggleFavoriteBrand } from "@core-shop/models/favorites";
import { ReorderFavoritesNewMutationVariables } from "@core-shop/types";

export const favoritesMutations = {
  toggleFavorites: (id: string) => ({
    mutationFn: (forcedId = "") => toggleFavoriteBrand({ id: id || forcedId }),
    // this mutation manually updates cache
    // for brand and viewer favorites
  }),
  reorderFavorites: () => ({
    mutationFn: (brandIds: ReorderFavoritesNewMutationVariables["brandIds"] = []) =>
      reorderFavorites({ brandIds }),
    // this mutation manually updates cache
    // for viewer favorites
  }),
} as const;
