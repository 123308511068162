import { CSSProperties } from "react";
import { useImageLoader } from "@shared/utils";
import classNames from "classnames";
import NextImage, { ImageProps } from "next/image";

// == Types ================================================================

export interface IImageProps {
  src: ImageProps["src"];
  alt?: ImageProps["alt"];
  className?: string;
  imagePositionClassName?: string;
  imageProps?: Partial<ImageProps>;
  shouldForceCloud?: boolean;
  style?: CSSProperties;
}

// == Component ============================================================

export function Image({
  src,
  alt,
  className,
  imageProps,
  imagePositionClassName,
  style,
  shouldForceCloud,
}: ImageProps & IImageProps) {
  const loader = useImageLoader(src, { shouldForceCloud });

  return (
    <div className={classNames("relative shrink-0", className)} style={style}>
      <NextImage
        alt={alt}
        layout="fill"
        src={src}
        title={alt}
        {...imageProps}
        className={classNames(
          imageProps?.className,
          imagePositionClassName || "object-contain",
          "w-full h-full"
        )}
        loader={loader}
      />
    </div>
  );
}
