import gql from "graphql-tag";
import { collectionFragment } from "./collectionFragment";

export const storefrontFragment = gql`
  fragment StorefrontFragment on StoreFront {
    id
    name
    collections {
      id
      ...CollectionFragment
    }
  }
  ${collectionFragment}
`;
