import React, { useMemo } from "react";
import { useCloudAuthState, useCollection, useToggleFavoriteBrand } from "@core-shop/hooks";
import { getPaginatedNodes } from "@core-shop/utils";
import { ICollectionViewProps } from "./ICollectionViewProps";

export interface IProps {
  View: React.FC<ICollectionViewProps>;
  id?: string;
}

export const Collection: React.FC<IProps> = ({ View, id }) => {
  const { fgStatus, isLoading } = useCloudAuthState();
  const collectionQuery = useCollection({ id: id ?? "" });
  const collection = useMemo(
    () => ({
      ...collectionQuery.data?.Collection,
      brands: getPaginatedNodes(collectionQuery.data?.Collection?.brands.edges),
    }),
    [collectionQuery.data?.Collection]
  );

  const toggleFavoriteMutation = useToggleFavoriteBrand();

  return (
    <View
      collection={collection as ICollectionViewProps["collection"]}
      collectionQuery={collectionQuery}
      shouldShowLikeButton={!isLoading && fgStatus.hasUser}
      title={collection?.name ?? ""}
      toggleFavoriteMutation={toggleFavoriteMutation}
    />
  );
};
