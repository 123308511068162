import React from "react";
import { useTeam } from "@core-shop/hooks";
import { ITeamDashboardViewProps } from "./ITeamDashboardViewProps";

interface IProps {
  View: React.FC<ITeamDashboardViewProps>;
}

export const TeamDashboard: React.FC<IProps> = ({ View }) => {
  const teamQuery = useTeam();
  return <View teamQuery={teamQuery} />;
};
