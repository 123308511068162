import { useQuery } from "@tanstack/react-query";
import { queries } from "@core-shop/cache";

interface ICollectionOptions {
  id: string;
}

export function useCollection({ id }: ICollectionOptions) {
  return useQuery(queries.collecitonById(id));
}
