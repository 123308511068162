import gql from "graphql-tag";

export const intentFragment = gql`
  fragment IntentFragment on Intent {
    id
    createdAt
    reference
    isTransferrable
    id
    buyer_name
    intentType
    canClaimMissing
    supportedAt
    expectedEarnedOn
    token
    link {
      id
      type
      defaultBrand {
        id
        name
        images {
          invertedLogo
          logo
          logoBackgroundColor
        }
      }
    }
    fundraiser {
      id
      firstName
      lastName
      avatarImage
    }
    campaignAmount {
      formatted
    }
    missingClaim {
      id
      status
      denialReason
    }
  }
`;
