import React, { useMemo } from "react";
import { IFormattedLink } from "@core-shop/components/link/LinkModal/ILinkModalViewProps";
import { LinkType } from "@core-shop/types";
import { ActionPreview } from "./components/ActionPreview";
import { ShopAction } from "./components/ShopAction";

interface IProps {
  link: IFormattedLink;
  onOpenGiftcardBuy?: () => void;
}

const redirectTypes = [
  LinkType.BRAND,
  LinkType.EMPYR,
  LinkType.TRAVELSHOP,
  LinkType.BOUNTYSHOP,
  LinkType.VOUCHER,
];

const buyTypes = [LinkType.GIFTCARDOFFER, LinkType.AUTORELOADGIFTCARD, LinkType.GIFTCARDSHOP];

export const BottomAction: React.FC<IProps> = ({ link, onOpenGiftcardBuy }) => {
  const isActionRedirect = useMemo(() => redirectTypes.includes(link.type), [link.type]);
  const isActionBuy = useMemo(() => buyTypes.includes(link.type), [link.type]);

  if (isActionRedirect && link.merchant?.website) return <ShopAction link={link} />;

  if (isActionBuy)
    return (
      <ActionPreview
        buttonCTA={link.buttonCTA}
        giveSentence={link.giveSentence}
        secondaryLabel={link.promotionInfo?.promotionSecondaryLabel ?? ""}
        type={link.type}
        onClick={onOpenGiftcardBuy}
      />
    );

  return null;
};
