import axios from "axios";
import { DocumentNode, print } from "graphql";
import { IApiAdapter } from "./types";

export const axiosInstance = axios.create({
  baseURL: process.env.CLOUD_API,
});

export const apiService: IApiAdapter<DocumentNode> = {
  request: (query, variables, config) => {
    return axiosInstance.post("", { query: print(query), variables }, config).then(({ data }) => {
      // eslint-disable-next-line promise/no-return-wrap
      if (!config?.debug && data?.errors?.length) return Promise.reject(data);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return data.data;
    });
  },
};

export const { request } = apiService;
