import gql from "graphql-tag";

export const PageInfoFragment = gql`
  fragment PageInfoFragment on PageInfo {
    endCursor
    hasNextPage
    hasPreviousPage
    startCursor
  }
`;

export const getSearchQuery = gql`
  query SearchQuery($query: String!, $page: Int, $orderBy: String, $orderDirection: String, $first: Int) {
    LabelPromotionSearch(query: $query, page: $page, orderBy: $orderBy, orderDirection: $orderDirection) {
      id
      url
      label
      description
      merchant {
        id
        name
      }
      endsAt
      link {
        id
        name
        giveSentence
        images {
          logo
          square
        }
      }
    }
    AdvancedBrandSearch(
      query: $query
      page: $page
      orderBy: $orderBy
      orderDirection: $orderDirection
      first: $first
    ) {
      edges {
        cursor
        node {
          id
          name
          website
          description
          slug
          description
          viewerHasFavorited
          maxGiveLink {
            id
            giveSentence
            promotionInfo {
              promotionLabel
              promotionSecondaryLabel
            }
          }
          images {
            logo
            invertedLogo
            logoBackgroundColor
          }
        }
      }
    }
  }
`;

export const labelPromotionSearchQuery = gql`
  query LabelPromotionSearchQueryType(
    $query: String!
    $page: Int
    $orderBy: String
    $orderDirection: String
  ) {
    LabelPromotionSearch(query: $query, page: $page, orderBy: $orderBy, orderDirection: $orderDirection) {
      id
      url
      label
      description
      merchant {
        id
        name
      }
      endsAt
      link {
        id
        name
        giveSentence
        images {
          logo
          square
        }
      }
    }
  }
`;

export const advancedBrandSearchQuery = gql`
  query AdvancedBrandSearchQueryType(
    $query: String!
    $page: Int
    $orderBy: String
    $orderDirection: String
    $first: Int
    $before: String
    $after: String
  ) {
    AdvancedBrandSearch(
      query: $query
      page: $page
      orderBy: $orderBy
      orderDirection: $orderDirection
      first: $first
      before: $before
      after: $after
    ) {
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          id
          name
          website
          description
          slug
          description
          viewerHasFavorited
          maxGiveLink {
            id
            giveSentence
            promotionInfo {
              promotionLabel
              promotionSecondaryLabel
            }
          }
          images {
            logo
            invertedLogo
            logoBackgroundColor
          }
        }
      }
    }
  }
  ${PageInfoFragment}
`;

export const getAutocompleteSearchQuery = gql`
  query AutocompleteSearchQuery($query: String!, $first: Int!) {
    AutocompleteSearch(query: $query, first: $first) {
      value
    }
  }
`;

export const getRecentBrandSearchQuery = gql`
  query RecentBrandQuery($currency: Currency!) {
    RecentBrand(currency: $currency) {
      id
      name
      images {
        logo
        invertedLogo
        logoBackgroundColor
      }
    }
  }
`;

export const getRecentSearchesSearchQuery = gql`
  query RecentSearchQuery($currency: Currency!) {
    RecentSearch(currency: $currency) {
      term
    }
  }
`;

export const getBrandSearchSuggestionsQuery = gql`
  query BrandSearchSuggestionsQuery($currency: Currency!) {
    BrandSearchSuggestion(currency: $currency) {
      id
      name
      images {
        logo
        invertedLogo
        logoBackgroundColor
      }
    }
  }
`;

export const getSearchTermSuggestionsQuery = gql`
  query SearchTermSuggestionsQuery($currency: Currency!) {
    SearchTermSuggestion(currency: $currency) {
      recommendation
    }
  }
`;
