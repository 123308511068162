import { TeamOnboardingStep } from "@core-shop/types";
import { IOnboardingStepsByTime, OnboardingStepName, StepsTime } from "./types";

const { CREATE_TEAM, SET_GOAL, ROSTER, INVITE, JOIN, ROLE_MEMBER, SELECT_PLAYER } = OnboardingStepName;

export const CAPTAIN_STEPS: IOnboardingStepsByTime = {
  [StepsTime.BEFORE_ACTION]: [CREATE_TEAM, SET_GOAL],
  [StepsTime.AFTER_ACTION]: [ROSTER, INVITE],
};

export const MEMBER_STEPS: IOnboardingStepsByTime = {
  [StepsTime.BEFORE_ACTION]: [JOIN, SELECT_PLAYER],
  [StepsTime.AFTER_ACTION]: [ROLE_MEMBER],
};

const {
  CAPTAIN_DOWNLOAD_RESOURCES,
  CAPTAIN_INVITE_TEAM,
  CAPTAIN_SET_GOAL,
  CAPTAIN_SET_OBJECTIVE,
  CAPTAIN_SET_ROSTER,
  // TODO: consult with BE to disable these steps
  // CAPTAIN_SET_TEAM_PICKS,
  // TEAM_MEMBER_ESTIMATE_EARNINGS,
  // TEAM_MEMBER_SET_FAVORITES,
  TEAM_MEMBER_SET_PLAYER,
  TEAM_MEMBER_SET_ROLE,
} = TeamOnboardingStep;

export const newToOldStepsMapper: Record<OnboardingStepName, TeamOnboardingStep[]> = {
  [CREATE_TEAM]: [],
  [SET_GOAL]: [CAPTAIN_SET_GOAL, CAPTAIN_SET_OBJECTIVE],
  [ROSTER]: [CAPTAIN_SET_ROSTER],
  [INVITE]: [CAPTAIN_DOWNLOAD_RESOURCES, CAPTAIN_INVITE_TEAM],
  [JOIN]: [],
  [SELECT_PLAYER]: [TEAM_MEMBER_SET_PLAYER],
  [ROLE_MEMBER]: [TEAM_MEMBER_SET_ROLE],
};
