import React, { useMemo } from "react";
import { useGiftcards } from "@core-shop/hooks";
import { getPaginatedNodes } from "@core-shop/utils";
import { formatGiftcard } from "../utils/formatGiftcard";
import { IWalletIndexViewProps } from "./IWalletIndexViewProps";

export interface IProps {
  id: string;
  View: React.FC<IWalletIndexViewProps>;
}

export const WalletIndex: React.FC<IProps> = ({ View, id }) => {
  const giftcardsQuery = useGiftcards({ ids: [id] });
  const giftcard = useMemo(
    () => getPaginatedNodes(giftcardsQuery.data?.pages[0].Viewer.giftcardsPaginated.edges)[0],
    [giftcardsQuery.data?.pages]
  );

  const formattedGiftcard = formatGiftcard(giftcard);

  return <View formattedGiftcard={formattedGiftcard} giftcard={giftcard} giftcardsQuery={giftcardsQuery} />;
};
