import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { queries } from "@core-shop/cache";
import { Currency } from "@shared/types";
import {
  AdvancedBrandSearchQueryType,
  AdvancedBrandSearchQueryTypeVariables,
  AutocompleteSearchQuery,
  BrandSearchSuggestionsQuery,
  LabelPromotionSearchQueryType,
  LabelPromotionSearchQueryTypeVariables,
  RecentBrandQuery,
  RecentSearchQuery,
  SearchQuery,
  SearchQueryVariables,
} from "../../types/apolloTypes";

const MINIMUM_SEARCH_LENGTH = 3;

export function useSearch(params: SearchQueryVariables) {
  const isEnabled = (params.query?.length ?? 0) >= MINIMUM_SEARCH_LENGTH;
  return useQuery<SearchQuery>({
    ...queries.search(params),
    enabled: isEnabled,
  });
}

export function useSearchAutocomplete({ query, first }: SearchQueryVariables) {
  return useQuery<AutocompleteSearchQuery>(queries.searchAutocomplete({ query, first: first || 5 }));
}

export const useBrandSearch = (params: AdvancedBrandSearchQueryTypeVariables) => {
  const isEnabled = (params.query?.length ?? 0) >= MINIMUM_SEARCH_LENGTH;

  return useInfiniteQuery<AdvancedBrandSearchQueryType>({
    ...queries.brandSearch(params),
    enabled: isEnabled,
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      return lastPage?.AdvancedBrandSearch?.pageInfo?.hasNextPage
        ? lastPage?.AdvancedBrandSearch.pageInfo.endCursor
        : undefined;
    },
    getPreviousPageParam: (firstPage) => {
      return firstPage?.AdvancedBrandSearch?.pageInfo?.hasPreviousPage
        ? firstPage?.AdvancedBrandSearch?.pageInfo?.startCursor
        : undefined;
    },
  });
};

export const useDealSearch = (params: LabelPromotionSearchQueryTypeVariables) => {
  const isEnabled = (params?.query?.length ?? 0) >= MINIMUM_SEARCH_LENGTH;
  return useInfiniteQuery<LabelPromotionSearchQueryType>({
    ...queries.labelSearch(params),
    enabled: isEnabled,
  });
};

export const useRecentBrandSearch = (currency: Currency) => {
  return useQuery<RecentBrandQuery>(queries.recentBrandSearch(currency));
};

export const useRecentSearches = (currency: Currency) => {
  return useQuery<RecentSearchQuery>(queries.recentSearches(currency));
};

export const useBrandSearchSuggestions = (currency: Currency) => {
  return useQuery<BrandSearchSuggestionsQuery>(queries.brandSearchSuggestions(currency));
};

export const useSearchTermSuggestions = (currency: Currency) => {
  return useQuery(queries.searchTermSuggestions(currency));
};
