import { useMutation } from "@tanstack/react-query";
import { mutations, queries } from "@core-shop/cache";
import { queryClient } from "@core-shop/providers/QueryProvider";
import { OptInMutation_OptIn_ShopCloud } from "@core-shop/types";

export function useOptIn() {
  const { mutationFn } = mutations.optIn();
  return useMutation({
    mutationFn,
    onSuccess: (response) => {
      if (!response) return;
      const { OptIn } = response;
      if (OptIn.__typename === "MutationError") return;
      updateViewer(OptIn);
    },
  });
}

function updateViewer(cloud: OptInMutation_OptIn_ShopCloud) {
  queryClient.setQueryData(queries.cloud().queryKey, { ShopCloud: cloud });
}
