import React, { useMemo, useState } from "react";
import { useToggleFavoriteBrand } from "@core-shop/hooks";
import {
  useBrandSearch,
  useDealSearch,
  useSearch,
  useSearchAutocomplete,
} from "@core-shop/hooks/storefront/useSearch";
import { SearchQueryVariables, SearchQuery_LabelPromotionSearch } from "@core-shop/types";
import { getPaginatedNodes } from "@core-shop/utils";
import { ISearchModalViewProps } from "./ISearchModalViewProps";

export interface IProps {
  View: React.FC<ISearchModalViewProps>;
}

const defaultSearchParams: SearchQueryVariables = {
  query: "",
  page: 1,
  orderBy: "",
  orderDirection: "",
  first: 9,
};

export const SearchModal: React.FC<IProps> = ({ View }: IProps) => {
  const [autocompleteQuery, setAutoCompleteQuery] = useState("");
  const [searchParams, setSearchParams] = useState(defaultSearchParams);

  const searchResultsQuery = useSearch(searchParams);
  const autoCompleteResultQuery = useSearchAutocomplete({ query: autocompleteQuery });
  const brandSearchResultsQuery = useBrandSearch(searchParams);
  const dealsSearchResultsQuery = useDealSearch(searchParams);
  const toggleFavoriteMutation = useToggleFavoriteBrand();

  const searchResults = useMemo(() => {
    const brands = getPaginatedNodes(searchResultsQuery.data?.AdvancedBrandSearch?.edges);
    // casting to get rid of readonly
    const deals = (searchResultsQuery.data?.LabelPromotionSearch as SearchQuery_LabelPromotionSearch[]) ?? [];
    return {
      deals,
      brands,
    };
  }, [searchResultsQuery.data?.AdvancedBrandSearch?.edges, searchResultsQuery.data?.LabelPromotionSearch]);

  const brandsSearchResults = useMemo(() => {
    const brands = brandSearchResultsQuery.data?.pages
      .map((page) => getPaginatedNodes(page.AdvancedBrandSearch.edges))
      .flat();

    return brands;
  }, [brandSearchResultsQuery.data?.pages]);

  const dealsSearchResults = useMemo(() => {
    const deals = dealsSearchResultsQuery.data?.pages.map((page) => page.LabelPromotionSearch).flat();

    return deals;
  }, [dealsSearchResultsQuery.data?.pages]);

  return (
    <View
      autocompleteQuery={autocompleteQuery}
      autoCompleteResultsQuery={autoCompleteResultQuery}
      brandSearchResultsQuery={brandSearchResultsQuery}
      brandsSearchResults={brandsSearchResults}
      dealsSearchResults={dealsSearchResults}
      dealsSearchResultsQuery={dealsSearchResultsQuery}
      searchParams={searchParams}
      searchResults={searchResults}
      searchResultsQuery={searchResultsQuery}
      setAutoCompleteQuery={setAutoCompleteQuery}
      setSearchParams={setSearchParams}
      toggleFavoriteMutation={toggleFavoriteMutation}
    />
  );
};
