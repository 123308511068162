import gql from "graphql-tag";
import { cloudFragment } from "./fragments/cloudFragment";

export const getCloudData = gql`
  query CloudData {
    ShopCloud {
      id
      ...CloudFragment
    }
  }
  ${cloudFragment}
`;
