import { getViewerFavorites } from "@core-shop/models";
import { day, minute } from "../constants";
import { EntityKey } from "../types";

export const favoritesQueries = {
  viewerFavorites: () => ({
    queryKey: [EntityKey.VIEWER, "favorites"],
    queryFn: () => getViewerFavorites(),
    staleTime: day,
    cacheTime: day + 5 * minute,
  }),
} as const;
