import React from "react";
import { useOptIn } from "@core-shop/hooks";
import { IOptInViewProps } from "./IOptInViewProps";

interface IProps {
  View: React.FC<IOptInViewProps>;
}

export const OptIn: React.FC<IProps> = ({ View }) => {
  const optInMutation = useOptIn();

  return <View optInMutation={optInMutation} />;
};
