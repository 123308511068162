import React, { Fragment, ReactNode } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { ZINDEX } from "@shared/utils";
import classNames from "classnames";
import { Loader } from "@shared-tailwind/atoms";

// == Types ================================================================

export enum ModalSizes {
  full = "full",
  lg = "lg",
  md = "md",
  sm = "sm",
  xl = "xl",
}

export interface IModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: React.ReactNode | React.ReactNode[];
  contentWrapperClassNames?: string;
  CustomHeader?: ReactNode;
  description?: React.ReactNode;
  hasX?: boolean;
  id?: string;
  isFullScreen?: boolean;
  isInnerContainerDisabled?: boolean;
  isLoading?: boolean;
  isMobileFullScreen?: boolean;
  overlayClassName?: string;
  shouldShowOverflow?: boolean;
  size?: ModalSizes | "md" | "sm" | "lg" | "xl";
  // todo: strings are added for compatibility, delete later
  title?: string | ReactNode;
  zIndex?: number;
}

// == Constants ============================================================

Modal.defaultProps = {
  onClose: () => null,
};

// == Component ============================================================

export function Modal({
  isOpen,
  onClose,
  children,
  isLoading,
  shouldShowOverflow,
  size = ModalSizes.sm,
  description,
  title,
  contentWrapperClassNames,
  CustomHeader,
  isMobileFullScreen,
  isInnerContainerDisabled,
  id,
  hasX,
  zIndex = ZINDEX.modal,
  isFullScreen,
  overlayClassName,
}: IModalProps) {
  return (
    <Transition.Root as={Fragment} show={isOpen}>
      <Dialog as="div" className="fixed inset-0 overflow-y-auto" style={{ zIndex }} onClose={onClose}>
        <div
          className={classNames(
            "flex items-end justify-center min-h-screen text-center sm:block sm:p-0",
            isInnerContainerDisabled && isMobileFullScreen && "sm:pt-4 sm:px-4 sm:pb-20",
            isInnerContainerDisabled && !isMobileFullScreen && !isFullScreen && "pt-4 px-4 pb-20",
            isMobileFullScreen || isFullScreen ? "w-screen" : "pb-5"
          )}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              className={classNames(
                "fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity",
                overlayClassName
              )}
            />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}
          <span aria-hidden="true" className="hidden sm:inline-block sm:align-middle sm:h-screen">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                contentWrapperClassNames,
                isFullScreen ? "w-full h-screen rounded-none" : "rounded-lg",
                isMobileFullScreen ? "sm:rounded-lg w-full h-screen sm:h-auto" : "sm:w-unset",
                `inline-block align-bottom bg-white text-left shadow-xl`,
                `transform transition-all sm:align-middle sm:w-full`,
                {
                  relative: !contentWrapperClassNames?.includes("fixed"),
                  "overflow-hidden": !shouldShowOverflow,
                  "sm:max-w-3xl": size === "xl",
                  "sm:max-w-lg": size === "lg",
                  "sm:max-w-md": size === "md",
                  "sm:max-w-sm": size === "sm",
                }
              )}
              id={id}
              style={{ minWidth: 330 }}
            >
              {CustomHeader}
              <div className={classNames(!isInnerContainerDisabled && "px-4 pt-5 pb-4 sm:p-6 sm:mb-8 ")}>
                {(title || description) && (
                  <div className="mb-6 w-full">
                    {title && (
                      <h2 className="text-gray-900 text-xl w-full text-center mb-2 font-bold">{title}</h2>
                    )}
                    {description && (
                      <h2 className="text-gray-500 text-sm w-full text-center">{description}</h2>
                    )}
                  </div>
                )}
                {hasX && (
                  <XIcon
                    className="text-gray-500 w-5 h-5 absolute right-2 top-2 cursor-pointer"
                    onClick={onClose}
                  />
                )}
                <div className={classNames(isLoading && "opacity-0")}>{children}</div>
                {isLoading && (
                  <div className="absolute bg-white w-full h-full left-0 top-0 flex justify-center items-center opacity-70">
                    <Loader size={50} />
                  </div>
                )}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

// == Styles ===============================================================
