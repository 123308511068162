import { useMemo } from "react";
import { useCloud } from "@core-shop/hooks";
import {
  useBrandSearchSuggestions,
  useRecentBrandSearch,
  useRecentSearches,
  useSearchTermSuggestions,
} from "@core-shop/hooks/storefront/useSearch";
import { Currency } from "@shared/types";
import colors from "tailwindcss/colors";
import { IBrandCircleProps } from "../types";
import { SuggestedSearch } from "./SuggestedSearch";

interface IProps {
  handleSearch: (searchString: string) => void;
}

const localSearchTerms = ["Nike", "Walmart", "Old Navy"];
const suggestedProducts = ["air jordans", "home renos", "costa rica vacation"];
// const waysToEarn = ["ordering uniforms", "refer another team", "ask for donations"];

export const SuggestedSearchView = ({ handleSearch }: IProps) => {
  const { data: cloudData } = useCloud();
  const { currency } = cloudData?.ShopCloud?.activeCampaign ?? {};
  const { data: recentBrandsData } = useRecentBrandSearch(currency ?? Currency.USD);
  const { data: recentSearchesData } = useRecentSearches(currency ?? Currency.USD);

  const recentBrands = useMemo(() => {
    return (
      recentBrandsData?.RecentBrand?.map<IBrandCircleProps>((search) => ({
        bgColor: search.images.logoBackgroundColor ?? colors.white,
        src: search.images.invertedLogo ?? search.images.logo,
        name: search.name,
        id: search.id,
      })).splice(0, 4) ?? []
    );
  }, [recentBrandsData?.RecentBrand]);

  const searchTerms = useMemo(() => {
    const result = recentSearchesData?.RecentSearch.map((term) => term.term);
    return result?.filter((item, index) => result.indexOf(item) === index).splice(0, 3) ?? [];
  }, [recentSearchesData?.RecentSearch]);

  const { data: brandSearchSuggestionsData } = useBrandSearchSuggestions(currency ?? Currency.USD);
  const { data: searchTermSuggestionsData } = useSearchTermSuggestions(currency ?? Currency.USD);

  const brandSearchSuggestions = useMemo(() => {
    return (
      brandSearchSuggestionsData?.BrandSearchSuggestion?.map<IBrandCircleProps>((search) => ({
        bgColor: search.images.logoBackgroundColor ?? colors.white,
        src: search.images.invertedLogo ?? search.images.logo,
        name: search.name,
        id: search.id,
      })).splice(0, 4) ?? []
    );
  }, [brandSearchSuggestionsData?.BrandSearchSuggestion]);

  const searchTermsSuggestions = useMemo(() => {
    const result = searchTermSuggestionsData?.SearchTermSuggestion.map((term) => term.recommendation);
    return result?.filter((item, index) => result.indexOf(item) === index).splice(0, 3) ?? [];
  }, [searchTermSuggestionsData?.SearchTermSuggestion]);

  const hasRecent = !!recentBrandsData?.RecentBrand?.length || !!recentSearchesData?.RecentSearch?.length;
  const hasSuggestions =
    !!brandSearchSuggestionsData?.BrandSearchSuggestion?.length ||
    !!searchTermSuggestionsData?.SearchTermSuggestion?.length;

  return (
    <div>
      <SuggestedSearch
        logoSearchItems={hasRecent ? recentBrands : []}
        searchTerms={hasRecent ? searchTerms : localSearchTerms}
        title={hasRecent ? "Recent" : "Try searching for brands"}
        onClickItem={handleSearch}
      />
      <SuggestedSearch
        logoSearchItems={hasSuggestions ? brandSearchSuggestions : []}
        searchTerms={hasSuggestions ? searchTermsSuggestions : suggestedProducts}
        title={hasSuggestions ? "Recommended" : "Or products and services"}
        onClickItem={handleSearch}
      />
      {/* <SuggestedSearch
        searchTerms={waysToEarn}
        title="Or ways to earn more money"
        onClickItem={handleSearch}
      /> */}
    </div>
  );
};
