import React, { useEffect, useState } from "react";
import { BrandModal } from "@core-shop/components";
import { useRouter } from "@shared/hooks";
import { queryParams } from "@shared/utils/routes";
import { BrandModalView } from "./BrandModalView";

interface IProps {
  onClose: () => void;
}

export const Brand: React.FC<IProps> = ({ onClose }) => {
  const router = useRouter();
  const [id, setId] = useState<string>();
  const [locationId, setLocationId] = useState<string>();

  useEffect(() => {
    if (!router.isReady) return;
    const brandId = router.getQuery(queryParams.brandId);
    const locationIdParam = router.getQuery(queryParams.locationId);
    setId(brandId);
    setLocationId(locationIdParam);
  }, [router]);

  return <BrandModal id={id} locationId={locationId} View={BrandModalView} onClose={onClose} />;
};
