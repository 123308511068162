import { useInfiniteQuery } from "@tanstack/react-query";
import { queries } from "@core-shop/cache";
import { GetFundraisersListVariables } from "@core-shop/types";

export function useFundraisers(variables?: GetFundraisersListVariables) {
  return useInfiniteQuery({
    ...queries.fundraisers(variables),
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      return lastPage?.ShopCloud?.activeCampaign?.teamMembers.all.pageInfo.endCursor || undefined;
    },
  });
}
