import React from "react";
import { IFormattedLink } from "@core-shop/components/link/LinkModal/ILinkModalViewProps";
import { useRouter } from "@shared/hooks";
import { routes } from "@shared/utils";
import { ActionPreview } from "./ActionPreview";

interface IProps {
  link: IFormattedLink;
}

export const ShopAction: React.FC<IProps> = ({ link }) => {
  const router = useRouter();

  return (
    <ActionPreview
      buttonCTA={link.buttonCTA}
      giveSentence={link.giveSentence}
      secondaryLabel={link.promotionInfo?.promotionSecondaryLabel ?? ""}
      type={link.type}
      onClick={() =>
        router.push(
          routes.shopcloud.createIntent({
            linkId: link.id,
          })
        )
      }
    />
  );
};
