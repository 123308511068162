import React from "react";
import { useNews, useOnLikeNews } from "@core-shop/hooks";
import { NewsQuery_Activities_edges_node_activityObject_Broadcast } from "@core-shop/types";
import { getPaginatedNodes } from "@core-shop/utils";
import { INewsViewProps } from "./INewsViewProps";

interface IProps {
  View: React.FC<INewsViewProps>;
}

type TBroadcast = NewsQuery_Activities_edges_node_activityObject_Broadcast;

export const News: React.FC<IProps> = ({ View }) => {
  const newsQuery = useNews();
  const onLikeNewsMutation = useOnLikeNews();
  const news = getPaginatedNodes(newsQuery.data?.Activities.edges).filter(
    // because we're querying only broadcasts, if activityObject exists -> it's a broadcast
    ({ activityObject }) => !!(activityObject as TBroadcast)?.id
  );

  return <View news={news} newsQuery={newsQuery} onLikeNewsMutation={onLikeNewsMutation} />;
};
