import React, { useMemo } from "react";
import { useShortcuts } from "@core-shop/hooks";
import { IShortcutsViewProps } from "./IShortcutsViewProps";

interface IProps {
  View: React.FC<IShortcutsViewProps>;
}

export const Shortcuts: React.FC<IProps> = ({ View }) => {
  const shortcutsQuery = useShortcuts();
  const shortcuts = useMemo(
    () => shortcutsQuery.data?.ShopCloud?.shortcuts?.links ?? [],
    [shortcutsQuery.data?.ShopCloud?.shortcuts]
  );

  return <View shortcuts={shortcuts} shortcutsQuery={shortcutsQuery} />;
};
