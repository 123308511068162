import gql from "graphql-tag";

export const fundraisersListQuery = gql`
  query GetFundraisersList($first: Int, $after: String) {
    ShopCloud {
      id
      activeCampaign {
        id
        teamMembers {
          all(first: $first, after: $after) {
            pageInfo {
              hasNextPage
              endCursor
            }
            edges {
              node {
                id
                userId
                avatarImage
                firstName
                lastName
                amountRaised {
                  formatted
                }
              }
              cursor
            }
          }
        }
      }
    }
  }
`;
