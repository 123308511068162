import { getAmountsBreakdown } from "@core-shop/models";
import {
  getPayouts,
  getActivityAll,
  getActivityFundraiser,
  getFundraisersList,
  getJoinedFundraisers,
  getIntent,
  getAmountsWithdrawnBreakdown,
  getPayoutDetails,
} from "@core-shop/models/funds";
import {
  ActivityQueryAllVariables,
  ActivityQueryFundraiserVariables,
  GetFundraisersListVariables,
  GetIntentQueryVariables,
  GetPayoutDetailsQueryVariables,
  GetPayoutsQueryVariables,
} from "@core-shop/types";
import { minute } from "../constants";
import { EntityKey } from "../types";

export const fundsQueries = {
  amountsBreakdown: () => ({
    queryKey: [EntityKey.VIEWER, "funds"],
    queryFn: () => getAmountsBreakdown(),
    staleTime: 5 * minute,
    cacheTime: 5.5 * minute,
  }),
  amountsWithdrawnBreakdown: () => ({
    queryKey: [EntityKey.VIEWER, "withdraws"],
    queryFn: () => getAmountsWithdrawnBreakdown(),
    staleTime: 5 * minute,
    cacheTime: 5.5 * minute,
  }),
  activityAll: (vars?: ActivityQueryAllVariables) => ({
    queryKey: [EntityKey.ACTIVITY, EntityKey.VIEWER, vars],
    queryFn: ({ pageParam }: { pageParam?: string }) => {
      return getActivityAll({ ...vars, after: pageParam });
    },
    staleTime: 5 * minute,
    cacheTime: 5.5 * minute,
  }),
  activityFundraiser: (vars?: ActivityQueryFundraiserVariables) => ({
    queryKey: [EntityKey.ACTIVITY, EntityKey.CAMPAIGN, vars],
    queryFn: ({ pageParam }: { pageParam?: string }) => {
      return getActivityFundraiser({ ...vars, after: pageParam });
    },
    staleTime: 5 * minute,
    cacheTime: 5.5 * minute,
  }),
  joinedFundraisers: () => ({
    queryKey: [EntityKey.ACTIVITY, EntityKey.CAMPAIGN, "joinedFundraisers"],
    queryFn: () => getJoinedFundraisers(),
    staleTime: 5 * minute,
    cacheTime: 5.5 * minute,
  }),
  fundraisers: (vars?: GetFundraisersListVariables) => ({
    queryKey: [EntityKey.CAMPAIGN, "allFundraisers"],
    queryFn: ({ pageParam }: { pageParam?: string }) => getFundraisersList({ ...vars, after: pageParam }),
    staleTime: 5 * minute,
    cacheTime: 5.5 * minute,
  }),

  intent: (vars: GetIntentQueryVariables) => ({
    queryKey: [EntityKey.INTENT, vars],
    queryFn: () => getIntent(vars),
    staleTime: 5 * minute,
    cacheTime: 5.5 * minute,
  }),
  payouts: (vars: GetPayoutsQueryVariables) => ({
    queryKey: [EntityKey.CAMPAIGN, "payouts", { vars }],
    queryFn: ({ pageParam }: { pageParam?: string }) => {
      return getPayouts({ after: pageParam, ...vars });
    },
    staleTime: 5 * minute,
    cacheTime: 5.5 * minute,
  }),
  payoutDetails: (vars: GetPayoutDetailsQueryVariables) => ({
    queryKey: [EntityKey.CAMPAIGN, "payout", { vars }],
    queryFn: ({ pageParam }: { pageParam?: string }) => {
      return getPayoutDetails({ after: pageParam, ...vars });
    },
    staleTime: 5 * minute,
    cacheTime: 5.5 * minute,
  }),
} as const;
