import { getGiftcardsQuery } from "@core-shop/models";
import { GiftCardListQueryVariables } from "@core-shop/types";
import { day, minute } from "../constants";
import { EntityKey } from "../types";

export const walletQueries = {
  giftcards: (variables: GiftCardListQueryVariables) => ({
    queryKey: [EntityKey.WALLET, "all", { filters: variables }],
    queryFn: ({ pageParam }: { pageParam?: string }) => {
      return getGiftcardsQuery({ after: pageParam, ...variables });
    },
    staleTime: day,
    cacheTime: day + 5 * minute,
  }),
} as const;
