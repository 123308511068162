import { XCircleIcon } from "@heroicons/react/outline";

export const EmptyResult = ({ searchTerm }: { searchTerm: string }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <XCircleIcon className="w-16 h-16 text-gray-400" />
      <h3 className="my-4 text-2xl font-bold text-center">
        No results found for <span className="text-primary-500">{searchTerm}</span>
      </h3>
      <p className="mt-2 text-sm text-center text-gray-500">Try searching for something else</p>
    </div>
  );
};
