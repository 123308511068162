import { useCallback, memo } from "react";
import Script from "next/script";
import { INTERCOM_APP_ID, IS_CLIENT } from "@shared/config/environment";

// == Types ================================================================

export interface IIntercomUser {
  email: string;
  id: string;
  name: string | null;
  activeCampaign?: {
    id?: string;
  } | null;
}

// == Constants ============================================================

// == Functions ============================================================

export function initIntercom(viewer?: IIntercomUser | null) {
  if (IS_CLIENT || window.intercomSettings?.user_id) return;

  window.intercomSettings = {
    app_id: INTERCOM_APP_ID,
    user_id: viewer?.id,
    email: viewer?.email,
    name: viewer?.name,
    company: {
      company_id: viewer?.activeCampaign?.id,
    },
  };
}

const IntercomScriptTag = memo(() => {
  return (
    <Script id="intercom-chat" strategy="lazyOnload">
      {`
        window.intercomSettings = {
          app_id: "${INTERCOM_APP_ID}",
        };
        (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + '${INTERCOM_APP_ID}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
      `}
    </Script>
  );
});

// == Hook =================================================================

export function useIntercom() {
  const toggleIntercom = useCallback(() => {
    window.Intercom?.("show");
  }, []);

  return {
    toggleIntercom,
    IntercomScriptTag,
  };
}
