import React from "react";
import { useCloud } from "@core-shop/hooks";
import { useJoinedFundraisers } from "@core-shop/hooks/funds/useJoinedFundraisers";
import { useTransferFunds } from "@core-shop/hooks/funds/useTransferFunds";
import { ITransferFundsViewProps } from "./ITransferFundsViewProps";

interface IProps {
  intentId: string;
  View: React.FC<ITransferFundsViewProps>;
  onTransferSuccess?: () => void;
}

export const TransferFunds: React.FC<IProps> = ({ View, intentId, onTransferSuccess }) => {
  const { data: joinedFundraisers } = useJoinedFundraisers();
  const { data: cloudData } = useCloud();
  const transferFundsMutation = useTransferFunds();

  return (
    <View
      fundraisers={joinedFundraisers?.filter(({ id }) => cloudData?.ShopCloud?.activeFundraiser?.id !== id)}
      intentId={intentId}
      transferFundsMutation={transferFundsMutation}
      onTransferSuccess={onTransferSuccess}
    />
  );
};
