import gql from "graphql-tag";

export const amountsBreakdownQuery = gql`
  query AmountsBreakdownQuery {
    ShopCloud {
      id
      activeCampaign {
        id
        goal {
          dollarsAmount
        }
        amountRaised {
          formatted
        }
        amountRaisedBreakdown {
          shopping {
            formatted
            dollarsAmount
          }
          bonuses {
            formatted
            dollarsAmount
          }
          donations {
            formatted
            dollarsAmount
          }
          team {
            formatted
            dollarsAmount
          }
          transfers {
            formatted
            dollarsAmount
          }
        }
      }
    }
  }
`;
