import { QueryProvider } from "@core-shop/providers";
import { NextComponentType, NextPageContext } from "next";
import { AppProps } from "next/app";
import NextHead from "next/head";
import PageTransitionProgressBar from "nextjs-progressbar";
import colors from "tailwindcss/colors";
import { AnalyticsProvider } from "@providers/AnalyticsProvider/AnalyticsProvider";
import { AuthProvider } from "@providers/AuthProvider/AuthProvider";
import { ToastContainer } from "@shared-tailwind/atoms";
import { ShopModalsContainer } from "@shared-tailwind/organisms";
import { CloudStylesProvider } from "@shared-tailwind/providers";
import "tailwindcss/tailwind.css";

// == Types ================================================================

type TNextComponent = NextComponentType<NextPageContext, $IntentionalAny, Record<string, $IntentionalAny>>;

interface IMarketingAppProps extends AppProps {
  Component: TNextComponent & { pageConfig?: IPageConfig };
}

// == Component ============================================================

export default function App({ Component, pageProps }: IMarketingAppProps) {
  return (
    <QueryProvider>
      <AuthProvider isDisabled={!!Component?.pageConfig?.shouldSkipAuth}>
        <AnalyticsProvider>
          <CloudStylesProvider>
            <ShopModalsContainer>
              <NextHead>
                <title>Cloud</title>
              </NextHead>
              <div className="mx-auto max-w-2xl bg-white md:shadow-lg min-h-screen">
                <PageTransitionProgressBar
                  color={colors.gray[300]}
                  height={2}
                  options={{ showSpinner: false }}
                />
                <Component {...pageProps} />
              </div>
              <ToastContainer />
            </ShopModalsContainer>
          </CloudStylesProvider>
        </AnalyticsProvider>
      </AuthProvider>
    </QueryProvider>
  );
}
