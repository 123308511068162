import React, { useContext } from "react";

// default grey-ish colors
export const defaultCloudStylesContextValue = {
  primary: "#475569",
  secondary: "#f1f5f9",
  deals: "#F9B634",
};

export const CloudStylesContext = React.createContext(defaultCloudStylesContextValue);

export const useCloudStyles = () => {
  const colors = useContext(CloudStylesContext);
  return { colors };
};
