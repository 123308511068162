import React from "react";
import { useAmountsBreakdown } from "@core-shop/hooks";
import { IAmountsBreakdownViewProps } from "./IAmountsBreakdownViewProps";

interface IProps {
  View: React.FC<IAmountsBreakdownViewProps>;
}

export const AmountsBreakdown: React.FC<IProps> = ({ View }) => {
  const amountsQuery = useAmountsBreakdown();

  return (
    <View
      amounts={amountsQuery.data?.ShopCloud?.activeCampaign?.amountRaisedBreakdown}
      amountsQuery={amountsQuery}
      goal={amountsQuery.data?.ShopCloud?.activeCampaign?.goal?.dollarsAmount}
    />
  );
};
