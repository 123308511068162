import React, { useMemo } from "react";
import { useGiftcards } from "@core-shop/hooks";
import { GiftCardListQueryVariables, GiftcardSortEnum } from "@core-shop/types";
import { getPaginatedNodes } from "@core-shop/utils";
import { formatGiftcard } from "../utils/formatGiftcard";
import { IWalletListViewProps } from "./IWalletListViewProps";
import { groupByBalance, groupByDate, groupByName } from "./groupBy";

export interface IProps {
  filters: Omit<GiftCardListQueryVariables, "after" | "first">;
  View: React.FC<IWalletListViewProps>;
}

export const WalletList: React.FC<IProps> = ({ View, filters }) => {
  const giftcardsQuery = useGiftcards(filters);
  const giftcards = useMemo(() => {
    if (!giftcardsQuery.data) return [];
    const paginatedCards = giftcardsQuery.data.pages.flatMap(({ Viewer }) => Viewer.giftcardsPaginated.edges);
    const cards = getPaginatedNodes(paginatedCards);
    const formattedCards = cards.map((node) => {
      if (!node) return {};
      return formatGiftcard(node);
    });

    const sortType: GiftcardSortEnum = filters.sortBy ?? GiftcardSortEnum.DATE;

    if (sortType === GiftcardSortEnum.NAME) return groupByName(formattedCards);
    if (sortType === GiftcardSortEnum.BALANCE) return groupByBalance(formattedCards);
    return groupByDate(formattedCards);
  }, [filters.sortBy, giftcardsQuery.data]);

  return <View giftcards={giftcards} giftcardsQuery={giftcardsQuery} />;
};
