import { request } from "@core-shop/services/api/request";
import {
  AdvancedBrandSearchQueryType,
  AdvancedBrandSearchQueryTypeVariables,
  AutocompleteSearchQuery,
  AutocompleteSearchQueryVariables,
  BrandSearchSuggestionsQuery,
  BrandSearchSuggestionsQueryVariables,
  Currency,
  LabelPromotionSearchQueryType,
  LabelPromotionSearchQueryTypeVariables,
  RecentBrandQuery,
  RecentBrandQueryVariables,
  RecentSearchQuery,
  RecentSearchQueryVariables,
  SearchQuery,
  SearchQueryVariables,
  SearchTermSuggestionsQuery,
  SearchTermSuggestionsQueryVariables,
} from "@core-shop/types";
import {
  advancedBrandSearchQuery,
  getAutocompleteSearchQuery,
  getBrandSearchSuggestionsQuery,
  getRecentBrandSearchQuery,
  getRecentSearchesSearchQuery,
  getSearchQuery,
  getSearchTermSuggestionsQuery,
  labelPromotionSearchQuery,
} from "./api/getSearchQuery";

export function getSearch({ query = "", first = 3, ...rest }: SearchQueryVariables) {
  return request<SearchQuery, SearchQueryVariables>(getSearchQuery, {
    ...rest,
    first,
    query,
  });
}

export function getBrandSearch({ query = "", ...rest }: AdvancedBrandSearchQueryTypeVariables) {
  return request<AdvancedBrandSearchQueryType, AdvancedBrandSearchQueryTypeVariables>(
    advancedBrandSearchQuery,
    {
      ...rest,
      query,
    }
  );
}

export function getLabelPromotionSearch({ query = "", ...rest }: LabelPromotionSearchQueryTypeVariables) {
  return request<LabelPromotionSearchQueryType, LabelPromotionSearchQueryTypeVariables>(
    labelPromotionSearchQuery,
    {
      ...rest,
      query,
    }
  );
}

export function getSearchAutocomplete({ query = "", first = 5 }: AutocompleteSearchQueryVariables) {
  return request<AutocompleteSearchQuery, AutocompleteSearchQueryVariables>(getAutocompleteSearchQuery, {
    query,
    first,
  });
}

export function getRecentBrandSearch(currency: Currency) {
  return request<RecentBrandQuery, RecentBrandQueryVariables>(getRecentBrandSearchQuery, {
    currency,
  });
}

export function getRecentSearches(currency: Currency) {
  return request<RecentSearchQuery, RecentSearchQueryVariables>(getRecentSearchesSearchQuery, {
    currency,
  });
}

export function getBrandSearchSuggestions(currency: Currency) {
  return request<BrandSearchSuggestionsQuery, BrandSearchSuggestionsQueryVariables>(
    getBrandSearchSuggestionsQuery,
    {
      currency,
    }
  );
}

export function getSearchTermSuggestions(currency: Currency) {
  return request<SearchTermSuggestionsQuery, SearchTermSuggestionsQueryVariables>(
    getSearchTermSuggestionsQuery,
    {
      currency,
    }
  );
}
