import gql from "graphql-tag";

export const getCloudViewerQuery = gql`
  query CloudViewer {
    ShopCloud {
      id
      viewer {
        id
        name
        country
        email
      }
    }
  }
`;
