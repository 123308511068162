import React from "react";
import classNames from "classnames";

interface IProps {
  children?: React.ReactNode;
  className?: string;
  hasCenteredItems?: boolean;
  hasSnap?: boolean;
  justify?: "start" | "center" | "between";
  rows?: number;
}

export const HorizontalScroll: React.FC<IProps> = ({
  children,
  rows = 1,
  hasSnap = true,
  justify = "start",
  className,
  hasCenteredItems,
}) => {
  return (
    <div
      className={classNames(
        "overflow-x-auto grid gap-x-3 gap-y-10 grid-flow-col scrollbar-hide py-1 w-full",
        `grid-rows-${rows} justify-${justify}`,
        hasSnap && `snap-x snap-mandatory [&>*]:snap-start`,
        hasCenteredItems && "items-center",
        className
      )}
    >
      {children}
    </div>
  );
};
