import { useBaseLink, useCreateIntent } from "@core-shop/hooks";
import { ICreateIntentLoaderViewProps } from "./ICreateIntentLoaderViewProps";

interface IProps {
  id: string;
  View: React.FC<ICreateIntentLoaderViewProps>;
  authToken?: string;
}

export const CreateIntentLoader: React.FC<IProps> = ({ id, View, authToken }) => {
  const linkQuery = useBaseLink({ id });
  const createIntentMutation = useCreateIntent();

  return (
    <View
      authToken={authToken}
      createGiftcardIntentMutation={createIntentMutation.giftcard}
      createShopIntentMutation={createIntentMutation.shop}
      linkQuery={linkQuery}
      reloadGiftcardIntentMutation={createIntentMutation.reloadGiftcard}
    />
  );
};
