import { getStorefrontById } from "@core-shop/models";
import { getBrand, locationSearch } from "@core-shop/models/brand";
import { getLink, IGetLink, getGiftcardDetails, getBaseLink } from "@core-shop/models/link";
import {
  getBrandSearch,
  getBrandSearchSuggestions,
  getLabelPromotionSearch,
  getRecentBrandSearch,
  getRecentSearches,
  getSearch,
  getSearchAutocomplete,
  getSearchTermSuggestions,
} from "@core-shop/models/search";
import { getCollectionById, getDefaultStorefrontByType, getShortcuts } from "@core-shop/models/storefront";
import {
  AdvancedBrandSearchQueryTypeVariables,
  AutocompleteSearchQueryVariables,
  Currency,
  DefaultStoreFrontType,
  LabelPromotionSearchQueryTypeVariables,
  LocationSearchVariables,
  SearchQueryVariables,
} from "@core-shop/types";
import { hour, minute } from "../constants";
import { EntityKey } from "../types";

export const storefrontQueries = {
  storefrontById: (id: string) => ({
    queryKey: [EntityKey.STOREFRONT, { id }],
    queryFn: () => getStorefrontById(id),
    staleTime: hour,
    cacheTime: hour + 5 * minute,
  }),
  collecitonById: (id: string) => ({
    queryKey: [EntityKey.COLLECTION, { id }],
    queryFn: () => getCollectionById(id),
    staleTime: hour,
    cacheTime: hour + 5 * minute,
  }),
  defaultStorefrontByType: (type: DefaultStoreFrontType) => ({
    queryKey: [EntityKey.STOREFRONT, "default", { type }],
    queryFn: () => getDefaultStorefrontByType(type),
    staleTime: hour,
    cacheTime: hour + 5 * minute,
  }),
  brand: ({ id = "", locationId = "" }) => {
    const key: unknown[] = [EntityKey.BRAND, { id }];
    if (locationId) key.push({ locationId });
    return {
      queryKey: key,
      queryFn: () => getBrand({ id, locationId }),
      staleTime: hour,
      cacheTime: hour + 5 * minute,
    };
  },
  locationSearch: (vars: LocationSearchVariables) => ({
    queryKey: [EntityKey.BRAND, "location-search", vars],
    queryFn: () => locationSearch(vars),
    staleTime: hour,
    cacheTime: hour + 5 * minute,
  }),
  link: (vars: IGetLink) => ({
    queryKey: [EntityKey.LINK, vars],
    queryFn: () => getLink(vars),
    staleTime: 5 * minute,
    cacheTime: 6 * minute,
  }),
  baseLink: (id: string) => ({
    queryKey: [EntityKey.LINK, id, "base"],
    queryFn: () => getBaseLink({ id }),
    staleTime: 5 * minute,
    cacheTime: 6 * minute,
  }),
  giftcardDetails: (id: string) => ({
    queryKey: [EntityKey.LINK, "giftcard-details", { id }],
    queryFn: () => getGiftcardDetails({ id }),
    staleTime: 1 * minute,
    cacheTime: 2 * minute,
  }),
  search: (vars: SearchQueryVariables) => ({
    queryKey: [EntityKey.SEARCH, vars],
    queryFn: () => getSearch(vars),
    staleTime: 4.5 * minute,
    cacheTime: 5 * minute,
  }),
  labelSearch: (vars: LabelPromotionSearchQueryTypeVariables) => ({
    queryKey: [EntityKey.LABEL_PROMO_SEARCH, vars],
    queryFn: () => getLabelPromotionSearch(vars),
    staleTime: 4.5 * minute,
    cacheTime: 5 * minute,
  }),
  brandSearch: (vars: AdvancedBrandSearchQueryTypeVariables) => ({
    queryKey: [EntityKey.BRAND_SEARCH, vars],
    queryFn: ({ pageParam }: { pageParam?: string }) => {
      return getBrandSearch({ ...vars, after: pageParam });
    },
    staleTime: 4.5 * minute,
    cacheTime: 5 * minute,
  }),
  searchAutocomplete: (vars: AutocompleteSearchQueryVariables) => ({
    queryKey: [EntityKey.AUTOCOMPLETE_SEARCH, vars],
    queryFn: () => getSearchAutocomplete(vars),
    staleTime: 4.5 * minute,
    cacheTime: 5 * minute,
  }),
  shortcuts: () => ({
    queryKey: [EntityKey.COLLECTION, "shortcuts"],
    queryFn: () => getShortcuts(),
    staleTime: 1 * hour,
    cacheTime: 1 * hour + 5 * minute,
  }),
  recentBrandSearch: (currency: Currency) => ({
    queryKey: [EntityKey.RECOMMENDED_SEARCHES, "recent-brand-search"],
    queryFn: () => getRecentBrandSearch(currency),
    staleTime: 4.5 * minute,
    cacheTime: 5 * minute,
  }),
  recentSearches: (currency: Currency) => ({
    queryKey: [EntityKey.RECOMMENDED_SEARCHES, "recent-searches"],
    queryFn: () => getRecentSearches(currency),
    staleTime: 4.5 * minute,
    cacheTime: 5 * minute,
  }),
  brandSearchSuggestions: (currency: Currency) => ({
    queryKey: [EntityKey.RECOMMENDED_SEARCHES, "brand-search-suggestions"],
    queryFn: () => getBrandSearchSuggestions(currency),
    staleTime: 4.5 * minute,
    cacheTime: 5 * minute,
  }),
  searchTermSuggestions: (currency: Currency) => ({
    queryKey: [EntityKey.RECOMMENDED_SEARCHES, "search-term-suggestions"],
    queryFn: () => getSearchTermSuggestions(currency),
    staleTime: 4.5 * minute,
    cacheTime: 5 * minute,
  }),
} as const;
