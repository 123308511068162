import { transferFunds } from "@core-shop/models/funds";
import { queryClient } from "@core-shop/providers/QueryProvider";
import { TransferFundsMutationVariables } from "@core-shop/types";
import { EntityKey } from "../types";

export const fundsMutations = {
  transferFunds: () => ({
    mutationFn: (vars: TransferFundsMutationVariables) => transferFunds(vars),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: [EntityKey.ACTIVITY] });
    },
  }),
} as const;
