import { getCloudDataQuery } from "@core-shop/models";
import { getCloudViewer } from "@core-shop/models/cloud";
import { day, minute } from "../constants";
import { EntityKey } from "../types";

export const cloudQueries = {
  cloud: (opts?: { token: string }) => ({
    queryKey: [EntityKey.CLOUD, opts],
    queryFn: () => getCloudDataQuery(opts),
    staleTime: day,
    cacheTime: day + 5 * minute,
  }),
  viewer: () => ({
    queryKey: [EntityKey.VIEWER],
    queryFn: () => getCloudViewer(),
    staleTime: day,
    cacheTime: day + 5 * minute,
  }),
} as const;
