import { request } from "@core-shop/services/api/request";
import {
  GetGiftcardDetailsQuery,
  GetGiftcardDetailsQueryVariables,
  GetLinkQuery,
  GetLinkQueryVariables,
  IntentCreateInput,
  GiftcardIntentCreateInput,
  CreateIntentGiftcardMutation,
  CreateIntentGiftcardMutationVariables,
  CreateShopMutation,
  CreateShopMutationVariables,
  GetBaseLinkQuery,
  GetBaseLinkQueryVariables,
  CreateIntentGiftcardReloadMutation,
  CreateIntentGiftcardReloadMutationVariables,
  ReloadIntentCreateInput,
} from "@core-shop/types";
import { createIntentMutation } from "./api/createIntentMutation";
import { getBaseLinkQuery } from "./api/getBaseLinkQuery";
import { getGiftcardDetailsQuery } from "./api/getGiftcardDetailsQuery";
import { getLinkQuery } from "./api/getLinkQuery";

export interface IGetLink {
  id?: string;
  promotionId?: string;
}

export function getLink({ id = "", promotionId = "" }: IGetLink) {
  return request<GetLinkQuery, GetLinkQueryVariables>(getLinkQuery, {
    id,
    promotionId,
    hasPromotionId: !!promotionId,
  });
}

export function getBaseLink({ id = "" }) {
  return request<GetBaseLinkQuery, GetBaseLinkQueryVariables>(getBaseLinkQuery, {
    id,
  });
}

export function getGiftcardDetails({ id = "" }) {
  return request<GetGiftcardDetailsQuery, GetGiftcardDetailsQueryVariables>(getGiftcardDetailsQuery, {
    id,
  });
}

export function createShopIntent(input: IntentCreateInput) {
  return request<CreateShopMutation, CreateShopMutationVariables>(createIntentMutation.shop, {
    input,
  });
}

export function createGiftcardIntent(input: GiftcardIntentCreateInput) {
  return request<CreateIntentGiftcardMutation, CreateIntentGiftcardMutationVariables>(
    createIntentMutation.giftcard,
    {
      input,
    }
  );
}

export function reloadGiftcardIntent(input: ReloadIntentCreateInput) {
  return request<CreateIntentGiftcardReloadMutation, CreateIntentGiftcardReloadMutationVariables>(
    createIntentMutation.reloadGiftcard,
    {
      input,
    }
  );
}
