import { LinkType } from "@core-shop/types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faCreditCard as faCreditCardRegular } from "@fortawesome/free-regular-svg-icons";
import {
  faPlaneDeparture,
  faCreditCard,
  faTicket,
  faPenToSquare,
  faStore,
  faCartShopping,
  IconName,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Icon: React.FC<{ icon: IconProp; className?: string }> = ({ icon, className = "text-primary-500" }) => (
  <FontAwesomeIcon className={className} icon={icon} />
);

export const icons: Record<LinkType, React.FC> = {
  [LinkType.TRAVELSHOP]: (props) => <Icon icon={faPlaneDeparture} {...props} />,
  [LinkType.BRAND]: (props) => <Icon icon={faCartShopping} {...props} />,
  [LinkType.GIFTCARDOFFER]: (props) => <Icon icon={faCreditCardRegular as unknown as IconName} {...props} />,
  [LinkType.GIFTCARDSHOP]: (props) => <Icon icon={faCreditCardRegular as unknown as IconName} {...props} />,
  [LinkType.AUTORELOADGIFTCARD]: (props) => <Icon icon={faCreditCard} {...props} />,
  [LinkType.RELOADABLEGIFTCARD]: (props) => <Icon icon={faCreditCard} {...props} />,
  [LinkType.VOUCHER]: (props) => <Icon icon={faTicket} {...props} />,
  [LinkType.BOUNTYSHOP]: (props) => <Icon icon={faPenToSquare} {...props} />,
  [LinkType.EMPYR]: (props) => <Icon icon={faStore} {...props} />,
  [LinkType.DEPARTMENT]: (props) => <Icon icon={faStore} {...props} />,
  [LinkType.SHORTCUT]: (props) => <Icon icon={faStore} {...props} />,
  [LinkType.PRODUCT]: (props) => <Icon icon={faStore} {...props} />,
};
