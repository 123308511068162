import React from "react";
import { Onboarding } from "@core-shop/components";
import { OnboardingModalView } from "./OnboardingModalView";

interface IProps {
  onClose: () => void;
}

export const OnboardingModals: React.FC<IProps> = () => {
  return <Onboarding View={OnboardingModalView} />;
};
