import gql from "graphql-tag";
import { cloudFragment } from "./fragments/cloudFragment";

export const optIn = gql`
  mutation OptInMutation($agreedToTos: Boolean!) {
    OptIn(agreedToTos: $agreedToTos) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on ShopCloud {
        id
        ...CloudFragment
      }
    }
  }
  ${cloudFragment}
`;
