import { request } from "@core-shop/services/api/request";
import { NewsQuery, OnLikeNewsMutation, OnLikeNewsMutationVariables } from "@core-shop/types";
import { newsQuery } from "./api/getNews";
import { onLikeNewsMutation } from "./api/onLikeNews";

export function getNewsQuery() {
  return request<NewsQuery>(newsQuery);
}

export function onLikeNews(variables: OnLikeNewsMutationVariables) {
  return request<OnLikeNewsMutation, OnLikeNewsMutationVariables>(onLikeNewsMutation, variables);
}
