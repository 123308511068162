import gql from "graphql-tag";

export const joinedFundraisersQuery = gql`
  query JoinedFundraisersQuery {
    ShopCloud {
      id
      activeCampaign {
        id
        teamMembers {
          # assuming that we get all, list doesn't have pagination
          joined(first: 1000) {
            edges {
              node {
                id
                name
                avatarImage
              }
              cursor
            }
          }
        }
      }
    }
  }
`;

export const transferFundsMutation = gql`
  mutation TransferFundsMutation($fundraiserId: ID, $intentId: ID!) {
    TransferIntent(fundraiserId: $fundraiserId, intentId: $intentId) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Campaign {
        id
      }
    }
  }
`;
