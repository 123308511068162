import gql from "graphql-tag";
import { storefrontFragment } from "./fragments/storefrontFragment";

export const getDefaultStorefront = gql`
  query DefaultStorefrontData($type: DefaultStoreFrontType!) {
    DefaultStoreFront(type: $type) {
      id
      ...StorefrontFragment
    }
  }
  ${storefrontFragment}
`;
