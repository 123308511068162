import React from "react";
import { useIntent } from "@core-shop/hooks";
import { IIntentViewProps } from "./IIntentViewProps";

interface IProps {
  id: string;
  View: React.FC<IIntentViewProps>;
}

export const Intent: React.FC<IProps> = ({ View, id }) => {
  const intentQuery = useIntent(id);
  const intent = intentQuery.data?.Intent;

  return <View intent={intent} intentQuery={intentQuery} />;
};
