import gql from "graphql-tag";

export const favoriteBrandFragment = gql`
  fragment FavoriteBrandFragment on Brand {
    id
    name
    viewerHasFavorited
    images {
      invertedLogo
      logo
      logoBackgroundColor
    }
    maxGiveLink {
      id
      giveSentence
      giveAmount
      promotionInfo {
        promotionSecondaryLabel
        promotionEndsAt
        promotionLabel
      }
    }
  }
`;
