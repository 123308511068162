import { useInfiniteQuery } from "@tanstack/react-query";
import { queries } from "@core-shop/cache";
import { ActivityQueryAllVariables, ActivityQueryFundraiserVariables } from "@core-shop/types";

export function useActivityAll(vars?: ActivityQueryAllVariables, opts?: { enabled: boolean }) {
  return useInfiniteQuery({
    ...queries.activityAll(vars),
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      return (
        lastPage?.ShopCloud?.viewer?.intentsPaginated?.pageInfo.hasNextPage &&
        lastPage.ShopCloud?.viewer?.intentsPaginated.pageInfo.endCursor
      );
    },
    ...opts,
  });
}

export function useActivityFundraiser(vars: ActivityQueryFundraiserVariables, opts?: { enabled: boolean }) {
  return useInfiniteQuery({
    ...queries.activityFundraiser(vars),
    keepPreviousData: true,
    getNextPageParam: (lastPage) => {
      return (
        lastPage?.Campaign?.supportedIntentsPaginated?.pageInfo.hasNextPage &&
        lastPage.Campaign?.supportedIntentsPaginated?.pageInfo.endCursor
      );
    },
    ...opts,
  });
}
