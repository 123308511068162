export const getPaginatedNodes = <TNode extends Record<string, $IntentionalAny>>(
  edges: TEdgesArray<TNode>
) => {
  if (!edges || !edges.length) return [];

  return edges.reduce<TNode[]>((array, edge) => {
    if (!edge) return array;
    const { node } = edge;
    if (!node) return array;

    array.push(node);
    return array;
  }, []);
};
