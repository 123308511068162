import { useEffect } from "react";
import { queries } from "@core-shop/cache";
import { queryClient } from "@core-shop/providers/QueryProvider";
import { useAuthContext } from "@providers/AuthProvider/authContext";
import { initIntercom } from "@shared/hooks";

export function useCloudIntercom() {
  const { shouldOptIn, isAuthorized } = useAuthContext();

  useEffect(() => {
    if (shouldOptIn || !isAuthorized) return;
    const setIntercom = async () => {
      const { ShopCloud } = await queryClient.fetchQuery(queries.viewer());
      const viewer = ShopCloud?.viewer;
      if (!viewer) return;
      initIntercom({ email: viewer.email ?? "", name: viewer.name ?? "", id: viewer.id ?? "" });
    };
    void setIntercom();
  }, [isAuthorized, shouldOptIn]);
}
