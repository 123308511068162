import { addQueryParam } from "@shared/utils";
import { queryParams } from "@shared/utils/routes";
import { InternalLink } from "@shared-tailwind/atoms";

interface IProps {
  title: string;
  type: string;
}

export const SearchListHeader = ({ title, type }: IProps) => {
  return (
    <div className="flex justify-between items-center text-primary-600 mb-3">
      <div className="font-bold text-gray-900">{title}</div>
      {type && (
        <InternalLink className="text-sm cursor-pointer" href={addQueryParam(queryParams.searchType, type)}>
          See All
        </InternalLink>
      )}
    </div>
  );
};
