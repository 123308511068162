import React from "react";
import classNames from "classnames";
import { BaseBrandBlock } from "../BaseBrandBlock/BaseBrandBlock";

interface IProps
  extends Pick<
    React.ComponentProps<typeof BaseBrandBlock>,
    | "imageSrc"
    | "hasLikeButton"
    | "isLiked"
    | "bgColor"
    | "buttonText"
    | "giveAmount"
    | "className"
    | "buttonUrl"
    | "onClickLike"
    | "buttonVariant"
    | "onToggleFavoriteMutation"
    | "id"
    | "shouldDisableInnerLike"
  > {
  givesSubtitle?: string;
  givesTitle?: string;
  isFeatured?: boolean;
  isLiked?: boolean;
  name?: string;
  onClick?: () => void;
}

export const BrandBlock: React.FC<IProps> = ({
  givesTitle,
  imageSrc,
  name,
  givesSubtitle,
  hasLikeButton,
  isLiked,
  isFeatured,
  bgColor,
  buttonText,
  buttonVariant,
  giveAmount,
  className,
  buttonUrl,
  onClickLike,
  onClick,
  onToggleFavoriteMutation,
  id,
  shouldDisableInnerLike,
}) => {
  return (
    <BaseBrandBlock
      bgColor={bgColor}
      buttonText={buttonText}
      buttonUrl={buttonUrl}
      buttonVariant={buttonVariant}
      className={classNames("min-w-[85vw] sm:min-w-[340px]", className)}
      giveAmount={giveAmount}
      hasLikeButton={hasLikeButton}
      id={id}
      imageSrc={imageSrc}
      isHightLighted={isFeatured}
      isLiked={isLiked}
      leftVerticalText={isFeatured ? "featured" : ""}
      shouldDisableInnerLike={shouldDisableInnerLike}
      subtitle={givesSubtitle}
      title={givesTitle}
      topText={name}
      onClick={onClick}
      onClickLike={onClickLike}
      onToggleFavoriteMutation={onToggleFavoriteMutation}
    />
  );
};
