import gql from "graphql-tag";
import { intentFragment } from "./fragments/intentFragment";

export const activityQueryAll = gql`
  query ActivityQueryAll($after: String, $first: Int, $filterBy: IntentFilterByEnum) {
    ShopCloud {
      id
      viewer {
        id
        intentsPaginated(first: $first, after: $after, filterBy: $filterBy) {
          edges {
            cursor
            node {
              id
              ...IntentFragment
            }
          }
          pageInfo {
            hasNextPage
            endCursor
          }
        }
      }
    }
  }
  ${intentFragment}
`;

export const activityQueryFundraiser = gql`
  query ActivityQueryFundraiser($after: String, $first: Int, $fundraiserUserId: ID) {
    Campaign {
      id
      supportedIntentsPaginated(first: $first, after: $after, fundraiserUserId: $fundraiserUserId) {
        edges {
          cursor
          node {
            id
            ...IntentFragment
          }
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${intentFragment}
`;
