import type { MapCache } from "lodash";
import memoize from "lodash/memoize";

// == Types ================================================================

// == Constants ============================================================

const CACHE_SIZE = 300;

const getMemoizedPaginatedNodes = memoize(
  <TNode extends Record<string, $IntentionalAny>>(edges: TEdgesArray<TNode>) => {
    if (!edges || !edges.length) return [];

    return edges.reduce<TNode[]>((array, edge) => {
      if (!edge) return array;
      const { node } = edge;
      if (!node) return array;

      array.push(node);
      return array;
    }, []);
  }
);

// == Exports ==============================================================

export function getPaginatedNodes<TNode extends Record<string, $IntentionalAny>>(
  edges: TEdgesArray<TNode>
): TNode[] {
  // Prevent Lodash memoize cache from growing too big
  const { cache } = getMemoizedPaginatedNodes;
  if ((cache as MapCache & { size: number })?.size >= CACHE_SIZE) cache.clear?.();
  return getMemoizedPaginatedNodes(edges);
}
