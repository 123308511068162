import { queries } from "@core-shop/cache";
import { queryClient } from "@core-shop/providers/QueryProvider";
import { CLOUD_AUTH_HEADER_NAME } from "@core-shop/utils";
import { getAuthHeaderValue } from "./getAuthHeaderValue";
import { axiosInstance } from "./request";

interface IAuthenticate {
  token?: string | null;
}

interface ITokenStatus {
  hasTeam: boolean;
  hasUser: boolean;
}

interface IFGStatus {
  hasTeam: boolean;
  hasUser: boolean;
}

export interface IAuthResp {
  isValid: boolean;
  fgStatus?: IFGStatus;
  tokenStatus?: ITokenStatus;
}

export function encryptStatus(status: string) {
  const [isUserInTokenValue, isUserInFGValue, isTeamInTokenValue, isTeamInFgValue] = status.split("");
  const tokenStatus: ITokenStatus = {
    hasTeam: isTeamInTokenValue === "1",
    hasUser: isUserInTokenValue === "1",
  };
  const fgStatus: IFGStatus = {
    hasTeam: isTeamInFgValue === "1",
    hasUser: isUserInFGValue === "1",
  };

  return { tokenStatus, fgStatus };
}

export async function authenticate({ token = "" }: IAuthenticate): Promise<IAuthResp> {
  if (!token) return { isValid: false };

  try {
    const cloudData = await queryClient.fetchQuery(queries.cloud({ token }));
    const { fgStatus, tokenStatus } = encryptStatus(cloudData.ShopCloud?.state ?? "");

    if (!cloudData.ShopCloud?.id) {
      axiosInstance.defaults.headers.common[CLOUD_AUTH_HEADER_NAME] = "";
      return { isValid: false };
    }

    axiosInstance.defaults.headers.common[CLOUD_AUTH_HEADER_NAME] = getAuthHeaderValue(token);

    return { isValid: true, fgStatus, tokenStatus };
  } catch (err) {
    axiosInstance.defaults.headers.common[CLOUD_AUTH_HEADER_NAME] = "";
    return { isValid: false };
  }
}
