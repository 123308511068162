import React from "react";
import { HeadingSection } from "@shared-tailwind/molecules/HeadingSection/HeadingSection";
import { Container } from "../Container/Container";
import { XButton } from "../XButton/XButton";

interface IProps {
  onClose: () => void;
  children?: React.ReactNode;
  title?: string;
}

export const BottomActionCard: React.FC<IProps> = ({ children, onClose, title }) => {
  return (
    <div className="w-full sticky bottom-0 -mt-1">
      <Container className="bg-gray-50 flex items-center space-x-2 justify-between py-3 border-t border-primary-500 shadow-md">
        <div className="w-full py-3">
          <HeadingSection hasNoBorder shouldForceOneLine Buttons={<XButton onClick={onClose} />}>
            {title}
          </HeadingSection>
          {children}
        </div>
      </Container>
    </div>
  );
};
