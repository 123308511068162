import React from "react";
import classNames from "classnames";
import { Image } from "../Image/Image";
import { InternalLink } from "../InternalLink/InternalLink";

interface IProps {
  href: string | URL;
  imageSrc: string | null;
  name: string | null;
  className?: string;
}

export const Department: React.FC<IProps> = ({ href, className, imageSrc, name }) => {
  return (
    <InternalLink className={classNames("border rounded-md p-2", className)} href={href}>
      <Image
        alt={name ?? ""}
        className="rounded-md h-[120px]"
        imagePositionClassName="object-cover"
        imageProps={{ className: "rounded-md h-full w-full" }}
        src={imageSrc ?? ""}
      />
      <p className="w-full text-center text-primary-500 text-xs mt-2">{name}</p>
    </InternalLink>
  );
};
