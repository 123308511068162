import { useMutation } from "@tanstack/react-query";
import { mutations, queries } from "@core-shop/cache";
import { queryClient } from "@core-shop/providers/QueryProvider";
import {
  FavoritesData,
  ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_Viewer_favoriteBrands,
} from "@core-shop/types";

type TFavorites = readonly ReorderFavoritesNewMutation_ViewerReorderFavoriteBrands_Viewer_favoriteBrands[];

export function useReorderFavorites() {
  const { mutationFn } = mutations.reorderFavorites();
  return useMutation({
    mutationFn,
    onSuccess: (response) => {
      if (
        !response ||
        response.ViewerReorderFavoriteBrands.__typename === "MutationError" ||
        response.ViewerReorderFavoriteBrands.__typename === "User"
      )
        return;

      updateViewerFavorites(response.ViewerReorderFavoriteBrands.favoriteBrands);
    },
  });
}

const updateViewerFavorites = (reorderedFavorites: TFavorites) => {
  const favoritesKey = queries.viewerFavorites().queryKey;
  const previousFavoritesData = queryClient.getQueryData<FavoritesData>(favoritesKey);

  const updatedFavoritesData = {
    ...previousFavoritesData,
    Viewer: {
      ...previousFavoritesData?.Viewer,
      favoriteBrands: reorderedFavorites,
    },
  };

  queryClient.setQueryData(favoritesKey, updatedFavoritesData);
};
