import React, { useEffect, useState, ReactNode, ButtonHTMLAttributes, forwardRef } from "react";
import { colors } from "@shared/styles";
import classNames from "classnames";
import { ButtonSizes } from "@shared-tailwind/types/buttons";
import { IconPosition } from "@shared-tailwind/types/icon";
import { Loader } from "../Loader/Loader";

// == Types ================================================================

export enum ButtonVariants {
  dangerousRed = "dangerousRed",
  gold = "gold",
  green = "green",
  link = "link",
  primary = "primary",
  red = "red",
  secondary = "secondary",
  toggle = "toggle",
  toggleNotSelected = "toggleNotSelected",
}

interface IProps {
  bgColor?: string;
  bgColorClassName?: string;
  children?: ReactNode;
  className?: string;
  colorClassName?: string;
  hasBorder?: boolean;
  hasRing?: boolean;
  Icon?: React.FC<React.HtmlHTMLAttributes<SVGSVGElement>>;
  iconClassName?: string;
  iconPosition?: IconPosition;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRounded?: boolean;
  onClick?: () => void;
  resetSizeClasses?: boolean;
  size?: ButtonSizes;
  variant?: ButtonVariants;
}

// == Constants ============================================================

const { primary, secondary, link, red, dangerousRed, green, gold, toggle, toggleNotSelected } =
  ButtonVariants;
const { left, right } = IconPosition;

const buttonClasses: Record<ButtonSizes, { [key in ButtonVariants | "default"]?: string }> = {
  xs: {
    default: "px-3 py-2 text-xs leading-4",
    link: "py-2 text-xs leading-4",
  },
  sm: {
    default: "px-3 py-2 text-sm leading-5",
    link: "py-2 text-sm leading-5",
  },
  md: {
    default: "px-4 py-2.5 text-sm leading-5",
    link: "py-2.5 text-sm leading-5",
  },
  lg: {
    default: "px-4 py-2.5 text-base leading-6",
    link: "py-2.5 text-base leading-6",
  },
  xl: {
    default: "px-6 py-3 text-base leading-6",
    link: "py-3 text-base leading-6",
  },
  xxl: {
    default: "px-8 py-5 text-lg leading-6",
    link: "py-3 text-base leading-6",
  },
};

const loaderColors: Record<ButtonVariants, string> = {
  primary: colors.white,
  secondary: colors.gray,
  red: colors.danger,
  dangerousRed: colors.white,
  link: colors.gray,
  green: colors.white,
  gold: colors.white,
  toggle: colors.primary,
  toggleNotSelected: colors.primary,
};

// == Functions ============================================================

const useIconSize = (size: ButtonSizes) => {
  const [classes, setClasses] = useState("");

  useEffect(() => {
    if (size === ButtonSizes.xs || size === ButtonSizes.sm) {
      setClasses("w-3");
      return;
    }

    setClasses("w-4");
  }, [size]);

  return classes;
};

// == Component ============================================================

export const Button = forwardRef<HTMLButtonElement, IProps & ButtonHTMLAttributes<HTMLButtonElement>>(
  (
    {
      children,
      Icon,
      iconPosition = IconPosition.left,
      variant = ButtonVariants.primary,
      size = ButtonSizes.md,
      onClick,
      className,
      isLoading,
      type = "button",
      isDisabled,
      isRounded = true,
      hasBorder = true,
      bgColorClassName = "",
      colorClassName = "",
      bgColor,
      color,
      hasRing = true,
      iconClassName,
      resetSizeClasses,
      ...buttonProps
    },
    ref
  ) => {
    const iconSizeClasses = useIconSize(size);
    const shouldIgnoreVariant = (bgColorClassName && colorClassName) || (bgColor && color);

    return (
      <button
        className={classNames(
          "relative",
          "focus:outline-none font-normal",
          "flex items-center",
          shouldIgnoreVariant
            ? `${bgColorClassName} ${colorClassName}`
            : {
                "bg-primary-600 hover:bg-primary-700 text-white border-transparent focus:ring-primary-500":
                  variant === primary,
                "bg-white hover:bg-gray-60 text-gray-700 border-gray-200 border focus:ring-primary-500":
                  variant === secondary,
                "bg-red-100 hover:bg-gray-60 text-red-700 border-transparent focus:ring-red-500":
                  variant === red,
                "bg-red-600 hover:bg-gray-60 text-white border-transparent focus:ring-red-500":
                  variant === dangerousRed,
                "bg-green-600 hover:bg-gray-60 text-white border-transparent focus:ring-green-500":
                  variant === green,
                "bg-amber-500 hover:bg-gray-60 text-black border-transparent focus:ring-amber-500":
                  variant === gold,
                "h-9 bg-primary-50 hover:bg-primary-150 text-gray-900 border border-primary-500 focus:ring-primary-500 rounded-xl":
                  variant === toggle,
                "h-9 bg-transparent text-gray-500 border border-gray-200 rounded-xl bg-white font-light":
                  variant === toggleNotSelected,
                "shadow-sm": ![toggleNotSelected, toggle, link].includes(variant),
                "border-transparent": !hasBorder,
              },
          isLoading && "justify-center",
          !resetSizeClasses && (buttonClasses[size][variant] ?? buttonClasses[size].default),
          isRounded && ![toggleNotSelected, toggle].includes(variant) && "rounded-md",
          hasRing && "focus:ring-2 focus:ring-offset-2",
          isDisabled && "opacity-20 cursor-not-allowed",
          className
        )}
        disabled={isDisabled}
        ref={ref}
        style={{ background: bgColor, color }}
        // eslint-disable-next-line react/button-has-type
        type={type}
        onClick={isDisabled ? undefined : onClick}
        {...buttonProps}
      >
        {isLoading && <Loader className="absolute" color={loaderColors[variant]} />}
        <div
          className={classNames(
            "flex items-center w-full justify-center",
            { "flex-row-reverse": iconPosition === right, "justify-center": !Icon },
            isLoading && "invisible"
          )}
        >
          {Icon && <Icon aria-hidden="true" className={iconClassName || iconSizeClasses} />}
          {children && (
            <span className={Icon && classNames(iconPosition === left ? "pl-1.5" : "pr-1.5")}>
              {children}
            </span>
          )}
        </div>
      </button>
    );
  }
);
