import { IBrandModalViewProps } from "@core-shop/components";
import { BrandCircle, Image } from "@shared-tailwind/atoms";

interface IProps {
  images: IBrandModalViewProps["brand"]["images"];
  name?: string;
}

export const Hero: React.FC<IProps> = ({ images, name }) => {
  if (!images) return null;

  const { hero, invertedLogo, logoBackgroundColor, logo } = images;

  return (
    <div className="-mb-10">
      <div className="bg-primary-500 h-[120px] w-full">
        {hero && (
          <Image
            alt={name}
            className="h-[150px] w-full"
            imagePositionClassName="object-cover"
            imageProps={{ className: "h-[120px] w-full" }}
            src={hero}
          />
        )}
      </div>
      <div className="flex justify-center relative -top-12">
        <BrandCircle
          bgColor={logoBackgroundColor}
          name={name}
          sizeClassName="w-[100px] h-[100px]"
          src={invertedLogo || logo}
        />
      </div>
    </div>
  );
};
