import gql from "graphql-tag";

export const updateProfileSettingsMutation = gql`
  mutation UpdateProfile($hidePurchaseActivity: Boolean, $privacyLevel: PrivacyLevel) {
    ViewerSettingsUpdate(hidePurchaseActivity: $hidePurchaseActivity, privacyLevel: $privacyLevel) {
      ... on MutationError {
        errors {
          input
          inputErrors
        }
      }
      ... on Viewer {
        id
        hidePurchaseActivity
        privacyLevel
      }
    }
  }
`;
