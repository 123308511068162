import React from "react";
import { IGiftcardDetailsViewProps } from "@core-shop/components";
import { Button, ButtonVariants, Label } from "@shared-tailwind/atoms";

interface IProps {
  denominations: IGiftcardDetailsViewProps["denominations"];
  onChange: (amountId: string) => void;
  amountId?: string;
}

export const Amount: React.FC<IProps> = ({ denominations, amountId, onChange }) => {
  return (
    <div className="mb-3">
      <Label>Amount</Label>
      <div className="grid gap-2 grid-cols-5">
        {denominations.map(({ id, value }) => (
          <Button
            key={`denom-${id}`}
            variant={amountId === id ? ButtonVariants.toggle : ButtonVariants.toggleNotSelected}
            onClick={() => onChange(id)}
          >
            {value.formatted}
          </Button>
        ))}
      </div>
    </div>
  );
};
