import { useEffect, useRef } from "react";
import { Loader } from "../Loader/Loader";

interface IProps {
  children: React.ReactNode;
  onReachBottom: () => void;
  className?: string;
  hasMore?: boolean;
  isLoading?: boolean;
}

export const InfiniteScrollWrapper = ({ children, onReachBottom, className, hasMore, isLoading }: IProps) => {
  const observerTarget = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && hasMore) onReachBottom();
      },
      { threshold: 1 }
    );

    const { current } = observerTarget;

    if (current) observer.observe(current);

    return () => {
      if (current) observer.unobserve(current);
    };
  }, [hasMore, observerTarget, onReachBottom]);

  return (
    <div className={className}>
      {children}
      <div ref={observerTarget} />
      {isLoading && (
        <div className="w-full flex justify-center py-4">
          <Loader size={40} />
        </div>
      )}
    </div>
  );
};
