import { updateNotificationsSettings } from "@core-shop/models";
import { updateProfileSettings } from "@core-shop/models/team";
import { queryClient } from "@core-shop/providers/QueryProvider";
import { UpdateNotificationsVariables, UpdateProfileVariables } from "@core-shop/types";
import { queries } from "../queries";

export const teamMutations = {
  updateNotifications: () => ({
    mutationFn: (vars: UpdateNotificationsVariables) => updateNotificationsSettings(vars),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queries.notificationsSettings().queryKey });
    },
  }),
  updateProfile: () => ({
    mutationFn: (vars: UpdateProfileVariables) => updateProfileSettings(vars),
    onSuccess: () => {
      void queryClient.invalidateQueries({ queryKey: queries.profileSettings().queryKey });
    },
  }),
} as const;
