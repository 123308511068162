import { IncomingMessage, ServerResponse } from "http";
import { gql } from "@apollo/client";
import Cookies from "js-cookie";
import { IS_CLIENT, IS_SERVER } from "@shared/config/environment";
import { SiteCookieFragment } from "@shared/types";
import { ErrorService } from "@shared/utils/Sentry";
import { getDomainForCookie } from "./getDomainForCookie";

// == Types ================================================================

export interface ISiteCookie {
  base_url?: string;
  id?: string;
  logo?: string;
}

// == Constants ============================================================

export const SITE_FRAGMENT = gql`
  fragment SiteCookieFragment on Site {
    id
    url
    logoImage
  }
`;

export const SITE_COOKIE_NAME = "site_data";

let cachedSite: ISiteCookie | undefined;

// == Functions ============================================================

// == Exports ==============================================================

export const siteCookie = {
  readClient() {
    try {
      if (!IS_CLIENT) return undefined;

      if (cachedSite) return cachedSite;
      const siteJSON = Cookies.get(SITE_COOKIE_NAME);

      try {
        const parsedSite = JSON.parse(siteJSON ?? "{}") as ISiteCookie;
        if (parsedSite) cachedSite = parsedSite;
      } catch (_error) {
        // Ignore JSON parse error
      }
      return cachedSite;
    } catch (error) {
      ErrorService.error(error);
      cachedSite = undefined;
      return cachedSite;
    }
  },
  async readServer(req?: IncomingMessage, res?: ServerResponse) {
    try {
      if (!IS_SERVER) throw new Error("Calling siteCookie.readServer on client");
      if (!req || !res) return {};
      const ServerCookies = (await import("cookies")).default;
      if (!ServerCookies) throw new Error("ServerCookies not imported");
      const cookies = new ServerCookies(req, res);
      let siteJSON = null;
      siteJSON = cookies.get(SITE_COOKIE_NAME);
      if (siteJSON) siteJSON = decodeURIComponent(siteJSON);

      cachedSite = JSON.parse(siteJSON ?? "{}") as ISiteCookie;
      return cachedSite;
    } catch (error) {
      ErrorService.error(error);
      cachedSite = undefined;
      return cachedSite;
    }
  },
  writeClient(site: ISiteCookie) {
    if (!IS_CLIENT) return;
    try {
      const siteData = JSON.stringify({
        id: site?.id,
        logo: site?.logo,
        base_url: site?.base_url,
      });
      Cookies.set(SITE_COOKIE_NAME, siteData, {
        path: "/",
        domain: getDomainForCookie(window.location.host),
        httpOnly: false,
        overwrite: true,
      });
    } catch (error) {
      ErrorService.error(error);
    }
  },
  async writeServer(req: IncomingMessage, res: ServerResponse, site: SiteCookieFragment | null) {
    try {
      if (!site) return;
      if (!IS_SERVER) throw new Error("Calling siteCookie.writeServer on client");

      const ServerCookies = (await import("cookies")).default;
      const cookies = new ServerCookies(req, res);
      const siteData = JSON.stringify({
        id: site?.id,
        logo: site?.logoImage,
        base_url: site?.url,
      });

      const domain = getDomainForCookie(req.headers["x-forwarded-host"]);
      cookies.set(SITE_COOKIE_NAME, encodeURIComponent(siteData), {
        path: "/",
        domain,
        httpOnly: false,
        overwrite: true,
      });
    } catch (error) {
      ErrorService.error(error);
    }
  },
};
