import { useQuery } from "@tanstack/react-query";
import { queries } from "@core-shop/cache";

interface IBrandOptions {
  id?: string;
  locationId?: string;
}

export function useBrand({ id, locationId }: IBrandOptions) {
  return useQuery({ ...queries.brand({ id, locationId }), enabled: !!id });
}
