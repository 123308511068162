import React, { useMemo } from "react";
import { useLink } from "@core-shop/hooks";
import { GetLinkQuery_Link } from "@core-shop/types";
import { getPaginatedNodes } from "@core-shop/utils";
import { IFormattedLink, ILinkModalViewProps } from "./ILinkModalViewProps";

interface IProps {
  onClose: $IntentionalAny;
  View: React.FC<ILinkModalViewProps>;
  id?: string | null;
  promotionId?: string | null;
}

export const LinkModal: React.FC<IProps> = ({ View, id, promotionId, onClose }) => {
  const linkQuery = useLink({ id: id ?? "", promotionId: promotionId ?? "" });
  const link = useMemo(() => formatLink(linkQuery?.data?.Link), [linkQuery?.data]);
  const topDeal = link?.previewInfo?.topPromotionInfo;

  return <View link={link} linkQuery={linkQuery} topDeal={topDeal} onClose={onClose} />;
};

function formatLink(link?: GetLinkQuery_Link): IFormattedLink {
  if (!link) return {} as IFormattedLink;
  const linkData = link;
  const formattedLink: IFormattedLink = {
    ...link,
    relatedLinks: getPaginatedNodes(linkData.relatedLinks.edges),
    promotions: getPaginatedNodes(linkData.promotionInfo?.promotions?.edges),
  };
  return formattedLink;
}
