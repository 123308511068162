import { createGiftcardIntent, createShopIntent, reloadGiftcardIntent } from "@core-shop/models/link";
import { GiftcardIntentCreateInput, IntentCreateInput, ReloadIntentCreateInput } from "@core-shop/types";

export const linkMutations = {
  createShopIntent: () => ({
    mutationFn: (input: IntentCreateInput) => createShopIntent(input),
  }),
  createGiftcardIntent: () => ({
    mutationFn: (input: GiftcardIntentCreateInput) => createGiftcardIntent(input),
  }),
  reloadGiftcardIntent: () => ({
    mutationFn: (input: ReloadIntentCreateInput) => reloadGiftcardIntent(input),
  }),
} as const;
