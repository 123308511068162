import gql from "graphql-tag";

export const getOnboardingInfoQuery = gql`
  query OnboardingInfoData {
    Viewer {
      id
      isCaptain
      activeCampaign {
        id
        shopInitialized
      }
      teamOnboardingInfo {
        currentStep
        isOnboardingActive
        onboardingSteps {
          id
          name
          isHidden
        }
      }
    }
  }
`;
