import { IOnboardingStep, OnboardingRoles, OnboardingStepName, StepsTime } from "./types";

const { CREATE_TEAM, INVITE, JOIN, ROLE_MEMBER, ROSTER, SET_GOAL, SELECT_PLAYER } = OnboardingStepName;

export const onboardingSteps: Record<OnboardingStepName, IOnboardingStep> = {
  // captain
  [CREATE_TEAM]: {
    name: CREATE_TEAM,
    query: () => "CREATE_TEAM query",
    submitMutation: () => "CREATE_TEAM submitMutation",
  },
  [SET_GOAL]: {
    name: SET_GOAL,
    query: () => "SET_GOAL query",
    submitMutation: () => "SET_GOAL submitMutation",
  },
  [ROSTER]: {
    name: ROSTER,
    query: () => "ROSTER query",
    submitMutation: () => "ROSTER submitMutation",
  },
  [INVITE]: {
    name: INVITE,
    query: () => "INVITE query",
    submitMutation: () => "INVITE submitMutation",
  },

  // member
  [JOIN]: {
    name: JOIN,
    query: () => "JOIN query",
    submitMutation: () => "JOIN submitMutation",
  },
  [SELECT_PLAYER]: {
    name: SELECT_PLAYER,
    query: () => "SELECT_PLAYER query",
    submitMutation: () => "SELECT_PLAYER submitMutation",
  },
  [ROLE_MEMBER]: {
    name: ROLE_MEMBER,
    query: () => "ROLE_MEMBER query",
    submitMutation: () => "ROLE_MEMBER submitMutation",
  },
};

interface ISteps {
  [StepsTime.BEFORE_ACTION]: Record<OnboardingRoles, OnboardingStepName[]>;
  [StepsTime.AFTER_ACTION]: Record<OnboardingRoles, OnboardingStepName[]>;
}

const { CAPTAIN, MEMBER } = OnboardingRoles;

export const steps: ISteps = {
  [StepsTime.BEFORE_ACTION]: { [CAPTAIN]: [CREATE_TEAM, SET_GOAL], [MEMBER]: [JOIN, SELECT_PLAYER] },
  [StepsTime.AFTER_ACTION]: { [CAPTAIN]: [ROSTER, INVITE], [MEMBER]: [ROLE_MEMBER] },
};
