import React from "react";
import { GetLinkQuery_Link_promotionInfo_promotions_edges_node } from "@core-shop/types";
import { useRouter } from "@shared/hooks";
import { addQueryParam, removeQueryParam } from "@shared/utils";
import { queryParams } from "@shared/utils/routes";
import classNames from "classnames";
// eslint-disable-next-line import/no-duplicates
import format from "date-fns/format";
// eslint-disable-next-line import/no-duplicates
import parseISO from "date-fns/parseISO";
import { DealBlock, ModalSection } from "@shared-tailwind/atoms";
import { BlocksCarousel } from "@shared-tailwind/molecules";

type TPromotion = GetLinkQuery_Link_promotionInfo_promotions_edges_node;

interface IProps {
  bgColor?: string | null;
  logo?: string | null;
  promotions?: TPromotion[];
}

export const Promotions: React.FC<IProps> = ({ promotions, bgColor, logo }) => {
  const router = useRouter();
  const selectedId = router.getQuery(queryParams.promotionId);

  return (
    <ModalSection>
      <BlocksCarousel buttonTitle="See all" title="Deals">
        {promotions?.map(({ description, endsAt, id }) => (
          <DealBlock
            bgColor={bgColor}
            className={classNames(
              selectedId === id ? "bg-amber-50 border-deals-500" : "border-transparent",
              "rounded-md p-1 border-2",
              "min-w-[300px]"
            )}
            expires={endsAt ? `Exp. ${format(parseISO(endsAt), "MM/dd/yyyy")}` : ""}
            hasButton={false}
            href={
              selectedId === id
                ? removeQueryParam(queryParams.promotionId).toString()
                : addQueryParam(queryParams.promotionId, id).toString()
            }
            imageSrc={logo ?? ""}
            key={`deal-${id}`}
            name={description}
          />
        ))}
      </BlocksCarousel>
    </ModalSection>
  );
};
