import React, { useEffect, useMemo, useState } from "react";
import { IGiftcardDetailsViewProps } from "@core-shop/components";
import { LinkType } from "@core-shop/types";
import { useRouter } from "@shared/hooks";
import { formatMoney, routes } from "@shared/utils";
import { queryParams } from "@shared/utils/routes";
import { Button, Loader } from "@shared-tailwind/atoms";
import { Amount } from "./components/Amount";
import { Quantity } from "./components/Quantity";
import { Recepient } from "./components/Recepient";
import { ReloadThreshold } from "./components/ReloadThreshold";

const defaultQuantity = 1;

export const BuyGiftcardView: React.FC<IGiftcardDetailsViewProps> = ({
  giftcardDetailsQuery,
  denominations,
  type,
  reloadThresholdDenominations,
}) => {
  const [amountId, setAmountId] = useState<string>();
  const [quantity, setQuantity] = useState<number>(defaultQuantity);
  const [isGift, setIsGift] = useState<boolean>(false);
  const [reloadThresholdCents, setReloadThresholdCents] = useState<number>(0);

  const router = useRouter();

  const isReload = router.getQuery(queryParams.isReload);

  useEffect(() => {
    // set initial amount and quantity
    if (giftcardDetailsQuery.isLoading) return;
    const defaultAmount = denominations.find(({ default: isDefault }) => isDefault);
    if (!defaultAmount) return;
    setAmountId(defaultAmount.id);
    setQuantity(defaultAmount.minimumCards ?? defaultQuantity);
  }, [denominations, giftcardDetailsQuery.isLoading]);

  useEffect(() => {
    // set initial autoreloadable threshold
    if (giftcardDetailsQuery.isLoading || !reloadThresholdDenominations?.length) return;
    setReloadThresholdCents(reloadThresholdDenominations?.[0].centsAmount ?? 0);
  }, [giftcardDetailsQuery.isLoading, reloadThresholdDenominations]);

  const seletedDenom: IGiftcardDetailsViewProps["denominations"][0] | undefined = useMemo(() => {
    if (!amountId) return undefined;
    return denominations.find(({ id }) => amountId === id);
  }, [amountId, denominations]);

  const payResult = useMemo(() => {
    if (!seletedDenom || !quantity) return "$0";
    return formatMoney(quantity * seletedDenom?.value.dollarsAmount, 0);
  }, [quantity, seletedDenom]);

  useEffect(() => {
    if (!seletedDenom?.minimumCards || !seletedDenom?.maximumCards) return;
    if (quantity < seletedDenom.minimumCards || quantity > seletedDenom.maximumCards)
      setQuantity(seletedDenom?.minimumCards);
  }, [quantity, seletedDenom]);

  if (giftcardDetailsQuery.isLoading)
    return (
      <div className="w-full flex justify-center">
        <Loader size={40} />
      </div>
    );

  return (
    <div className="w-full py-3">
      {!isReload && <Recepient isGift={isGift} onChange={setIsGift} />}
      <Amount
        amountId={amountId}
        denominations={denominations.filter(({ maximumCards }) => !!maximumCards)}
        onChange={setAmountId}
      />
      {!isReload && type === LinkType.GIFTCARDOFFER && (
        <Quantity
          maxCards={seletedDenom?.maximumCards ?? 0}
          minCards={seletedDenom?.minimumCards ?? 0}
          quantity={quantity}
          onChange={setQuantity}
        />
      )}
      {type === LinkType.AUTORELOADGIFTCARD && seletedDenom?.value.dollarsAmount && (
        <ReloadThreshold
          reloadThresholdCents={reloadThresholdCents}
          reloadThresholdDenominations={reloadThresholdDenominations}
          seletedDenom={seletedDenom}
          onChange={setReloadThresholdCents}
        />
      )}
      <p className="text-gray-500 italic">{seletedDenom?.deliveryEstimate}</p>
      <Button
        className="w-full my-3"
        onClick={() =>
          router.push(
            routes.shopcloud.createIntent({
              [queryParams.linkId]: giftcardDetailsQuery.data?.Link.id ?? "",
              [queryParams.isGift]: isGift ? "true" : "false",
              [queryParams.quantity]: quantity.toString(),
              [queryParams.reloadThresholdCents]: reloadThresholdCents.toString(),
              [queryParams.amountCents]: seletedDenom?.value.centsAmount.toString() ?? "",
              [queryParams.reloadAmountCents]: seletedDenom?.value.centsAmount.toString() ?? "",
            })
          )
        }
      >
        Pay {payResult}
      </Button>
    </div>
  );
};
