import gql from "graphql-tag";

export const newsQuery = gql`
  query NewsQuery {
    Activities(first: 1000, feed: TEAM_MEMBER) {
      edges {
        cursor
        node {
          id
          viewerHasLiked
          likesCount
          time
          details {
            ctaText
            ctaUrl
            description
            destination
            photoImage
            quote
            youtubeId
          }
          activityObject {
            ... on Broadcast {
              description
              id
              photoImage
            }
          }
        }
      }
    }
  }
`;
