import gql from "graphql-tag";

export const getLinkQuery = gql`
  query GetLinkQuery($id: ID!, $promotionId: ID!, $hasPromotionId: Boolean!) {
    Link(id: $id) {
      id
      type
      name
      earnInstructions
      isBulkShop
      buttonCTA
      description
      giveSentence
      cashbackTiming
      exclusions
      exclusionGiftCardNotEligible
      exclusionPurchaseWithGiftCardNotEligible
      variableRates
      terms
      termsSummary
      images {
        logo
        image
      }
      previewInfo {
        description
        promotionsCount
        topPromotionInfo {
          id
          link {
            id
            name
            promotionInfo {
              promotionSecondaryLabel
            }
            giveSentence
          }
        }
      }
      promotionInfo {
        promotionSecondaryLabel
        promotionEndsAt
        promotionLabel
        promotion(id: $promotionId) @include(if: $hasPromotionId) {
          id
          couponCode
          description
          endsAt
        }
        promotions(first: 20) {
          edges {
            cursor
            node {
              id
              couponCode
              description
              endsAt
              link {
                id
                name
                promotionInfo {
                  promotionSecondaryLabel
                  promotionEndsAt
                  promotionLabel
                }
                giveAmount
                giveSentence
              }
            }
          }
        }
      }
      defaultBrand {
        id
        name
        images {
          logo
          logoBackgroundColor
          invertedLogo
        }
      }
      merchant {
        id
        name
        website
      }
      relatedLinks(first: 100, linkTypes: [DEPARTMENT]) {
        edges {
          cursor
          node {
            id
            type
            images {
              square
            }
            name
            shortName
            promotionInfo {
              promotionSecondaryLabel
              promotionEndsAt
              promotionLabel
            }
            giveSentence
            giveAmount
            isBulkShop
          }
        }
      }
    }
  }
`;
