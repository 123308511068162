import { ErrorService } from "./Sentry";

// == Types ================================================================

// == Constants ============================================================

const DEFAULT_VALUE = "$0";

// == Exports ==============================================================

export function formatMoney(number: number | null, mantissa = 2) {
  try {
    if (!number) return DEFAULT_VALUE;

    let returnNum;
    if (mantissa === 0) {
      returnNum = number.toFixed().replace(/(\d)(?=(\d{3})+(,|$))/g, "$1,");
    } else {
      returnNum = number.toFixed(mantissa).replace(/(\d)(?=(\d{3})+\.)/g, "$1,");
    }

    return `$${returnNum}`;
  } catch (error) {
    ErrorService.error(error);
    return DEFAULT_VALUE;
  }
}
