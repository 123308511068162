import React from "react";
import { usePayment } from "@core-shop/hooks";
import { getPaginatedNodes } from "@core-shop/utils";
import { IPayoutViewProps } from "./IPayoutViewProps";

interface IProps {
  id: string;
  View: React.FC<IPayoutViewProps>;
}

export const Payout: React.FC<IProps> = ({ View, id }) => {
  const paymentQuery = usePayment({ first: 10, id });

  const fundraisers =
    paymentQuery.data?.pages
      .map((page) => getPaginatedNodes(page.Campaign?.payment?.recipients.edges))
      .flat() ?? [];

  const paymentData = paymentQuery.data?.pages[0].Campaign?.payment;
  const payment = {
    id: paymentData?.id ?? "",
    amount: paymentData?.amount.formatted ?? "",
    processedAt: paymentData?.processedAt ?? "",
    state: paymentData?.state ?? "",
  };

  return <View fundraisers={fundraisers} id={id} payment={payment} paymentQuery={paymentQuery} />;
};
