import gql from "graphql-tag";
import { intentFragment } from "./fragments/intentFragment";

export const getIntentQuery = gql`
  query GetIntentQuery($id: ID!) {
    Intent(id: $id) {
      id
      ...IntentFragment
    }
  }
  ${intentFragment}
`;
