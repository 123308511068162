import React, { ReactNode } from "react";
import classNames from "classnames";
import { HeadingSection } from "@shared-tailwind/molecules/HeadingSection/HeadingSection";
import { Container } from "../Container/Container";

interface IProps {
  children: ReactNode;
  className?: string;
  hasBorder?: boolean;
  title?: string;
}

export const ModalSection: React.FC<IProps> = ({ children, title, className, hasBorder = true }) => {
  return (
    <Container className={classNames("flex space-y-4 flex-col  py-3", hasBorder && "border-b", className)}>
      {title && (
        <HeadingSection hasNoBorder shouldForceOneLine className="-mb-4">
          {title}
        </HeadingSection>
      )}
      {children}
    </Container>
  );
};
