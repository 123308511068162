import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer as Container } from "react-toastify";

export const ToastContainer: React.FC = () => {
  return (
    <Container
      closeOnClick
      draggable
      hideProgressBar
      autoClose={3000}
      newestOnTop={false}
      position="bottom-center"
      rtl={false}
    />
  );
};
